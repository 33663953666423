import { CancelButton, SaveButton } from '@sinecycle/growcomponents';
import { useMutation } from '@tanstack/react-query';
import { Col, InputNumber, Row } from 'antd';
import { DatePickerComponent } from 'components/BaseComponents/Calendar';
import { GrowCard } from 'components/BaseComponents/Card';
import DescriptionTextArea from 'components/BaseComponents/DescriptionTextArea/TextArea';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { notify } from 'components/BaseComponents/Notifications';
import { GrowText } from 'components/BaseComponents/Typography';
import { ControlledFormItem } from 'components/HigherOrderComponent/GenerateForm';
import { Dayjs } from 'dayjs';
import { useFormContext, useWatch } from 'react-hook-form';
import { createSlippage } from 'services/promise-to-pay';
import styled from 'styled-components';
import { Slippage } from 'types/activities/promise-to-pay';
import { getCurrencySymbol } from 'util/number-formatter';

const Container = styled(GrowCard)`
  background: var(--gray-1);

  .ant-card-head {
    border-bottom: 1px solid var(--gray-4);
    background: var(--gray-2);
  }
  .ant-card-body {
    padding: var(--space-24) var(--space-16);
  }
  .ant-card-actions {
    background: var(--gray-2);
    padding: var(--space-16);
    li {
      margin: 0;
    }
  }

  .label {
    //Overriding Error color fixed by ControlledFormItem
    color: var(--red-7) !important;
  }
`;

interface PaymentSlipFormProps {
  currency: string;
  amount: number;
  currentPtpDate: string;
  activityId: number;
  onCancel: () => void;
  onNewSlipAdded: (x: Slippage) => void;
}

const t = {
  record_payment_slip: 'Record Payment Slip',
  slipped_amount: 'Slipped Amount',
  add: 'Add',
  comments: 'Comments',
  add_comments: 'Add Comments',
  revised_date: 'Revised Date',
};

export default function PaymentSlipForm(props: PaymentSlipFormProps) {
  const { currency, amount, currentPtpDate, activityId, onCancel, onNewSlipAdded } = props;
  const methods = useFormContext();
  const { control, setValue, handleSubmit, resetField } = methods;
  const to_date = useWatch({ control, name: 'to_date' });

  async function addPaymentSlip(data: any) {
    try {
      const resp = (await createSlippage(activityId, {
        amount: '' + data.amount,
        comments: data.comments,
        to_date: data.to_date,
      })) as Slippage;

      onNewSlipAdded(resp);
      notify.success('Payment Slip Added Successfully');
    } catch (e) {
      notify.error('Something went wrong');
    }
  }
  const mutate = useMutation({
    mutationFn: addPaymentSlip,
  });

  function getNextDay(dateString: string) {
    const today = new Date(dateString);
    today.setDate(today.getDate() + 1);
    return today;
  }

  const currencySymbol = currency ? getCurrencySymbol(currency) : '';

  const Footer = (
    <Flex justify="flex-end" gap="var(--space-8)">
      <CancelButton
        onClick={() => {
          onCancel();
          resetField('comments');
        }}
      />

      <SaveButton
        text={t.add}
        onClick={handleSubmit(mutate.mutateAsync as any, console.log)}
        loading={mutate.isLoading}
        savingText={'Adding'}
      />
    </Flex>
  );

  const Title = (
    <GrowText size="var(--space-20)" color="var(--gray-9)" strong>
      {t.record_payment_slip}
    </GrowText>
  );
  return (
    <Container actions={[Footer]} title={Title}>
      <Row style={{ gap: 'var(--space-8)' }}>
        <Col span={8}>
          <span className="sub-title">{t.slipped_amount}</span>

          <ControlledFormItem defaultValue={amount} fieldName={'amount'} control={control}>
            <InputNumber precision={2} min={0} addonBefore={currencySymbol} type="number" />
          </ControlledFormItem>
        </Col>
        <Col span={8}>
          <span className="sub-title">{t.revised_date}</span>
          <ControlledFormItem
            defaultValue={getNextDay(currentPtpDate).toISOString()}
            fieldName={'to_date'}
            control={control}
          >
            <DatePickerComponent
              size="middle"
              allowClear={false}
              disabledDate={(current: Dayjs) =>
                current.isBefore(new Date(currentPtpDate)) ||
                current.isSame(new Date(currentPtpDate))
              }
              currentDate={to_date ?? getNextDay(currentPtpDate).toISOString()}
              onChangeCallBack={(dateObj: Dayjs, stringDate) => {
                setValue('to_date', new Date(stringDate as string).toISOString(), {
                  shouldValidate: true,
                });
              }}
            />
          </ControlledFormItem>
        </Col>
      </Row>
      <Col>
        <span className="sub-title">{t.comments}</span>
        <ControlledFormItem defaultValue="" fieldName={'comments'} control={control}>
          <DescriptionTextArea placeholder={t.add_comments} />
        </ControlledFormItem>
      </Col>
    </Container>
  );
}
