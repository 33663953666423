import CommentComponent from 'components/HigherOrderComponent/KeyActivitesContainer/Comment/comment';
import React from 'react';
import { ActivityType } from 'types/activities/activity-types';
import { Commento } from 'types/activities/comment';

const t = {
  comment: 'Comment',
};

interface CommentForm {
  style?: React.CSSProperties;
  referenceActivityId: number;
  referenceType?: ActivityType;
  customerId?: number;
  newCommentCallBack: (commentAdded: Commento) => void;
  isOpen?: boolean;
  onCancel?: () => void;
  wrapperStyle?: React.CSSProperties;
}
export function Form(props: CommentForm) {
  const { referenceActivityId, newCommentCallBack, customerId, referenceType, onCancel } = props;

  const CommentForm = (
    <CommentComponent
      referenceIds={[referenceActivityId]}
      customerId={customerId}
      referenceType={referenceType}
      updateOnSuccess={(commentAdded: unknown) => {
        newCommentCallBack(commentAdded as Commento);
      }}
    />
  );

  return <div>{CommentForm}</div>;
}
