import { Select, Skeleton } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { useConfig } from 'components/HigherOrderComponent/Config/config';
import { debounce, map } from 'lodash';
import { useState } from 'react';
import { AccountConfigKey } from 'types/entities/account';
import { disputeTypeSelectCustomizer, selectOptionsInitializer } from '../Utils';
import useDisputeTypeSearch from '../hooks/useDisputeTypeSearch';
import useGetDisputeTypeOptions from '../hooks/useGetDisputeTypeOptions';
import {
  ListOptions,
  MaxTagListRenderer,
  customTagRender,
  generateDisputeTypeOptions,
} from '../predicate';
import { DisputeType, DisputeTypeMapping, DisputeTypeSelectProps } from '../types';
import CreateDisputeType from './CreateDisputeType';

export default function DisputeTypesSelect<T>(props: DisputeTypeSelectProps<T>) {
  const {
    onChangeDisputeType,
    selectedDisputeId,
    placeholder,
    queryFn,
    queryKey,
    selectedDisputes,
    transformSchema,
  } = props;

  const [optionsMap, setOptionsMap] = useState<Map<number, DisputeTypeMapping>>();
  const [selectedValues, setSelectedValues] = useState<number[] | undefined>(selectedDisputeId);
  const [searchValue, setSearchValue] = useState<string>();
  const [options, setOptions] = useState<DefaultOptionType[]>([]);
  const canCreateDisputeType = useConfig(AccountConfigKey.ALLOW_NON_ADMINS_CREATE_DISPUTE_TYPE);

  const { data, isLoading } = useGetDisputeTypeOptions<T>({
    queryFn,
    queryKey,
    selectedDisputes,
    setOptionsMap,
    transformSchema,
    setOptions,
  });

  const { searchDisputeTypeList, searchOptions } = useDisputeTypeSearch<T>({
    disputeData: data,
    setOptionsMap,
    selectedDisputes,
    transformSchema,
  });

  if (isLoading) {
    return <Skeleton.Input active block />;
  }

  const onSearch = debounce((searchTerm: string) => {
    searchDisputeTypeList(searchTerm);
    setSearchValue(searchTerm);
  }, 800);

  function onDisputeCreate(value?: number[]) {
    setSelectedValues(value);
    onChangeDisputeType(value);
    setSearchValue('');
  }

  const createDispute =
    canCreateDisputeType && searchValue ? (
      <CreateDisputeType
        disputeName={searchValue}
        selectedValue={selectedValues}
        onDisputeCreate={onDisputeCreate}
        queryKey={queryKey}
      />
    ) : undefined;

  const isOptionsEmpty = searchOptions && !Object.keys(searchOptions).length;

  function handleDropDownchange(open: boolean) {
    if (open) {
      if (selectedValues?.length) {
        const selectedDisputeOptions = map(selectedValues, (item) =>
          optionsMap?.get(item)
        ) as DisputeTypeMapping[];

        const optionsList = generateDisputeTypeOptions(selectedDisputeOptions);

        const sortedOptions = selectOptionsInitializer(
          options,
          optionsList,
          disputeTypeSelectCustomizer,
          'id',
          selectedValues
        );

        setOptions(sortedOptions);
      }
    } else {
      setSearchValue('');
    }
  }

  return (
    <Select
      placeholder={placeholder}
      value={selectedValues}
      mode={'multiple'}
      listHeight={250}
      style={{ width: '100%' }}
      suffixIcon={null}
      filterOption={false}
      showSearch
      fieldNames={{ label: 'name', value: 'id' }}
      onSearch={(searchValue: string) => {
        onSearch(searchValue);
      }}
      onDropdownVisibleChange={handleDropDownchange}
      options={searchValue ? searchOptions : options}
      loading={isLoading}
      dropdownRender={(options) => {
        return ListOptions(options, isLoading, isOptionsEmpty, createDispute);
      }}
      tagRender={(props) => {
        return customTagRender<DisputeType, DisputeType>(props, 'name', optionsMap);
      }}
      maxTagPlaceholder={(list) => {
        return (
          <MaxTagListRenderer<DisputeType>
            list={list}
            optionsMap={optionsMap}
            extractKey={'name'}
          />
        );
      }}
      getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
      maxTagCount="responsive"
      onChange={(value: number[]) => {
        setSelectedValues(value);
        onChangeDisputeType(value);
      }}
    />
  );
}
