import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { dateFormatSelector } from 'store/authentication/authentication';
import { CustomerStatement, statementsTexts } from 'types/customerstatement/customer-statement';

interface OutstandingStatementsProps {
  hide?: boolean;
  statementType?: 'OUTSTANDING_TRANSACTIONS';
  statement?: CustomerStatement;
}
const Label = <GrowText>{statementsTexts.as_of}</GrowText>;
function OutstandingStatements(props: Readonly<OutstandingStatementsProps>) {
  const format = useSelector(dateFormatSelector);
  if (props.hide) return null;
  const dateToday = dayjs().format(format);

  return (
    <Flex direction="column" gap="var(--space-4)">
      <GrowText strong>Outstanding Transactions</GrowText>
      <Flex align="center">
        {Label}
        <GrowText style={{ marginLeft: 'var(--space-2)' }} strong>
          {dateToday}
        </GrowText>
      </Flex>
    </Flex>
  );
}

export default OutstandingStatements;
