import { uniqBy } from 'lodash';
import {
  useGetCustomerDropdownList,
  useGetCustomerDropdownListSearch,
  useGetSelectedCustomerDropdownList,
} from 'queries/Customers/customers';
import { useMemo } from 'react';
import { CustomerDropDownDTO } from 'types/entities/customer';

interface useCustomerListProps {
  selectedIds?: number[];
  unscoped?: boolean;
}

const optionPredicate = (values: CustomerDropDownDTO) => {
  return { label: values.name, value: values.id };
};
function useCustomerList(props: useCustomerListProps) {
  const { data: defaultList } = useGetCustomerDropdownList(props.unscoped);
  const { data: selectedValueList } = useGetSelectedCustomerDropdownList(props.selectedIds);
  const customerSearch = useGetCustomerDropdownListSearch(props.unscoped);
  function getCustomerList() {
    if (!selectedValueList?.length) {
      return defaultList?.length ? defaultList?.map(optionPredicate) : [];
    }
    const list = [...selectedValueList, ...(defaultList ?? [])];
    const modifiedList = list?.map(optionPredicate);

    return uniqBy(modifiedList, 'value');
  }

  const customersList = useMemo(getCustomerList, [defaultList, selectedValueList]);
  return { customerSearch, customersList, optionPredicate };
}

export default useCustomerList;
