import { Descriptions, Divider } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { CommentsBlock } from 'components/CollectionActivities/Comment/CommentsBlock';
import { ActionButtons } from 'components/CollectionActivities/Common/Details/ActionButons/ActionButtons';
import {
  AttachmentsBlock,
  InvoicesBlock,
  LabelBlock,
  LineItemBlock,
  TextBlock,
} from 'components/CollectionActivities/Common/Details/BodyBlocks';
import { ViewInvoicesButton } from 'components/CollectionActivities/Common/Details/HeaderButtons';
import { SubscriptionType } from 'components/CollectionActivities/Common/SubscriptionType';
import useActivityBackButton from 'components/CollectionActivities/hooks/useActivityBackButton';
import useGetActivityLink from 'components/CollectionActivities/hooks/useGetActivityLink';
import { ActivityContent, ActivityTitle } from 'components/CollectionActivities/style';
import { getActivityUnitItems } from 'components/CollectionActivities/utils';
import { OPEN_TYPE, useEntitySearchParams } from 'components/Common/hooks/useEntitySearchParams';
import HideWrapper from 'components/Common/Util/HideWrapper';
import { EActivityQueryParams } from 'components/HigherOrderComponent/KeyActivitesContainer/type';

import { ActivityIconButton } from 'components/Inbox/CollectionActivitiesV2/Activities/style';
import { ActivityDetailsBase, ActivityType } from 'types/activities/activity-types';
import { Escalation } from 'types/activities/escalation';

interface EscalationDetailsProps extends ActivityDetailsBase {
  data?: Escalation;
  isLoading: boolean;
}

const t = {
  detailTitle: 'Escalation Details',
  deleteTitle: 'Delete Escalation',
  deleteDescription: 'Are you sure you want to Delete the Escalation entry?',
};

function EscalationDetailsBody(props: EscalationDetailsProps) {
  const {
    hideActions = false,
    data,
    isLoading,
    subscriptionType,
    onDelete,
    deleteLoading,
    editLoading,
    customer,
    activitiesMode,
  } = props;
  const { cardLabel, Icon } = getActivityUnitItems(ActivityType.ESCALATION);
  const { entitySearchParams } = useEntitySearchParams();
  const ActivityCopyLink = useGetActivityLink(activitiesMode);
  const ActivityBackButton = useActivityBackButton();

  const Sub = !isLoading && data ? <SubscriptionType subscriptionType={subscriptionType} /> : null;
  const Title = (
    <ActivityTitle align="center" gap="var(--space-8)">
      <ActivityBackButton />
      <ActivityIconButton
        icon={Icon}
        size="small"
        css={{ '--activityBg': 'var(--volcano-1)' }}
        viewMode
        loading={isLoading}
        hideBackground
      />
      <GrowText>{cardLabel}</GrowText>
      <ActivityCopyLink data={data} customer={customer} type={ActivityType.ESCALATION} />
    </ActivityTitle>
  );

  const Header = (
    <Flex justify="space-between">
      {Title}
      <ActionButtons
        hide={hideActions}
        onDelete={onDelete}
        id={data?.id}
        deleteLoading={deleteLoading}
        editLoading={editLoading}
        title={t.deleteTitle}
        description={t.deleteDescription}
        type={ActivityType.ESCALATION}
      />
    </Flex>
  );

  const InvoiceArea =
    !isLoading && data ? <InvoicesBlock invoices={data.invoices} isBaseCurrency /> : null;
  const LineItem = !isLoading && data ? <LineItemBlock item={data.invoice_line_items} /> : null;
  const PDF = data?.invoices ? (
    <div style={{ marginTop: 'var(--space-8)' }}>
      <ViewInvoicesButton invoices={data?.invoices} title={t.detailTitle} text="Invoice" />
    </div>
  ) : null;

  const Note =
    data && !isLoading ? <TextBlock value={data?.description} size={'var(--fs-16)'} /> : null;
  const Attachments =
    !isLoading && data ? <AttachmentsBlock attachments={data.attachments ?? []} /> : null;
  const Attachment =
    data?.attachments?.length && !isLoading ? (
      <ActivityContent layout="vertical" colon={false}>
        <Descriptions.Item label={<LabelBlock label="Attachments" />}>
          {Attachments}
        </Descriptions.Item>
      </ActivityContent>
    ) : null;

  const Content = (
    <Flex gap="var(--space-12)" direction="column">
      <Flex direction="column" gap="var(--space-8)">
        {Header}
        {Sub}
      </Flex>
      <Flex direction="column" gap="var(--space-16)">
        {Note}
        {Attachment}
        {PDF}
        <ActivityContent layout="vertical" colon={false}>
          <Descriptions.Item label={<LabelBlock label="Invoices" />}>
            {InvoiceArea}
          </Descriptions.Item>
        </ActivityContent>
        <HideWrapper hide={!(data?.invoice_line_items && data?.invoice_line_items.length)}>
          <ActivityContent layout="vertical" colon={false}>
            <Descriptions.Item label={<LabelBlock label="Invoice Line Item" />}>
              {LineItem}
            </Descriptions.Item>
          </ActivityContent>
        </HideWrapper>
      </Flex>
    </Flex>
  );

  const Footer =
    data && !isLoading ? (
      <CommentsBlock
        customerId={props.customer?.id}
        activityId={data.id}
        activityType={ActivityType.ESCALATION}
        comments={data.comments}
        isOpen={entitySearchParams[EActivityQueryParams.OPEN] === OPEN_TYPE.COMMENTS}
      />
    ) : null;
  return (
    <>
      {Content}
      <Divider style={{ margin: 'var(--space-24) 0', borderColor: 'var(--gray-5)' }} />
      {Footer}
    </>
  );
}

export { EscalationDetailsBody };
