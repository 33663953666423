// Draft-js Imports
import '@draft-js-plugins/mention/lib/plugin.css';
import { AddComment, GrowTypography } from '@sinecycle/growcomponents';
import { UploadFile } from 'antd';
import { useForm } from 'antd/es/form/Form';
import UploadFormFiles from 'components/Common/ActivitiesForm/FormElements/UploadFormFiles';
import 'draft-js/dist/Draft.css';
import { flatMap } from 'lodash';
import { usePostCommentsMutation } from 'queries/invoices';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { authenticationSelector } from 'store/authentication/authentication';
import { IComment } from '../../../../store/activity-feed/type';
import { notify } from '../../../BaseComponents/Notifications';
const t = {
  comment: 'Comment',
  markAsPrivate: 'Mark as Private',
  addMembers: 'Add Members',
  typeToStartAdding: 'Type to start adding members...',
  postedComment: 'Comment Posted',
  postCommentDescription: 'Collaborators in comments can also view the whole thread',
  errorPostingComment: 'Comment Unsuccessful',
  tryAgain: 'Please Try Again',
  add: 'Add',
  addingText: 'Adding',
  via: 'via',
};

export interface IParamsForPostComments {
  comments: string;
  customer_id?: number;
  reference_ids?: Array<number | string>;
  reference_type?: string;
  private: boolean;
  tagged_user_ids?: string[];
  attached_files?: string[];
  comments_content?: string;
  file_upload_ids?: string[];
}

const Styledwraper = styled.div`
  .ant-form-item {
    margin: 0;
  }
`;
interface ICommentComponent {
  referenceIds?: Array<number | string>;
  referenceType?: string;
  customerId?: number;
  updateOnSuccess?: (comment: IComment) => void;
  cancelCallBack?: () => void;
  style?: React.CSSProperties;
  wrapperStyle?: React.CSSProperties;
  handleSave?: (comment: IParamsForPostComments) => void;
  preventResetFocus?: boolean;
  loading?: boolean;
  hideMessage?: boolean;
}

import { mentionsDataSelector } from 'components/BaseComponents/RichComment';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { Attachment } from 'types/common/attachments';

const CommentComponent = (props: ICommentComponent) => {
  const [searchParams] = useSearchParams();
  const { mutateAsync: postCommentsMutation, isLoading } = usePostCommentsMutation();
  const [form] = useForm();
  const [fileList, setFileList] = useState<UploadFile<Attachment>[]>();
  const mentions = useSelector(mentionsDataSelector);
  const [disable, setDisable] = useState(false);
  const { user } = useSelector(authenticationSelector);
  function notifyCommentAdded() {
    const event = new CustomEvent('comment-added');
    document.dispatchEvent(event);
  }
  function postComments(params: IParamsForPostComments) {
    postCommentsMutation({ params, key: 'comments' })
      .then((comment) => {
        props.updateOnSuccess?.(comment);
        const successText =
          comment.private && props.referenceType === 'DISPUTE'
            ? 'Private Comment Posted'
            : 'Comment posted';
        const desc =
          comment.private && props.referenceType === 'DISPUTE'
            ? `The comment by ‘${user?.first_name} ${
                user?.last_name ?? ''
              }’ is successfully posted privately`
            : t.postCommentDescription;
        notify.success(successText, { description: desc });
        notifyCommentAdded();
      })
      .catch(() => {
        notify.error(t.errorPostingComment, { description: t.tryAgain });
        notifyCommentAdded();
      });
  }

  useEffect(() => {
    const hasCommentOpen = searchParams.get('open');

    if (hasCommentOpen === 'comments') {
      const event = new CustomEvent('trigger-focus');
      document.dispatchEvent(event);
    }
  }, [searchParams]);

  const Upload = (
    <UploadFormFiles
      formFieldName="attachments"
      form={form}
      hideLabel
      onChange={(info) => {
        setFileList(info.fileList);
        setDisable(info.file.status === 'uploading');
      }}
      className={fileList?.length ? '' : 'uploaded'}
    />
  );

  const Heading = (
    <GrowTypography.Typography>
      <GrowTypography.Text strong>{`${user?.first_name} ${
        user?.last_name ?? ''
      }`}</GrowTypography.Text>
      {user?.designation ? (
        <GrowTypography.Text>{`(${user?.designation})`}</GrowTypography.Text>
      ) : null}
    </GrowTypography.Typography>
  );

  const Placeholder = props.referenceType === 'DISPUTE' ? undefined : 'Add Comments';
  return (
    <Styledwraper>
      <AddComment
        heading={Heading}
        convertedValue
        isLoading={isLoading || props.loading}
        extraComponent={Upload}
        handleCancel={props.cancelCallBack}
        disable={disable}
        placeholder={Placeholder}
        private_details={{
          hidePrivate: props.referenceType !== 'DISPUTE',
        }}
        hasExtraValue={Boolean(fileList?.length)}
        handleSave={(value) => {
          const commentData: IParamsForPostComments = {
            comments: value.comment ?? '',
            customer_id: props.customerId,
            reference_ids: props.referenceIds,
            reference_type: props.referenceType,
            private: props.referenceType === 'DISPUTE' ? value.is_private : true,
            tagged_user_ids: flatMap([...(value.mentionsIds ?? [])]).map((id) => String(id)),
            file_upload_ids: fileList?.map((file) => String(file.response?.id)),
          };

          if (props.handleSave) {
            props.handleSave(commentData);
          } else {
            postComments(commentData);
          }
        }}
        mentionData={mentions}
        wrapperStyles={props.wrapperStyle}
        preventResetFocus={props.preventResetFocus}
      />
    </Styledwraper>
  );
};

export default CommentComponent;
