import { ContactGroupLabel } from 'components/Common/Contact/ContactType';
import { ParticipantRelation } from 'store/contacts/contacts';
import { Emailable, EmailableType } from 'types/activities/email';
import { ContactType, ParentContactType } from 'types/entities/contact';
import { ISendEmailParams } from '../../../../store/email/types';
import { EmailGroupedOptions } from './NewEmail/util';

export interface stakeHolderListAttributes {
  email?: string;
  type: string;
  id?: number;
  label?: string;
  contact_type?: ContactType | ParentContactType;
  user_type?: string;
  participant_relation?: ParticipantRelation;
  contact_type_identifier?: 'CUSTOMER' | 'INVOICE';
  contact_type_id?: number;
  value?: string | number;
  title?: string;
  /* #association_level similar to contact_type_identifier 
  but for more general purpose.contact_type_identifier will
  be removed in the future when the migration is done.
   */
  association_level?: 'CUSTOMER' | 'INVOICE';
}

export enum EEmailParams {
  customer_ids = 'customer_ids',
  invoice_ids = 'invoice_ids',
  to = 'to',
  from = 'from',
  cc = 'cc',
  bcc = 'bcc',
  subject = 'subject',
  body = 'body',
  attach_current_invoice = 'attach_current_invoice',
  attach_all_open_invoices = 'attach_all_open_invoices',
  group_by_customer = 'group_by_customer',
  file_upload_ids = 'file_upload_ids',
  generative_attachments = 'generative_attachments',
  followup_type = 'followup_type',
  invoice_delivery = 'invoice_delivery',
  attachments = 'attachments',
  customer_document_ids = 'customer_document_ids',
  invoice_document_ids = 'invoice_document_ids',
  customer_document_type_ids = 'customer_document_type_ids',
  invoice_document_type_ids = 'invoice_document_type_ids',
}

export interface IComposeEmail {
  to?: Emailable[];
  cc?: Emailable[];
  bcc?: Emailable[];
  from?: Emailable;
  subject?: string;
  body?: string;
  groupedOptions?: EmailGroupedOptions;
  onComposeChange?: (emailComponents: ISendEmailParams) => void;
  showRelativeOptionsList?: boolean;
}

export enum RECIPIENT_OPT_GROUP_KEYS {
  CUSTOMER = 'CUSTOMER',
  INVOICE = 'INVOICE',
  OTHER_CONTACTS = 'OTHER_CONTACTS',
  INTERNAL_CONTACT = 'INTERNAL_CONTACTS',
  OTHERS = 'OTHERS',
}

export const RECIPIENT_OPT_GROUPS = [
  {
    key: RECIPIENT_OPT_GROUP_KEYS.INVOICE,
    label: ContactGroupLabel.INVOICE_CONTACT,
  },
  {
    key: RECIPIENT_OPT_GROUP_KEYS.CUSTOMER,
    label: ContactGroupLabel.CUSTOMER_CONTACT,
    allowedTypes: [EmailableType.CONTACT.toString()],
  },
  {
    key: RECIPIENT_OPT_GROUP_KEYS.INTERNAL_CONTACT,
    label: ContactGroupLabel.INTERNAL_CONTACT,
    allowedTypes: [EmailableType.USER.toString()],
  },
  {
    key: RECIPIENT_OPT_GROUP_KEYS.OTHERS,
    label: ContactGroupLabel.USERS,
    allowedTypes: [EmailableType.USER.toString()],
  },
];
