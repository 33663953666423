import { Row } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { Overlay } from 'components/BaseComponents/Overlay';
import { GrowText } from 'components/BaseComponents/Typography';
import { InputLoader } from 'components/Common/FilterComponents/Common/SkeletonLoader';
import { AmountWithCurrencySplit } from 'components/Common/MultiCurrency';
import { AppliedPaymentsCredits } from 'components/Common/MultiCurrency/UnappliedPaymentsAndCredits';
import ArrowSelect from 'components/Common/SelectComponents/ArrowSelect';
import { useConfig } from 'components/HigherOrderComponent/Config/config';
import { RelativeDateRangeLabels } from 'constants/date-time';
import { PageLink } from 'lib/router';
import DashboardCardTemplate from 'pages/Dashboard/AdminDashboard/SnapShot/CollectionBalanceAndOutstanding/CardTemplate';
import { DashboardComponentProps } from 'pages/Dashboard/types';
import { useTotalCollectionsTileQuery } from 'queries/admin-dashboard';
import React, { useState } from 'react';
import { AccountConfigKey } from 'types/entities/account';
import { RelativeDateRange } from 'util/relative-date-range';
import TotalCustomersInvoices from '../TotalCustomersInvoices';
import { StyledTileCard } from '../styles';
import { dashboardTexts } from '../texts';
import { MainCardItem, TotalCollected } from './style';

const t = dashboardTexts.totalCollected;

const PERIODS = [
  RelativeDateRange.TODAY,
  RelativeDateRange.YESTERDAY,
  RelativeDateRange.LAST_7_DAYS,
  RelativeDateRange.THIS_WEEK,
  RelativeDateRange.THIS_MONTH,
];

const PERIOD_OPTIONS = PERIODS.map((PERIOD) => ({
  label: RelativeDateRangeLabels[PERIOD],
  value: PERIOD,
}));

function TotalCollectedTile({ filterParams }: DashboardComponentProps) {
  const [timePeriod, setTimePeriod] = useState<RelativeDateRange>(RelativeDateRange.THIS_MONTH);
  const hideTotalCollected = useConfig(AccountConfigKey.HIDE_DASHBOARD_TOTAL_COLLECTED_TILE);

  const { data: totalCollection, isLoading: loading } = useTotalCollectionsTileQuery({
    ...filterParams,
    period: timePeriod,
  });

  const totalCollected = totalCollection?.total_payment_amount.amount;
  const customersCount = totalCollection?.total_payment_amount.customer_count;
  const invoicesCount = totalCollection?.total_payment_amount.invoice_count;

  const InputLoaderJSX = <InputLoader active={loading} />;

  const TotalCollectedJSX = totalCollected && (
    <PageLink
      appPage="PAYMENT_LIST"
      state={{ addPrimaryPathAsChildPath: true }}
      className="value amount"
    >
      <AmountWithCurrencySplit
        type="multi"
        amountWithCurrencySplit={totalCollected}
        className="text-24 semi-bold"
        showTooltip
        context={t.title}
        formatOptions={{ maximumFractionDigits: 0 }}
      />
    </PageLink>
  );

  if (hideTotalCollected) {
    return (
      <StyledTileCard>
        <Overlay shouldCover={hideTotalCollected} style={{ opacity: '.5' }}>
          <Row className="no-wrap">
            <TotalCollected flex={1}>
              <MainCardItem>
                <GrowText>{t.title}</GrowText>
                <GrowText size="var(--fs-30)">-</GrowText>
              </MainCardItem>
            </TotalCollected>
          </Row>
        </Overlay>
      </StyledTileCard>
    );
  }

  const TotalCustInvoiceInfo = (
    <TotalCustomersInvoices
      loading={loading}
      customersCount={customersCount}
      invoicesCount={invoicesCount}
    />
  );

  const PeriodFilter = (
    <ArrowSelect
      style={{ height: 'var(--space-16)' }}
      options={PERIOD_OPTIONS}
      value={timePeriod}
      onChange={setTimePeriod}
      arrowStyle={'Bold'}
    />
  );

  const Extra = (
    <>
      {totalCollection && (
        <div style={{ cursor: 'pointer' }}>
          <AppliedPaymentsCredits appliedPayments={totalCollection} />
        </div>
      )}
    </>
  );
  const CustomerInvoicePeriodFilter = (
    <Flex justify="space-between" gap={'var(--space-8)'} align="center" style={{ width: '100%' }}>
      <>{TotalCustInvoiceInfo}</>
      <>{PeriodFilter}</>
    </Flex>
  );

  const AmountInfo = <> {loading ? InputLoaderJSX : TotalCollectedJSX}</>;

  return (
    <DashboardCardTemplate
      title={t.title}
      extra={Extra}
      amountInfo={AmountInfo}
      customerInvoiceCount={CustomerInvoicePeriodFilter}
    />
  );
}

export default React.memo(TotalCollectedTile);
