import { Col, Row } from 'antd';
import { GrowParagraph, GrowText } from 'components/BaseComponents/Typography';

export type DetailsContentItems = {
  name: string;
  email?: string;
  title?: string;
};

interface DetailsContentProps {
  heading: string;
  items: Array<DetailsContentItems>;
}

const mapDetailsContentPredicate = (content: DetailsContentItems) => {
  return (
    <Row key={content.name} align="middle" gutter={[2, 2]}>
      <Col>
        <GrowParagraph style={{ margin: 0 }} ellipsis>
          {content.name}
        </GrowParagraph>
      </Col>
      <Col>{content.email ? <GrowText>{`<${content.email}>`}</GrowText> : null}</Col>
      <Col>
        {content.title ? (
          <GrowText style={{ color: 'var(--gray-7)' }}>{`(${content.title})`}</GrowText>
        ) : null}
      </Col>
    </Row>
  );
};

function DetailsContent(props: DetailsContentProps) {
  const { heading, items } = props;
  return (
    <Row gutter={[8, 8]}>
      <Col flex={60}>
        <GrowText style={{ color: 'var(--gray-7)' }}>{heading}</GrowText>
      </Col>
      <Col style={{ color: 'var(--gray-7)' }}>:</Col>
      <Col span={20} style={{ alignSelf: 'center' }}>
        {items.map(mapDetailsContentPredicate)}
      </Col>
    </Row>
  );
}

export { DetailsContent };
