import '@draft-js-plugins/mention/lib/plugin.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CommentView } from '@sinecycle/growcomponents';
import { useIsMutating } from '@tanstack/react-query';
import { Spin, Tooltip } from 'antd';
import { mentionsDataSelector } from 'components/BaseComponents/RichComment';
import { FilePreviewList } from 'components/BaseComponents/UploadFiles/FilePreview';
import { SourceAppComp } from 'components/Common/SourceApp/SourceApp';
import { ActivityIconButton } from 'components/Inbox/CollectionActivitiesV2/Activities/style';
import { useSelector } from 'react-redux';
import { dateFormatSelector } from 'store/authentication/authentication';
import styled from 'styled-components';
import { ActivityType } from 'types/activities/activity-types';
import { Commento, ExternalMappingEventType } from 'types/activities/comment';
import { Komento } from '.';
import { CommentSource } from './CommentSource';
const ListWrapper = styled.div`
  .ant-list-items {
    max-height: 250px;
    overflow: auto;
    scrollbar-width: none;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    scroll-margin: 48px;
    scroll-padding-top: 48px;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

export function CommentsList({
  comments,
  activityType,
}: {
  comments?: Commento[];
  activityType?: ActivityType;
}) {
  const mentions = useSelector(mentionsDataSelector);
  const format = useSelector(dateFormatSelector);
  const isLoading = useIsMutating({ mutationKey: ['post-comments'] });
  function getComments() {
    return comments?.map((comment) => {
      const PrivateIcon = (
        <Tooltip title="Not visible to customers">
          <div>
            <ActivityIconButton
              icon={<FontAwesomeIcon icon={['far', 'lock']} color="var(--red-7)" />}
              size="small"
              css={{ '--activityBg': 'var(--red-1)' }}
              viewMode
            />
          </div>
        </Tooltip>
      );
      const source =
        comment.source_app === 'CRM' &&
        comment.external_event_type === ExternalMappingEventType.SALESFORCE ? (
          <CommentSource
            source={comment.external_event_type}
            containerStyle={{ alignSelf: 'start' }}
          />
        ) : comment.source_app === 'CUSTOMER_PORTAL' ? (
          <SourceAppComp source_app={comment.source_app} />
        ) : null;
      return {
        format,
        commentId: String(comment.id),
        private: comment.private,
        firstName: comment.created_by.first_name,
        lastName: comment.created_by.last_name,
        role:
          comment.created_by.app_type === 'CUSTOMER_PORTAL'
            ? 'Customer'
            : comment.created_by.designation,
        comment: comment.comments,
        date: comment.updated_date,
        deletable: false,
        source,
        titleIcon: comment.private
          ? activityType === ActivityType.DISPUTE
            ? PrivateIcon
            : null
          : null,
        extra: (
          <div className="tw-flex tw-items-center tw-gap-8 tw-flex-wrap">
            <FilePreviewList files={comment?.attachments} />
          </div>
        ),
      };
    });
  }

  return (
    <Spin
      spinning={Boolean(isLoading)}
      tip="Posting..."
      style={{ display: 'flex', alignItems: 'center' }}
    >
      <>
        {comments?.length ? (
          <CommentView
            commentList={getComments()}
            richCommentProps={{ mentions }}
            searchParams={{ deleteKeys: ['open'], url: window.location.href }}
          />
        ) : (
          <div style={{ height: '40px' }} />
        )}
      </>
    </Spin>
  );
}

export function CommentSection({
  comment,
  hideDivider,
}: {
  comment: Commento;
  hideDivider: boolean;
}) {
  return <Komento {...comment} />;
}
