import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { businessUnitsSelector } from 'store/business-units/business-units';
import { regionsSelector } from 'store/regions/regions';
import { UserCategory } from 'types/api/user-category';
import { User } from 'types/entities/user';
import useFilterUsers from '../../Common/hooks/useFilterUsers';
import { FilterDropdown } from '../../FilterDropdown';
import { MultiSelectCheckboxWrapperWithSearch } from '../../FilterWrapper/MultiSelectCheckboxWrapperWithSearch';
import { FilterConfig, FilterType } from '../../types';
import { BaseFilterBlockProps } from '../types';

type MinMaxValue = { min: number; max: number };

export function BusinessUnits(props: BaseFilterBlockProps<number[]>) {
  const { onClear, onSubmit, value, showClear = true } = props;
  const businessUnits = useSelector(businessUnitsSelector).businessUnits ?? [];

  const filterConfig: FilterConfig = {
    name: 'business-units',
    label: 'Business Units',
    type: FilterType.MULTI_SELECT_CHECKBOX,
    options: businessUnits.map((businessUnit) => ({
      value: businessUnit.id,
      label: businessUnit.name,
    })),
  };

  function handleSubmit(value: number[]) {
    onSubmit && onSubmit(value);
  }
  function handleClear() {
    onClear && onClear();
    onSubmit && onSubmit([]);
  }

  return (
    <FilterDropdown
      value={value}
      filterConfig={filterConfig}
      onSubmit={handleSubmit}
      onClear={handleClear}
      showClear={showClear}
      emptyText={'All'}
    />
  );
}

export function Regions(props: BaseFilterBlockProps<number[]>) {
  const { onClear, onSubmit, value, showClear = true } = props;
  const regions = useSelector(regionsSelector).regions ?? [];

  const filterConfig: FilterConfig = {
    name: 'regions',
    label: 'Regions',
    type: FilterType.MULTI_SELECT_CHECKBOX,
    options: regions.map((region) => ({
      value: region.id,
      label: region.name,
    })),
  };

  function handleSubmit(value: number[]) {
    onSubmit && onSubmit(value);
  }
  function handleClear() {
    onClear && onClear();
    onSubmit && onSubmit([]);
  }

  return (
    <FilterDropdown
      value={value}
      filterConfig={filterConfig}
      onSubmit={handleSubmit}
      onClear={handleClear}
      showClear={showClear}
      emptyText={'All'}
    />
  );
}

function getEmptyText(name: string) {
  return `No ${name} Found`;
}

function getPlaceholderText(name: string) {
  return `Search ${name}s`;
}

interface categoryRequest {
  bussUnit?: number[] | undefined;
  regionId?: number[] | undefined;
  subsidariesId: number[] | undefined;
}

interface CustomerContactProps {
  label: string;
  key?: string;
  category?: UserCategory;
  value: number[];
  onClear?: () => void;
  onSubmit?: (value: number[]) => void;
  categoryRequest?: categoryRequest;
}
export function CustomerContact(props: CustomerContactProps) {
  const [searchTerm, setSearchTerm] = useState<string | undefined>();
  const { label, key, category, value, onClear, onSubmit } = props;

  function filterMemoCallback() {
    return {
      businessUnitids: props?.categoryRequest?.bussUnit,
      regionIds: props?.categoryRequest?.regionId,
      subsidiaryIds: props?.categoryRequest?.subsidariesId,
    };
  }

  const searchParams = useMemo(filterMemoCallback, [
    props?.categoryRequest?.bussUnit,
    props?.categoryRequest?.regionId,
    props?.categoryRequest?.subsidariesId,
  ]);

  const [, , customerContacts] = useFilterUsers({
    filterValues: searchParams,
    categoryId: category?.id,
  });

  const computedCustomerContacts = useMemo(() => {
    const transformedContacts = customerContacts?.map((contact) => ({
      label: `${contact.first_name} ${contact.last_name}`,
      value: contact.id,
    }));

    transformedContacts?.unshift({
      label: 'Unassigned',
      value: -1,
    });

    return transformedContacts;
  }, [customerContacts]);

  const searchCustomerContacts = useMemo(() => {
    return computedCustomerContacts?.filter((contact) => {
      return contact.label.toLowerCase().includes(searchTerm ?? '');
    });
  }, [computedCustomerContacts, searchTerm]);

  const FilterConfig = {
    name: key as string,
    label,
    options: (searchTerm ? searchCustomerContacts : computedCustomerContacts) ?? [],
  };

  function onClearCallback() {
    onClear && onClear();
  }

  function onSubmitCallback(value: number[]) {
    onSubmit && onSubmit(value);
  }

  function onSearchCallback(search?: string) {
    setSearchTerm(search);
  }

  const CustomerContactsFilter = (
    <MultiSelectCheckboxWrapperWithSearch<User, number[]>
      filterConfig={FilterConfig}
      value={value}
      onClear={onClearCallback}
      onSubmit={onSubmitCallback}
      onSearchCallback={onSearchCallback}
      placeholder={getPlaceholderText(label)}
      emptyText={getEmptyText(label)}
      showClear
    />
  );

  return CustomerContactsFilter;
}
