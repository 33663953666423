import { useActivityFilterParams } from 'components/Inbox/CollectionActivitiesV2/hooks/useActivityFilterParams';
import { useActivitySearchParams } from 'components/Inbox/CollectionActivitiesV2/hooks/useActivitySearchParams';

import { useEntitySearchParams } from 'components/Common/hooks/useEntitySearchParams';
import { EActivityQueryParams } from 'components/HigherOrderComponent/KeyActivitesContainer/type';
import useEmailFilters, {
  useEmailSearchParams,
  useGetInboxUnreadParams,
} from 'components/Inbox/EmailConversations/hooks';
import { useMutation, useQuery } from 'lib/query-client.ts';
import { useInboxNotificationStatus } from 'pages/Inbox/hooks';
import {
  fetchAssignedActivities,
  fetchEmailThread,
  fetchSubscribedActivities,
  fetchUnreadCountEmailThreads,
  getEmailActivityFeed,
  getEmailThreads,
  markActivityAsRead,
  markActivityAsUnread,
  markEmailAsRead,
  markEmailAsUnRead,
} from 'services/inbox';
import { getKeyActivityData } from 'services/invoice';
import { EmailActivityFeedCollection } from 'store/activity-feed/type';
import { EmailFilters } from 'types/activities/email';
import {
  AssignedActivities,
  InboxActivitiesFilters,
  InboxActivityQueryParam,
  SubscribedActivities,
} from 'types/api/inbox/activity';
import { EmailThreadsList, InboxEmailSearchParam } from 'types/api/inbox/email';
import { getSanitizedObject } from 'util/remove-null-and-undefined';

export const inboxQueryKeys = {
  myInbox: 'email-conversations-my-inbox',
  arInbox: "'email-conversations-ar-inbox'",
  subscribed_activities: 'subscribed-activities',
  recent_replies: 'recent-replies',
  collection_activity: 'collection_activity',
  assigned_activities: 'assigned-activities',
  emailunread_count: 'email-unread-count',
  arEmailunread_count: 'ar-email-unread-count',
  email_threads: 'email_threads',
  unread_count: 'unread_count',
};
interface useInboxEmailThreadsQueryProps {
  handleSuccess(value: EmailThreadsList): void;
}

interface useInboxActivitiesQueryProps {
  handleSuccess(value: AssignedActivities | SubscribedActivities): void;
}

interface EmailFeedProp {
  id?: number;
  type: string;
  onSuccessCallBack: (data: EmailActivityFeedCollection) => EmailActivityFeedCollection | undefined;
  size?: number;
}

export function useGetRecentRepliesTreads(params: Partial<EmailFilters>) {
  return useQuery({
    queryKey: [inboxQueryKeys.recent_replies, params],
    queryFn: () => getEmailThreads(params),
    cacheTime: 0,
    staleTime: 0,
  });
}

export function useGetInboxEmailUnReadCount() {
  const { requestParam } = useGetInboxUnreadParams();
  return useQuery({
    queryKey: [inboxQueryKeys.unread_count, requestParam.ar_email_id, requestParam.is_my_inbox],
    queryFn: () => fetchUnreadCountEmailThreads(requestParam),
  });
}

export function useInboxEmailThreads(props: useInboxEmailThreadsQueryProps) {
  const { emailSearchParams, filters, setEmailSearchParams } = useEmailFilters();

  const { updateEmailSeen } = useInboxNotificationStatus();

  return useQuery({
    queryKey: [inboxQueryKeys.email_threads, filters],
    queryFn: () => getEmailThreads(filters),
    enabled: Boolean(Object.keys(getSanitizedObject(emailSearchParams)).length),
    onSuccess(data) {
      setEmailSearchParams(
        {
          thread_id: data.data.length
            ? emailSearchParams[InboxEmailSearchParam.threadId]
              ? emailSearchParams[InboxEmailSearchParam.threadId]
              : data.data[0].thread_id
            : emailSearchParams[InboxEmailSearchParam.threadId],
        },
        { state: null, replace: true }
      );
      props.handleSuccess(data);

      if (data.current_page === 1) {
        updateEmailSeen();
      }
    },
    staleTime: 0,
    cacheTime: 0,
  });
}
export function useDetailsEmailThreads() {
  const { emailSearchParams, filters } = useEmailFilters();
  const { setEntitySearchParams } = useEntitySearchParams();
  return useQuery({
    queryKey: ['details-email-threads', filters],
    queryFn: () => getEmailThreads(filters),
    enabled: Boolean(Object.keys(getSanitizedObject(emailSearchParams)).length),
    onSuccess(data) {
      setEntitySearchParams(
        {
          email_id: data.data.length
            ? emailSearchParams[InboxEmailSearchParam.threadId]
              ? emailSearchParams[InboxEmailSearchParam.threadId]
              : data.data[0].thread_id
            : emailSearchParams[InboxEmailSearchParam.threadId],
        },
        { state: null, replace: true }
      );
    },
    staleTime: 0,
    cacheTime: 0,
  });
}

export function useMarkAsReadUpdating() {
  return useMutation({
    mutationFn: (threadId: number[]) => markEmailAsRead(threadId),
  });
}

export function useMarkAsUnReadUpdating() {
  return useMutation({
    mutationFn: (threadId: number[]) => markEmailAsUnRead(threadId),
  });
}

export function useInboxThreadsQuery() {
  const { emailSearchParams } = useEmailSearchParams();

  return useQuery({
    queryKey: ['email-thread', emailSearchParams[InboxEmailSearchParam.threadId]],
    queryFn: () => fetchEmailThread(emailSearchParams[InboxEmailSearchParam.threadId]),
    cacheTime: 0,
    staleTime: 0,
    enabled: Boolean(emailSearchParams[InboxEmailSearchParam.threadId]),
  });
}

export function useInboxAssignedActivitiesQuery(props: useInboxActivitiesQueryProps) {
  const { activitySearchParams, setActivitySearchParams } = useActivitySearchParams();
  const { filters, searchParams } = useActivityFilterParams();

  return useQuery({
    queryKey: [inboxQueryKeys.assigned_activities, filters, searchParams],
    queryFn: () => fetchAssignedActivities(filters, searchParams),
    cacheTime: 0,
    staleTime: 0,
    enabled:
      Boolean(Object.keys(getSanitizedObject(filters)).length) &&
      Boolean(Object.keys(getSanitizedObject(searchParams)).length),
    onSuccess(data) {
      setActivitySearchParams(
        {
          activity_id: data.data.length
            ? activitySearchParams.activity_id
              ? activitySearchParams.activity_id
              : data.data[0].entity_detail.id
            : activitySearchParams.activity_id,

          activity_type: data.data.length
            ? activitySearchParams.activity_type
              ? activitySearchParams.activity_type
              : data.data[0].entity_type
            : activitySearchParams.activity_type,
        },
        { state: null, replace: true }
      );
      props.handleSuccess(data);
    },
  });
}

export function useInboxSubscribedActivitiesQuery(props: useInboxActivitiesQueryProps) {
  const { activitySearchParams, setActivitySearchParams } = useActivitySearchParams();
  const { filters, searchParams } = useActivityFilterParams();

  return useQuery({
    queryKey: [inboxQueryKeys.subscribed_activities, filters, searchParams],
    queryFn: () => fetchSubscribedActivities(filters, searchParams),
    cacheTime: 0,
    staleTime: 0,
    enabled:
      Boolean(Object.keys(getSanitizedObject(filters)).length) &&
      Boolean(Object.keys(getSanitizedObject(searchParams)).length),
    onSuccess(data) {
      setActivitySearchParams(
        {
          activity_id: data.data.length
            ? activitySearchParams.activity_id
              ? activitySearchParams.activity_id
              : data.data[0].entity_detail.id
            : activitySearchParams.activity_id,

          activity_type: data.data.length
            ? activitySearchParams.activity_type
              ? activitySearchParams.activity_type
              : data.data[0].entity_type
            : activitySearchParams.activity_type,
        },
        { state: null, replace: true }
      );
      props.handleSuccess(data);
    },
  });
}

export function useActivityMarkAsRead() {
  return useMutation({
    mutationFn: (threadId: number[]) => markActivityAsRead(threadId),
  });
}

export function useActivityMarkAsUnRead() {
  return useMutation({
    mutationFn: (threadId: number[]) => markActivityAsUnread(threadId),
  });
}

export function useGetAssignedActivitiesQuery(
  filters: InboxActivitiesFilters,
  params: InboxActivityQueryParam
) {
  return useQuery({
    queryKey: [inboxQueryKeys.assigned_activities, filters, params],
    queryFn: () => fetchAssignedActivities(filters, params),
    cacheTime: 0,
    staleTime: 0,
    enabled:
      Boolean(Object.keys(getSanitizedObject(filters)).length) &&
      Boolean(Object.keys(getSanitizedObject(params)).length),
  });
}

export function useGetEmailActivityFeedsQuery(feedQueryParam: EmailFeedProp) {
  const { entitySearchParams } = useEntitySearchParams();
  const { page_size, page } = entitySearchParams;
  return useQuery<EmailActivityFeedCollection>({
    queryKey: ['EMAIL', page, page_size],
    queryFn: () =>
      getEmailActivityFeed(feedQueryParam.type, 'EMAIL', page, feedQueryParam?.id, page_size),
    onSuccess: (data) => {
      feedQueryParam?.onSuccessCallBack(data);
    },
    cacheTime: 0,
    staleTime: 0,
    enabled: Boolean(feedQueryParam?.id),
  });
}

export function useGetEmailActivityData<T>(emailId?: string) {
  const { entitySearchParams } = useEntitySearchParams();
  const id = entitySearchParams[EActivityQueryParams.EMAIL_ID]
    ? entitySearchParams[EActivityQueryParams.EMAIL_ID]
    : emailId;

  return useQuery<T>({
    queryKey: ['email-activity-thread', id],
    queryFn: () => getKeyActivityData('email_conversations', id),
    cacheTime: 0,
    staleTime: 0,
    enabled: Boolean(id),
  });
}
