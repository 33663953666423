import { HideWrapper } from '@sinecycle/growcomponents';
import { Button } from 'antd';
import useOpen from 'util/hooks/useOpen';
import { PTPFormElementProps, PTPFormModalProps } from '../types';
import PTPFormModal from './PTPFormModal';
import { getPTPConfig } from 'components/HigherOrderComponent/KeyActivitesContainer/predicate';

export default function PTPButton<PTPElements extends PTPFormElementProps>(
  props: Omit<
    PTPFormModalProps<PTPElements>,
    'openModal' | 'form' | 'remainder' | 'onClose' | 'onFormClose'
  >
) {
  const { buttonConfig, disabled, mode = 'BUTTON' } = props;
  const { open, toggleOpen, onClose } = useOpen({ open: props.open ?? false });
  const config = getPTPConfig(disabled, buttonConfig);

  function handleClose() {
    onClose();
    props.handleClose?.();
  }
  const Title = !buttonConfig?.hidetext && (buttonConfig?.text || config.label);
  return (
    <>
      <HideWrapper hide={mode === 'LIST'}>
        <Button
          icon={!buttonConfig?.hideIcon ? config.icon : null}
          onClick={toggleOpen}
          disabled={disabled}
          {...buttonConfig}
        >
          {Title}
        </Button>
      </HideWrapper>

      <PTPFormModal<PTPElements> openModal={open} onClose={handleClose} {...props} />
    </>
  );
}
