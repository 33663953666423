import { Divider } from 'antd';

interface ReplyDividerProps {
  children?: React.ReactNode;
}
function ReplyDivider(props: ReplyDividerProps) {
  return (
    <Divider style={{ color: 'var(--gray-5)', margin: '6px 0' }}>
      {props.children ? props.children : null}
    </Divider>
  );
}

export { ReplyDivider };
