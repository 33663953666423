import { Flex } from 'components/BaseComponents/Layout/Flex';
import styled from 'styled-components';

export const StyledFlaggedInvoiceItem = styled(Flex)`
  width: 100%;
  padding: var(--space-16) 0;
  &:not(:last-child) {
    border-bottom: 1px solid var(--gray-5);
  }
`;
