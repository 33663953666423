// import { ShowIfActive } from 'components/Common/FilterComponents/Common/ShowIfActive';
import { ShowIfActive } from 'components/Common/FilterComponents/Common/ShowIfActive';
import { MultiSelectCheckboxWrapperWithSearch } from 'components/Common/FilterComponents/FilterWrapper/MultiSelectCheckboxWrapperWithSearch';
import { StandardFilterGroup } from 'components/Common/FilterComponents/types';
import useCustomerList from 'hooks/useCustomerList';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getAllTags } from 'services/tags';
import { TagsSearchResponseInterface } from 'store/aging/types';
import { CustomFieldEntityType } from 'types/entities/custom-field';
import { CustomerDropDownDTO } from 'types/entities/customer';
import { useOpenInvoiceListFilter } from '../../hooks/useOpenInvoiceListFilter';
import { ArrayKeys, updateArrayPayload } from '../../reducers/filterValuesReducer';
import { FilterKeys } from '../../reducers/type';
import { Contacts } from '../Contacts';
import { CustomFieldFilters } from '../CustomField';

type CustomerFilterProps = ReturnType<typeof useOpenInvoiceListFilter>;

const t = {
  placeholder: {
    tags: 'Search Tags',
    customers: 'Search Customers',
  },
  emptyText: {
    tags: 'No Tags Found',
    customers: 'No Customers Found',
  },
};
export function useCustomerFilter(props: CustomerFilterProps) {
  const { filterValues, updateFilterValues, filterGroups, updateFilterGroups } = props;
  const [tags, setTags] = useState<TagsSearchResponseInterface[]>([]);
  const { customerSearch, customersList } = useCustomerList({
    selectedIds: filterValues.customer_ids,
  });
  function initialDataFetch() {
    getAllTags()
      .then((data) => {
        setTags(data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function computedTagOptionsMemo() {
    if (tags.length) {
      return tags.map((tag) => ({
        value: tag.id,
        label: tag.name,
      }));
    }
    return [];
  }

  async function searchTags(keyword: string) {
    return tags.filter((tag) => tag.name.toLowerCase().includes(keyword.toLowerCase()));
  }

  function updateArrayValue<T extends ArrayKeys>(payload: updateArrayPayload<T>) {
    const { key, value } = payload;

    updateFilterValues({
      type: 'UPDATE_ARRAY',
      payload: { key, value },
    });
  }

  function onRemoveFilter(groupId: string, filterId: string) {
    updateFilterGroups({
      type: 'UPDATE_FILTER',
      payload: { groupId: groupId, filterId: filterId, updatedValues: { active: false } },
    });
  }

  const handleSearchTags = useCallback(searchTags, [tags]);
  useEffect(initialDataFetch, []);
  /** Tags Filter Start */
  const tagOptions = useMemo(computedTagOptionsMemo, [tags]);
  const tagFilterConfig = {
    name: 'tags',
    label: 'Tags',
    options: tagOptions,
  };

  const TagsFilter = (
    <ShowIfActive
      filterGroupId={StandardFilterGroup.CUSTOMER}
      filterId={FilterKeys.TAGS}
      filterGroups={filterGroups}
      key={FilterKeys.TAGS}
    >
      <MultiSelectCheckboxWrapperWithSearch<TagsSearchResponseInterface, number[]>
        labelAttribute="name"
        valueAttribute="id"
        filterConfig={tagFilterConfig}
        value={filterValues.tag_ids}
        showClear
        onClear={() => onRemoveFilter(StandardFilterGroup.CUSTOMER, FilterKeys.TAGS)}
        onSearch={handleSearchTags}
        onSubmit={(value) => updateArrayValue({ key: 'tag_ids', value })}
        placeholder={t.placeholder.tags}
        emptyText={t.emptyText.tags}
      />
    </ShowIfActive>
  );
  /** Tags Filter end */

  /** CustomFields  start */
  const CustomFieldFilter = (
    <CustomFieldFilters
      key={FilterKeys.CUSTOM_FIELD}
      {...props}
      customFieldType={CustomFieldEntityType.CUSTOMER}
    />
  );
  /** CustomFields  end */

  /**
   * Customer Contacts start
   */

  const CustomerContacts = <Contacts {...props} />;

  /**
   * Customer Contacts end
   */

  const customerFilterConfig = {
    name: 'Customer Name',
    label: 'Customer Name',
    options: customersList,
  };

  const CustomerFilter = (
    <ShowIfActive
      filterGroupId={StandardFilterGroup.CUSTOMER}
      filterId={FilterKeys.CUSTOMER_DROPDOWN}
      filterGroups={filterGroups}
      key={FilterKeys.CUSTOMER_DROPDOWN}
    >
      <MultiSelectCheckboxWrapperWithSearch<CustomerDropDownDTO, number[]>
        labelAttribute={'name'}
        valueAttribute="id"
        filterConfig={customerFilterConfig}
        value={filterValues.customer_ids}
        showClear
        onClear={() => onRemoveFilter(StandardFilterGroup.CUSTOMER, FilterKeys.CUSTOMER_DROPDOWN)}
        onSearch={customerSearch.mutateAsync}
        onSubmit={(value) => {
          updateArrayValue({ key: 'customer_ids', value });
        }}
        shouldDebounce
        placeholder={t.placeholder.customers}
        emptyText={t.emptyText.customers}
        key="Customer-dropdown"
      />
    </ShowIfActive>
  );

  const DefaultFilterComponents = null;

  const ExtraFilterComponents = [TagsFilter, CustomFieldFilter, CustomerContacts, CustomerFilter];

  return {
    DefaultFilterComponents,
    ExtraFilterComponents,
  };
}
