import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Upload } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { AsyncSaves, generateIsSavingSelector } from '../../../store/async-fetches/fetches';
import { Flex } from '../Layout/Flex';
import { FileUploadPreview } from './FilePreview';
import { AcceptedFileTypes } from './utils';

interface Props {
  fileList?: File[];
  onFileUpload?: Function;
  onFileRemove?: (file: File | undefined) => void;
  uploadProps?: React.ComponentProps<typeof Upload>;
  showFileItemsPreview?: boolean;
  text?: string;
  icon?: React.ReactNode;
  loading?: boolean;
  className?: string;
}

const componentTexts = {
  uploadButton: 'Upload File(s)',
};

const StyledDocUpload = styled(Upload)`
  &.uploaded {
    .ant-upload-list {
      margin-top: 0;
    }
  }
  .ant-upload-list {
    margin-top: var(--space-16);
    display: flex;
    flex-wrap: wrap;
    gap: var(--space-8);
    justify-content: flex-start;

    &::before {
      content: none;
    }
    &::after {
      content: none;
    }
  }
`;

const acceptInputAttr = AcceptedFileTypes.join();
interface FilesPreviewProps {
  fileList?: File[];
  onFileRemove?: (file: File | undefined) => void;
}

const DefaultUploadIcon = <FontAwesomeIcon icon={['far', 'arrow-to-top']} />;
export function FilesPreview(props: FilesPreviewProps) {
  const isUploading = useSelector(generateIsSavingSelector(AsyncSaves.UPLOAD_FILE));

  return (
    <>
      {props.fileList?.map((file) => (
        <FileUploadPreview
          key={file.name}
          file={file}
          showLoading={isUploading}
          onRemove={props.onFileRemove}
        />
      ))}
    </>
  );
}

export const UploadFilesComponent = React.memo((props: Props) => {
  const defaultUploadProps = {
    action: 'some-action',
    accept: acceptInputAttr,
    maxCount: 1,
    beforeUpload(file: UploadFile) {
      props.onFileUpload?.(file);
      // returning false makes sure no request goes to backend on selecting the file to upload
      return false;
    },
    // this makes sure Ant Does not render uploaded file items list by default
    itemRender(origNode: ReactElement, file: UploadFile) {
      return null;
    },
    fileList: props.fileList,
  };

  const uploadProps = Object.assign(defaultUploadProps, props.uploadProps ?? {});

  return (
    <Flex wrap="wrap" align="center" gap="--space-8">
      <StyledDocUpload className={`upload-component ${props.className}`} {...uploadProps}>
        <Button
          icon={props.icon ? props.icon : DefaultUploadIcon}
          style={{ color: 'var(--primary-7)' }}
          loading={props?.loading}
        >
          {props.text ? props.text : componentTexts.uploadButton}
        </Button>
      </StyledDocUpload>
    </Flex>
  );
});
