import * as SentrySdk from '@sentry/react';
import { HTTPError } from 'ky';
import { logRocket } from 'lib/logrocket';

class SentryEventBuilder {
  event: SentrySdk.Event;
  hint?: SentrySdk.EventHint;

  constructor(event: SentrySdk.Event, hint?: SentrySdk.EventHint) {
    this.event = event;
    this.hint = hint;
  }

  async attachLogRocketSession() {
    const sessionURL = await logRocket.getSessionURL();
    this.event.extra = { ...this.event.extra, sessionURL };
  }

  // {method + path + status} combo
  async fingerPrintError() {
    const orginalError = this.hint?.originalException;
    if (orginalError instanceof HTTPError) {
      const reqUrl = new URL(orginalError.request.url);
      this.event.fingerprint = [
        orginalError.request.method,
        reqUrl.pathname,
        `${orginalError.response.status}`,
      ];
    }
  }

  getFinalEvent() {
    return this.event;
  }
}

function isUrlMatchingPattern(path: string, url?: string) {
  const urlPattern = new RegExp(`/${path}/\\d+`);
  return url ? urlPattern.test(url) : false;
}

export async function handleBeforeSend(event: SentrySdk.Event, hint?: SentrySdk.EventHint) {
  const sentryEventBuilder = new SentryEventBuilder(event, hint);
  const url = event.request?.url;
  const responseStatus = (hint as any)?.responseStatus;
  const isForbidden = (path: string) => isUrlMatchingPattern(path, url) && responseStatus === 403;

  if (isForbidden('customers') || isForbidden('invoices')) {
    return null;
  }

  try {
    await Promise.all([
      sentryEventBuilder.attachLogRocketSession(),
      sentryEventBuilder.fingerPrintError(),
    ]);
  } finally {
    return sentryEventBuilder.getFinalEvent();
  }
}
