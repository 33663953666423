import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AutoComplete, Input } from 'antd';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
interface BasicSearchComponentProps {
  placeholder?: string;
  searchCallBack: (search: string) => void;
  style?: React.CSSProperties;
  shouldDebounce?: boolean;
  shouldReset: boolean;
  onClearCallBack?: () => void;
  allowClear?: boolean;
  contentOnly?: boolean;
}

function SearchComponent(props: BasicSearchComponentProps) {
  const {
    placeholder,
    searchCallBack,
    style,
    shouldDebounce = false,
    onClearCallBack,
    allowClear,
  } = props;
  const [value, setValue] = useState<string | undefined>();

  const SearchIcon = <FontAwesomeIcon icon={['far', 'search']} color="var(--gray-9)" />;

  const ClearIcon = (
    <FontAwesomeIcon
      icon={['fal', 'times-circle']}
      color="var(--gray-7)"
      onClick={handleOnClear}
      className="cursor-pointer"
    />
  );

  function handleChange(value: string) {
    searchCallBack?.(value);
  }

  const onChange = useCallback(handleChange, [searchCallBack]);
  function handleOnClear() {
    if (value) {
      setValue(undefined);
      onClearCallBack && onClearCallBack();
    }
  }

  function handleReset() {
    if (props.shouldReset) {
      setValue(undefined);
    }
  }

  useEffect(handleReset, [props.shouldReset]);

  const debouncedSearch = useMemo(() => {
    return debounce(onChange, shouldDebounce ? 1000 : 0);
  }, [onChange, shouldDebounce]);
  return (
    <AutoComplete
      onSearch={debouncedSearch}
      onChange={(value) => {
        setValue(value);
      }}
      value={value}
    >
      <Input
        prefix={props.contentOnly ? undefined : SearchIcon}
        placeholder={placeholder}
        style={style}
        value={value}
        suffix={allowClear && ClearIcon}
      />
    </AutoComplete>
  );
}

export default SearchComponent;
