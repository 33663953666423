import { useQueryClient } from '@tanstack/react-query';
import { EActivityQueryParams } from 'components/HigherOrderComponent/KeyActivitesContainer/type';
import { ActivityKeyMap, useCollectionActivityQuery } from 'queries/activites';
import { updateKeyActivities } from 'services/invoice';
import { RaiseADisputeActivity } from 'store/activity-feed/type';
import { CallLog } from 'types/activities/callLog';
import { CreditMemo } from 'types/activities/credit-memo';
import { Escalation } from 'types/activities/escalation';
import { Note } from 'types/activities/note';
import { Payment } from 'types/activities/payment';
import { PromiseToPay } from 'types/activities/promise-to-pay';
import { Task } from 'types/activities/task';
import { WriteOff } from 'types/activities/write-off';
import { useEventBus } from 'util/hooks/useCustomEventEmitter';
import useOpen from 'util/hooks/useOpen';
import { MutationFunctionProps } from '../ActivitiesForm/types';
import { useEntitySearchParams } from '../hooks/useEntitySearchParams';
import useGetActivityEditForm from '../hooks/useGetActivityEditForm';
import useGetActivityLoading from '../hooks/useGetActivityLoading';

export type CollectionActivityTypes =
  | CallLog
  | CreditMemo
  | RaiseADisputeActivity
  | Escalation
  | Note
  | Payment
  | PromiseToPay
  | Task
  | WriteOff;

export default function ActivitiesFormEdit() {
  const { entitySearchParams, removeEntitySearchParams } = useEntitySearchParams();
  const activityId =
    entitySearchParams[EActivityQueryParams.ASSOCIATED_ACTIVITY_ID] ||
    entitySearchParams[EActivityQueryParams.ACTIVITY_ID];
  const activityType =
    entitySearchParams[EActivityQueryParams.ASSOCIATED_ACTIVITY_TYPE] ||
    entitySearchParams[EActivityQueryParams.ACTIVITY_TYPE];

  const { data: activity, isLoading } = useCollectionActivityQuery<CollectionActivityTypes>({
    id: activityId,
    type: activityType,
    isListFetched: true,
  });
  const { isPageLoaded } = useGetActivityLoading();
  const queryClient = useQueryClient();
  const openModal =
    isPageLoaded &&
    activityId &&
    activityType &&
    entitySearchParams[EActivityQueryParams.EDIT] &&
    !isLoading;

  const { open, toggleOpen } = useOpen({ open: openModal });
  const { emitEvent } = useEventBus();

  const queryKey = ['collection_activity', Number(activityId), activityType];

  function onClose() {
    toggleOpen();
    removeEntitySearchParams([EActivityQueryParams.EDIT]);
  }

  function onMutateFn<ActivityMutationProps>(data: MutationFunctionProps<ActivityMutationProps>) {
    return updateKeyActivities<ActivityMutationProps>(
      data.params,
      ActivityKeyMap[data.type],
      activityId
    ).then(async (data) => {
      await queryClient.invalidateQueries({ queryKey });
      emitEvent({
        event: 'updatedActivity',
        data: {
          data: queryClient.getQueryData<CollectionActivityTypes>(queryKey),
          enabled: !entitySearchParams[EActivityQueryParams.QUERY_KEY],
        },
      });

      return data;
    });
  }

  const editForm = useGetActivityEditForm({
    type: activityType,
    openModal: open,
    onClose,
    activity,
    onMutateFn,
  });

  return editForm ? editForm : null;
}
