import { clone, differenceWith } from 'lodash';
import { useMemo } from 'react';
import { AppPageName } from 'router/constants/page-info';

import { useRelativeContactTypes } from 'components/Common/Contact/ContactType';
import { Emailable } from 'types/activities/email';
import { cleanArrayPredicate } from 'util/predicates';
import { ActionableEntity } from '../..';
import { useCustomerContacts, useInvoiceContacts } from '../query';
import {
  EmailGroupedOptions,
  getFormatedEmailData,
  getGroupTemplate,
  transformToGroupOptions,
} from '../util';

interface ComputeRecepientProps {
  entity?: `${ActionableEntity}`;
  customerId?: number;
  invoiceId?: number;
  isRelativeOptions?: boolean;
  page?: AppPageName;
  defaultValues?: Emailable[];
}
const noDataOption = [{ value: 'no_data', label: 'Not Available', disabled: true, type: '' }];
export function useComputeRecepient({
  entity = 'CUSTOMER',
  page,
  customerId,
  invoiceId,
  isRelativeOptions = false,
  defaultValues,
}: ComputeRecepientProps): {
  computedGroupedOptions: EmailGroupedOptions;
  customerLoading?: Boolean;
} {
  const customerQueryKey = `customer-contacts-${customerId}`;
  const invoiceQueryKey = `invoice-contacts-${invoiceId}`;

  const {
    data: customerContacts,
    isLoading: customerLoading,
    isFetching,
  } = useCustomerContacts(customerQueryKey, customerId);

  const { data: invoiceContacts } = useInvoiceContacts(invoiceQueryKey, invoiceId);
  const relativeContactOptions = useRelativeContactTypes();

  const defaultRelativeValues = useMemo(() => {
    const options = clone(relativeContactOptions);

    if (entity === ActionableEntity.CUSTOMER) {
      delete options.invoice;
    }

    return options;
  }, [entity, relativeContactOptions]);

  const groupedSingleOptions = useMemo(() => {
    const groupedOptions = getGroupTemplate();

    if (customerContacts?.length) {
      const distinctContacts = differenceWith(
        customerContacts,
        entity === 'INVOICE' ? invoiceContacts ?? [] : [],
        (cusCont, invCont) => {
          return invCont.email === cusCont.email;
        }
      );

      const cleanDefaultValues = defaultValues?.filter(cleanArrayPredicate);

      const formatedCustomerContacts = cleanDefaultValues?.length
        ? getFormatedEmailData([...distinctContacts, ...cleanDefaultValues])
        : getFormatedEmailData(distinctContacts);

      groupedOptions['CUSTOMER']['options'] = formatedCustomerContacts;
    }

    if (entity === 'INVOICE') {
      if (invoiceContacts?.length) {
        const formatedInvoiceContacts = getFormatedEmailData(invoiceContacts);
        groupedOptions['INVOICE']['options'] = formatedInvoiceContacts;
      } else {
        groupedOptions['INVOICE']['options'] = noDataOption;
      }
    }

    return groupedOptions;
  }, [customerContacts, defaultValues, entity, invoiceContacts]);

  const groupedRelativeOptions = useMemo(() => {
    const groupedOptions = getGroupTemplate();

    groupedOptions['CUSTOMER']['options'] =
      page === 'CUSTOMER_DETAILS'
        ? groupedSingleOptions['CUSTOMER']['options']
        : defaultRelativeValues['customer'] ?? [];
    groupedOptions['INVOICE']['options'] = defaultRelativeValues['invoice'] ?? [];

    return groupedOptions;
  }, [defaultRelativeValues, groupedSingleOptions, page]);

  const computedGroupedOptions = useMemo(() => {
    let options = {} as ReturnType<typeof transformToGroupOptions>;

    if (isRelativeOptions) {
      options = groupedRelativeOptions;
    } else {
      options = groupedSingleOptions as any;
    }

    return options;
  }, [groupedRelativeOptions, groupedSingleOptions, isRelativeOptions]);

  return { computedGroupedOptions, customerLoading: customerLoading && isFetching };
}
