import { useGetEmailActivityData } from 'queries/inbox';
import { IEmailActivity } from 'store/activity-feed/type';
import { EmailThreadResponse } from 'types/api/inbox/email';
import { BaseCustomer } from 'types/entities/customer';
import { processEmail } from '../utils/processEmail';
import useExtractCustomerData from './useExtractCustomerData';

export default function useGetActivityConversation(
  type: string,
  customer: BaseCustomer,
  emailId?: string
) {
  const { data, isLoading, isSuccess, ...rest } = useGetEmailActivityData<IEmailActivity>(emailId);
  const customerData = useExtractCustomerData(customer, type);

  /**
   * @param data: IEmailActivity
   * this function takes the data which is of type IEmailActivity and converts
   * it to EmailConversationData just moves the replies into a seperate array and
   * the main conversation into parent_email key.
   */
  function transformEmailData(data?: IEmailActivity): EmailThreadResponse | undefined {
    if (!data) return;

    const parent_email = processEmail(data, customerData);
    const repliesWithParticipants = (data?.replies ?? []).map((reply) => {
      return processEmail(reply, customerData);
    });

    const emailConversationData = {
      parent_email,
      replies: repliesWithParticipants,
    };

    return emailConversationData;
  }

  return {
    transformedData: transformEmailData(data),
    isLoading,
    ...rest,
  };
}
