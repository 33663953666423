import { useState } from 'react';
import { EmailView } from '../Body';
const defaultValue: EmailView = { expand: false, minimize: false };
const resetPredicate = (value: EmailView) => {
  return defaultValue;
};
const maximizePredicate = (value: EmailView): EmailView => {
  return { ...defaultValue, expand: true };
};
const minimizePredicate = (value: EmailView): EmailView => {
  return { ...defaultValue, minimize: true };
};
function useEmailView() {
  const [emailView, setEmailView] = useState<EmailView>(defaultValue);

  function onClose() {
    setEmailView(resetPredicate);
  }
  function handleMinimize() {
    setEmailView(minimizePredicate);
  }
  function handleMaximize() {
    setEmailView(maximizePredicate);
  }

  return {
    onClose,
    emailView,
    handleMinimize,
    handleMaximize,
  };
}

export default useEmailView;
