import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { take } from 'lodash';
import { EmailConversation } from 'types/activities/email';
import { ConversationDetails } from '../../ConversationDetails/ConversationDetails';
import { extractNameFromEmail } from 'util/regex';

interface PanelDescProps {
  conversation: EmailConversation;
}
function PanelDesc(props: PanelDescProps) {
  const { conversation } = props;
  const firstTo = conversation.to
    ? conversation.to.length
      ? take(conversation.to, 2)
      : null
    : null;

  const names = firstTo
    ?.map((to) => {
      const nameFromEmail = extractNameFromEmail(to.email);
      return `${to.first_name ?? nameFromEmail?.firstName} ${
        to.last_name ?? nameFromEmail?.lastName
      }`;
    })
    .join(', ');

  return (
    <Flex gap="--space-4">
      <Flex gap="--space-2">
        <GrowText>To:</GrowText>
        <GrowText>{names}</GrowText>
      </Flex>
      <ConversationDetails emailConversation={conversation} />
    </Flex>
  );
}

export { PanelDesc };
