import { Tooltip } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import DisputeButton from 'components/Common/ActivitiesForm/Dispute/DisputeButton';
import PTPButton from 'components/Common/ActivitiesForm/PromiseToPay/PTPButton';
import { DisputeFormText, P2PFormText } from 'components/Common/ActivitiesForm/text';
import {
  CustomActivityProps,
  FormItemNames,
  MutationFunctionProps,
} from 'components/Common/ActivitiesForm/types';
import { ViewInvoicePDFButton } from 'components/Common/ViewInvoiceButton';
import {
  DisputeFormActivityProps,
  DisputeResponseSchema,
  PTPFormActivityProps,
  PTPResponseSchema,
} from 'components/Common/hooks/type';
import {
  ActivitySchema,
  invoiceTransformSchema,
  ptpInvoiceTransformSchema,
} from 'components/HigherOrderComponent/KeyActivitesContainer/ActivitySchema';
import useFormCustomTransformer from 'components/HigherOrderComponent/KeyActivitesContainer/hooks/useFormCustomTransformer';
import useFormSubmitMutate from 'components/HigherOrderComponent/KeyActivitesContainer/hooks/useFormSubmitMutate';
import {
  ActivityMutationProps,
  MutationFunctionResponseType,
} from 'components/HigherOrderComponent/KeyActivitesContainer/type';
import {
  invoiceItemsFn,
  lineItemsFn,
} from 'components/HigherOrderComponent/KeyActivitesContainer/utils';
import { castArray, map } from 'lodash';
import { getAllDisputeTypes } from 'services/dispute-type';
import { getDisputeType } from 'services/invoice';
import { InvoiceBasicDetails } from 'types/entities/invoice';
import { ConversationActionProps } from './type';

function ConversationActions(props: ConversationActionProps) {
  const { invoices, customerId, customerCurrency } = props;

  const { onActivityFormTransform, onActivityAssociatedFormTransform } = useFormCustomTransformer();
  const onFormMutateFn = useFormSubmitMutate();

  const lineItemQueries = lineItemsFn(customerId);
  const invoiceItemQueries = invoiceItemsFn<InvoiceBasicDetails>(customerId);

  const pdfInvoices = map(invoices ?? [], (f) => {
    return { invoiceNumber: f.invoice_no, invoiceId: f.id };
  });

  function customTransformer(data: Record<FormItemNames, any>, customProps?: CustomActivityProps) {
    if (!customProps) return {};

    const { associatedData, parentType } = customProps;

    if (!associatedData && !parentType) {
      return onActivityFormTransform({
        data,
        customProps,
      });
    } else if (associatedData && parentType) {
      return onActivityAssociatedFormTransform({
        data,
        customProps,
        customerId: castArray(customerId),
      });
    }

    return {};
  }

  function onMutateFn(data: MutationFunctionProps<ActivityMutationProps>) {
    return onFormMutateFn(data);
  }

  const Dispute = (
    <Tooltip title="Raise a Dispute">
      <DisputeButton<DisputeFormActivityProps<InvoiceBasicDetails>>
        invoices={invoices}
        text={DisputeFormText}
        responseSchema={ActivitySchema.DISPUTE}
        taskRemainderResponseSchema={ActivitySchema.TASK_REMAINDER}
        invoiceTransformSchema={invoiceTransformSchema}
        disputeTypesearchQueryFn={(searchTerm: string) => getDisputeType(searchTerm)}
        disputeTypeQueryKey={['get-all-dispute-types', customerId]}
        disputeTypeQueryFn={() => getAllDisputeTypes()}
        buttonConfig={{
          hidetext: true,
        }}
        customTransformer={customTransformer}
        onMutateFn={onMutateFn as MutationFunctionResponseType<DisputeResponseSchema>}
        {...lineItemQueries}
        {...invoiceItemQueries}
      />
    </Tooltip>
  );
  const Ptp = (
    <Tooltip title="Promise to pay">
      <PTPButton<PTPFormActivityProps<InvoiceBasicDetails>>
        invoices={invoices}
        text={P2PFormText}
        responseSchema={ActivitySchema.PROMISE_TO_PAY}
        customerCurrency={customerCurrency!}
        invoiceTransformSchema={ptpInvoiceTransformSchema}
        taskRemainderResponseSchema={ActivitySchema.TASK_REMAINDER}
        buttonConfig={{
          hidetext: true,
        }}
        customTransformer={customTransformer}
        onMutateFn={onMutateFn as MutationFunctionResponseType<PTPResponseSchema>}
        {...invoiceItemQueries}
      />
    </Tooltip>
  );

  return (
    <Flex gap="var(--space-8)">
      {Dispute}
      {Ptp}
      {pdfInvoices.length ? <ViewInvoicePDFButton invoices={pdfInvoices} /> : null}
    </Flex>
  );
}

export { ConversationActions };
