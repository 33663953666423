import { InputLoader } from 'components/Common/FilterComponents/Common/SkeletonLoader';
import { AmountWithCurrencySplit } from 'components/Common/MultiCurrency';
import DashboardCardTemplate from 'pages/Dashboard/AdminDashboard/SnapShot/CollectionBalanceAndOutstanding/CardTemplate';
import React from 'react';
import { TotalOutstandingResponse } from 'types/api/admin-dashboard';
import TotalCustomersInvoices from '../TotalCustomersInvoices';
import { dashboardTexts } from '../texts';

const componentTexts = dashboardTexts.overdueTile;

interface OverdueTileProps {
  loading: boolean;
  totalOutstanding?: TotalOutstandingResponse;
  style?: React.CSSProperties;
}
function OverdueTile({ loading, totalOutstanding, style }: OverdueTileProps) {
  const overDue = totalOutstanding?.over_due;
  const invoiceCount = totalOutstanding?.over_due.invoice_count;
  const InputLoaderJSX = <InputLoader active={loading} />;
  const OverDueAmountJSX = overDue && (
    <AmountWithCurrencySplit
      type="multi"
      amountWithCurrencySplit={overDue.amount}
      className="text-20 semi-bold"
      showTooltip
      context={componentTexts.title}
      formatOptions={{ maximumFractionDigits: 0 }}
    />
  );

  const TotalCustInvoiceInfo = (
    <TotalCustomersInvoices loading={loading} invoicesCount={invoiceCount} showCustomer={false} />
  );

  const AmountInfo = <>{loading ? InputLoaderJSX : OverDueAmountJSX}</>;
  return (
    <DashboardCardTemplate
      title={componentTexts.title}
      tagColor=" var(--volcano-3)"
      amountInfo={AmountInfo}
      customerInvoiceCount={TotalCustInvoiceInfo}
    />
  );
}

export default React.memo(OverdueTile);
