import { useQuery } from '@tanstack/react-query';
import { getCustomerEmailRecepients } from 'services/customers';
import { getInvoiceEmailRecepients } from 'services/invoice';

export function useCustomerContacts(customerQueryKey: string, customerId?: number) {
  return useQuery({
    queryKey: [customerQueryKey, customerId],
    retry: false,
    enabled: !!customerId,
    queryFn: () => (customerId ? getCustomerEmailRecepients(customerId) : []),
    cacheTime: 0,
    staleTime: 0,
  });
}

export function useInvoiceContacts(invoiceQueryKey: string, invoiceId?: number) {
  return useQuery({
    queryKey: [invoiceQueryKey],
    retry: false,
    enabled: !!invoiceId,
    queryFn: () => (invoiceId ? getInvoiceEmailRecepients(invoiceId) : []),
  });
}
