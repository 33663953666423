import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GrowTypography } from '@sinecycle/growcomponents';
import { Tooltip } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { InputLoader } from 'components/Common/FilterComponents/Common/SkeletonLoader';
import { NumberFormat } from 'components/Common/NumberFormat/NumberFormat';
import { ReactNode } from 'react';

interface TotatalCustomersInvoicesProps {
  customerLabel?: ReactNode;
  invoiceLabel?: ReactNode;
  customersCount?: number;
  invoicesCount?: number;
  showCustomer?: boolean;
  showInvoice?: boolean;
  loading?: boolean;
}
const CustomerIcon = <FontAwesomeIcon icon={['far', 'briefcase']} color={'var(--gray-7)'} />;
const InvoiceIcon = <FontAwesomeIcon icon={['far', 'file-invoice']} color={'var(--geekblue-4)'} />;
const t = {
  total_customers: 'Total Customers',
  total_invoices: 'Total Invoices',
};

function TotalCustomersInvoices(props: TotatalCustomersInvoicesProps) {
  const {
    customersCount = 0,
    invoicesCount = 0,
    showCustomer = true,
    showInvoice = true,
    loading,
  } = props;

  const InputLoaderJSX = (
    <InputLoader active={loading} style={{ minWidth: 'unset', width: '20px' }} />
  );
  const CustomerCount = (
    <GrowTypography.Text strong>
      {loading ? InputLoaderJSX : <NumberFormat value={customersCount} />}
    </GrowTypography.Text>
  );
  const CustomerInfo = (
    <Tooltip title={t.total_customers}>
      <Flex gap="var(--space-4)" align="center">
        {CustomerIcon}
        {CustomerCount}
      </Flex>
    </Tooltip>
  );
  const InvoiceCount = (
    <GrowTypography.Text strong>
      {loading ? InputLoaderJSX : <NumberFormat value={invoicesCount} />}
    </GrowTypography.Text>
  );
  const InvoiceInfo = (
    <Tooltip title={t.total_invoices}>
      <Flex gap="var(--space-4)" align="center">
        {InvoiceIcon}
        {InvoiceCount}
      </Flex>
    </Tooltip>
  );
  return (
    <Flex className="total-count-container" gap="var(--space-16)" align="center">
      {showCustomer && CustomerInfo}
      {showInvoice && InvoiceInfo}
    </Flex>
  );
}

export default TotalCustomersInvoices;
