import { QueryKey } from '@tanstack/react-query';
import { Alert } from 'antd';
import { GrowCollapse } from 'components/BaseComponents/Collapse/Antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { LabelBlock } from 'components/CollectionActivities/Common/Details/BodyBlocks';
import { emailSchema } from 'components/HigherOrderComponent/KeyActivitesContainer/Email/validation';
import EmailFormWrapper from 'components/Inbox/EmailConversations/EmailForm/EmailFormWrapper';
import { EmailConversationFooter } from 'components/Inbox/EmailConversations/EmailThread/Footer';
import { EmailFormType } from 'components/Inbox/EmailConversations/EmailThread/type';
import { defaultNewEmailValues } from 'components/Inbox/EmailConversations/EmailThread/utils';
import { useEmailSearchParams } from 'components/Inbox/EmailConversations/hooks';
import { useHookForm } from 'hooks/use-hook-form';
import { EmailEditorStyles } from 'lib/RichText';
import { Style } from 'lib/style/style';
import { useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { EmailConversation } from 'types/activities/email';
import { EmailThreadResponse, InboxEmailSearchParam } from 'types/api/inbox/email';
import useOpen from 'util/hooks/useOpen';
import { Attachments } from './Attachments';
import PanelHeader from './Panel/PanelHeader';
import useEmailView from './hooks/useEmailView';
import { StyledCollapse } from './style';
const { Panel } = GrowCollapse;
interface BodyProps {
  conversation: EmailConversation;
  defaultOpen: boolean;
  data: EmailThreadResponse;
  queryKey?: QueryKey;
}
//commenting out this for revisit
// const MAX_CONTENT_LENGTH = 350;
export type EmailViewKey = 'expand' | 'minimize';
export type EmailView = Record<EmailViewKey, boolean>;

const t = {
  info: "You're currently editing in a another window",
};

const StyledWrapper = Style('div', {
  variants: {
    modal: {
      true: {
        position: 'fixed',
        zIndex: 1000,
        overflow: 'auto',
        inset: '0px',

        '.email-form-footer': {
          display: 'flex',
        },
        '.email-form-body': {
          display: 'flex',
        },

        '.email-mask': {
          height: '100%',
          width: '100%',
          background: 'rgba(0, 0, 0, 0.53)',
          position: 'fixed',
          zIndex: '-2',
        },
      },
    },
    minimize: {
      true: {
        top: 'unset !important',
        position: 'fixed',
        right: '0',
        bottom: '0',
        zIndex: '1000',
        width: '480px',
        '.email-form-footer': {
          display: 'none',
        },
        '.email-form-body': {
          display: 'none',
        },
        '.email-mask': {
          display: 'none',
        },
      },
    },
  },
});

function Body(props: BodyProps) {
  const { conversation, defaultOpen, data, queryKey } = props;
  const [emailFormType, setEmailFormType] = useState<EmailFormType>();
  const { open, handleOpenChange } = useOpen({ open: defaultOpen });
  const { emailSearchParams, removeEmailSearchParams } = useEmailSearchParams();
  // const { open: expansionOpen, toggleOpen } = useOpen({ open: false });
  const { emailView, handleMaximize, handleMinimize, onClose } = useEmailView();
  const formMethods = useHookForm(emailSchema, defaultNewEmailValues);
  const isExpand = emailView.expand || emailView.minimize;

  //commenting out for next release
  // const expand = extractCurrentReply(conversation.body ?? '');

  const Attachment = conversation.attachments.length ? (
    <Flex direction={'column'} gap="var(--space-12)" style={{ marginTop: 'var(--space-16)' }}>
      <LabelBlock label={'Attachments'} />
      <Flex wrap={'wrap'} gap="var(--space-12)">
        <Attachments attachments={conversation.attachments} hideTitle={true} />
      </Flex>
    </Flex>
  ) : null;

  //commenting out for next release
  // const ExpansionArea = (
  //   <HideWrapper hide={!expand?.length}>
  //     <Expansion onClick={toggleOpen} open={expansionOpen} />
  //   </HideWrapper>
  // );
  // const GFQuote = (
  //   <Flex vertical gap="var(--space-16)">
  //     <EmailEditorStyles
  //       dangerouslySetInnerHTML={{
  //         __html: extractCurrentReply(conversation.body ?? '') ?? conversation.body ?? '',
  //       }}
  //       style={{ overflowX: 'auto' }}
  //     />
  //   </Flex>
  // );

  // const LargeContent = (
  //   <Flex vertical gap="var(--space-16)">
  //     {ExpansionArea}
  //     <HideWrapper hide={!expansionOpen}>
  //       <EmailEditorStyles
  //         dangerouslySetInnerHTML={{ __html: conversation?.body ?? '' }}
  //         style={{ overflowX: 'auto' }}
  //       />
  //     </HideWrapper>
  //   </Flex>
  // );

  function openForm(formType: EmailFormType) {
    setEmailFormType(formType);
  }

  function handleClose() {
    setEmailFormType(undefined);
    removeEmailSearchParams(['form_type']);
  }

  useEffect(() => {
    setEmailFormType(emailSearchParams[InboxEmailSearchParam.form_type]);
  }, [emailSearchParams]);

  const AlertText = <GrowText>{t.info}</GrowText>;

  return (
    <StyledCollapse
      ghost
      bordered
      defaultActiveKey={defaultOpen ? `${conversation.id}` : undefined}
      onChange={(key) => {
        handleOpenChange(Boolean(key.length));
        onClose();
        setEmailFormType(undefined);
      }}
      collapsible={defaultOpen ? 'disabled' : undefined}
    >
      <Panel
        key={`${conversation.id}`}
        header={<PanelHeader conversation={conversation} expand={open} />}
        showArrow={false}
      >
        <EmailEditorStyles
          dangerouslySetInnerHTML={{ __html: conversation?.body ?? '' }}
          style={{ overflowX: 'auto' }}
        />
        {/* {GFQuote}
        {LargeContent} */}
        {Attachment}
        {emailFormType || emailSearchParams[InboxEmailSearchParam.form_type] ? (
          <>
            <StyledWrapper modal={emailView.expand} minimize={emailView.minimize}>
              {isExpand ? <div className="email-mask cursor-pointer" onClick={onClose} /> : null}
              <FormProvider {...formMethods}>
                <EmailFormWrapper
                  emailView={emailView}
                  currentThreadId={conversation.id}
                  emailFormType={emailFormType}
                  onClose={isExpand ? onClose : handleClose}
                  handleMaximize={handleMaximize}
                  handleMinimize={handleMinimize}
                  emailThread={data}
                  queryKey={queryKey}
                  attachments={conversation.attachments}
                />
              </FormProvider>
            </StyledWrapper>
            {isExpand ? (
              <Alert
                type="info"
                message={AlertText}
                style={{
                  backgroundColor: 'var(--geekblue-1)',
                  border: 0,
                  marginTop: 'var(--space-8)',
                }}
              />
            ) : null}
          </>
        ) : (
          <EmailConversationFooter openForm={openForm} hideReplyAll={false} />
        )}
      </Panel>
    </StyledCollapse>
  );
}

export { Body };
