import {
  useCollectionManagersQuery,
  useCustomerContactsQuery,
  useSalesManagersQuery,
} from 'queries/users';
import { useEffect, useMemo } from 'react';
import { User } from 'types/entities/user';

type FilterValues = {
  businessUnitids?: number[];
  regionIds?: number[];
  subsidiaryIds?: number[];
};

interface FilterUsersProps {
  filterValues: FilterValues;
  categoryId?: number;
}

interface StakeHolderDetails {
  collectionOwners: User[];
  csmManagers: User[];
  accountManagers: User[];
  collectionManagers: User[];
  salesManagers: User[];
  customerContacts: User[];
}

export interface CustomEventListener extends CustomEvent<StakeHolderDetails> {}

export enum FilterCustomEvents {
  USERS_FETCHED = 'USERS_FETCHED',
}

function useFilterUsers(props: FilterUsersProps) {
  const { filterValues } = props;

  const businessUnitids = filterValues?.businessUnitids;
  const regionIds = filterValues?.regionIds;
  const subsidiaryIds = filterValues?.subsidiaryIds;

  function filterMemoCallback() {
    //improve by fetching only when it is active.
    const urlSearchParams = new URLSearchParams();

    if (businessUnitids?.length) {
      urlSearchParams.set('business_unit_ids', businessUnitids.toString());
    }

    if (regionIds?.length) {
      urlSearchParams.set('region_ids', regionIds.toString());
    }

    if (subsidiaryIds?.length) {
      urlSearchParams.set('subsidiary_ids', subsidiaryIds.toString());
    }

    return urlSearchParams;
  }

  const searchParams = useMemo(filterMemoCallback, [businessUnitids, regionIds, subsidiaryIds]);

  const { data: collectionManagers } = useCollectionManagersQuery(searchParams);
  const { data: salesManagers } = useSalesManagersQuery(searchParams);
  const customerContactQueryKey = `customer-contacts-filters-${props.categoryId ?? ''}`;
  const { data: customerContacts } = useCustomerContactsQuery(
    customerContactQueryKey,
    props?.categoryId,
    searchParams
  );
  function handleCustomEvent() {
    const detail = {} as StakeHolderDetails;

    if (collectionManagers && Boolean(collectionManagers.length)) {
      detail.collectionManagers = collectionManagers;
    }

    if (customerContacts && Boolean(customerContacts.length)) {
      detail.customerContacts = customerContacts;
    }

    if (salesManagers && Boolean(salesManagers.length)) {
      detail.salesManagers = salesManagers;
    }

    if (Object.keys(detail).length) {
      const customEvent = new CustomEvent(FilterCustomEvents.USERS_FETCHED, {
        detail,
      });
      document.dispatchEvent(customEvent);
    }
  }

  useEffect(handleCustomEvent, [collectionManagers, customerContacts, salesManagers]);

  return [collectionManagers, salesManagers, customerContacts];
}

export default useFilterUsers;
