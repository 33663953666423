import { GrowFlex } from '@sinecycle/growcomponents';
import { Card } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { StyledTileCard } from 'components/Dashboard/styles';
import React, { ReactNode } from 'react';
import { Title } from './TileTitle';

interface CardTemplateProps {
  title: string;
  tagColor?: string;
  extra?: ReactNode;
  amountInfo?: ReactNode;
  customerInvoiceCount?: ReactNode;
  className?: string;
  layout?: React.ReactNode;
  style?: React.ComponentProps<typeof Card>['styles'];
}
export default function DashboardCardTemplate(props: CardTemplateProps) {
  const TitleWithAmount = (
    <Flex direction="column" gap="var(--space-4)">
      <Title title={props.title} extra={props.extra} tagColor={props.tagColor} />
      {props.amountInfo}
    </Flex>
  );
  return (
    <StyledTileCard>
      <Flex direction="column" gap="var(--space-20)" align="flex-start" style={{ height: '100%' }}>
        {TitleWithAmount}
        {props.customerInvoiceCount}
      </Flex>
    </StyledTileCard>
  );
}
