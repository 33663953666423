import { IConversationParticipant } from 'store/activity-feed/type';
import {
  EmailConversationParticipant,
  EmailConversationParticipantType,
} from 'types/activities/email';

/**
 * @param participantsData
 * takes in email activity conversation_participants data which is of type IConversationParticipant
 * it is seperated into each entity like From, To, CC, BCC as a seperate array
 * of objects except from is just an object and also created by is added here.
 */
export function extractParticipants<
  T extends EmailConversationParticipant | IConversationParticipant
>(participantsData: T[], type: EmailConversationParticipantType) {
  return participantsData.filter((participant) => participant.type === type);
}
