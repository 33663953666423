import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { AnyObjectSchema } from 'yup';

type DefaultValues = { [x: string]: {} | undefined };

export function useHookForm(schema: AnyObjectSchema, defaultValues: DefaultValues) {
  const defaultValuesRef = useRef(defaultValues);

  // Form Related Code START (nice to abstract it into a hook)
  const formMethods = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });
  const { reset, unregister } = formMethods;

  useEffect(() => {
    const defaultValues = defaultValuesRef.current;
    if (defaultValues) {
      const fieldNames = Object.keys(defaultValues) as string[];
      unregister(fieldNames, { keepDefaultValue: true });
    }
  }, [unregister]);

  // to change the values upon editing different users, should run after unregister
  useEffect(() => {
    const defaultValues = defaultValuesRef.current;
    if (defaultValues) {
      reset(defaultValues);
    }
  }, [reset]);

  // Form Related Code END
  return formMethods;
}
