import { CellRenderProps } from '@sinecycle/growcomponents';
import { SubsidiaryList } from 'components/Common/Subsidiary/SubsidiaryList';
import { Subsidiary } from 'types/entities/subsidiary';

function SubsidiaryCell<T>(props: CellRenderProps<T, Subsidiary[], unknown>) {
  const { value } = props;
  return <SubsidiaryList subsidiary={value} emptyPlaceholder={'-'} />;
}

export default SubsidiaryCell;
