import { Col, Row } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import DashboardLoader from '../Loader';

function SnapShotSkeleton() {
  const Row1 = (
    <Row gutter={16}>
      <Col span={8}>
        <DashboardLoader />
      </Col>
      <Col span={8}>
        <DashboardLoader />
      </Col>
      <Col span={8}>
        <DashboardLoader />
      </Col>
    </Row>
  );
  const Row2 = (
    <Row gutter={16}>
      <Col span={6}>
        <DashboardLoader />
      </Col>
      <Col span={18}>
        <DashboardLoader />
      </Col>
    </Row>
  );
  const Row3 = (
    <Row gutter={[16, 16]}>
      <Col span={8}>
        <DashboardLoader />
      </Col>
      <Col span={8}>
        <DashboardLoader />
      </Col>
      <Col span={8}>
        <DashboardLoader />
      </Col>
    </Row>
  );
  return (
    <Flex direction="column" gap="var(--space-16)">
      {Row1}
      {Row2}
      {Row3}
      {Row3}
    </Flex>
  );
}

export default SnapShotSkeleton;
