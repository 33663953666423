import { GrowTypography } from '@sinecycle/growcomponents';
import { Col, Row } from 'antd';
import { sanitizeBodyAndSubject } from 'components/BaseComponents/RTE/util/sanitizeSubject';
import { CustomerAndInvoice } from 'components/Inbox/CollectionActivitiesV2/Activities/CustomerAndInvoice';
import { ToggleActivityReadButton } from 'components/Inbox/CollectionActivitiesV2/Activities/ToggleActivityReadButton';
import { ActivityType } from 'types/activities/activity-types';
import { EmailThread } from 'types/activities/email';
import ActivityCardActions from '../Common/Card/ActivityCardActions';
import ActivityCardHeader from '../Common/Card/ActivityCardHeader';
import { LatestActivityFooter } from '../Common/LatestActivity';
import { InboxActivityCardProps } from '../types';

const t = {
  email: 'Email',
};

interface EmailCardProps extends InboxActivityCardProps<ActivityType.EMAIL> {}
const EmailCardHeader = (props: EmailCardProps) => {
  const { email, viewMode, activitiesMode, activityBg } = props;

  return (
    <ActivityCardHeader>
      <div className="tw-flex tw-items-center tw-gap-4">
        <ToggleActivityReadButton
          activity={email}
          viewMode={viewMode}
          hideDot={activitiesMode}
          css={activityBg}
        />
        <GrowTypography.Text strong style={{ color: 'var(--gray-8)' }}>
          {t.email}
        </GrowTypography.Text>
      </div>
      <ActivityCardActions isEmail activity={email} />
    </ActivityCardHeader>
  );
};
const EmailCardBody = (props: EmailCardProps) => {
  const Subject = (
    <GrowTypography.Text strong fs="16" ellipsis>
      {sanitizeBodyAndSubject(props.email.title)}
    </GrowTypography.Text>
  );
  const Body = (
    <GrowTypography.Text ellipsis fs="12">
      {sanitizeBodyAndSubject(props.email.description)}
    </GrowTypography.Text>
  );
  const Invoices = <CustomerAndInvoice activitiesMode invoices={props.email.invoices} />;
  return (
    <Row gutter={[0, 6]}>
      <Col span={24}> {Subject}</Col>
      <Col span={24}> {Body}</Col>
      <Col span={24}> {Invoices}</Col>
    </Row>
  );
};

function EmailCardFooter(props: EmailCardProps) {
  const { email } = props;
  return <LatestActivityFooter latestActivity={email.latest_activity} />;
}

export { EmailCardHeader, EmailCardFooter, EmailCardBody };
