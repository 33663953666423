import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { usePrivilegeStatus } from 'components/HigherOrderComponent/Privileged';
import { useMemo } from 'react';
import { pagePath, pagePrivileges } from 'router/constants/page-info';
import { AppSubRoute } from '../sidebar.components';
import { PageListItems } from './usePageList';

function useCustomersRoute() {
  const hasAllCustomer = usePrivilegeStatus(pagePrivileges('ALL_CUSTOMERS'), {
    shallowCheck: true,
  });
  const hasARAging = usePrivilegeStatus(pagePrivileges('AR_AGING'), { shallowCheck: true });
  function handleCustomersSubLink() {
    const subLinks: AppSubRoute[] = [];
    if (hasARAging) subLinks.push({ label: 'AR Aging', url: pagePath('AR_AGING') });
    if (hasAllCustomer) subLinks.push({ label: 'All Customers', url: pagePath('ALL_CUSTOMERS') });

    return subLinks;
  }

  const customersSubLinks: AppSubRoute[] = useMemo(handleCustomersSubLink, [
    hasARAging,
    hasAllCustomer,
  ]);
  const hasCustomers = usePrivilegeStatus(
    [...pagePrivileges('AR_AGING'), ...pagePrivileges('ALL_CUSTOMERS')],
    {
      shallowCheck: true,
    }
  );

  const customerPageList: Array<PageListItems> = [
    {
      key: 'ALL_CUSTOMERS',
      subLinks: customersSubLinks,
      label: 'Customers',
      access: hasCustomers,
      icon: (
        <FontAwesomeIcon
          icon={['far', 'briefcase-blank']}
          color="var(--gray-1)"
          fontSize={20}
          size="lg"
        />
      ),
    },
  ];
  return { customerPageList };
}

export default useCustomersRoute;
