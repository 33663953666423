import { Attachment } from 'types/common/attachments';
import { SourceApp } from 'types/common/source-app';
import { User } from 'types/entities/user';

export enum ExternalMappingEventType {
  SALESFORCE = 'SALESFORCE',
}

export interface Commento {
  id: number;
  tagged_user_list: User[];
  attached_files?: string[];
  private: boolean;
  private_user_list: User[];
  created_date?: string;
  updated_date: string;
  created_by: User;
  comment_count?: number;
  comments: string;
  external_event_type: ExternalMappingEventType;
  isOpen?: boolean;
  source_app?: SourceApp;
  attachments?: Attachment[];
}
