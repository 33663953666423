import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row, Tooltip } from 'antd';
import useGetActivityActions from 'components/CollectionActivities/hooks/useGetActivityActions';
import { ActivityCardActionsProps } from 'components/CollectionActivities/types';
import {
  OPEN_TYPE,
  VIEW_TYPE,
  useEntitySearchParams,
} from 'components/Common/hooks/useEntitySearchParams';
import { EActivityQueryParams } from 'components/HigherOrderComponent/KeyActivitesContainer/type';
import { ActivityIconButton } from 'components/Inbox/CollectionActivitiesV2/Activities/style';
import { EmailConversationFooter } from 'components/Inbox/EmailConversations/EmailThread/Footer';

const CommentIcon = (
  <FontAwesomeIcon icon={['far', 'message-lines']} color="var(--primary-7)" fontSize={12} />
);

export default function ActivityCardActions(props: ActivityCardActionsProps) {
  const { activity, onStatusUpdate, extraActions, isEmail } = props;
  const ActivityActions = useGetActivityActions(activity, onStatusUpdate);
  const { setEntitySearchParams } = useEntitySearchParams();

  return (
    <>
      <Row
        gutter={[8, 8]}
        onClick={(e) => e.stopPropagation()}
        align="middle"
        className="activity-actions-update"
        wrap={false}
      >
        <Col>{ActivityActions}</Col>
        <Col>
          {isEmail ? (
            <EmailConversationFooter
              openForm={(type) => {
                setEntitySearchParams({
                  [EActivityQueryParams.FROM_TYPE]: type,
                  [EActivityQueryParams.ACTIVITY_TYPE]: activity?.entity_type,
                  [EActivityQueryParams.ACTIVITY_ID]: activity?.entity_detail.id,
                  [EActivityQueryParams.VIEW]: VIEW_TYPE.MODAL,
                });
              }}
              iconMode
            />
          ) : (
            <Tooltip title="Comment">
              <ActivityIconButton
                icon={CommentIcon}
                size="small"
                onClick={() => {
                  setEntitySearchParams({
                    [EActivityQueryParams.OPEN]: OPEN_TYPE.COMMENTS,
                    [EActivityQueryParams.ACTIVITY_TYPE]: activity?.entity_type,
                    [EActivityQueryParams.ACTIVITY_ID]: activity?.entity_detail.id,
                    [EActivityQueryParams.VIEW]: VIEW_TYPE.MODAL,
                  });
                }}
                hideBackground
              />
            </Tooltip>
          )}
        </Col>
      </Row>
      <Row className="activity-status">{extraActions}</Row>
    </>
  );
}
