import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MenuProps } from 'antd';
import { Button } from 'components/BaseComponents/Buttons/Button';
import { MenuClickEvent } from 'components/BaseComponents/Menu/Antd/type';
import { useAREmailData } from 'components/Settings/Company/AREmail/useAREmailData';
import { useCurrentUser } from 'hooks/use-current-user';
import { find, map } from 'lodash';
import { useCallback, useEffect } from 'react';
import { commonButtonProps } from '../../Toolbar';
import { DropdownButton } from '../../common/DropdownWrapper';
import { ToolbarOptionProps } from '../../type';

const t = {
  label: 'Signature',
  signature: 'Insert Signature',
  noSignature: 'No signature provided for the selected FROM email',
  mySignature: 'My Signature',
  arEmailSignature: 'AR Email Signature',
};

const EMPTY_SIGNATURES = ['<div><br></div>', '<div></br></div>'];
const Icon = <FontAwesomeIcon icon={['far', 'signature']} />;

interface SignatureProps extends ToolbarOptionProps {
  signature?: string;
}

type SignatureAlign = 'mySignature' | 'arEmailSignature';

// type SignaturePosition = { start: number; end: number };
export function Signature({ editor, signature }: SignatureProps) {
  const currentLoggedInUser = useCurrentUser();

  const { filteredList } = useAREmailData();

  // const [signaturePosition, setSignaturePosition] = useState<SignaturePosition>();
  function insertSignatureCallback(signature?: string) {
    /**
     * The below logic takes care of replacing signature just like Gmail exp
     * Disabling this for now since it doesn't work for nodes with images
     *
     *
     */

    const ts = signature ? `<div class='growfin-signature'>${signature}</div>` : `<div></div>`;
    const node = editor.view.dom.getElementsByClassName('growfin-signature');

    if (!(editor.state.doc.lastChild && Boolean(node.length))) {
      editor.chain().focus().insertContentAt(editor.state.doc.content.size, ts).run();
      return;
    }
    const startPos = editor.state.doc.content.size - editor.state.doc.lastChild.nodeSize;
    const endPos = editor.state.doc.content.size;

    editor
      .chain()
      .focus()
      .insertContentAt({ from: startPos, to: endPos }, ts, {
        parseOptions: { preserveWhitespace: true },
      })
      .run();
    return;
  }

  const insertSignature = useCallback(insertSignatureCallback, [editor]);

  useEffect(() => {
    // Inserting singature only on first time
    if (!editor) return;
    insertSignature(signature);
  }, [editor, insertSignature, signature]);

  const buttonProps: React.ComponentProps<typeof Button>['buttonProps'] = {
    ...commonButtonProps,
    icon: Icon,
  };

  const toolTipProps: React.ComponentProps<typeof Button>['toolTipProps'] = {
    title: t.label,
  };

  const CurrentSignatureMode = <Button buttonProps={buttonProps} toolTipProps={toolTipProps} />;

  function getAREmailSignatureList(): MenuProps['items'] {
    const signatureList = filteredList.filter((list) => {
      const trimmedContent = list.signature?.content?.trim();
      return (
        trimmedContent && !EMPTY_SIGNATURES.includes(trimmedContent) && Boolean(list.signature?.id)
      );
    });

    return signatureList?.length
      ? [
          {
            type: 'group',
            label: t.arEmailSignature,
            children: map(signatureList, (list) => {
              return {
                key: list.id,
                label: list.email,
              };
            }),
          },
        ]
      : [];
  }

  const arEmailList = getAREmailSignatureList();
  function onInsertCallback(value: string) {
    if (value === 'mySignature') {
      insertSignature(currentLoggedInUser?.email_signature?.content ?? '');
    } else if (filteredList) {
      const selectedSignature = find(filteredList, { id: Number(value) })?.signature?.content;
      insertSignature(selectedSignature ?? '');
    }
  }

  const alignItems: MenuProps['items'] = [
    {
      type: 'group',
      label: t.mySignature,
      children: [
        {
          key: 'mySignature',
          label: currentLoggedInUser.email,
          disabled: !currentLoggedInUser?.email_signature,
        },
      ],
    },
    ...(arEmailList ?? []),
  ];

  function handleMenuClick(event: MenuClickEvent) {
    onInsertCallback(event.key);
  }

  return (
    <DropdownButton
      menu={{ items: alignItems, onClick: handleMenuClick }}
      placement="top"
      arrow={false}
    >
      <div>{CurrentSignatureMode}</div>
    </DropdownButton>
  );
}
