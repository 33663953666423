import { GrowTypography } from '@sinecycle/growcomponents';
import { Tooltip } from 'antd';
import { InputLoader } from 'components/Common/FilterComponents/Common/SkeletonLoader';
import { InfoIcon } from 'components/Common/Icons';
import { dashboardTexts } from 'components/Dashboard/texts';
import DashboardCardTemplate from 'pages/Dashboard/AdminDashboard/SnapShot/CollectionBalanceAndOutstanding/CardTemplate';
import { DashboardComponentProps } from 'pages/Dashboard/types';
import { useSelector } from 'react-redux';
import { localeSelector } from 'store/authentication/authentication';
import { TotalOutstandingResponse } from 'types/api/admin-dashboard';
import { AmountInvoiceCountCustomerCount } from 'types/common/multi-currency';
import { getOverdueRatio } from 'util/getOverdueRatio';

interface OverdueRatioTileProps extends DashboardComponentProps {
  totalOutstanding?: TotalOutstandingResponse;
  loading: boolean;
  totalReceivablesLoading?: boolean;
  totalReceivablesAmount?: AmountInvoiceCountCustomerCount;
}

function OverdueRatio({
  totalOutstanding,
  loading,
  filterParams,
  totalReceivablesAmount,
  totalReceivablesLoading,
}: OverdueRatioTileProps) {
  const locale = useSelector(localeSelector);

  const ratio = getOverdueRatio(
    totalOutstanding?.over_due.amount.value,
    totalReceivablesAmount?.amount.value,
    locale,
    { maximumFractionDigits: 2 }
  );
  const extra = (
    <Tooltip title="Ratio of Overdue and Outstanding Receivables">
      <InfoIcon />
    </Tooltip>
  );
  const AmountInfo =
    loading || totalReceivablesLoading ? (
      <InputLoader active={loading} />
    ) : (
      <GrowTypography.Text strong fs="20">
        {ratio}
      </GrowTypography.Text>
    );
  return (
    <DashboardCardTemplate
      extra={extra}
      title={dashboardTexts.overdueRatio.title}
      amountInfo={AmountInfo}
      style={{
        body: {
          padding: 0,
        },
        cover: {
          padding: 0,
        },
      }}
    />
  );
}

export default OverdueRatio;
