import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CancelButton, SaveButton } from '@sinecycle/growcomponents';
import { Button, Row, Space } from 'antd';
import { CtaWithConfirmation } from 'components/BaseComponents/ButtonWithConfirmation';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { notify } from 'components/BaseComponents/Notifications';
import { sanitizeSubject } from 'components/BaseComponents/RTE/util/sanitizeSubject';
import SliderModal from 'components/BaseComponents/SliderModal';
import { openInvoiceStatus } from 'components/CustomerDetails/Body/Statements/OpenInvoicesStatements';
import { COMMON_EVENT } from 'events/common';
import { dispatchAppEvent } from 'lib/pub-sub';
import { head } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { UploadFileTypes, saveAttachment } from 'services/attachments';
import {
  postAgingKeyActivitiesBulk,
  postInvoiceListKeyActivitiesBulk,
  postKeyActivitiesCustomerDetailsBulk,
} from 'services/bulkaction';
import { sendEmail } from 'services/email';
import { ELastContactActivity } from 'store/activity-feed/type';
import { TFilterViews } from 'store/aging/types';
import { AsyncSaves, addToSavingHandler, clearSavingHandler } from 'store/async-fetches/fetches';
import { accountConfigSelector } from 'store/authentication/authentication';
import { InvoiceDropdownInterfaceProps } from 'store/invoice/type';
import { BaseCustomer } from 'types/entities/customer';
import { InvoiceBasicDetails } from 'types/entities/invoice';
import useDisableStatus from 'util/hooks/useDisableStatus';
import * as yup from 'yup';
import { WrappedFormProvider } from '../../GenerateForm';
import Email, { ActionableEntity, InvoiceFollowupAction } from '../Email';
import { EEmailParams } from '../Email/type';
import { validateEmailPayload } from '../Email/validation';
import PreviewDrawer from '../PreviewDrawer';
import { getKeyActivityConfig, keyActivites } from '../key-acivities-config';

enum ActivitiesArea {
  INVOICE_DETAILS_HEADER = ' INVOICE_DETAILS_HEADER',
  INVOICE_DETAILS_BODY = 'INVOICE_DETAILS_BODY',
  CUSTOMER_DETAILS_HEADER = 'CUSTOMER_DETAILS_HEADER',
  CUSTOMER_DETAILS_BODY = 'CUSTOMER_DETAILS_BODY',
}

interface IActivitiesModal {
  customerId?: number; // for bulk action in customer-details page
  customerCurrency?: string; // p2p & writeOff
  invoiceList?: InvoiceDropdownInterfaceProps[] | InvoiceBasicDetails[]; //populating selected invoices
  invoiceLineItems?: number[]; // listing lineitems
  quickAction: ELastContactActivity | undefined; //type of activity
  resetToIntialState?: () => void; //default callback
  entity?: ActionableEntity; // decide the type of bulk action to make
  customers?: BaseCustomer[]; // to operate on top of selected customer
  callback?: (activityType: ELastContactActivity, data: any) => void; //used in onsuccess callback
  showRelativeOptionsList?: boolean; //redudant information, need for removal
  context?: ActivitiesArea | string;
  selectAll?: boolean; // email and invoice bulk selection
  currentFilterView?: TFilterViews; // param required for sending email
  totalRecords?: number; // for showing total count view
  isFromInvoiceList?: boolean; // invoice list page
  invoiceId?: number; //unused
  totalCustomerRecords?: number; //looks like a similar usecase of totalrecords
  overrideEmailStyles?: React.CSSProperties;
  handleActivityBulkActionCallBack?: (
    postData: any,
    footerAction?: ELastContactActivity
  ) => Promise<void>;
  handleAllCustomersEmailBulkActionCallBack?: (postData: any) => Promise<void>;
  isCustomerLevelInvoice?: boolean;
  mask?: boolean;
  isInbox?: boolean;
  showCustomerSelect?: boolean;
  hideFrom?: boolean;
}

export function deduceActionableEntityFromUrl() {
  const currentPathValues = window.location.pathname.split('/');

  return currentPathValues.includes('customer-detail')
    ? ActionableEntity.CUSTOMER
    : ActionableEntity.INVOICE;
}

const t = {
  newEmail: 'New Email',
  send: 'Send',
  addAsTask: 'Set Reminder',
  deleteConfirm: {
    title: 'Discard Email',
    message: 'Are you sure you want to discard the message?',
  },
  task: {
    placeHolder: 'Task title',
    label: 'Task Title',
  },
  emailSucces: {
    message: 'Email Sent',
    description: 'Email was successfully Sent! ',
  },
  emailFailure: {
    message: 'Delivery Unsuccessful',
    description: 'The contents are saved in draft. Please try resending the Email :(',
  },
  showPreview: 'Show Preview',
  reminderInfo: 'Reminders can be viewed under Task Management',
};

const ActivitiesModal = (props: IActivitiesModal) => {
  const dispatch = useDispatch();
  const configList = useSelector(accountConfigSelector);
  const formMethods = useFormContext(),
    { handleSubmit, getValues, watch } = formMethods;
  const [loading, setLoading] = useState(false);

  const [previewDrawerVisible, setPreviewDrawerVisible] = useState(false);
  const [selectedInvoicesList, setSelectedInvoicesList] = useState<InvoiceDropdownInterfaceProps[]>(
    []
  );

  const customerIds = watch('customer_ids', []);

  const [invoiceFollowupAction, setInvoiceFollowupAction] = useState(
    InvoiceFollowupAction.INDIVIDUAL_INVOICE
  );

  const { disabled } = useDisableStatus();
  useEffect(() => {
    return () => {
      resetToInitialState();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setInvoiceFollowupAction(InvoiceFollowupAction.INDIVIDUAL_INVOICE);
  }, []);

  const uploadActivity = async (fileParams: { fileList?: File[]; type?: UploadFileTypes }) => {
    let uploadedIds;

    if (fileParams.fileList && fileParams.fileList.length && fileParams.type) {
      try {
        dispatch(addToSavingHandler(AsyncSaves.UPLOAD_FILE));
        uploadedIds = await saveAttachment(fileParams.fileList, fileParams.type);
      } catch (response) {
        const errResponse = await (response as Response).json();
        notify.error(errResponse.message);
      } finally {
        dispatch(clearSavingHandler(AsyncSaves.UPLOAD_FILE));
      }
    }

    return Promise.resolve(uploadedIds?.map((fileId) => fileId.id));
  };
  const setInvoiceSelectedList = (selectedInvoicesList: InvoiceDropdownInterfaceProps[]) => {
    setSelectedInvoicesList(selectedInvoicesList);
  };

  const resetToInitialState = () => {
    props.resetToIntialState && props.resetToIntialState();
    setInvoiceSelectedList([]);
  };

  const sendEmailActivity = async (paramData: any) => {
    setLoading(true);
    try {
      if (paramData?.fileParams?.fileList.length) {
        const fileUploadResponse = await uploadActivity({
          fileList: paramData?.fileParams?.fileList,
          type: paramData?.fileParams?.type,
        });

        if (fileUploadResponse && fileUploadResponse.length) {
          paramData = {
            ...paramData,
            file_upload_ids: fileUploadResponse,
          };
        } else {
          return;
        }
      }

      delete paramData?.fileParams;

      if (props.selectAll) {
        const param = {
          ...props.currentFilterView,
          status: props.currentFilterView?.status ?? openInvoiceStatus,
          size: 10,
          page: 1,
          followup_email: paramData,
          bulk_action: 'EMAIL',
        };
        if (
          (props.entity === ActionableEntity.CUSTOMER &&
            !props.handleAllCustomersEmailBulkActionCallBack) ||
          (props.entity === ActionableEntity.INVOICE &&
            !props.handleAllCustomersEmailBulkActionCallBack &&
            props.isCustomerLevelInvoice)
        ) {
          await postAgingKeyActivitiesBulk({ ...param, bulk_action: 'EMAIL' }, 'customers');
        } else if (props.handleAllCustomersEmailBulkActionCallBack) {
          props.handleAllCustomersEmailBulkActionCallBack &&
            props.handleAllCustomersEmailBulkActionCallBack(paramData).then(resetToInitialState);
        } else if (props.entity === ActionableEntity.INVOICE) {
          if (props.isFromInvoiceList) {
            await postInvoiceListKeyActivitiesBulk(param, 'invoices');
          } else {
            const parentConfig = configList?.parent_child_config;
            const invoicesBulkActionParam = { ...param, view_child: parentConfig?.view_child };
            await postKeyActivitiesCustomerDetailsBulk(
              { ...invoicesBulkActionParam, filter: props.currentFilterView },
              `customers/${props.customerId}/invoices`
            );
          }
        }
      } else {
        await sendEmail(validateEmailPayload(paramData));
      }

      resetToInitialState();
      notify.success(t.emailSucces.message, { description: t.emailSucces.description });
      setLoading(false);
      dispatchAppEvent({
        type: COMMON_EVENT.ACTION_WITHIN_COLLECTION_ACTIVITY_CREATION,
        payload: { collectionAction: 'Follow up Email', actionName: 'Send Email' },
      });

      return paramData;
    } catch (error: any) {
      notify.error(t.emailFailure.message, {
        description: t.emailFailure.description,
      });
      setLoading(false);
      return Error(error);
    }
  };

  const onInvalidCallBack = (error: any) => {
    //perform invalid case logic

    return error;
  };

  const showPreviewActivity = () => {
    !previewDrawerVisible && showPreviewDrawer();
    dispatchAppEvent({
      type: COMMON_EVENT.ACTION_WITHIN_COLLECTION_ACTIVITY_CREATION,
      payload: {
        collectionAction: 'Followup email',
        actionName: 'Show Preview',
      },
    });
  };

  const showPreviewDrawer = () => {
    setPreviewDrawerVisible(true);
  };

  const onPreviewDrawerclose = () => {
    setPreviewDrawerVisible(false);
  };

  const EmailHeader = (
    <Flex
      align="center"
      justify="space-between"
      style={{ background: 'var(--gray-2)', padding: 'var(--space-16) var(--space-24)' }}
    >
      <Flex align="center" gap="var(--space-4)">
        <Flex.Child>
          <FontAwesomeIcon icon={['far', 'envelope']} color="var(--geekblue-6)" size="lg" />
        </Flex.Child>
        <Flex.Child style={{ fontSize: 'var(--fs-16)', fontWeight: 'var(--fs-semibold)' }}>
          {t.newEmail}
        </Flex.Child>
      </Flex>
      <Flex.Child>
        <Button
          icon={<FontAwesomeIcon icon={['fal', 'times']} />}
          onClick={resetToInitialState}
          size="small"
        />
      </Flex.Child>
    </Flex>
  );

  const EmailFooter = (
    <Row justify="end" style={{ padding: 'var(--space-16) var(--space-24)' }}>
      <Space className="quick-action">
        <CtaWithConfirmation
          title={t.deleteConfirm.title}
          message={t.deleteConfirm.message}
          onConfirm={resetToInitialState}
        >
          <CancelButton />
        </CtaWithConfirmation>
        <Button onClick={handleSubmit(showPreviewActivity, onInvalidCallBack)} disabled={disabled}>
          {t.showPreview}
        </Button>
        <SaveButton
          onClick={handleSubmit(sendEmailActivity, onInvalidCallBack)}
          disabled={disabled}
          text={t.send}
          savingText="Sending..."
          loading={loading}
        />
      </Space>
    </Row>
  );
  const invoiceListForDropdown =
    selectedInvoicesList && selectedInvoicesList.length > 0
      ? selectedInvoicesList
      : props.invoiceList && props.invoiceList.length > 0
      ? props.invoiceList
      : [];

  const ModalsList = keyActivites?.map((activity, index) => {
    if (activity.key === ELastContactActivity.EMAIL) {
      return (
        <SliderModal
          key={index}
          open={props.quickAction === ELastContactActivity.EMAIL}
          destroyOnClose={true}
          width={'var(--compose-email-width)'}
          onClose={resetToInitialState}
          closeIcon={false}
          push={false}
          title={EmailHeader}
          styles={{ footer: { padding: '24px' } }}
          footer={EmailFooter}
          isEmail
          mask={props?.mask || false}
        >
          {props.quickAction === ELastContactActivity.EMAIL && (
            <Email
              invoiceList={
                Array.isArray(props.invoiceList) && props.invoiceList.length
                  ? props.invoiceList
                  : []
              }
              customerId={props.customerId}
              isNewEmail={true}
              entity={props.entity}
              customers={
                props.showCustomerSelect
                  ? [{ id: head(customerIds) } as BaseCustomer]
                  : props.customers
              }
              showRelativeOptionsList={props.showRelativeOptionsList}
              setInvoiceSelectedList={setInvoiceSelectedList}
              isFromInvoiceList={props.isFromInvoiceList}
              selectAll={props.selectAll}
              totalRecords={props.totalRecords}
              totalCustomerRecords={props.totalCustomerRecords}
              onInvoiceFollowupActionSelect={(followup) => {
                setInvoiceFollowupAction(followup);
              }}
              isCustomerLevelInvoice={props.isCustomerLevelInvoice}
              drawerClassName="new-email"
              isInbox={props?.isInbox}
              showCustomerSelect={props.showCustomerSelect}
              hideFrom={props.hideFrom}
            />
          )}
          {previewDrawerVisible && (
            <PreviewDrawer
              previewDrawerVisible={previewDrawerVisible}
              invoiceList={invoiceListForDropdown}
              customerList={
                props.showCustomerSelect
                  ? [{ id: head(customerIds) } as BaseCustomer]
                  : props.customers
              }
              emailFor={props.entity}
              to={getValues('to')}
              from={getValues('from')}
              subject={sanitizeSubject(getValues('subject'))}
              body={getValues('body')}
              onPreviewDrawerClose={onPreviewDrawerclose}
              invoiceFollowupAction={invoiceFollowupAction}
              selectAll={props.selectAll}
              totalRecords={props.totalRecords}
              isCustomerLevelInvoice={props.isCustomerLevelInvoice}
            />
          )}
        </SliderModal>
      );
    }
    return null;
  });

  return <>{ModalsList}</>;
};

const FormWrappedActivitiesModal = (props: IActivitiesModal) => {
  const currentActivityConfig = getKeyActivityConfig(props.quickAction);

  if (!props.quickAction) {
    return null;
  }

  function getSchema() {
    if (currentActivityConfig?.key === ELastContactActivity.EMAIL) {
      return props.showCustomerSelect
        ? currentActivityConfig.config?.schema.shape({
            [EEmailParams.customer_ids]: yup.array().required('this field is required'),
          })
        : currentActivityConfig?.config?.schema;
    }
    return currentActivityConfig?.config?.schema;
  }
  const schema = getSchema();

  return (
    <WrappedFormProvider schema={schema}>
      <ActivitiesModal {...props} />
    </WrappedFormProvider>
  );
};

export default React.memo(FormWrappedActivitiesModal);
