import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { usePrivilegeStatus } from 'components/HigherOrderComponent/Privileged';
import { useMemo } from 'react';
import { pagePath, pagePrivileges } from 'router/constants/page-info';
import { AppSubRoute } from '../sidebar.components';
import { PageListItems } from './usePageList';

function useCollectionsRoute() {
  const hasCollectorManagement = usePrivilegeStatus(pagePrivileges('COLLECTORS_MANAGEMENT'), {
    shallowCheck: true,
  });
  const hasCollectionActivities = usePrivilegeStatus(pagePrivileges('COLLECTION_ACTIVITIES'), {
    shallowCheck: true,
  });
  function handleCollectionsSubLink() {
    const subLinks: AppSubRoute[] = [];

    if (hasCollectorManagement) {
      subLinks.push({
        label: 'Collector Management',
        url: pagePath('COLLECTORS_MANAGEMENT'),
      });
    }

    if (hasCollectionActivities) {
      subLinks.push({
        label: 'Disputes',
        url: pagePath('COLLECTION_ACTIVITIES'),
      });
    }
    return subLinks;
  }

  const collectionsSubLinks: AppSubRoute[] = useMemo(handleCollectionsSubLink, [
    hasCollectionActivities,
    hasCollectorManagement,
  ]);
  const hasCollections = usePrivilegeStatus(
    [...pagePrivileges('COLLECTORS_MANAGEMENT'), ...pagePrivileges('COLLECTION_ACTIVITIES')],
    {
      shallowCheck: true,
    }
  );

  const collectionsPageList: Array<PageListItems> = [
    {
      key: 'COLLECTORS_MANAGEMENT',
      subLinks: collectionsSubLinks,
      label: 'Collections',
      access: hasCollections,
      icon: (
        <FontAwesomeIcon
          icon={['far', 'money-from-bracket']}
          color="var(--gray-1)"
          fontSize={20}
          size="lg"
        />
      ),
    },
  ];
  return { collectionsPageList };
}

export default useCollectionsRoute;
