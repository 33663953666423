import { BaseCustomer } from 'types/entities/customer';
import { CustomerDataInfo } from '../types';

export default function useExtractCustomerData(
  customer: BaseCustomer,
  type: string
): CustomerDataInfo | undefined {
  if (type === 'customers') {
    return {
      id: customer.id,
      name: customer.name,
    };
  } else {
    const { id, name, currency } = customer;
    return {
      id,
      name,
      currency,
    };
  }
}
