import React from 'react';

import { dashboardTexts } from '../texts';

import { InputLoader } from 'components/Common/FilterComponents/Common/SkeletonLoader';
import { AmountWithCurrencySplit } from 'components/Common/MultiCurrency';

import DashboardCardTemplate from 'pages/Dashboard/AdminDashboard/SnapShot/CollectionBalanceAndOutstanding/CardTemplate';
import { TotalOutstandingResponse } from 'types/api/admin-dashboard';
import TotalCustomersInvoices from '../TotalCustomersInvoices';

interface CurrentDueTileProps {
  loading: boolean;
  totalOutstanding?: TotalOutstandingResponse;
}

function CurrentDueTile({ loading, totalOutstanding }: CurrentDueTileProps) {
  const InputLoaderJSX = <InputLoader active={loading} />;

  const currentDue = totalOutstanding?.current_due;
  const dueAmt = currentDue?.amount;
  const invoicesCount = currentDue?.invoice_count;

  const TotalCustInvoiceInfo = (
    <TotalCustomersInvoices loading={loading} invoicesCount={invoicesCount} showCustomer={false} />
  );

  const DueAmountJSX = dueAmt && (
    <AmountWithCurrencySplit
      type="multi"
      amountWithCurrencySplit={dueAmt}
      className="amount text-20 semi-bold"
      showTooltip
      formatOptions={{ maximumFractionDigits: 0 }}
    />
  );

  const AmountInfo = <>{loading ? InputLoaderJSX : DueAmountJSX}</>;
  return (
    <DashboardCardTemplate
      title={dashboardTexts.currentDue.title}
      tagColor="var(--gold-3)"
      amountInfo={AmountInfo}
      customerInvoiceCount={TotalCustInvoiceInfo}
    />
  );
}

export default React.memo(CurrentDueTile);
