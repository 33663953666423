import { Input } from 'antd';
import DescriptionTextArea from 'components/BaseComponents/DescriptionTextArea/TextArea';
import { useFormWatch } from 'components/BaseComponents/Form/Antd/Antd';
import { EllipsisList } from 'components/Common/EllipsisList';
import { StyledFormItem } from 'components/Common/Styles/Styles';
import { ActionableEntity } from 'components/HigherOrderComponent/KeyActivitesContainer/Email';
import { filter, isEmpty, map, size, some } from 'lodash';
import AssignOwners from '../FormElements/AssignOwners';
import DisputeTypesSelect from '../FormElements/DisputeTypesSelect';
import InvoiceSelect from '../FormElements/InvoiceSelect';
import ShowLineItems from '../FormElements/ShowLineItems';
import UploadFormFiles from '../FormElements/UploadFormFiles';
import { ActivityFormItem, EllipsisListContainer } from '../style';
import { DisputeFormModalFormElementProps, DisputeFormModalProps } from '../types';

export default function DisputeFormItems<DisputeElements extends DisputeFormModalFormElementProps>(
  props: Omit<DisputeFormModalProps<DisputeElements>, 'openModal'>
) {
  const {
    title,
    notes,
    participants,
    participantConfig,
    disputeType,
    invoices,
    form,
    invoiceLineItems,
    lineItemsQueryFn,
    invoiceQueryKey,
    invoiceSearchFn,
    invoiceQueryFn,
    disputeTypeQueryFn,
    disputeTypesearchQueryFn,
    disputeTypeQueryKey,
    lineItemsQueryKey,
    invoiceTransformSchema,
    disputeTypeTransformSchema,
    lineItemsTransformSchema,
    attachments,
    text,
    bulkAction,
    bulkRecordsLength,
  } = props;
  const {
    invoiceLabel,
    invoicePlaceholder,
    disputeTypeLabel,
    disputeTypePlaceholder,
    lineItemsPlaceholder,
    titleLabel,
    titlePlaceholder,
    descriptionLabel,
    descriptionPlaceholder,
    assignedOwnersLabel,
    assignOwnersPlaceholder,
  } = text;

  const invoiceIdsList: number[] | undefined = useFormWatch('disputeInvoices', form);
  const referLineItems: boolean | undefined = useFormWatch('referLineItem', form);
  const fileList: File[] | undefined = useFormWatch('disputeFileParams', form);
  const selectedInvoiceIds = map(invoices, (item) => (item as { id: number })?.id);
  const selectedDisputeIds = map(disputeType, (item) => (item as { id: number })?.id);
  const selectedInvoiceNo = map(invoices, (item) => (item as any)?.invoice_no);
  const selectedUsers =
    filter(participants, (valueItem) =>
      some(participantConfig, (assigneeItem) => valueItem.id === assigneeItem.id)
    ) || [];

  const assigneeOptionType = selectedInvoiceIds.length
    ? ActionableEntity.INVOICE
    : ActionableEntity.CUSTOMER;

  function onChangeInvoiceList(invoiceList: number[]) {
    if (isEmpty(invoiceList) || size(invoiceList) > 1) {
      form.setFieldValue('disputeLineItems', undefined);
    }
    form.setFieldValue('disputeInvoices', invoiceList);
  }

  const InvoiceSelectList = (
    <StyledFormItem
      label={invoiceLabel}
      name={'disputeInvoices'}
      initialValue={selectedInvoiceIds}
      rules={[
        {
          required: true,
          message: `${invoiceLabel} is required`,
        },
      ]}
      required
    >
      <InvoiceSelect<DisputeElements['invoiceSelect']>
        placeholder={invoicePlaceholder}
        selectedInvoicesId={selectedInvoiceIds}
        selectedInvoices={invoices}
        onChangeInvoice={onChangeInvoiceList}
        queryFn={invoiceQueryFn}
        searchQueryFn={invoiceSearchFn}
        queryKey={invoiceQueryKey}
        transformSchema={invoiceTransformSchema}
      />
    </StyledFormItem>
  );

  const ShowLineItemsBox = (
    <ShowLineItems<DisputeElements['lineItemsSelect']>
      form={form}
      checked={referLineItems}
      disabled={invoiceIdsList && (invoiceIdsList?.length > 1 || !invoiceIdsList?.length)}
      onChange={(e) => form.setFieldValue('referLineItem', e.target.checked)}
      placeholder={lineItemsPlaceholder}
      onChangeLineItems={(value) => form.setFieldValue('disputeLineItems', value)}
      queryFn={() => lineItemsQueryFn(invoiceIdsList)}
      queryKey={lineItemsQueryKey}
      invoiceLineItems={invoiceLineItems}
      invoiceIdsList={invoiceIdsList}
      lineItemFieldKey={'disputeLineItems'}
      transformSchema={lineItemsTransformSchema}
      bulkAction={bulkAction}
    />
  );

  const DisputeSelect = (
    <ActivityFormItem
      label={disputeTypeLabel}
      name={'disputeType'}
      initialValue={selectedDisputeIds}
      rules={[
        {
          required: true,
          message: `${disputeTypeLabel} is required`,
        },
      ]}
      required
    >
      <DisputeTypesSelect<DisputeElements['disputeTypeSelect']>
        queryFn={disputeTypeQueryFn}
        queryKey={disputeTypeQueryKey}
        selectedDisputeId={selectedDisputeIds}
        selectedDisputes={disputeType}
        onChangeDisputeType={(disputeList) => {
          form.setFieldValue('disputeType', disputeList);
        }}
        placeholder={disputeTypePlaceholder}
        transformSchema={disputeTypeTransformSchema}
      />
    </ActivityFormItem>
  );

  const DisputeTitle = (
    <ActivityFormItem
      label={titleLabel}
      name="disputeTitle"
      initialValue={title}
      rules={[
        {
          required: true,
          message: `${titleLabel} is required`,
        },
      ]}
      required
    >
      <Input placeholder={titlePlaceholder} />
    </ActivityFormItem>
  );

  const DisputeDescription = (
    <ActivityFormItem label={descriptionLabel} name="disputeDescription" initialValue={notes}>
      <DescriptionTextArea placeholder={descriptionPlaceholder} />
    </ActivityFormItem>
  );

  const AssignedToDisputeOwner = (
    <ActivityFormItem
      label={assignedOwnersLabel}
      name="disputeOwner"
      initialValue={participantConfig}
    >
      <AssignOwners
        assigneeUserList={selectedUsers}
        placeholder={assignOwnersPlaceholder}
        assigneeOptionType={assigneeOptionType}
        maxTagCount={'responsive'}
      />
    </ActivityFormItem>
  );

  const UploadDisputeFile = (
    <UploadFormFiles
      form={form}
      formFieldName={'disputeFileParams'}
      value={attachments}
      selectedValues={fileList}
    />
  );

  return (
    <>
      {bulkAction ? (
        <EllipsisListContainer>
          <EllipsisList
            label="Added Invoices"
            list={map(selectedInvoiceNo, (item) => String(item))}
            totalRecords={bulkRecordsLength}
            showScrollablePopover
          />
        </EllipsisListContainer>
      ) : (
        InvoiceSelectList
      )}
      {ShowLineItemsBox}
      {DisputeSelect}
      {DisputeTitle}
      {DisputeDescription}
      {AssignedToDisputeOwner}
      {UploadDisputeFile}
    </>
  );
}
