import { useSelector } from 'react-redux';
import { scopedPlaceholdersSelector } from 'store/insert';

export function useTranslatePlaceholderToLabel() {
  const scopedPlaceholders = useSelector(scopedPlaceholdersSelector);

  function getPlaceholderLabel(placeholderValue: string) {
    const key = placeholderValue.replace(/[{}]/g, '');

    return scopedPlaceholders[key]?.displayName
      ? (scopedPlaceholders[key].displayName as string)
      : scopedPlaceholders[key]?.label
      ? scopedPlaceholders[key].label
      : key;
  }
  function getPlaceholderSection(placeholderValue: string) {
    const key = placeholderValue.replace(/[{}]/g, '');
    return scopedPlaceholders[key]?.section;
  }
  return { getPlaceholderLabel, getPlaceholderSection };
}
