import { TaskStatus } from 'types/activities/task';

export enum INBOX_EMAIL_EVENT {
  UNREAD_OR_ALL_SWITCH = 'Inbox:Email:Unread or All switch',
  PAGINATION = 'Inbox:Email:Pagination',
  RECENT_EMAILS_CLICK = 'Inbox:Email:Recent emails click',
  EMAIL_MARK_AS_READ = 'Mark as Read',
  EMAIL_MARK_AS_UNREAD = 'Mark as Unread',
  EMAIL_MARK_ALL_AS_READ = 'Mark all as Read',
  FORWARD_EMAIL = 'Forward Email',
  REPLY_EMAIL = 'Reply Email',
  REPLY_ALL_EMAIL = 'Reply All Email',
}

export enum INBOX_ASSIGNED_EVENT {
  PAGINATION = 'Inbox:Assigned:Pagination',
  UNREAD_OR_ALL_SWITCH = 'Inbox:Assigned:Unread or All switch',
  ACTIVITY_MARK_AS_READ = 'Mark as Read',
  ACTIVITY_MARK_AS_UNREAD = 'Mark as Unread',
  ACTIVITY_MARK_ALL_AS_READ = 'Mark all as Read',
  DISPUTE_STATUS = 'Dispute Status',
  TASK_STATUS = 'Task Status',
  ESCALATION_STATUS = 'Escalation Status',
  ADD_COMMENT = 'Add Comment',
}

type INBOX_EMAIL_EVENT_WITH_PROPS_PAYLOAD = {
  type: INBOX_EMAIL_EVENT.UNREAD_OR_ALL_SWITCH;
  payload: { view: 'UNREAD' | 'ALL' };
};

type INBOX_ASSIGNED_ACTIVITIES_PROPS_PAYLOAD =
  | {
      type: INBOX_ASSIGNED_EVENT.DISPUTE_STATUS;
      payload: {
        status: string;
      };
    }
  | {
      type: INBOX_ASSIGNED_EVENT.TASK_STATUS;
      payload: {
        status: TaskStatus;
      };
    }
  | {
      type: INBOX_ASSIGNED_EVENT.ADD_COMMENT;
      payload: {
        context: string;
      };
    }
  | {
      type: INBOX_ASSIGNED_EVENT.ESCALATION_STATUS;
      payload: {
        status: string;
      };
    }
  | {
      type: INBOX_ASSIGNED_EVENT.UNREAD_OR_ALL_SWITCH;
      payload: { view: 'UNREAD' | 'ALL' };
    };

type INBOX_EMAIL_EVENTS_WITH_PROPS = INBOX_EMAIL_EVENT_WITH_PROPS_PAYLOAD['type'];

type INBOX_EMAIL_EVENTS_WITHOUT_PROPS = Exclude<INBOX_EMAIL_EVENT, INBOX_EMAIL_EVENTS_WITH_PROPS>;

type INBOX_ASSIGNED_EVENTS_WITH_PROPS = INBOX_ASSIGNED_ACTIVITIES_PROPS_PAYLOAD['type'];

type INBOX_ASSIGNED_EVENTS_WITHOUT_PROPS = Exclude<
  INBOX_ASSIGNED_EVENT,
  INBOX_ASSIGNED_EVENTS_WITH_PROPS
>;

export type INBOX_EMAIL_EVENT_TYPES =
  | INBOX_EMAIL_EVENT_WITH_PROPS_PAYLOAD
  | { type: INBOX_EMAIL_EVENTS_WITHOUT_PROPS; payload?: undefined };

export type INBOX_ASSIGNED_EVENT_TYPES =
  | INBOX_ASSIGNED_ACTIVITIES_PROPS_PAYLOAD
  | { type: INBOX_ASSIGNED_EVENTS_WITHOUT_PROPS; payload?: undefined };
