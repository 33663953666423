import { Drawer, Select, Skeleton } from 'antd';
import { CloseButton } from 'components/BaseComponents/Buttons/CloseButton';
import { Texto } from 'components/BaseComponents/Typography/Texto';
import EmailPreview from 'components/HigherOrderComponent/EmailPreview';
import { EmailPreviewItem } from 'components/HigherOrderComponent/EmailPreview/types';
import { useEffect, useState } from 'react';
import { previewEmailForCustomer, previewEmailForInvoice } from 'services/email';
import { Emailable } from 'types/activities/email';

const t = {
  invoicePlaceholder: 'Select an Invoice',
  customerPlacehlder: 'Select a Customer',
  emailPreviewTitle: 'Email Preview',
  previewError: {
    error: 'Preview Unsuccessful',
    description: 'Please try again:(',
  },
};

interface ComposedEmailPreviewProps {
  previewDrawerVisible: boolean;
  onPreviewDrawerClose: () => void;
  subject?: string;
  body?: string;
  to?: Emailable[];
  from?: Emailable;
  customerId?: number;
  invoiceList?: {
    invoiceId: number;
    invoiceNumber: string;
  }[];
}

export function ComposedEmailPreview(props: ComposedEmailPreviewProps) {
  const { previewDrawerVisible, onPreviewDrawerClose, to, from, subject, body, customerId } = props;

  const initPreviewContent: EmailPreviewItem = {
    to: to ?? [],
    from: from,
    subject: subject ?? '',
    body: body ?? '',
  };

  const [previewContent, setPreviewContent] = useState<EmailPreviewItem>();
  const [selectedInvoiceId, setSelectedInvoiceId] = useState<number | undefined>(() => {
    return props.invoiceList?.length ? props.invoiceList[0].invoiceId : undefined;
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    function setContent(subject: string, body: string) {
      setPreviewContent((previewContent) => ({
        ...previewContent,
        subject,
        body,
      }));
    }

    if (selectedInvoiceId && from?.id) {
      setLoading(true);
      previewEmailForInvoice(selectedInvoiceId, subject ?? '', body ?? '', from)
        .then(({ subject, body }) => setContent(subject, body))
        .catch((e) => console.log('Error fetching preview details'))
        .finally(() => setLoading(false));
    } else if (customerId && from?.id) {
      setLoading(true);
      previewEmailForCustomer(customerId, subject ?? '', body ?? '', from)
        .then(({ subject, body }) => setContent(subject, body))
        .catch((e) => console.log('Error fetching preview details'))
        .finally(() => setLoading(false));
    }
  }, [body, customerId, from, selectedInvoiceId, subject]);

  const selectOptions = props.invoiceList?.map((invoice) => ({
    value: invoice.invoiceId,
    label: invoice.invoiceNumber,
  }));

  const shouldShowInvoiceSelect = Boolean(props.invoiceList && props.invoiceList.length > 1);

  const InvoiceSelectJSX = (
    <Select
      bordered
      value={selectedInvoiceId}
      dropdownMatchSelectWidth
      style={{ marginBottom: 'var(--space-24)', width: '100%' }}
      showSearch
      options={selectOptions}
      onChange={(value: number) => setSelectedInvoiceId(value)}
    />
  );

  const LoadingSkeleton = <Skeleton />;
  const EmailPreviewJSX = (
    <EmailPreview
      emailPreview={previewContent ?? initPreviewContent}
      users={[]}
      internalContacts={[]}
    />
  );

  return (
    <Drawer
      title={<Texto size="16">{t.emailPreviewTitle}</Texto>}
      width={'var(--compose-email-width)'}
      styles={{
        header: { backgroundColor: 'var(--gray-2)', padding: 'var(--space-12) var(--space-24)' },
      }}
      closable={false}
      onClose={onPreviewDrawerClose}
      open={previewDrawerVisible}
      extra={<CloseButton onClick={onPreviewDrawerClose} />}
    >
      {shouldShowInvoiceSelect && InvoiceSelectJSX}
      {loading ? LoadingSkeleton : EmailPreviewJSX}
    </Drawer>
  );
}
