import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { InputLoader } from 'components/Common/FilterComponents/Common/SkeletonLoader';
import { AmountWithCurrencySplit } from 'components/Common/MultiCurrency';
import { InfoPopover } from 'components/Common/UtilityPopover';
import { useConfig } from 'components/HigherOrderComponent/Config/config';
import DashboardCardTemplate from 'pages/Dashboard/AdminDashboard/SnapShot/CollectionBalanceAndOutstanding/CardTemplate';
import React from 'react';
import styled from 'styled-components';
import { TotalOutstandingResponse } from 'types/api/admin-dashboard';
import { AccountConfigKey } from 'types/entities/account';
import TotalCustomersInvoices from '../TotalCustomersInvoices';
import { dashboardTexts } from '../texts';

const componentTexts = dashboardTexts.totalOutstanding;
const StyledExtra = styled.div``;
const t = {
  totalOustanding: 'Total Outstanding',
  extraContent: 'Total Balance - ( Unapplied Credits + Unapplied Payments)',
  extraContentWhenFlagEnabled: 'Total balance of all customers',
};

function ExtraInfo() {
  const isBalanceComputed = useConfig(AccountConfigKey.SHOULD_EVALUATE_BALANCES);

  const TooltipWhenBalanceisComputed = (
    <>
      <Flex align="center" gap="var(--space-4)">
        <GrowText size="var(--fs-16)" strong>
          {t.totalOustanding}
        </GrowText>
        =
      </Flex>
      <GrowText>{t.extraContent}</GrowText>
    </>
  );

  const Content = (
    <StyledExtra className="extra-info">
      {!isBalanceComputed ? t.extraContentWhenFlagEnabled : TooltipWhenBalanceisComputed}
    </StyledExtra>
  );

  return (
    <div style={{ cursor: 'pointer' }}>
      <InfoPopover content={Content} />
    </div>
  );
}

interface TotalOutstandingTileProps {
  totalOutstanding?: TotalOutstandingResponse;
  loading: boolean;
}

function TotalOutstandingTile({ totalOutstanding, loading }: TotalOutstandingTileProps) {
  const total_outstanding = totalOutstanding?.total_outstanding;
  const customersCount = total_outstanding?.detailed_outstanding_amount?.customer_count;
  const InputLoaderJSX = <InputLoader active={loading} />;

  const OutstandingAmount = total_outstanding?.detailed_outstanding_amount?.amount ? (
    <AmountWithCurrencySplit
      type="multi"
      amountWithCurrencySplit={total_outstanding.detailed_outstanding_amount.amount}
      className="text-24 semi-bold value amount"
      showTooltip
      context={componentTexts.title}
      formatOptions={{ maximumFractionDigits: 0 }}
    />
  ) : null;

  const TotalOutstandingInformation = (
    <div className="main-card-item">{loading ? InputLoaderJSX : OutstandingAmount}</div>
  );
  const CustomerInfo = (
    <TotalCustomersInvoices loading={loading} customersCount={customersCount} showInvoice={false} />
  );
  return (
    <DashboardCardTemplate
      title={componentTexts.title}
      extra={<ExtraInfo />}
      amountInfo={TotalOutstandingInformation}
      customerInvoiceCount={CustomerInfo}
    />
  );
}

export default React.memo(TotalOutstandingTile);
