import { CheckboxRef, Form, InputNumber, Space } from 'antd';
import { RuleObject } from 'antd/lib/form';
import { TypographyText } from 'components/BaseComponents/AntTypography/AntTypography';
import { FormRules } from 'components/BaseComponents/Form/Antd/type';
import { Flex } from 'components/BaseComponents/Layout/Flex';

import { useForm } from 'antd/lib/form/Form';
import { Radio, RadioGroup } from 'components/BaseComponents/Radio';
import { GenericRadioChangeEvent } from 'components/BaseComponents/Radio/Antd/type';
import { StyledFormItem } from 'components/Common/Styles/Styles';
import HideWrapper from 'components/Common/Util/HideWrapper';
import { useScrollIntoView } from 'hooks/use-scroll-into-view';
import { Ref } from 'react';
import { dispatchErrorValidation } from '../CustomFiledRange/CustomRange';
import CustomRange from './CustomRange';

const MAX_DAY = 10000;

export interface DayRangeFilterAdditionalProps {
  type?: string;
  dayRangeOptions: DayRange[];
  showCustomRangeSelect?: boolean;
}

export type DayRanges = 'GREATER_THAN' | 'LESS_THAN' | 'IN_BETWEEN' | 'CURRENT_DUE';
export const dayRangeTypes: Array<DayRanges> = [
  'GREATER_THAN',
  'IN_BETWEEN',
  'LESS_THAN',
  'CURRENT_DUE',
];
interface DayRangeFilterProps<T> {
  label?: string;
  name: string;
  value?: T;
  onChange?: (value: T) => void;
  additionalProps?: DayRangeFilterAdditionalProps;
}
export type DayRangeValueType = {
  start?: number;
  end?: number;
  type?: string;
};

export type DayRange = {
  label?: string;
  id: string;
  value: DayRangeValueType;
};

const defaultRanges: DayRange[] = [
  {
    label: '1 - 30 Days',
    id: '0-30',
    value: {
      start: 1,
      end: 30,
      type: '30',
    },
  },
  {
    label: '31 - 60 Days',
    id: '31-60',
    value: {
      start: 31,
      end: 60,
      type: '31',
    },
  },
  {
    label: '61 - 90 Days',
    id: '61-90',
    value: {
      start: 61,
      end: 90,
      type: '61',
    },
  },
  {
    label: '> 90 Days',
    id: '>90',
    value: {
      start: 91,
      end: MAX_DAY,
      type: '90',
    },
  },
];

const t = {
  greaterThan: 'Greater than or equals',
  lessThan: 'Less than or equals',
  from: 'From',
  to: 'To',
  unassigned: 'Is unassigned',
  equalTo: 'Equals',
  Between: 'Between',
};
const fieldIds = {
  single_values: 'single_values',
  range_values: {
    from: 'from',
    to: 'to',
  },
};

function DayRangeFilter<T extends DayRangeValueType>(props: DayRangeFilterProps<T>) {
  const [form] = useForm();
  const scrollRef = useScrollIntoView<HTMLDivElement>({ alignToTop: true });
  const { onChange, value, additionalProps } = props;
  const { showCustomRangeSelect = true } = additionalProps ?? {};
  const dayRangeOptions = props.additionalProps?.dayRangeOptions ?? defaultRanges;
  function customRangeChangeEvent(event: GenericRadioChangeEvent<DayRanges>) {
    const selectedRange = event.target.value;

    form.resetFields();
    dispatchErrorValidation(false);
    switch (event.target.value) {
      case 'GREATER_THAN':
        onChange?.({
          start: 1,
          type: 'GREATER_THAN',
          end: MAX_DAY,
        } as T);
        break;
      case 'LESS_THAN':
        onChange?.({
          start: 1,
          type: 'LESS_THAN',
          end: 1,
        } as T);
        break;

      case 'IN_BETWEEN':
        onChange?.({
          start: 1,
          type: 'IN_BETWEEN',
          end: 2,
        } as T);
        break;
      default:
        const option = dayRangeOptions.find((option) => option.id === selectedRange);
        if (onChange && option) onChange(option.value as T);
        break;
    }
  }

  function singleValueValidator(_: RuleObject, inputValue: number) {
    if (!inputValue && inputValue !== 0) {
      dispatchErrorValidation(true);
      return Promise.reject('Value should not be empty');
    } else {
      dispatchErrorValidation(false);
      return Promise.resolve();
    }
  }

  const singleValueRules: FormRules = [
    {
      validator: singleValueValidator,
    },
  ];

  function handleSingleValueChange(singleValue: number | null) {
    if (value?.type === 'GREATER_THAN') {
      onChange?.({ start: singleValue, end: MAX_DAY, type: value?.type } as T);
      return;
    } else if (value?.type === 'LESS_THAN') {
      onChange?.({ start: 1, end: singleValue, type: value?.type } as T);
      return;
    }
  }

  const SingleValues = (
    <>
      <Form form={form}>
        <StyledFormItem
          fieldId={fieldIds.single_values}
          shouldUpdate
          name="radio-group"
          rules={singleValueRules}
          className="single-value"
          preserve={false}
          initialValue={value?.type === 'LESS_THAN' ? value?.end : value?.start}
        >
          <InputNumber onChange={handleSingleValueChange} controls={false} />
        </StyledFormItem>
      </Form>
    </>
  );

  const GreaterThan = (
    <Radio<DayRanges>
      value="GREATER_THAN"
      checked={value?.type === 'GREATER_THAN'}
      ref={scrollRef as unknown as Ref<CheckboxRef>}
    >
      <TypographyText>{t.greaterThan}</TypographyText>
      {value?.type === 'GREATER_THAN' && SingleValues}
    </Radio>
  );

  const LessThan = (
    <Radio<DayRanges>
      value="LESS_THAN"
      checked={value?.type === 'LESS_THAN'}
      ref={scrollRef as unknown as Ref<CheckboxRef>}
    >
      <TypographyText>{t.lessThan}</TypographyText>
      {value?.type === 'LESS_THAN' && SingleValues}
    </Radio>
  );

  const CustomRanges = (
    <Radio<DayRanges>
      value="IN_BETWEEN"
      checked={value?.type === 'IN_BETWEEN'}
      ref={scrollRef as unknown as Ref<CheckboxRef>}
    >
      <TypographyText>{t.Between}</TypographyText>
      {value?.type === 'IN_BETWEEN' && (
        <CustomRange
          value={value}
          onChange={(value) => {
            onChange?.(value as T);
          }}
        />
      )}
    </Radio>
  );

  return (
    <Flex direction="column" gap="--space-8">
      <RadioGroup
        onChange={customRangeChangeEvent}
        value={value?.type}
        style={{ height: '150px', overflowY: 'auto' }}
      >
        <Space direction="vertical">
          {dayRangeOptions.map((option) => (
            <Radio value={option.id} key={option.id}>
              {option.label}
            </Radio>
          ))}
          <HideWrapper hide={!showCustomRangeSelect}>
            <Space direction="vertical">
              {GreaterThan}
              {LessThan}
              {CustomRanges}
            </Space>
          </HideWrapper>
        </Space>
      </RadioGroup>
    </Flex>
  );
}

export { DayRangeFilter };
