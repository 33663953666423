import { Popover } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { EActivityQueryParams } from 'components/HigherOrderComponent/KeyActivitesContainer/type';
import { PageLink } from 'lib/router';
import { Style } from 'lib/style/style';
import { AmountMaybeWithMultiCurrencySplit } from 'types/common/multi-currency';
import { StatementViews } from 'types/entities/invoice';
import { InvoicesText } from './InvoicesText';

export interface InvoicesColumns {
  id: number;
  title: string;
  amount?: number | AmountMaybeWithMultiCurrencySplit;
  currency?: string;
  isAccessible?: boolean;
}
interface InvoicesColumnProps {
  invoices: InvoicesColumns[];
  title?: string;
  style?: React.CSSProperties;
  onClick?: (id: number) => void;
}
const textStyles: React.CSSProperties = {
  color: 'var(--primary-7)',
};

const StyledText = Style(GrowText, {
  '&.more-invoices': {
    '&::after ': {
      content: ',',
    },
  },
});
const t = {
  title: 'Associated Invoices',
};

function InvoicesColumn(props: InvoicesColumnProps) {
  const { invoices, title = t.title, style, onClick } = props;

  if (!invoices) return null;
  if (!invoices.length) return <GrowText>{'-'}</GrowText>;
  const [first, second, ...Rest] = invoices;
  const restTypes = Rest.length;

  const mapInvoicePredicate = (invoice: InvoicesColumns) => {
    return (
      <InvoicesText
        invoice={invoice}
        key={invoice.id}
        onClick={onClick}
        textStyle={{ title: { ellipsis: true } }}
      />
    );
  };
  const popOverContent = (
    <Flex align="flex-start" direction="column" gap="var(--space-12)">
      <GrowText strong>{title}</GrowText>
      <div
        style={{
          maxHeight: '220px',
          overflow: 'auto',
          width: '100%',
        }}
      >
        {invoices.map(mapInvoicePredicate)}
      </div>
    </Flex>
  );

  const FirstInvoice = (
    <PageLink
      appPage="INVOICE_DETAILS"
      pathParams={{ id: first.id }}
      style={{ display: 'flex', cursor: 'pointer' }}
      searchParams={
        {
          [EActivityQueryParams.CURRENT_SECTION]: StatementViews.ACTIVITIES,
          [EActivityQueryParams.CURRENT_ACTIVITY_TAB]: StatementViews.ALL,
        } as unknown as URLSearchParams
      }
    >
      <StyledText
        ellipsis={restTypes > 0 ? true : { tooltip: first.title }}
        className={second ? 'more-invoices' : ''}
        style={{
          ...textStyles,
          maxWidth: '120px',
          cursor: 'pointer',
        }}
        onClick={() => {
          onClick?.(first.id);
        }}
      >
        {first.title}
      </StyledText>
    </PageLink>
  );

  const SecondInvoice = second ? (
    <PageLink
      appPage="INVOICE_DETAILS"
      pathParams={{ id: second.id }}
      style={{ display: 'flex', cursor: 'pointer' }}
      searchParams={
        {
          [EActivityQueryParams.CURRENT_SECTION]: StatementViews.ACTIVITIES,
          [EActivityQueryParams.CURRENT_ACTIVITY_TAB]: StatementViews.ALL,
        } as unknown as URLSearchParams
      }
    >
      <GrowText
        ellipsis={restTypes > 0 ? true : { tooltip: second.title, onEllipsis(ellipsis) {} }}
        style={{
          ...textStyles,
          maxWidth: second ? '60px' : '120px',
          cursor: 'pointer',
        }}
      >
        {second.title}
      </GrowText>
    </PageLink>
  ) : null;

  const RestTypes = restTypes ? (
    <GrowText
      style={{ color: 'var(--primary-7)', cursor: 'pointer' }}
    >{`+ ${restTypes} more`}</GrowText>
  ) : null;

  return (
    <Popover
      arrow={false}
      content={popOverContent}
      placement="bottom"
      open={restTypes > 0 ? undefined : false}
    >
      <Flex
        justify="center"
        align="flex-start"
        gap="var(--space-2)"
        direction="column"
        style={{ height: '100%', ...style }}
      >
        <Flex align="center" gap="var(--space-2)">
          {FirstInvoice}
          {SecondInvoice}
        </Flex>
        {RestTypes}
      </Flex>
    </Popover>
  );
}

export { InvoicesColumn };
