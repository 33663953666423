import { DefaultOptionType } from 'antd/lib/select';
import { map } from 'lodash';
import { useState } from 'react';
import { DisputeType } from 'types/entities/dispute-type';
import transformData from 'util/transformData';
import { generateDisputeTypeOptions } from '../predicate';
import { DisputeTypeMapping, useGetDisputeTypeSearchProps } from '../types';

export default function useDisputeTypeSearch<T>(props: useGetDisputeTypeSearchProps<T>) {
  const { setOptionsMap, transformSchema, disputeData } = props;
  const [searchOptions, setSearchOptions] = useState<DefaultOptionType[]>();

  async function searchDisputeTypeList(searchTerm: string) {
    if (!searchTerm) return;

    const data = (disputeData as DisputeTypeMapping[])?.filter((dispute) =>
      dispute.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const optionsListData = transformSchema
      ? map(data, (item) => {
          return transformData<T, DisputeTypeMapping>({
            originalData: item as T,
            schemaMapping: transformSchema,
          });
        })
      : data;

    const optionsMapList = new Map(map(optionsListData, (item: DisputeType) => [item.id, item]));
    const optionsList = generateDisputeTypeOptions(optionsListData);

    setOptionsMap((prev) => {
      const mergedMap = new Map(
        prev ? [...prev.entries(), ...optionsMapList.entries()] : optionsMapList.entries()
      );
      return mergedMap;
    });
    setSearchOptions(optionsList);
  }

  return { searchDisputeTypeList, searchOptions };
}
