import { QueryKey, useQueryClient } from '@tanstack/react-query';
import { Style } from 'lib/style/style';
import { slice } from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  currentLoggedInUserSelector,
  dateFormatSelector,
} from 'store/authentication/authentication';
import { EmailThreadResponse } from 'types/api/inbox/email';
import { Attachment } from 'types/common/attachments';
import { EmailView } from '../Conversations/Body/ConversationBody/Body';
import {
  generateCcRecipients,
  generateToRecipients,
  getBodyFor,
  getSubjectFor,
} from '../EmailThread/utils';
import { EmailForm } from './EmailForm';
import { EmailFormType } from './types';

interface EmailFormWrapperProps {
  currentThreadId: number;
  emailFormType: EmailFormType | undefined;
  onClose?: () => void;
  emailView: EmailView;
  handleMaximize?: () => void;
  handleMinimize?: () => void;
  emailThread: EmailThreadResponse;
  queryKey?: QueryKey;
  attachments: Attachment[];
}

const EmailStyledWrapper = Style('div', {
  marginTop: 'var(--space-24)',
  boxShadow: 'var(--shadow-2)',
  border: '1px solid var(--gray-4)',
  borderRadius: '6px',

  variants: {
    modal: {
      true: {
        width: '80%',
        margin: '24px auto',
      },
    },
  },
});

function EmailFormWrapper(props: EmailFormWrapperProps) {
  const { currentThreadId, emailFormType, onClose, emailThread, queryKey } = props;
  const format = useSelector(dateFormatSelector);
  const formType = useMemo(() => emailFormType, [emailFormType]);
  const currentUser = useSelector(currentLoggedInUserSelector);
  const queryClient = useQueryClient();

  const contentBody = useMemo(getContentBody, [
    currentThreadId,
    emailFormType,
    emailThread,
    format,
  ]);

  function getContentBody() {
    if (emailThread?.replies.length) {
      const index = emailThread.replies.findIndex((thread) => thread.id === currentThreadId);

      if (index === -1) {
        return getBodyFor(emailFormType, { ...emailThread, replies: [] }, currentThreadId, format);
      }
      const slicedThread = slice(emailThread.replies, 0, index + 1);

      return getBodyFor(
        emailFormType,
        { ...emailThread, replies: slicedThread },
        currentThreadId,
        format
      );
    } else {
      return getBodyFor(
        emailFormType,
        emailThread,
        currentThreadId.toFixed as unknown as number,
        format
      );
    }
  }

  function handleClose() {
    onClose?.();
  }

  function onSend() {
    handleClose();
    queryClient.invalidateQueries(queryKey);
  }

  const invoicesList = emailThread?.parent_email.invoices ?? [];
  const simpleInvoicesList = invoicesList.map((invoice) => ({
    invoiceNumber: invoice.invoice_no,
    invoiceId: invoice.id,
  }));

  return (
    <>
      {formType && emailThread ? (
        <EmailStyledWrapper modal={props.emailView.expand}>
          <EmailForm
            emailView={props.emailView}
            onCancel={handleClose}
            onSend={onSend}
            parentEmailId={emailThread?.parent_email.id}
            emailType={formType}
            subject={getSubjectFor(formType, emailThread)}
            to={generateToRecipients(formType, emailThread, currentThreadId, currentUser?.email)}
            cc={generateCcRecipients(formType, emailThread, currentThreadId, currentUser?.email)}
            from={emailThread?.parent_email.from?.emailable}
            customerIds={[emailThread?.parent_email?.customer?.id as number]}
            invoiceList={simpleInvoicesList}
            body={contentBody}
            handleMaximize={props.handleMaximize}
            handleMinimize={props.handleMinimize}
            attachements={props.attachments}
          />
        </EmailStyledWrapper>
      ) : null}
    </>
  );
}

export default EmailFormWrapper;
