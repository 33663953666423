import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Popover, Tooltip } from 'antd';
import { TypographyParagraph } from 'components/BaseComponents/AntTypography/AntTypography';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { Texto } from 'components/BaseComponents/Typography/Texto';
import { NAVIGATION_EVENT } from 'events/navigation';
import { dispatchAppEvent } from 'lib/pub-sub';
import { PageLink } from 'lib/router';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { invoiceFlagListSelector } from 'store/invoice-flag/invoice-flag';
import styled from 'styled-components';
import { InvoiceBasicDetails, StatementViews } from 'types/entities/invoice';
import { TFilterViews } from '../../../store/aging/types';
import { IInvoices } from '../../../store/customer/types';
import { EActivityQueryParams } from '../KeyActivitesContainer/type';

interface GenerateInvoiceListItemsOption {
  commaSeperator?: boolean;
}

interface IInvoiceList {
  invoiceList: (IInvoices | InvoiceBasicDetails)[];
  invoiceType?: 'open' | 'closed' | 'draft';
  currentPage?: number;
  spliceLength?: number;
  invoiceFilter?: TFilterViews;
  openNewPage?: boolean;
  moreInfoOnNewLine?: boolean;
  context?: string;
  className?: string;
  titleWidth?: string;
  rowCount?: number;
}

const SInvoiceItem = styled.div`
  .invoice-item {
    color: var(--blue-4);
    font-size: var(--fs-14);
  }
`;

const t = {
  Invoices: 'Invoices',
};

export const InvoiceDetailsList = (props: IInvoiceList) => {
  const flagStatus = useSelector(invoiceFlagListSelector);
  const [spliceLength] = useState(props.spliceLength ?? 2);

  if (!props.invoiceList?.length) return null;

  const handleDispatch = () => {
    dispatchAppEvent({
      type: NAVIGATION_EVENT.NAVIGATION,
      payload: {
        to: 'Invoice Details',
        context: props.context,
      },
    });
  };

  const generateInvoiceListItems = (
    invoiceList: IInvoiceList['invoiceList'],
    options: GenerateInvoiceListItemsOption = {}
  ) => {
    return invoiceList?.map((invoice, key) => {
      return !props.moreInfoOnNewLine ? (
        <PageLink
          key={key}
          onClick={handleDispatch}
          appPage="INVOICE_DETAILS"
          pathParams={{ id: invoice.id }}
          state={{
            currentPage: props.currentPage,
            invoiceType: props.invoiceType,
            currentInvoiceId: invoice.id,
            invoiceFilter: props.invoiceFilter,
            showToggle: true,
          }}
          style={{ width: 'inherit' }}
          searchParams={
            {
              [EActivityQueryParams.CURRENT_SECTION]: StatementViews.ACTIVITIES,
              [EActivityQueryParams.CURRENT_ACTIVITY_TAB]: StatementViews.ALL,
            } as unknown as URLSearchParams
          }
        >
          <Flex align="center" gap="var(--space-4)">
            <TypographyParagraph
              style={{
                color: 'var(--purple-7)',
                maxWidth: props.titleWidth ? props.titleWidth : '140px',
                margin: 0,
              }}
              ellipsis={{
                rows: props.rowCount ? props.rowCount : 2,
                tooltip: invoice.invoice_no,
                onEllipsis(ellipsis) {},
              }}
            >
              {invoice.invoice_no}
              {options.commaSeperator && <span>{key !== invoiceList.length - 1 ? ', ' : ''}</span>}
            </TypographyParagraph>
            {flagStatus.find((inv) => inv.id === invoice.id)?.flag ? (
              <Tooltip title="Flagged">
                <FontAwesomeIcon icon={['far', 'flag']} color="var(--volcano-5)" />
              </Tooltip>
            ) : null}
          </Flex>
        </PageLink>
      ) : (
        <div className="ellipsis">
          <PageLink
            key={key}
            appPage="INVOICE_DETAILS"
            pathParams={{ id: invoice.id }}
            state={{
              currentPage: props.currentPage,
              invoiceType: props.invoiceType,
              currentInvoiceId: invoice.id,
              invoiceFilter: props.invoiceFilter,
            }}
            onClick={handleDispatch}
            searchParams={
              {
                [EActivityQueryParams.CURRENT_SECTION]: StatementViews.ACTIVITIES,
                [EActivityQueryParams.CURRENT_ACTIVITY_TAB]: StatementViews.ALL,
              } as unknown as URLSearchParams
            }
          >
            <Flex align="center" gap="var(--space-4)">
              <span className="invoice-no">{invoice.invoice_no}</span>
              {flagStatus.find((inv) => inv.id === invoice.id)?.flag ? (
                <Tooltip title="Flagged">
                  <FontAwesomeIcon icon={['far', 'flag']} color="var(--volcano-5)" />
                </Tooltip>
              ) : null}
            </Flex>
          </PageLink>
        </div>
      );
    });
  };

  const PopoverContent = () => (
    <>
      {props.moreInfoOnNewLine && (
        <div>
          <GrowText
            size={'var(--fs-12)'}
            color={'--gray-8'}
            style={{ marginBottom: `var( --space-2)` }}
          >{`${t.Invoices} (${props.invoiceList?.length - spliceLength})`}</GrowText>
        </div>
      )}
      <Flex direction="column" style={{ maxHeight: 200, overflowY: 'auto' }}>
        {generateInvoiceListItems(props.invoiceList?.slice(spliceLength, props.invoiceList.length))}
      </Flex>
    </>
  );

  const MoreInfoOnNewLine = () =>
    Boolean(props.invoiceList.length) && (
      <div className="cursor-pointer">
        <Texto size="12" color="--gray-8">{`+${
          props.invoiceList.length - spliceLength
        } more`}</Texto>
      </div>
    );

  const ShowMoreButton = () =>
    Boolean(props.invoiceList.length) && (
      <Button
        style={{
          marginLeft: 'var(--space-4)',
          fontSize: 'var(--fs-12)',
          height: 'fit-content',
          padding: '0 var(--space-4)',
        }}
      >{`+${props.invoiceList.length - spliceLength}`}</Button>
    );

  return (
    <>
      <SInvoiceItem className={`invoice-list ${props.className}`} style={{ maxWidth: '120px' }}>
        {generateInvoiceListItems(props.invoiceList?.slice(0, spliceLength), {
          commaSeperator: true,
        })}

        {props.invoiceList?.length > spliceLength && (
          <Popover placement="bottom" content={PopoverContent()} trigger="hover">
            {props.moreInfoOnNewLine ? MoreInfoOnNewLine() : ShowMoreButton()}
          </Popover>
        )}
      </SInvoiceItem>
    </>
  );
};

export default InvoiceDetailsList;
