import { StatusTagStyle } from 'components/Common/StatusCell/StatusCell';
import { EmailableType } from 'types/activities/email';

export type EmailableTagConfig = Record<
  Exclude<EmailableType, EmailableType.AR_EMAIL>,
  { label: string; style: StatusTagStyle }
>;

export const emailableTagConfig: EmailableTagConfig = {
  [EmailableType.CONTACT]: {
    label: 'Stakeholder',
    style: {
      backgroundColor: 'var(--magenta-1)',
      textColor: 'var(--magenta-8)',
    },
  },
  [EmailableType.USER]: {
    label: 'Stakeholder',
    style: {
      backgroundColor: 'var(--magenta-1)',
      textColor: 'var(--magenta-8)',
    },
  },
  [EmailableType.ACCOUNT]: {
    label: 'Customer',
    style: {
      backgroundColor: 'var(--green-1)',
      textColor: 'var(--green-8)',
    },
  },
};

export function getEmailableConfig(type: Exclude<EmailableType, EmailableType.AR_EMAIL>) {
  return emailableTagConfig[type];
}
