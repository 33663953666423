import ReceivableOutstanding from 'components/Dashboard/ReceivablesOutstanding/ReceivableOutstanding';
import TotalBalanceTile from 'components/Dashboard/TotalBalanceTile';
import TotalCollectedTile from 'components/Dashboard/TotalCollectedTile';
import { Tile } from '../TileTitle';
import { DashBoardCardProps } from './type';

export function PrimaryColumn(props: DashBoardCardProps) {
  return (
    <>
      <Tile>
        <TotalBalanceTile
          totalOutstanding={props.totalBalance}
          loading={props.totalBalanceLoading}
        />
      </Tile>
      <Tile>
        <ReceivableOutstanding
          snapshotFilterParams={props.snapshotFilterParams}
          totalReceivablesAmount={props.totalReceivablesAmount}
          totalReceivablesLoading={props.totalReceivablesLoading}
        />
      </Tile>
      <Tile>
        <TotalCollectedTile filterParams={props.snapshotFilterParams} />
      </Tile>
    </>
  );
}
