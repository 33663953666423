import { Menu } from 'antd';
import { MenuProps } from 'antd/lib';
import { SelectOption } from '../../types';

interface SingleSelectProps<T> {
  label?: string;
  name?: string;
  options?: SelectOption[];
  onChange?: (value: T) => void;
  value: T;
}
type SingleSelectCheckboxValueType = string;
function SingleSelect<T extends SingleSelectCheckboxValueType>(props: SingleSelectProps<T>) {
  const { onChange, value, options } = props;

  function getOptions(): MenuProps['items'] {
    return options?.map((option) => {
      return { key: option.value as React.Key, label: option.label, type: 'item' };
    });
  }
  const items = getOptions();

  return (
    <Menu
      items={items}
      onSelect={(info) => {
        onChange?.(info.key as unknown as T);
      }}
      selectedKeys={[value]}
      style={{ border: 0 }}
    />
  );
}

export { SingleSelect };
