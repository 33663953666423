import { CellRenderProps } from '@sinecycle/growcomponents';
import { Popover } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { EActivityQueryParams } from 'components/HigherOrderComponent/KeyActivitesContainer/type';
import { PageLink } from 'lib/router';
import { useSelector } from 'react-redux';
import { baseCurrencySelector, localeSelector } from 'store/authentication/authentication';
import styled from 'styled-components';
import { InvoiceBasicDetails, StatementViews } from 'types/entities/invoice';
import { formatAmountShort } from 'util/number-formatter';

const textStyles: React.CSSProperties = {
  fontSize: 'var(--fs-14)',
  color: 'var(--primary-7)',
};

const StyledPageLink = styled(PageLink)`
  line-height: 1.2;
  &.more-invoices {
    &::after {
      content: ',';
    }
  }
`;

export function InvoicesCell<T>(props: CellRenderProps<T>) {
  const { value } = props;
  const locale = useSelector(localeSelector);
  const baseCurrency = useSelector(baseCurrencySelector);
  const invoices = value as InvoiceBasicDetails[];
  if (!invoices || !invoices.length) return '-';
  const [first, second, ...Rest] = invoices;
  const restTypes = Rest.length;

  const mapInvoicesPredicate = (invoice: InvoiceBasicDetails) => {
    const AccessibleInvoice = (
      <PageLink appPage="INVOICE_DETAILS" pathParams={{ id: invoice.id }} target="_blank">
        <GrowText color="var(--primary-7)">{invoice.invoice_no}</GrowText>
      </PageLink>
    );
    const NonAccessibleInvoice = <GrowText>{invoice.invoice_no}</GrowText>;
    return (
      <Flex align="center" gap="--space-8" key={invoice.id}>
        {invoice.is_accessible ? AccessibleInvoice : NonAccessibleInvoice}
        <GrowText>
          {formatAmountShort(
            String(invoice.balance_due_amount_base_currency),
            baseCurrency ?? 'USD',
            locale
          )}
        </GrowText>
      </Flex>
    );
  };

  const popOverContext = (
    <Flex direction="column" align="flex-start" gap="--space-8">
      <GrowText weight="semibold">Associated Invoices</GrowText>
      {invoices.map(mapInvoicesPredicate)}
    </Flex>
  );

  return (
    <Flex>
      <Popover
        content={popOverContext}
        placement="rightTop"
        autoAdjustOverflow
        open={restTypes > 0 ? undefined : false}
        overlayClassName="ant-popover-no-arrow"
        overlayInnerStyle={{ maxHeight: '250px', overflow: 'auto' }}
      >
        <Flex align="center" gap="--space-4">
          <Flex align="center" gap="--space-2">
            <StyledPageLink
              appPage="INVOICE_DETAILS"
              pathParams={{ id: first.id }}
              target="_blank"
              className={second ? 'more-invoices' : ''}
              searchParams={
                {
                  [EActivityQueryParams.CURRENT_SECTION]: StatementViews.ACTIVITIES,
                  [EActivityQueryParams.CURRENT_ACTIVITY_TAB]: StatementViews.ALL,
                } as unknown as URLSearchParams
              }
            >
              <GrowText style={{ ...textStyles }}>{first.invoice_no}</GrowText>
            </StyledPageLink>
            &nbsp;
            {Boolean(second) && (
              <PageLink
                appPage="INVOICE_DETAILS"
                pathParams={{ id: second.id }}
                target="_blank"
                style={{ lineHeight: '1.2' }}
                searchParams={
                  {
                    [EActivityQueryParams.CURRENT_SECTION]: StatementViews.ACTIVITIES,
                    [EActivityQueryParams.CURRENT_ACTIVITY_TAB]: StatementViews.ALL,
                  } as unknown as URLSearchParams
                }
              >
                <GrowText style={{ ...textStyles }}>{second.invoice_no}</GrowText>
              </PageLink>
            )}
          </Flex>
          {restTypes > 0 && <GrowText color="var(--primary-7)">{`+ ${restTypes} more`}</GrowText>}
        </Flex>
      </Popover>
    </Flex>
  );
}
