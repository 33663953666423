import {
  ColGroupProps,
  ColProps,
  GridColumnProps,
  GridProps,
  SortOrder,
  getStoredState,
} from '@sinecycle/growcomponents';

import { usePrivilegeStatus } from 'components/HigherOrderComponent/Privileged';
import { ERolesAndPrivileges } from 'components/HigherOrderComponent/Privileged/privileges';
import { find } from 'lodash';
import { useMemo, useState } from 'react';
import {
  CustomFieldData,
  CustomFieldDataResponse,
  CustomFieldUIType,
  NumberBasedField,
} from 'types/entities/custom-field';
import CustomCellEditor from '../CustomCellEditor/CustomCellEditor';
import { getPersistedColumnGroupPredicate } from '../utils';
import { Unpacked } from 'types/utils/utils';

const BLOCKED_SORT_COLUMN: CustomFieldUIType[] = [CustomFieldUIType.TEXT_AREA];
export type CustomFiledColumn<T> = T & {
  custom_field_data?: CustomFieldData[];
};
function useCustomFieldColumns<T>(
  columnsDefs: GridProps<CustomFiledColumn<T>>['columnDefs'],
  storageKey: string,
  columnId?: string,
  sortBy?: SortOrder,
  customCol?: number
) {
  const [cfColumns, setCfColumns] = useState<GridProps<CustomFiledColumn<T>>['columnDefs']>();

  const [columnLoading, setColumnLoading] = useState<boolean>(false);
  const storedColumns = getStoredState('LOCAL', storageKey);
  const hasCustomFieldEditAccess = usePrivilegeStatus([ERolesAndPrivileges.EDIT_CUSTOM_FIELD_DATA]);
  function handleSuccessCallback(data: CustomFieldDataResponse[]) {
    if (!data?.length) {
      setColumnLoading(false);
      return;
    }

    const cfColumns: GridColumnProps<CustomFiledColumn<T>> = data.map((field) => {
      const id = `CUSTOM_FIELD_${field.id}`;
      const initialWidth = find(storedColumns, { column: id })?.width;

      const sortable = !BLOCKED_SORT_COLUMN.includes(field.ui_type);

      return {
        colId: id,
        headerName: field.display_name,
        cellEditor: CustomCellEditor<T>,
        cellStyle: {
          height: '100%',
          cursor: field.editable ? 'pointer' : 'default',
        },
        headerClass: ['NUMBER', 'DECIMAL'].includes(field.data_type as Unpacked<NumberBasedField>)
          ? 'ag-right-aligned-header'
          : undefined,
        cellClass: ['NUMBER', 'DECIMAL'].includes(field.data_type as Unpacked<NumberBasedField>)
          ? 'ag-right-aligned-cell'
          : undefined,
        suppressKeyboardEvent: (params) => true,
        initialWidth,
        sortable,
        headerComponent: 'defaultHeader',
        headerComponentParams: {
          hasSymbol: true,
          headerStyles: {
            cursor: sortable ? 'pointer' : 'default',
          },
        },
        singleClickEdit: true,
        editable: field.editable && hasCustomFieldEditAccess,
        valueGetter: (params) => {
          const customFieldData = params?.data?.custom_field_data;
          const fieldValue = customFieldData?.find?.((item) => item.id === field.id);

          return fieldValue ? fieldValue.value : '-';
        },
        valueSetter: (params) => {
          const customFieldData = params?.data?.custom_field_data;
          const fieldValue = customFieldData?.find?.((item) => item.id === field.id);
          return fieldValue ? true : false;
        },
        cellRenderer: 'customColumn',
        cellRendererParams: {
          columnCustom: {
            hasSymbol: true,
          },
          fieldData: field,
          customFieldId: field.id,
        },
        cellEditorParams: {
          fieldData: field,
        },
        comparator: () => 0,
      };
    });
    setCfColumns(cfColumns);
    setColumnLoading(false);
  }
  function handleErrorCallBack() {
    setColumnLoading(false);
  }
  function getColumns() {
    return [...(columnsDefs ?? []), ...(cfColumns ?? [])].map((col) => {
      return getPersistedColumnGroupPredicate(
        col as ColProps<CustomFiledColumn<T>> & ColGroupProps<CustomFiledColumn<T>>,
        columnId,
        sortBy,
        customCol
      );
    });
  }
  const columns = useMemo(getColumns, [cfColumns, columnId, columnsDefs, customCol, sortBy]);

  return { handleSuccessCallback, columns, columnLoading, handleErrorCallBack };
}

export { useCustomFieldColumns };
