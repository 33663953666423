import { InvoiceListTabKeys } from 'components/InvoiceListV2';
import { CUSTOMER_DETAILS_EVENT } from 'events/customer-details';
import { INVOICE_LIST_EVENT } from 'events/invoice-List';
import { dispatchAppEvent } from 'lib/pub-sub';
import { InvoiceStatus } from 'types/entities/invoice';

export const closedInvoiceStatus = [InvoiceStatus.CLOSED, InvoiceStatus.VOID];
export const handleDispatchEventPaginationClosed = (fromInvoiceList?: boolean) => {
  const eventToDispatch = fromInvoiceList
    ? INVOICE_LIST_EVENT.INVOICE_LIST_PAGINATION
    : CUSTOMER_DETAILS_EVENT.PAGINATION;

  dispatchAppEvent({
    type: eventToDispatch,
    payload: {
      tab: InvoiceListTabKeys.CLOSED_INVOICE,
    },
  });
};

export const handleDispatchEventViewPdfClosed = (fromInvoiceList?: boolean) => {
  const eventToDispatch = fromInvoiceList
    ? INVOICE_LIST_EVENT.INVOICE_LIST_VIEW_PDF
    : CUSTOMER_DETAILS_EVENT.VIEW_PDF;

  dispatchAppEvent({
    type: eventToDispatch,
    payload: {
      tab: InvoiceListTabKeys.CLOSED_INVOICE,
    },
  });
};

export const handleDispatchEventDownloadPdfClosed = (fromInvoiceList?: boolean) => {
  const eventToDispatch = fromInvoiceList
    ? INVOICE_LIST_EVENT.INVOICE_LIST_DOWNLOAD_PDF
    : CUSTOMER_DETAILS_EVENT.DOWNLOAD_PDF;
  dispatchAppEvent({
    type: eventToDispatch,
    payload: {
      tab: InvoiceListTabKeys.CLOSED_INVOICE,
    },
  });
};

export const handleDispatchEventSortingClosed = (field: string, fromInvoiceList?: boolean) => {
  const eventToDispatch = fromInvoiceList
    ? INVOICE_LIST_EVENT.INVOICE_LIST_SORTING
    : CUSTOMER_DETAILS_EVENT.SORTING;
  dispatchAppEvent({
    type: eventToDispatch,
    payload: {
      column: field,
      tab: InvoiceListTabKeys.CLOSED_INVOICE,
    },
  });
};
