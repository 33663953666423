import { BaseCustomer } from 'types/entities/customer';
import { InvoiceStatus } from 'types/entities/invoice';
import { SavedFilterViewInterface, TFilterViews } from '../aging/types';
import { IInvoicesQueryParams as InvoicesQueryParams } from '../customer/types';

export interface OpenInvoicesListFilters extends InvoicesQueryParams {
  amount_min: number;
  amount_max: number;
  followup_status: InvoiceFollowupStatus[];
  due_days_min: number;
  due_days_max: number;
  issue_date_from: string;
  issue_date_to: string;
  issue_date_range: string;
  last_activity_from: string;
  last_activity_to: string;
  last_activity_range: string;
  next_followup_from: string;
  next_followup_to: string;
  next_followup_range: string;
  promise_pay_from: string;
  promise_pay_to: string;
  promise_pay_range: string;
  filter_type: 'OPEN' | 'CLOSED';
}

export interface DraftInvoicesListFilters extends InvoicesQueryParams {
  amount_min: number;
  amount_max: number;
  followup_status: InvoiceFollowupStatus[];
  due_days_min: number;
  due_days_max: number;
  issue_date_from: string;
  issue_date_to: string;
  issue_date_range: string;
  last_activity_from: string;
  last_activity_to: string;
  last_activity_range: string;
  next_followup_from: string;
  next_followup_to: string;
  next_followup_range: string;
  promise_pay_from: string;
  promise_pay_to: string;
  promise_pay_range: string;
  filter_type: 'OPEN' | 'CLOSED' | 'DRAFT';
}

export interface ClosedInvoicesListFilters extends InvoicesQueryParams {
  amount_min: number;
  amount_max: number;
  issue_date_from: string;
  issue_date_to: string;
  issue_date_range: string;
  closed_date_from: string;
  closed_date_to: string;
  closed_date_range: string;
  filter_type: 'OPEN' | 'CLOSED';
}

export interface InvoiceListProps {
  filterViews: SavedFilterViewInterface<TFilterViews>;
  shouldTransitionToInvoiceDetails: boolean;
}

export const OpenInvoiceStatusDropDownList = [
  {
    label: 'Payment Pending',
    value: InvoiceStatus.PENDING,
  },
  {
    label: 'In Dispute',
    value: InvoiceStatus.DISPUTE,
  },
  {
    label: 'Partially Paid',
    value: InvoiceStatus.PARTIAL_PAYMENT,
  },
  {
    label: 'Fully Paid',
    value: InvoiceStatus.FULL_PAYMENT,
  },
];

export enum InvoiceFollowupStatus {
  CONTACT_MISSING = 'CONTACT_MISSING',
  INVALID_EMAIL_ID = 'INVALID_EMAIL_ID',
  EMAIL_SENT = 'EMAIL_SENT',
  EMAIL_OPENED = 'EMAIL_OPENED',
  EMAIL_BOUNCED = 'EMAIL_BOUNCED',
  VIEWED_ATTACHMENT = 'VIEWED_ATTACHMENT',
  RESPONDED = 'RESPONDED',
  PROMISE_TO_PAY = 'PROMISE_TO_PAY',
  PTP_OPEN = 'PTP_OPEN',
  PTP_CLOSED = 'PTP_CLOSED',
  ESCALATION = 'ESCALATION',
  ESCALATION_OPEN = 'ESCALATION_OPEN',
  ESCALATION_CLOSED = 'ESCALATION_CLOSED',
  OPEN = 'OPEN',
  WAITING_ON_CUSTOMER = 'WAITING_ON_CUSTOMER',
  CLOSED = 'CLOSED',
  DISPUTE_OPEN = 'DISPUTE_OPEN',
  DISPUTE_WAITING_ON_CUSTOMER = 'DISPUTE_WOC',
  DISPUTE_CLOSED = 'DISPUTE_CLOSED',
}

/* commented few statuses => https://growfin.atlassian.net/browse/GRO-567 */
export const InvoiceFollowupStatusDropDownList = [
  // {
  //   label: 'Missing Contacts',
  //   value: EInvoiceFollowupStatus.CONTACT_MISSING,
  // },
  // {
  //   label: 'Invalid Email Address',
  //   value: EInvoiceFollowupStatus.INVALID_EMAIL_ID,
  // },
  {
    label: 'Emails sent',
    value: InvoiceFollowupStatus.EMAIL_SENT,
  },
  {
    label: 'Emails opened',
    value: InvoiceFollowupStatus.EMAIL_OPENED,
  },
  {
    label: 'Emails not delievered',
    value: InvoiceFollowupStatus.EMAIL_BOUNCED,
  },
  // {
  //   label: 'Emails with viewed attachments',
  //   value: EInvoiceFollowupStatus.VIEWED_ATTACHMENT,
  // },
  {
    label: 'Response received',
    value: InvoiceFollowupStatus.RESPONDED,
  },
  {
    label: 'Promise to Pay - Open',
    value: InvoiceFollowupStatus.PTP_OPEN,
  },
  {
    label: 'Promise to Pay - Closed',
    value: InvoiceFollowupStatus.PTP_CLOSED,
  },
  {
    label: 'Escalation - Open',
    value: InvoiceFollowupStatus.ESCALATION_OPEN,
  },
  {
    label: 'Escalation - Closed',
    value: InvoiceFollowupStatus.ESCALATION_CLOSED,
  },
  {
    label: 'Dispute - Open',
    value: InvoiceFollowupStatus.DISPUTE_OPEN,
  },
  {
    label: 'Dispute - Waiting on Customer',
    value: InvoiceFollowupStatus.DISPUTE_WAITING_ON_CUSTOMER,
  },
  {
    label: 'Dispute - Closed',
    value: InvoiceFollowupStatus.DISPUTE_CLOSED,
  },
];

export interface InvoiceDropdownInterfaceProps {
  id: number;
  invoice_no: string;
  balance_due_amount?: number;
  promise_to_pay_amount?: number;
  invoice_amount?: number;
  invoice_date?: string;
  currency: string;
  invoice_title?: string;
  invoice_status: InvoiceStatus;
  customer: BaseCustomer;
  customer_id: number;
  customer_name?: string;
  balance_due_amount_base_currency?: number;
}
