import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Popover, Typography } from 'antd';
import { Flex, FlexBoxChild } from 'components/BaseComponents/Layout/Flex';

import { customerNameFormatter } from '@sinecycle/growcomponents';
import { Empty } from 'components/BaseComponents/Empty/Empty';
import { GrowText } from 'components/BaseComponents/Typography';
import { Texto } from 'components/BaseComponents/Typography/Texto';
import { InfoIconRegular } from 'components/Common/Icons/Icons';
import { Amount } from 'components/Common/MultiCurrency';
import { PageLink } from 'lib/router';
import styled from 'styled-components';
import { ConsolidatedBalance, CustomerFamily } from '.';
import TextWithHyperLink from './ParentText';
import { HierarchicalCustomer } from './type';

const { Text } = Typography;

const StyledChild = styled(Flex)`
  padding-right: var(--space-8);
  flex-direction: column;
  max-height: 350px;
  overflow: auto;
  gap: var(--space-12);
  padding-top: var(--space-8);

  ::-webkit-scrollbar {
    background-color: transparent;
    width: var(--space-4);
    height: var(--space-6);
    z-index: 999999;
  }
`;

const t = {
  relatedCustomers: 'Related customers',
  parent: 'Parent',
  child: 'Child',
  consolidatedBalance: 'Consolidated Balance',
  outstandingBalance: 'Outstanding Balance',
};

export function getParentChildStatus(self?: HierarchicalCustomer, child?: HierarchicalCustomer[]) {
  const hasNoParent = !(self && self.id);
  const hasNoChild = !(child && child.length);
  const isRootCustomer = hasNoParent && hasNoChild;

  return [hasNoParent, hasNoChild, isRootCustomer];
}

const ParentChildIcon = <FontAwesomeIcon icon={['far', 'buildings']} color="var(--cyan-6)" />;

interface DisplayAuthorisedNameProps {
  name?: string;
  id?: number;
  authorised?: boolean;
}

export function DisplayAuthorisedName({ name, id, authorised }: DisplayAuthorisedNameProps) {
  const authorisedName = customerNameFormatter({ name });
  const NameJSX = <>{authorisedName}</>;

  const Link = id && (
    <PageLink appPage={'CUSTOMER_DETAILS'} pathParams={{ id }} target="_blank">
      {NameJSX}
    </PageLink>
  );

  return <>{authorised ? Link : NameJSX}</>;
}

interface ParentChildHierarchyProps {
  showOnlyIcon?: boolean;
  customerFamily?: CustomerFamily;
}

export function ParentChildHierarchy(props: ParentChildHierarchyProps) {
  const { showOnlyIcon, customerFamily } = props;
  const parent = customerFamily?.parent;
  const children = customerFamily?.children;
  const [hasNoParent, hasNoChild, isRootCustomer] = getParentChildStatus(parent, children);

  const ParentChildButton = showOnlyIcon ? (
    ParentChildIcon
  ) : (
    <Button
      style={{
        border: '1px solid var(--gray-5)',
        padding: 'var(--space-4) var(--space-12)',
        background: 'var(--gray-2)',
      }}
    >
      {ParentChildIcon}
    </Button>
  );

  const Parent = <TextWithHyperLink hyperLinkInfo={customerFamily?.parent} />;

  const ChildItems = (
    <StyledChild>
      {children?.map((child) => {
        return (
          <div className="customer-item" key={child.id}>
            <Text ellipsis={{ tooltip: child.name }} style={{ width: '90%' }}>
              <DisplayAuthorisedName
                name={child?.name}
                id={child?.id}
                authorised={child?.authorised}
              />
            </Text>
          </div>
        );
      })}
    </StyledChild>
  );

  const Child = (
    <>
      <Texto className="title" color="var(--gray-9)" weight="semibold">
        {t.child} {!!children?.length && `(${children?.length})`}
      </Texto>
      <div className="children-list">{ChildItems}</div>
    </>
  );

  const Content = (
    <Flex className="hierarchy-container" direction="column" gap="var(--space-16)">
      <Texto className="title" color="var(--gray-8)">
        {t.relatedCustomers}
      </Texto>
      <Empty isEmpty={hasNoParent} emptyPlaceholder={<></>}>
        <div className="parent-container">{Parent}</div>
      </Empty>
      <Empty isEmpty={hasNoChild} emptyPlaceholder={<></>}>
        <div className="child-container">{Child}</div>
      </Empty>
    </Flex>
  );

  return (
    <>
      {!isRootCustomer && (
        <Popover
          content={Content}
          placement="leftTop"
          overlayStyle={{ width: '240px' }}
          align={{
            points: ['tr', 'cl'],
            offset: [-10, 0],
          }}
        >
          {ParentChildButton}
        </Popover>
      )}
    </>
  );
}

interface ParentChildConsolidatedBalanceProps {
  consolidatedBalance?: ConsolidatedBalance;
}

export function ParentChildConsolidatedBalance(props: ParentChildConsolidatedBalanceProps) {
  const { consolidatedBalance } = props;
  const totalBalance = consolidatedBalance?.total;
  const self = consolidatedBalance?.self;
  const child = consolidatedBalance?.children;
  const [hasNoParent, hasNoChild, isRootParent] = getParentChildStatus(self, child);

  const label = (
    <GrowText size="12" color="var(--gray-9)">
      {t.consolidatedBalance}
    </GrowText>
  );

  const AmountJSX = (
    <Amount
      amount={totalBalance?.value}
      currency={totalBalance?.currency}
      style={{ fontWeight: 'var(--fs-semibold)' }}
    />
  );

  const SelfBalance = (
    <Flex className="customer-item" justify="space-between">
      <FlexBoxChild style={{ overflow: 'hidden' }}>
        <Text ellipsis={{ tooltip: self?.name }} style={{ width: '90%' }}>
          <DisplayAuthorisedName name={self?.name} id={self?.id} authorised={self?.authorised} />
        </Text>
      </FlexBoxChild>
      <FlexBoxChild style={{ paddingRight: 'var(--space-12)' }}>
        <Amount amount={self?.amount?.value} currency={self?.amount.currency} />
      </FlexBoxChild>
    </Flex>
  );

  const ChildItems = (
    <>
      <StyledChild>
        {child?.map((child) => {
          return (
            <FlexBoxChild
              key={child.id}
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <Text
                ellipsis={{
                  tooltip: child?.name,
                }}
                style={{ width: '90%' }}
              >
                <DisplayAuthorisedName
                  name={child?.name}
                  id={child?.id}
                  authorised={child?.authorised}
                />
              </Text>
              <Amount amount={child?.amount?.value} currency={child?.amount.currency} />
            </FlexBoxChild>
          );
        })}
      </StyledChild>
    </>
  );

  const ChildBalance = (
    <>
      <Texto className="title" color="var(--gray-9)" weight="semibold">
        {t.child} {!!child?.length && `(${child?.length})`}
      </Texto>
      <Flex
        className="customer-item"
        gap="var(--space-12)"
        direction="column"
        style={{ width: '100%' }}
      >
        <Empty isEmpty={hasNoChild}>{ChildItems}</Empty>
      </Flex>
    </>
  );

  const Split = (
    <Flex direction="column" gap="var(--space-16)">
      <FlexBoxChild>
        <Texto size="16" weight="semibold">
          {t.outstandingBalance}
        </Texto>
      </FlexBoxChild>
      <FlexBoxChild>
        <Empty isEmpty={hasNoParent}>{SelfBalance}</Empty>
      </FlexBoxChild>
      {<FlexBoxChild>{ChildBalance}</FlexBoxChild>}
    </Flex>
  );

  const SplitIcon = (
    <Popover
      content={Split}
      overlayStyle={{ maxWidth: '280px' }}
      arrow={false}
      align={{
        points: ['tl', 'cr'],
        offset: [10, 0],
      }}
    >
      <InfoIconRegular />
    </Popover>
  );

  const ConsolidatedSplitJSX = (
    <Flex align="center" gap="var(--space-8)">
      {AmountJSX}
      {SplitIcon}
    </Flex>
  );

  return (
    <Empty isEmpty={isRootParent} emptyPlaceholder={<></>}>
      <Flex direction="column">
        {label}
        {ConsolidatedSplitJSX}
      </Flex>
    </Empty>
  );
}
