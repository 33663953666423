import { Card, Spin, Typography } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { Style } from 'lib/style/style';
import styled from 'styled-components';

const { Text } = Typography;

const CommonCardStyles = styled(Card)`
  width: 100%;
  height: 100%;
  border-radius: var(--br-1);
  padding: var(--space-16);
  .ant-card-body {
    padding: 0;
    height: 100%;
  }
`;

interface StyledTileCardProps {
  padding?: string;
}

export const StyledTileCard = styled(CommonCardStyles)`
  border: 0;
  /* box-shadow: var(--shadow-2); */
  padding: 'var(--space-16)';
  /* min-height: 118px; */
  height: 100%;

  .amount {
    overflow-wrap: anywhere;
  }
`;

const StyledTileLoadingCard = styled(StyledTileCard)`
  .ant-card-body {
    display: grid;
    height: 100%;
  }
`;

export const StyledTileCardLoader = () => {
  return (
    <StyledTileLoadingCard>
      <Spin style={{ margin: 'auto' }} />
    </StyledTileLoadingCard>
  );
};

//child
export const StyledChildCard = styled(CommonCardStyles)`
  padding: var(--space-12);
  overflow-wrap: anywhere;
  /* min-height: 118px; */
  /* &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    width: 100%;
    background: ${(props) => props.color}; */
  /* } */
`;

export const ChildLabelText = styled(Text)`
  color: var(--gray-8);
  display: block;
`;

export const StyledDashBoardCardWrapper = Style(Flex, {
  background: 'var(--gray-2)',
  borderRadius: 'var(--br-1)',
  overflow: 'auto',
  padding: 'var(--space-24) var(--space-20)',
  height: '660px',
  flexDirection: 'column',
  gap: 'var(--space-16)',
  scrollbarWidth: 'thin',
  scrollBehavior: 'smooth',

  variants: {
    mode: {
      table: {
        overflow: 'hidden',
        background: 'var(--gray-1)',
      },
    },
  },
});
