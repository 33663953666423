import { UserItemForActivation } from 'pages/public/ActivateAccount/ActivateAccount';
import { LoginProps } from 'queries/authentication';
import { CurrentUser } from 'types/entities/user';
import ky, { unprefixedKy } from './ky';

const baseUrl = 'users';

export function loginUser(data: LoginProps) {
  return ky.post('login', { json: data });
}

export async function profileOfUser() {
  return await ky.get(`${baseUrl}/current-user`).json<CurrentUser>();
}

export async function forgotPassword(email: string) {
  return await ky.post(`${baseUrl}/reset-password`, { json: { email } });
}

export async function resetPassword(hash_id: string, password: string) {
  return await ky.post(`${baseUrl}/set-password/${hash_id}`, {
    json: { password },
  });
}

type ChangePasswordData = {
  email: string;
  new_password: string;
  old_password: string;
};

type GMSRedirectUrl = {
  redirectUrl: string;
};

export async function changePassword(postData: ChangePasswordData) {
  return await ky.post(`${baseUrl}/change-password`, { json: { ...postData } });
}

export async function gmsSync(isArEmail: boolean, id?: number) {
  return await ky
    .get(`gms/auth`, {
      searchParams: {
        is_ar_email: isArEmail,
        ar_email_id: String(id),
      },
    })
    .json<GMSRedirectUrl>();
}

export async function activateAccount(hash_id: string, userDetails: UserItemForActivation) {
  return await ky.post(`${baseUrl}/activation/${hash_id}`, { json: userDetails });
}

export async function logoutUser() {
  return await ky.get(`${baseUrl}/logout`);
}

export type InitialUserData = {
  email: string;
  first_name?: string;
  hash: string;
  id: number;
  last_name?: string;
};

type InitialArEmailData = {
  ar_email: string;
};

export async function fetchAccountDetailsForActivation(hash: string) {
  return (await ky.get(`hash/${hash}/user`).json()) as InitialUserData;
}

export async function validateHashForArEmail(hash: string) {
  return (await ky.get(`hash/${hash}/account/verify`).json()) as InitialArEmailData;
}

export async function validateHash(hash: string) {
  return await ky.get(`hash/validate/${hash}`);
}

export async function fetchTimezoneData() {
  return (await unprefixedKy.get('https://worldtimeapi.org/api/ip').json()) as { timezone: string };
}

export async function getPasswordConfig() {
  return (await ky.get(`accounts/password-rules`).json()) as {
    password_filter: string[];
  };
}
