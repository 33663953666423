import { useMutation, useQuery } from '@tanstack/react-query';
import { DocumentFilter } from 'components/Documents/types';
import useCustomInfiniteQuery from 'hooks/use-custom-infinite-query';
import {
  createADocumentType,
  createDocuments,
  deleteADocumentType,
  deleteDocument,
  getCustomerDocumentsList,
  getDocumentTypesListPages,
  getInvoiceDocumentsList,
  updateDocumentTypes,
  updateDocuments,
} from 'services/documents';
import { RequestConfig } from 'types/common/common';
import {
  CreateADocumentTypes,
  CreateDocument,
  DocumentTypeFilterKeys,
  DocumentTypesList,
} from 'types/entities/documents';

export function useCreateADocumentType() {
  return useMutation({
    mutationFn: (params: CreateADocumentTypes) => createADocumentType(params),
    onSuccess() {},
    onError() {},
  });
}

export function useGetDocumentTypesLists(filters: DocumentTypeFilterKeys, enabled?: boolean) {
  return useQuery({
    queryKey: ['document-types-list', filters],
    queryFn: () =>
      getDocumentTypesListPages({
        page: 1,
        size: 200,
        filters,
      }),
    staleTime: 0,
    cacheTime: 0,
    enabled,
  });
}

export function useGetDocumentInfiniteTypesQuery(filters: DocumentTypeFilterKeys) {
  return useCustomInfiniteQuery({
    queryKey: ['document-types-list', filters],
    queryFn: (data) =>
      getDocumentTypesListPages({
        page: data?.pageParam || 1,
        size: 10,
        filters,
      }),
    enabled: Boolean(filters.entity_types.length),
  });
}

export function useEditDocumentType() {
  return useMutation({
    mutationFn: (params: Partial<DocumentTypesList>) => updateDocumentTypes(params),
    onSuccess() {},
    onError() {},
  });
}

export function useDeleteDocumentType() {
  return useMutation({
    mutationFn: (params: Pick<DocumentTypesList, 'id'>) => deleteADocumentType(params),
    onSuccess() {},
    onError() {},
  });
}

export function useCreateDocuments(customerId: number) {
  return useMutation({
    mutationFn: (params: CreateDocument) => createDocuments(params, customerId),
    onSuccess() {},
    onError() {},
  });
}

export function useGetCustomerDocumentLists(
  filters: RequestConfig<DocumentFilter>,
  customer_id: string
) {
  return useQuery({
    queryKey: ['customer-document-list', filters, customer_id],
    queryFn: () => getCustomerDocumentsList(filters, customer_id),
    staleTime: 0,
    cacheTime: 0,
    enabled: Boolean(customer_id),
  });
}

export function useGetInvoiceDocumentLists(
  filters: RequestConfig<DocumentFilter>,
  invoice_id: string
) {
  return useQuery({
    queryKey: ['invoice-document-list', filters],
    queryFn: () => getInvoiceDocumentsList(filters, invoice_id),
    staleTime: 0,
    cacheTime: 0,
    enabled: Boolean(invoice_id),
  });
}
export function useEditDocuments(documentId: string) {
  return useMutation({
    mutationFn: (params: Partial<CreateDocument>) => updateDocuments(params, documentId),
    onSuccess() {},
    onError() {},
  });
}

export function useDeleteDocument(documentId: string) {
  return useMutation({
    mutationFn: () => deleteDocument(documentId),
    onSuccess() {},
    onError() {},
  });
}

export function useInvoiceLevelDocumentLists(invoice_id: string, enabled: boolean) {
  return useQuery({
    queryKey: ['invoice-level-document-list', invoice_id],
    queryFn: () =>
      getInvoiceDocumentsList(
        { page: 1, size: 10, filters: { entity_types: ['INVOICE'] } },
        invoice_id
      ),
    staleTime: 0,
    cacheTime: 0,
    enabled,
  });
}
