export type APP_ENV_VALUES = 'production' | 'staging' | 'development' | 'ps0';

export const APP_ENV: Record<APP_ENV_VALUES, APP_ENV_VALUES> = {
  production: 'production',
  staging: 'staging',
  development: 'development',
  ps0: 'ps0',
};

export const IS_PROD = import.meta.env.MODE === APP_ENV.production;
const BLACKLISTED_URLS = ['demo.growfin.ai', 'cash-knight.growfin.ai'];
export const IS_DISABLED = BLACKLISTED_URLS.includes(window.location.hostname);
export const IS_LOCAL = import.meta.env.MODE === APP_ENV.development;
export const IS_STAGING = import.meta.env.MODE === APP_ENV.staging;
export const IS_PS0 = import.meta.env.MODE === APP_ENV.ps0;
export const IS_DEMO =
  IS_PROD &&
  (window.location.hostname === 'demo.growfin.ai' ||
    window.location.hostname === 'cash-knight.growfin.ai');
export const IS_STAGING_OR_DEMO = IS_STAGING || IS_DEMO;

export function getEnv(): APP_ENV_VALUES | null {
  if (IS_LOCAL) {
    return APP_ENV.development;
  }
  if (IS_STAGING) {
    return APP_ENV.staging;
  }
  if (IS_PS0) {
    return APP_ENV.ps0;
  }

  if (IS_PROD) {
    return APP_ENV.production;
  }
  return null;
}
