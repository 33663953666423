import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CellRenderProps, GrowTypography } from '@sinecycle/growcomponents';
import { Row, Tooltip } from 'antd';
import CellSelection from 'components/BaseComponents/GrowTable/CellSelection';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import HideWrapper from 'components/Common/Util/HideWrapper';
import { DocViewer } from 'components/HigherOrderComponent/DocViewer';
import { EActivityQueryParams } from 'components/HigherOrderComponent/KeyActivitesContainer/type';
import { PageLink } from 'lib/router';
import { TFilterViews } from 'store/aging/types';
import { IInvoices } from 'store/customer/types';
import { FileType } from 'types/common/attachments';
import { InvoiceStrategyStatus } from 'types/entities/collection-strategy';
import { StatementViews } from 'types/entities/invoice';
import { handleDispatchEventViewPdfOpen } from '../../OpenInvoicesStatements/utils';
import BillingContactIndicator from '../../common/BillingContactIndicator';

interface InvoiceRendererProps<T> extends CellRenderProps<T> {
  data: T;
  hideSelection?: boolean;
  filters: TFilterViews;
  isFromInvoiceList: boolean;
}

function Invoices(props: InvoiceRendererProps<IInvoices>) {
  const { data, hideSelection, filters, invoiceType } = props;
  const collectionStrategyEnabled = data.collection_strategy_V2?.enabled;
  const collectionStrategyTitle = data.collection_strategy_V2?.title;
  const strategyForInvoiceEnabled = data.collection_strategy_V2?.invoice_strategy_status;
  const hasFlagged = Boolean(data.invoice_flag);
  const FlagIcon = (
    <Tooltip title="Flagged">
      <FontAwesomeIcon icon={['far', 'flag']} color="var(--volcano-5)" fontSize={12} />
    </Tooltip>
  );

  function getCurrentPage() {
    if (!props.node || props.node.rowIndex === null) {
      return 0;
    }

    return !(props.currentPage > 1)
      ? props.node.rowIndex + 1
      : props.node.rowIndex !== 10
      ? Number(`${props.currentPage - 1}${props.node.rowIndex}`) + 1
      : Number(`${props.currentPage}0`);
  }

  const color =
    collectionStrategyEnabled && strategyForInvoiceEnabled === InvoiceStrategyStatus.RUNNING
      ? '--orange-6'
      : '--gray-5';
  const Strategy = (
    <>
      {data.collection_strategy_V2 ? (
        <Tooltip
          title={
            <Flex direction="column">
              <GrowTypography.Text fs="12" style={{ color: `var(${color})` }}>
                Collection Strategy
                {collectionStrategyEnabled &&
                  strategyForInvoiceEnabled === InvoiceStrategyStatus.PAUSED &&
                  ` - Paused`}
              </GrowTypography.Text>
              <GrowTypography.Text style={{ color: 'var(--gray-1)' }}>
                {collectionStrategyTitle}
              </GrowTypography.Text>
            </Flex>
          }
        >
          <FontAwesomeIcon
            // @ts-expect-error
            icon={['fak', 'strategy']}
            color={
              !data.collection_strategy_V2?.enabled ||
              data.collection_strategy_V2.invoice_strategy_status === InvoiceStrategyStatus.PAUSED
                ? 'var(--gray-6)'
                : 'var(--gold-6)'
            }
            cursor={'pointer'}
          />
        </Tooltip>
      ) : null}
    </>
  );

  const InvoiceLink = (
    <GrowTypography.Text strong ellipsis>
      <PageLink
        style={{ color: 'var(--primary-7)' }}
        pathParams={{ id: data.id }}
        state={{
          invoiceFilter: filters,
          currentPage: getCurrentPage(),
          invoiceType,
        }}
        appPage="INVOICE_DETAILS"
        searchParams={
          {
            [EActivityQueryParams.CURRENT_SECTION]: StatementViews.ACTIVITIES,
            [EActivityQueryParams.CURRENT_ACTIVITY_TAB]: StatementViews.ALL,
          } as unknown as URLSearchParams
        }
      >
        {data?.invoice_no}
      </PageLink>
    </GrowTypography.Text>
  );

  const BillingContact = <BillingContactIndicator contacts={data?.billing_contacts} hideWidth />;

  const Doc = data.invoice_url ? (
    <div>
      <DocViewer
        className="pdf-container cursor-pointer"
        docType={FileType.PDF}
        docUrl={data.invoice_url}
        onClick={() => {
          handleDispatchEventViewPdfOpen(props.isFromInvoiceList);
        }}
        toolTipText="Preview"
      />
    </div>
  ) : null;
  return (
    <Flex align="center" gap="var(--space-2)" style={{ height: '100%' }}>
      <HideWrapper hide={Boolean(hideSelection)}>
        <CellSelection {...props} />
      </HideWrapper>

      <Row
        align="middle"
        style={{ width: '100%', height: '100%' }}
        justify="space-between"
        wrap={false}
      >
        {InvoiceLink}
        <Flex gap="var(--space-8)" align="center">
          {hasFlagged ? FlagIcon : null}
          {Strategy}
          {BillingContact}
          {Doc}
        </Flex>
      </Row>
    </Flex>
  );
}

export default Invoices;
