import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { dateFormatSelector } from 'store/authentication/authentication';
const t = {
  today: 'Today',
  yesterday: 'Yesterday',
};

export default function useDateFormat() {
  const today = dayjs();
  const yesterday = dayjs().subtract(1, 'day');
  const dateFormat = useSelector(dateFormatSelector);
  function formatDate(date?: string) {
    const formattedDate = dayjs(date);

    if (formattedDate.isSame(today, 'day')) {
      return t.today;
    } else if (formattedDate.isSame(yesterday, 'day')) {
      return t.yesterday;
    } else {
      return formattedDate.format(dateFormat);
    }
  }

  return formatDate;
}

function useDateFormatWithoutTime() {
  const today = dayjs();
  const yesterday = dayjs().subtract(1, 'day');

  function formatDate(date?: string) {
    const formattedDate = dayjs(date);

    if (formattedDate.isSame(today, 'day')) {
      return t.today;
    } else if (formattedDate.isSame(yesterday, 'day')) {
      return t.yesterday;
    } else {
      return formattedDate.format('YYYY-MM-DD');
    }
  }

  return formatDate;
}

export { useDateFormatWithoutTime };
