import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CancelButton, GrowButton, GrowTypography, SaveButton } from '@sinecycle/growcomponents';
import { Button, Col, Form, Tooltip } from 'antd';
import { notify } from 'components/BaseComponents/Notifications';
import {
  sanitizeBodyAndSubject,
  sanitizeSubject,
} from 'components/BaseComponents/RTE/util/sanitizeSubject';
import { AssociatedFile } from 'components/Common/AssociatedFiles/type';
import BulkDocuments from 'components/Documents/Email/BulkDocuments';
import { CustomerSingleLevelDocument } from 'components/Documents/Email/CustomerLevelDocument';
import InvoiceLevelDocuments from 'components/Documents/Email/InvoiceLevelDocuments';
import useAddDocuments from 'components/Documents/Email/useAddDocuments';
import { ActionableEntity } from 'components/HigherOrderComponent/KeyActivitesContainer/Email';
import { useComputeRecepient } from 'components/HigherOrderComponent/KeyActivitesContainer/Email/NewEmail/hook/useComputeRecepient';
import ComposeEmail from 'components/HigherOrderComponent/KeyActivitesContainer/Email/NewEmail/new-email';
import { EEmailParams } from 'components/HigherOrderComponent/KeyActivitesContainer/Email/type';
import { useAREmailData } from 'components/Settings/Company/AREmail/useAREmailData';
import { COMMON_EVENT } from 'events/common';
import { dispatchAppEvent } from 'lib/pub-sub';
import { find, get } from 'lodash';
import React, { memo, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { sendEmail } from 'services/email';
import { Emailable, EmailableType } from 'types/activities/email';
import { InboxEmailSearchParam } from 'types/api/inbox/email';
import { Attachment } from 'types/common/attachments';
import { FolderType } from 'types/entities/email-templates';
import useDisableStatus from 'util/hooks/useDisableStatus';
import { EmailView } from '../Conversations/Body/ConversationBody/Body';
import { useEmailSearchParams } from '../hooks';
import { ComposedEmailPreview } from './ComposedEmailPreview';
import { FormBody, FormFooter, FormHeader } from './style';
import { EmailFormType } from './types';

const t = {
  setRemainder: 'Set Remainder',
  showPreview: 'Show Preview',
  send: 'Send',
  forwardEmail: 'Forward Email',
  replyEmail: 'Reply Email',
  replyAllEmail: 'Reply All Email',
  emailSucces: {
    message: 'Email Sent',
    description: 'Email was succesfully Sent! ',
  },
  emailFailure: {
    message: 'Delivery Unsuccessful',
    description: 'The contents are saved in draft. Please try resending the Email :(',
  },
};

export type ErrorType = 'SEND_FAILED';

interface EmailFormProps {
  onSend?: (data: any) => void;
  onPreview?: (data: any) => void;
  onCancel?: () => void;
  onError?: (type: ErrorType, payload: any) => void;
  emailType: EmailFormType;
  header?: React.ReactNode;
  defaultEmailValues?: any;
  to?: Emailable[];
  from?: Emailable;
  cc?: Emailable[];
  emailRecepients?: Emailable[];
  subject?: string;
  showRelativeOptionsList?: boolean;
  parentEmailId?: number;
  customerIds: number[];
  invoiceList?: {
    invoiceNumber: string;
    invoiceId: number;
  }[];
  body?: string;
  extraFilesComp?: React.ReactNode;
  emailView?: EmailView;
  handleMaximize?: () => void;
  handleMinimize?: () => void;
  attachements?: Attachment[];
}

export const EmailForm = memo(function EmailForm(props: EmailFormProps) {
  const { handleSubmit, getValues } = useFormContext();
  const { emailSearchParams } = useEmailSearchParams();
  const { filteredList } = useAREmailData();

  const [documents, setDocuments] = useState<AssociatedFile[]>();
  const [previewVisible, setPreviewVisible] = useState(false);
  const [sending, setSending] = useState(false);
  const { disabled } = useDisableStatus();
  const customerId = props.customerIds.length ? props.customerIds[0] : undefined;
  const Close = <FontAwesomeIcon color="var(--primary-7)" icon={['fal', 'times']} />;
  const Expand = (
    <FontAwesomeIcon
      icon={['far', 'arrow-up-right-and-arrow-down-left-from-center']}
      color="var(--gray-7)"
    />
  );
  const Minimize = <FontAwesomeIcon icon={['far', 'minus']} color="var(--gray-7)" />;
  // const Minimize = <FontAwesomeIcon icon={['far','window-minimize']} color="var(--primary-7)" />;
  const { computedGroupedOptions: groupedOptions } = useComputeRecepient({
    customerId,
    defaultValues: [...(props.cc ?? []), ...(props.to ?? [])] as unknown as Emailable[],
  });

  const isArEmailExist = find(filteredList, {
    id: Number(emailSearchParams[InboxEmailSearchParam.email_id]),
  });

  async function handleSendEmail(formData: any) {
    try {
      setSending(true);

      if (props.body) {
        formData = {
          ...formData,
          subject: sanitizeSubject(formData.subject),
          body: `<div>${formData.body ?? ''}</div>`,
        };
      }
      dispatchAppEvent({
        type: COMMON_EVENT.ACTION_WITHIN_COLLECTION_ACTIVITY_CREATION,
        payload: { collectionAction: 'Follow up Email', actionName: 'Send Email' },
      });

      const uploadedFileIds = getValues(EEmailParams.file_upload_ids);

      formData = {
        ...formData,
        file_upload_ids: uploadedFileIds,
      };

      delete formData?.fileParams;
      delete formData?.metaData;

      !formData?.cc?.length && delete formData.cc;
      !formData?.bcc?.length && delete formData.bcc;

      const finalEmailFormData = {
        ...formData,
        ...(props.parentEmailId ? { parent_email_id: props.parentEmailId } : {}),
        ...(props.customerIds ? { customer_ids: props.customerIds ?? [] } : {}),
      };

      await sendEmail(finalEmailFormData);
      notify.success(t.emailSucces.message, { description: t.emailSucces.description });

      props.onSend?.(formData);
    } catch (e) {
      notify.error(t.emailFailure.message, {
        description: t.emailFailure.description,
      });

      props.onError?.('SEND_FAILED', e);
    } finally {
      setSending(false);
    }
  }

  const validFolderTypes = useMemo(() => {
    if (!props.invoiceList) {
      return;
    }
    if (!props.invoiceList?.length) {
      return [FolderType.SINGLE_CUSTOMER];
    }
    if (props.invoiceList?.length === 1) {
      return [FolderType.SINGLE_CUSTOMER, FolderType.SINGLE_INVOICE];
    }
    if (props.invoiceList?.length > 1) {
      return [FolderType.SINGLE_CUSTOMER, FolderType.MULTIPLE_INVOICE];
    }
  }, [props.invoiceList]);

  const ComposedEmailPreviewDrawer = (
    <ComposedEmailPreview
      previewDrawerVisible={previewVisible}
      to={getValues(EEmailParams.to)}
      from={getValues(EEmailParams.from)}
      subject={getValues(EEmailParams.subject)}
      body={getValues(EEmailParams.body)}
      customerId={customerId}
      invoiceList={props.invoiceList}
      onPreviewDrawerClose={() => setPreviewVisible(false)}
    />
  );

  function showPreview(previewData: any) {
    setPreviewVisible(true);
    props.onPreview?.(previewData);
    dispatchAppEvent({
      type: COMMON_EVENT.ACTION_WITHIN_COLLECTION_ACTIVITY_CREATION,
      payload: { collectionAction: 'Follow up Email', actionName: 'Show Preview' },
    });
  }
  function getComp() {
    const customerIds = props.customerIds?.filter(Boolean);
    if (customerIds && !props.invoiceList?.length) {
      return customerIds?.length ? (
        customerIds.length > 1 ? (
          <BulkDocuments leveL={ActionableEntity.CUSTOMER} />
        ) : (
          <CustomerSingleLevelDocument customerId={String(props.customerIds[0])} />
        )
      ) : undefined;
    }
    if (props.invoiceList) {
      return props.invoiceList?.length ? (
        props.invoiceList.length > 1 ? (
          <BulkDocuments leveL={ActionableEntity.INVOICE} />
        ) : (
          <InvoiceLevelDocuments invoiceId={String(props.invoiceList[0].invoiceId)} />
        )
      ) : undefined;
    }
    return;
  }
  const comp = getComp();
  function handleDocuments(event: CustomEvent) {
    setDocuments(event.detail);
  }
  useAddDocuments({
    callBack: (event) => {
      handleDocuments(event);
    },
  });

  const subject = (getValues(EEmailParams.subject) as string) ?? '';
  const to = get(getValues(EEmailParams.to), '[0]', props.to);

  const emailFor = props.invoiceList?.length ? ActionableEntity.INVOICE : ActionableEntity.CUSTOMER;
  const fileName = props.invoiceList?.length
    ? props.invoiceList.length > 1
      ? 'All Selected invoices'
      : 'Invoice File'
    : 'All Open Invoices';

  const ExpandButton = (
    <Tooltip title="Expand">
      <GrowButton
        icon={Expand}
        onClick={(e) => {
          e.stopPropagation();
          props.handleMaximize?.();
        }}
        className="inbox-expand"
      />
    </Tooltip>
  );

  const MinimizeButton = (
    <Tooltip title="Minimize">
      <GrowButton
        icon={Minimize}
        onClick={(e) => {
          e.stopPropagation();
          props.onCancel?.();
        }}
        className="inbox-expand"
      />
    </Tooltip>
  );
  // will use it for future use
  // const MinimizeButton = (
  //   <Tooltip title='Minimize'>
  //     <StyledIconButton
  //       icon={Minimize}
  //       onClick={(e) => {
  //         e.stopPropagation();
  //         props.handleMinimize?.();
  //       }}
  //     />
  //   </Tooltip>
  // );

  return (
    <>
      <Form layout="vertical">
        <FormHeader wrap={false} align="middle">
          <Col flex={'auto'}>
            <GrowTypography.Paragraph
              strong
              style={{ marginLeft: 'var(--space-8)', marginBottom: '0' }}
              fs="16"
              ellipsis={{ rows: 2, tooltip: true }}
            >
              {subject.length
                ? sanitizeBodyAndSubject(subject)
                : sanitizeBodyAndSubject(props.subject ?? '')}
            </GrowTypography.Paragraph>
          </Col>

          <Col>
            <div className="tw-flex tw-items-center tw-gap-8">
              {props.emailView?.expand ? null : ExpandButton}
              {props.emailView?.expand ? (
                MinimizeButton
              ) : (
                <Tooltip title="Close">
                  <GrowButton icon={Close} onClick={props.onCancel} />
                </Tooltip>
              )}
            </div>
          </Col>
        </FormHeader>
        <FormBody direction="column" className="email-form-body">
          <ComposeEmail
            isInbox
            documents={documents}
            handleUpdateDocument={(value) => {
              setDocuments(value);
            }}
            groupedOptions={groupedOptions}
            showRelativeOptionsList={false}
            subject={subject.length ? subject : props.subject}
            to={to}
            cc={props.cc}
            validFolders={validFolderTypes}
            allowUpload
            periodType="ABSOLUTE"
            extraFilesComp={props.extraFilesComp ? props.extraFilesComp : comp}
            body={props.body}
            emailFor={emailFor}
            key={props.emailType}
            invoiceFileName={fileName}
            emailType={props.emailType}
            attachements={props.attachements}
            from={
              isArEmailExist
                ? ({
                    type: EmailableType.ACCOUNT,
                    id: isArEmailExist?.id as unknown as number,
                    email: isArEmailExist?.email,
                    label: isArEmailExist?.email,
                  } as Emailable)
                : undefined
            }
          />
        </FormBody>
        <FormFooter className="email-form-footer" gap="--space-8" align="center" justify="flex-end">
          <CancelButton type="link" onClick={props.onCancel} />

          <Button
            style={{ color: 'var(--primary-7)' }}
            disabled={disabled}
            onClick={handleSubmit(showPreview, showPreview)} // show preview even if invalid/empty fields
          >
            {t.showPreview}
          </Button>
          <SaveButton
            type="primary"
            onClick={handleSubmit(handleSendEmail)}
            loading={sending}
            disabled={disabled}
            text={t.send}
            savingText="Sending..."
          />
        </FormFooter>
      </Form>
      {previewVisible && ComposedEmailPreviewDrawer}
    </>
  );
});
