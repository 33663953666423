import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { InputLoader } from 'components/Common/FilterComponents/Common/SkeletonLoader';
import { AmountWithCurrencySplit } from 'components/Common/MultiCurrency';
import { InfoPopover } from 'components/Common/UtilityPopover';
import { PageLink } from 'lib/router';
import DashboardCardTemplate from 'pages/Dashboard/AdminDashboard/SnapShot/CollectionBalanceAndOutstanding/CardTemplate';
import React from 'react';
import { TotalOutstandingResponse } from 'types/api/admin-dashboard';
import TotatalCustomersInvoices from '../TotalCustomersInvoices';
import { dashboardTexts } from '../texts';
import { TotalBalance } from './style';
const t = {
  extraContent: 'Total balance of all invoice',
};

const componentTexts = dashboardTexts.totalBalance;

function ExtraInfo() {
  const Content = (
    <div className="extra-info">
      <GrowText>{t.extraContent}</GrowText>
    </div>
  );

  return (
    <div style={{ cursor: 'pointer' }}>
      <InfoPopover content={Content} />
    </div>
  );
}

interface TotalBalanceTileProps {
  totalOutstanding?: TotalOutstandingResponse;
  loading: boolean;
}

function TotalBalanceTile({ totalOutstanding, loading }: TotalBalanceTileProps) {
  const totalOutstandingAmount = totalOutstanding?.total_outstanding;
  const customersCount =
    totalOutstanding?.total_outstanding.open_invoices_balance_amount?.customer_count;
  const invoicesCount =
    totalOutstanding?.total_outstanding.open_invoices_balance_amount?.invoice_count;

  const InputLoaderJSX = <InputLoader active={loading} />;

  const TotalInvoiceBalance = totalOutstandingAmount?.open_invoices_balance_amount && (
    <PageLink appPage="INVOICE_LIST" state={{ addPrimaryPathAsChildPath: true }}>
      <AmountWithCurrencySplit
        type="multi"
        amountWithCurrencySplit={totalOutstandingAmount.open_invoices_balance_amount.amount}
        className="text-24 semi-bold"
        context={componentTexts.title}
        formatOptions={{ maximumFractionDigits: 0 }}
      />
    </PageLink>
  );

  const TotalBalanceInformation = (
    <TotalBalance>
      <Flex className="value amount" align="center" gap="var(--space-4)">
        {loading && !totalOutstanding ? InputLoaderJSX : TotalInvoiceBalance}
      </Flex>
    </TotalBalance>
  );

  const TotalCustInvoiceInfo = (
    <TotatalCustomersInvoices
      loading={loading}
      customersCount={customersCount}
      invoicesCount={invoicesCount}
    />
  );

  return (
    <DashboardCardTemplate
      title={componentTexts.title}
      extra={<ExtraInfo />}
      amountInfo={TotalBalanceInformation}
      customerInvoiceCount={TotalCustInvoiceInfo}
    />
  );
}

export default React.memo(TotalBalanceTile);
