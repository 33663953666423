import { EActivityQueryParams } from 'components/HigherOrderComponent/KeyActivitesContainer/type';
import { useEntitySearchParams } from './useEntitySearchParams';

export default function useActivityUtils(isPageLoaded?: boolean) {
  const { entitySearchParams, removeEntitySearchParams } = useEntitySearchParams();

  const openModal =
    isPageLoaded &&
    entitySearchParams[EActivityQueryParams.ACTIVITY_ID] &&
    entitySearchParams[EActivityQueryParams.ACTIVITY_TYPE] &&
    entitySearchParams[EActivityQueryParams.VIEW];

  function removeActivityQueryParams() {
    removeEntitySearchParams([
      EActivityQueryParams.ACTIVITY_TYPE,
      EActivityQueryParams.ACTIVITY_ID,
      EActivityQueryParams.CUSTOMER,
      EActivityQueryParams.CUSTOMER_ID,
      EActivityQueryParams.OPEN,
      EActivityQueryParams.VIEW,
      EActivityQueryParams.ASSOCIATED_ACTIVITY,
      EActivityQueryParams.QUERY_KEY,
      EActivityQueryParams.TOTAL_RECORDS,
      EActivityQueryParams.ACTIVITY_PAGE,
      EActivityQueryParams.TOTAL_PAGES,
      EActivityQueryParams.ASSOCIATED_ACTIVITY_ID,
      EActivityQueryParams.ASSOCIATED_ACTIVITY_TYPE,
      EActivityQueryParams.EDIT,
      EActivityQueryParams.COMMENT_ID,
      EActivityQueryParams.FROM_TYPE,
    ]);
  }

  return { removeActivityQueryParams, openModal };
}
