import { GrowButton } from '@sinecycle/growcomponents';
import { Flex, Table } from 'antd';
import { Style } from 'lib/style/style';
import styled from 'styled-components';
import { InvoiceFieldMapping } from '../ActivitiesForm/types';
import { StyledModal } from '../Styles/Styles';

export const StyledActivitiesModal = styled(StyledModal)`
  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-content {
    background-color: var(--trasparent);
    box-shadow: none;
    border-radius: 0;
  }
`;

export const IconButton = styled(GrowButton)`
  &.ant-btn[disabled] {
    svg {
      color: var(--gray-5);
    }
  }
`;

export const AttributeTable = Style(Table<InvoiceFieldMapping>, {
  variants: {
    design: {
      empty: {
        '.ant-table-cell': {
          padding: '0 !important',
          border: '0 !important',
        },
        '.ant-table-footer': {
          display: 'none',
        },
      },

      data: {
        '.ant-table-cell': {
          background: 'var(--gray-1) !important',
          padding: '8px !important',
        },
        '.ant-table-footer': {
          background: 'var(--gray-1) !important',
          padding: '12px 28px',
          minHeight: '50px',
        },
        '.ant-table-body': {
          padding: '0 20px 0',
        },

        '.ant-table-header': {
          paddingInline: '20px',
        },
        '&.ant-table-wrapper .ant-table-thead > tr > th,.ant-table-wrapper .ant-table-thead > tr > td':
          {
            borderColor: ' var(--gray-6)',
          },

        '&.ant-table-wrapper .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before, .ant-table-wrapper .ant-table-thead > tr > td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before':
          {
            content: 'none',
          },
        '&.ant-table-wrapper .ant-table-thead > tr > th, .ant-table-wrapper .ant-table-tbody > tr > th, .ant-table-wrapper .ant-table-tbody > tr > td, .ant-table-wrapper tfoot > tr > th, .ant-table-wrapper tfoot > tr > td':
          {
            padding: '12px',
          },
      },
    },
  },
});

export const EmptyPlaceHolder = Style(Flex, {
  width: '100%',
  height: '80px',
  backgroundColor: 'var(--gray-2)',
  color: 'var(--gray-7)',
});
