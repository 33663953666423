import { EActivityQueryParams } from 'components/HigherOrderComponent/KeyActivitesContainer/type';
import { EmailFormType } from 'components/Inbox/EmailConversations/EmailThread/type';
import { useURLSearchParams } from 'hooks/use-search-params';
import { keyBy, mapValues } from 'lodash';
import { useCallback, useMemo } from 'react';
import { NavigateOptions } from 'react-router-dom';
import { ELastContactActivity } from 'store/activity-feed/type';
import { ActivityType } from 'types/activities/activity-types';
import { emailSearchParamKeys } from 'types/api/inbox/email';
import { StatementViews } from 'types/entities/invoice';

export enum VIEW_TYPE {
  MODAL = 'modal',
}

export enum QUERY_KEYS {
  PENDING_ISSUES = 'pending-issues',
  DISPUTE_LIST = 'dispute-list',
}

export enum OPEN_TYPE {
  COMMENTS = 'comments',
  EMAIL = 'email',
}

interface EntitySearchParamsProps {
  [EActivityQueryParams.CURRENT_SECTION]: StatementViews;
  [EActivityQueryParams.CURRENT_ACTIVITY_TAB]: ActivityType | StatementViews;
  [EActivityQueryParams.ACTIVITY_TYPE]: ELastContactActivity | ActivityType;
  [EActivityQueryParams.ACTIVITY_ID]: string | number;
  [EActivityQueryParams.CURRENT_VIEW]: StatementViews;
  [EActivityQueryParams.EMAIL_ID]: number;
  [EActivityQueryParams.PAGE]: number;
  [EActivityQueryParams.CUSTOMER]: string;
  [EActivityQueryParams.CUSTOMER_ID]: number;
  [EActivityQueryParams.VIEW]: VIEW_TYPE;
  [EActivityQueryParams.OPEN]: OPEN_TYPE;
  [EActivityQueryParams.ASSOCIATED_ACTIVITY]: boolean;
  [EActivityQueryParams.EDIT]: boolean;
  [EActivityQueryParams.QUERY_KEY]: QUERY_KEYS | string;
  [EActivityQueryParams.TOTAL_RECORDS]: number;
  [EActivityQueryParams.ACTIVITY_PAGE]: number;
  [EActivityQueryParams.TOTAL_PAGES]: number;
  [EActivityQueryParams.ASSOCIATED_ACTIVITY_TYPE]: ActivityType | StatementViews;
  [EActivityQueryParams.ASSOCIATED_ACTIVITY_ID]: number;
  [EActivityQueryParams.PAGE_SIZE]: number;
  [EActivityQueryParams.FROM_TYPE]: EmailFormType;
}

export function useEntitySearchParams(includeEmailKeys?: boolean) {
  const { setSearchParams, getSearchParam, removeSearchParams } = useURLSearchParams();

  const entitySearchParams = useMemo(() => {
    const entityQueryParams = [
      EActivityQueryParams.CURRENT_SECTION,
      EActivityQueryParams.CURRENT_ACTIVITY_TAB,
      EActivityQueryParams.ACTIVITY_TYPE,
      EActivityQueryParams.ACTIVITY_ID,
      EActivityQueryParams.CURRENT_VIEW,
      EActivityQueryParams.EMAIL_ID,
      EActivityQueryParams.PAGE,
      EActivityQueryParams.CUSTOMER,
      EActivityQueryParams.CUSTOMER_ID,
      EActivityQueryParams.VIEW,
      EActivityQueryParams.OPEN,
      EActivityQueryParams.ASSOCIATED_ACTIVITY,
      EActivityQueryParams.EDIT,
      EActivityQueryParams.QUERY_KEY,
      EActivityQueryParams.TOTAL_RECORDS,
      EActivityQueryParams.ACTIVITY_PAGE,
      EActivityQueryParams.TOTAL_PAGES,
      EActivityQueryParams.ASSOCIATED_ACTIVITY_TYPE,
      EActivityQueryParams.ASSOCIATED_ACTIVITY_ID,
      EActivityQueryParams.PAGE_SIZE,
    ];

    return mapValues(
      keyBy(entityQueryParams, (param) => param),
      (param) => getSearchParam(param)
    );
  }, [getSearchParam]);

  const setEntitySearchParams = useCallback(
    (newValue: Partial<EntitySearchParamsProps>, options?: NavigateOptions) => {
      setSearchParams(newValue, options);
    },
    [setSearchParams]
  );

  const removeEntitySearchParams = useCallback(
    (keys: string[]) => {
      removeSearchParams(keys);
    },
    [removeSearchParams]
  );

  const resetEntitySearchParams = useCallback(() => {
    const defaultKeys = [
      EActivityQueryParams.CURRENT_SECTION,
      EActivityQueryParams.CURRENT_ACTIVITY_TAB,
      EActivityQueryParams.ACTIVITY_TYPE,
      EActivityQueryParams.ACTIVITY_ID,
      EActivityQueryParams.CURRENT_VIEW,
      EActivityQueryParams.EMAIL_ID,
      EActivityQueryParams.PAGE,
      EActivityQueryParams.CUSTOMER,
      EActivityQueryParams.CUSTOMER_ID,
      EActivityQueryParams.VIEW,
      EActivityQueryParams.OPEN,
      EActivityQueryParams.ASSOCIATED_ACTIVITY,
      EActivityQueryParams.EDIT,
      EActivityQueryParams.QUERY_KEY,
      EActivityQueryParams.TOTAL_RECORDS,
      EActivityQueryParams.ACTIVITY_PAGE,
      EActivityQueryParams.TOTAL_PAGES,
      EActivityQueryParams.ASSOCIATED_ACTIVITY_ID,
      EActivityQueryParams.ASSOCIATED_ACTIVITY_TYPE,
      EActivityQueryParams.PAGE_SIZE,
    ];
    removeSearchParams(includeEmailKeys ? [...defaultKeys, ...emailSearchParamKeys] : defaultKeys);
  }, [includeEmailKeys, removeSearchParams]);

  return {
    entitySearchParams,
    setEntitySearchParams,
    removeEntitySearchParams,
    resetEntitySearchParams,
  };
}
