import { Select, SelectProps } from 'antd';
import { CSSProperties } from 'styled-components';

export interface SearchableSelectProps {
  value?: string | number | string[] | number[];
  onChange?: SelectProps['onChange'];
  options: SelectProps['options'];
  style?: CSSProperties;
  placeHolder?: string;
  mode?: 'multiple' | 'tags';
  allowClear?: boolean;
  disabled?: boolean;
  loading?: boolean;
  onSearch?: SelectProps['onSearch'];
  onSelect?: SelectProps['onSelect'];
  onVisibleChange?: SelectProps['onDropdownVisibleChange'];
  optionRender?: SelectProps['optionRender'];
  open?: boolean;
  virtual?: SelectProps['virtual'];
  notFoundContent?: SelectProps['notFoundContent'];
  customSearch?: boolean;
  onClick?: SelectProps['onClick'];
}
export default function SearchableSelect(props: SearchableSelectProps) {
  const defaultSearch = props?.options?.length && props.options.length > 10 ? true : false;
  return (
    <Select
      onDropdownVisibleChange={props.onVisibleChange}
      mode={props.mode}
      open={props.open}
      loading={props.loading}
      disabled={props.disabled}
      allowClear={props.allowClear}
      options={props.options}
      optionRender={props.optionRender}
      showSearch={props.customSearch ? props.customSearch : defaultSearch}
      style={props.style ? props.style : { width: 'fit-content' }}
      value={props.value}
      onChange={props.onChange}
      onSearch={props.onSearch}
      popupMatchSelectWidth={false}
      placeholder={props.placeHolder ?? 'Please fill the field'}
      filterOption={(input, option) => {
        const label = option?.title ?? (option?.label as string);
        const labelInput = label?.toLowerCase();
        return labelInput.includes(input.toLowerCase());
      }}
      virtual={props.virtual}
      notFoundContent={props.notFoundContent}
      onClick={props.onClick}
    />
  );
}
