import { useMutation, useQuery } from '@tanstack/react-query';
import { notify } from 'components/BaseComponents/Notifications';
import { DisputeStatus } from 'components/CollectionActivites/Disputes/types';
import { activityUrlConfig } from 'components/CollectionActivities/types';
import { KyResponse } from 'ky';
import { FieldValues } from 'react-hook-form';
import { deleteDisputes, updateDisputeStatus } from 'services/dispute';
import { UpdateEscalationStatusProps, updateEscalationStatus } from 'services/escalation';
import {
  KeyActivityParams,
  deleteKeyActivityData,
  getKeyActivityData,
  updateKeyActivities,
} from 'services/invoice';
import { updatePromiseToPayStatus } from 'services/promise-to-pay';
import { changeTaskStatus } from 'services/tasks';
import { RaiseADisputeActivity } from 'store/activity-feed/type';
import { ActivityType } from 'types/activities/activity-types';
import { PromiseToPayStatus } from 'types/activities/promise-to-pay';
import { TaskStatus } from 'types/activities/task';

interface useUpdateEscalationStatusProps {
  params: UpdateEscalationStatusProps;
  id: number;
}
interface useUpdateDisputeStatusProps {
  params: { status: DisputeStatus };
  id: number;
}
interface useUpdatePTpStatusProps {
  params: { status: PromiseToPayStatus };
  id: number;
}
interface useUpdateTaskStatusProps {
  status: Exclude<TaskStatus, TaskStatus.DUE_TODAY>;
  id: number;
}

interface useCollectionActivityQueryProps {
  id?: number;
  type: Exclude<ActivityType, 'DOCUMENT'>;
  isListFetched?: boolean;
}

interface useDeleteActivitiesDataProps {
  currentActivity: ActivityKeyValues;
  id: number;
}

export type ActivityKey =
  | 'TASK'
  | 'CALL_LOG'
  | 'ESCALATION'
  | 'PROMISE_TO_PAY'
  | 'NOTE'
  | 'DISPUTE'
  | 'DOCUMENT';

export type ActivityKeyValues =
  | 'tasks'
  | 'call-logs'
  | 'escalations'
  | 'promise-to-pay'
  | 'notes'
  | 'disputes'
  | 'documents';

export const ActivityKeyMap: Record<ActivityKey, ActivityKeyValues> = {
  TASK: 'tasks',
  CALL_LOG: 'call-logs',
  ESCALATION: 'escalations',
  PROMISE_TO_PAY: 'promise-to-pay',
  NOTE: 'notes',
  DISPUTE: 'disputes',
  DOCUMENT: 'documents',
};

const t = {
  title: 'Status not updated',
  description: 'Please try performing the status update again',
};
export function useUpdateEscalationStatus() {
  return useMutation({
    mutationFn: (value: useUpdateEscalationStatusProps) =>
      updateEscalationStatus(value.params, value.id),
  });
}

export function useUpdateDisputeStatus() {
  return useMutation({
    mutationFn: (value: useUpdateDisputeStatusProps) => updateDisputeStatus(value.params, value.id),
    onError: () => {
      notify.error(t.title, {
        description: t.description,
      });
    },
  });
}

export function useCollectionActivityQuery<T>(props: useCollectionActivityQueryProps) {
  const { id, type, isListFetched } = props;

  return useQuery<T, KyResponse>({
    queryKey: ['collection_activity', id, type],
    queryFn: () => getKeyActivityData<T>(activityUrlConfig[type], id as number),
    cacheTime: 0,
    staleTime: 0,
    enabled: Boolean(id) && Boolean(type) && Boolean(isListFetched) && type !== ActivityType.EMAIL,
  });
}

export function useUpdateTaskStatus() {
  return useMutation({
    mutationFn: (value: useUpdateTaskStatusProps) => changeTaskStatus(value.id, value.status),
  });
}
export function useUpdateKeyActivities<T>() {
  return useMutation({
    mutationFn: (value: KeyActivityParams<T>) =>
      updateKeyActivities<T>(value.params, value.activity, value.id),
  });
}

export function useUpdatePTPStatus() {
  return useMutation({
    mutationFn: (value: useUpdatePTpStatusProps) =>
      updatePromiseToPayStatus(value.params, value.id),
    onError: () => {
      notify.error(t.title, {
        description: t.description,
      });
    },
  });
}

export function useDeleteActivitiesData() {
  return useMutation({
    mutationFn: ({ currentActivity, id }: useDeleteActivitiesDataProps) =>
      deleteKeyActivityData(currentActivity, id),
  });
}

export function useDeleteDisputeActivity() {
  return useMutation({
    mutationFn: (id: string[]) => deleteDisputes(id),
  });
}

export function useUpdateActivityFormMutation() {
  return useMutation({
    mutationFn: ({ data, path, id }: { data: FieldValues; path: string; id: number }) =>
      updateKeyActivities<RaiseADisputeActivity>(data, path, id),
    onError: () => {},
  });
}
