import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { sanitizeBodyAndSubject } from 'components/BaseComponents/RTE/util/sanitizeSubject';
import { Style } from 'lib/style/style';
import { useSelector } from 'react-redux';
import { dateFormatSelector } from 'store/authentication/authentication';
import { EmailConversation, EmailConversationParticipant } from 'types/activities/email';
import { getDateTimeArray } from 'util/datetime-formatter';
import { extractNameFromEmail } from 'util/regex';
import { DetailsContent, DetailsContentItems } from './DetailsContent';

const t = {
  from: 'From',
  to: 'To',
  cc: 'CC',
  bcc: 'BCC',
  subject: 'Subject',
  date: 'Date',
};

const StyledFlex = Style(Flex, {
  width: '16px',
  height: '16px',
  padding: '0px var(--space-2) 0px var(--space-4)',
  borderRadius: 'var(--br-2)',
  '&:hover': {
    backgroundColor: 'var(--primary-1)',
  },
});
interface ConversationDetailsProps {
  emailConversation: EmailConversation;
}

const contentPredicate = (conversation: EmailConversationParticipant): DetailsContentItems => {
  const nameFromEmail = extractNameFromEmail(conversation.email);
  return {
    name: `${conversation.first_name ?? nameFromEmail?.firstName} ${
      conversation.last_name ?? nameFromEmail?.lastName
    }`,
    email: conversation.email,
    title: conversation.emailable
      ? conversation.emailable.title
        ? conversation.emailable.title
        : undefined
      : undefined,
  };
};

const Icon = (
  <FontAwesomeIcon
    size="sm"
    color="var(--primary-7)"
    icon={['fas', 'caret-down']}
    width={8}
    height={5}
  />
);
const overlayStyles: React.CSSProperties = {
  maxWidth: '50%',
  minWidth: '460px',
};

const innerStyle: React.CSSProperties = {
  padding: 'var(--space-16)',
  borderRadius: 'var(--br-1)',
  border: '1px solid var(--gray-4)',
  maxHeight: '400px',
  overflow: 'auto',
};

function ConversationDetails(props: ConversationDetailsProps) {
  const { emailConversation } = props;
  const format = useSelector(dateFormatSelector);
  if (!emailConversation.from) return null;

  function getFromContent(): Array<DetailsContentItems> {
    return emailConversation.from ? [emailConversation.from].map(contentPredicate) : [];
  }
  function getToContent() {
    return emailConversation.to ? emailConversation.to.map(contentPredicate) : [];
  }
  function getCCContent() {
    return emailConversation.cc ? emailConversation.cc.map(contentPredicate) : [];
  }
  function getBccContent() {
    return emailConversation.bcc ? emailConversation.bcc.map(contentPredicate) : [];
  }
  function getSubject(): Array<DetailsContentItems> {
    return [{ name: sanitizeBodyAndSubject(emailConversation.subject) }];
  }
  function getDate(): Array<DetailsContentItems> {
    if (!emailConversation.sent_at) return [];
    const [date, time] = getDateTimeArray(emailConversation.sent_at, { dateFormatType: format });

    return [{ name: `${date}, ${time}` }];
  }

  const formContent = getFromContent();
  const toContent = getToContent();
  const ccContent = getCCContent();
  const bccContent = getBccContent();
  const subject = getSubject();
  const date = getDate();

  const PopoverContent = (
    <Flex direction="column" gap="var(--space-8)" justify="flex-start">
      <DetailsContent heading={t.from} items={formContent} />
      <DetailsContent heading={t.to} items={toContent} />
      {ccContent.length ? <DetailsContent heading={t.cc} items={ccContent} /> : null}
      {bccContent.length ? <DetailsContent heading={t.bcc} items={bccContent} /> : null}
      <DetailsContent heading={t.subject} items={subject} />
      {date.length ? <DetailsContent heading={t.date} items={date} /> : null}
    </Flex>
  );

  return (
    <Popover
      content={PopoverContent}
      placement="right"
      arrow={false}
      overlayInnerStyle={innerStyle}
      overlayClassName="ant-popover-body-no-padding"
      overlayStyle={overlayStyles}
      style={{ boxShadow: 'var(--shadow-2)' }}
    >
      <StyledFlex align="center">{Icon}</StyledFlex>
    </Popover>
  );
}

export { ConversationDetails };
