import { map } from 'lodash';
import { useEffect } from 'react';
import transformData from 'util/transformData';
import { generateDisputeTypeOptions } from '../predicate';
import { useGetFormElementQuery } from '../services/query';
import { DisputeTypeMapping, useGetDisputeTypeOptionsProps } from '../types';

export default function useGetDisputeTypeOptions<T>({
  queryFn,
  queryKey,
  setOptionsMap,
  selectedDisputes,
  transformSchema,
  setOptions,
}: useGetDisputeTypeOptionsProps<T>) {
  const { data, isLoading, isSuccess } = useGetFormElementQuery<T>({
    queryFn,
    queryKey,
  });

  useEffect(() => {
    if (!isLoading && isSuccess) {
      const optionsListData = transformSchema
        ? map(data, (item) => {
            return transformData<T, DisputeTypeMapping>({
              originalData: item,
              schemaMapping: transformSchema,
            });
          })
        : (data as DisputeTypeMapping[]);

      const optionsMapList = new Map(map(optionsListData, (item) => [item.id, item]));
      const optionsList = generateDisputeTypeOptions(optionsListData);

      setOptionsMap((prev) => {
        const mergedMap = new Map(
          prev ? [...prev.entries(), ...optionsMapList.entries()] : optionsMapList.entries()
        );
        return mergedMap;
      });
      setOptions(optionsList);
    }
  }, [data, isLoading, isSuccess, selectedDisputes, setOptions, setOptionsMap, transformSchema]);

  return { data, isLoading };
}
