import DescriptionTextArea from 'components/BaseComponents/DescriptionTextArea/TextArea';
import { useFormWatch } from 'components/BaseComponents/Form/Antd/Antd';
import { EllipsisList } from 'components/Common/EllipsisList';
import { StyledFormItem } from 'components/Common/Styles/Styles';
import { ActionableEntity } from 'components/HigherOrderComponent/KeyActivitesContainer/Email';
import { filter, isEmpty, map, size, some } from 'lodash';
import AssignOwners from '../FormElements/AssignOwners';
import InvoiceSelect from '../FormElements/InvoiceSelect';
import ShowLineItems from '../FormElements/ShowLineItems';
import { ActivityFormItem, EllipsisListContainer } from '../style';
import { EscalationFormModalProps, EscalationModalFormElementProps } from '../types';

export default function EscalationFormItems<
  EscalationElements extends EscalationModalFormElementProps
>(props: Omit<EscalationFormModalProps<EscalationElements>, 'openModal'>) {
  const {
    invoices,
    form,
    invoiceQueryKey,
    invoiceSearchFn,
    invoiceQueryFn,
    lineItemsQueryFn,
    lineItemsQueryKey,
    invoiceTransformSchema,
    lineItemsTransformSchema,
    description,
    participants,
    participantConfig,
    invoiceLineItems,
    text,
    bulkAction,
    bulkRecordsLength,
  } = props;
  const {
    assignedOwnersLabel,
    assignOwnersPlaceholder,
    invoiceLabel,
    invoicePlaceholder,
    lineItemsPlaceholder,
    descriptionLabel,
    descriptionPlaceholder,
  } = text;

  const selectedInvoiceIds = map(invoices, (item) => (item as { id: number })?.id);
  const selectedInvoiceNo = map(invoices, (item) => (item as any)?.invoice_no);
  const referLineItems: boolean | undefined = useFormWatch('referLineItem', form);
  const invoiceIdsList: number[] | undefined = useFormWatch('escalationInvoices', form);
  const selectedUsers =
    filter(participants, (valueItem) =>
      some(participantConfig, (assigneeItem) => valueItem.id === assigneeItem.id)
    ) || [];
  const assigneeOptionType = selectedInvoiceIds.length
    ? ActionableEntity.INVOICE
    : ActionableEntity.CUSTOMER;

  function onChangeInvoiceList(invoiceList: number[]) {
    if (isEmpty(invoiceList) || size(invoiceList) > 1) {
      form.setFieldValue('escalationLineItems', undefined);
    }
    form.setFieldValue('escalationInvoices', invoiceList);
  }

  const InvoiceSelectList = (
    <StyledFormItem
      label={invoiceLabel}
      name={'escalationInvoices'}
      initialValue={selectedInvoiceIds}
      rules={[
        {
          required: true,
          message: `${invoiceLabel} is required`,
        },
      ]}
      required
    >
      <InvoiceSelect<EscalationElements['invoiceSelect']>
        placeholder={invoicePlaceholder}
        selectedInvoicesId={selectedInvoiceIds}
        selectedInvoices={invoices}
        onChangeInvoice={onChangeInvoiceList}
        queryFn={invoiceQueryFn}
        searchQueryFn={invoiceSearchFn}
        queryKey={invoiceQueryKey}
        transformSchema={invoiceTransformSchema}
      />
    </StyledFormItem>
  );

  const ShowLineItemsBox = (
    <ShowLineItems<EscalationElements['lineItemsSelect']>
      form={form}
      checked={referLineItems}
      disabled={invoiceIdsList && (invoiceIdsList?.length > 1 || !invoiceIdsList?.length)}
      onChange={(e) => form.setFieldValue('referLineItem', e.target.checked)}
      placeholder={lineItemsPlaceholder}
      onChangeLineItems={(value) => form.setFieldValue('escalationLineItems', value)}
      queryFn={() => lineItemsQueryFn(invoiceIdsList)}
      queryKey={lineItemsQueryKey}
      invoiceLineItems={invoiceLineItems}
      invoiceIdsList={invoiceIdsList}
      lineItemFieldKey={'escalationLineItems'}
      transformSchema={lineItemsTransformSchema}
      bulkAction={bulkAction}
    />
  );

  const AssignedToEscalationOwner = (
    <ActivityFormItem
      label={assignedOwnersLabel}
      name="escalationOwner"
      initialValue={participantConfig}
    >
      <AssignOwners
        assigneeUserList={selectedUsers}
        placeholder={assignOwnersPlaceholder}
        assigneeOptionType={assigneeOptionType}
        maxTagCount={'responsive'}
      />
    </ActivityFormItem>
  );

  const EscalationDescription = (
    <ActivityFormItem
      label={descriptionLabel}
      name="escalationDescription"
      initialValue={description}
    >
      <DescriptionTextArea placeholder={descriptionPlaceholder} />
    </ActivityFormItem>
  );

  return (
    <>
      {bulkAction ? (
        <EllipsisListContainer>
          <EllipsisList
            label="Added Invoices"
            list={map(selectedInvoiceNo, (item) => String(item))}
            totalRecords={bulkRecordsLength}
            showScrollablePopover
          />
        </EllipsisListContainer>
      ) : (
        InvoiceSelectList
      )}
      {ShowLineItemsBox}
      {AssignedToEscalationOwner}
      {EscalationDescription}
    </>
  );
}
