import {
  CustomField,
  CustomFieldDataResponse,
  CustomFieldPage,
  CustomFieldSearch,
  UnFilteredCustomFiled,
} from 'types/entities/custom-field';
import ky from './ky';

const baseUrl = 'custom-fields/v2';

type CustomFieldParam = { entity: string; page: CustomFieldPage };

export function getCustomFields({ page, entity }: CustomFieldParam) {
  const url = `${baseUrl}/${entity}`;
  const searchParams = { page };

  return ky.get(url, { searchParams }).json<CustomField[]>();
}

export async function getCustomFieldValues(id: number, entity: string) {
  return (await ky.get(`${entity}/${id}/custom-fields`).json()) as CustomFieldDataResponse[];
}
export async function getAllCustomFields(entity: string) {
  const url = `custom-fields/${entity}`;
  return (await ky.get(url).json()) as UnFilteredCustomFiled;
}

//for future purpose

export async function searchCustomFields(text: string, id: number, size?: number) {
  return (await ky
    .get(`custom-fields/${id}/options/search`, { searchParams: { text, size: String(size) } })
    .json()) as CustomFieldSearch;
}
