import { isArray } from 'lodash';
import { localStore, sessionStore } from './browser-storage';
export interface ConfigProps {
  showWriteOff: boolean;
}
interface storageProp {
  config: ConfigProps;
}
export function clearStorageValues(propertyKey: PropertyKey, deleteAbleKey: string) {
  const keys = [
    'AGING_FILTERS',
    'ALL_CUSTOMERS_FILTERS',
    'OPEN_INVOICES',
    'PAYMENT_LIST_FILTERS',
    'CLOSED_INVOICES',
  ];
  const { getItem: getFromSessionStorage, setItem: setToSessionStorage } = sessionStore;
  keys.forEach((key: string) => {
    const value = getFromSessionStorage(key) as any;

    if (!value) return;
    for (const keys in value as any) {
      if (
        value[keys][propertyKey] &&
        Array.isArray(value[keys][propertyKey]) &&
        value[keys][propertyKey]
          .map((f: any) => Object.keys(f).includes(deleteAbleKey))
          .includes(true)
      ) {
        delete value[keys];
      }
    }
    setToSessionStorage(key, value);
  });
}

export function clearLocalStorageValues() {
  const keys = [
    'COLUMNS_CLOSED_IN',
    'COLUMNS_CLOSED_IN_C',
    'COLUMNS_DRAFT_IN',
    'COLUMNS_DRAFT_IN_C',
    'COLUMNS_OPEN_IN',
    'COLUMNS_OPEN_IN_C',
  ];
  const { getItem, setItem, removeItem } = localStore;

  const isColumnClear = getItem('COLUMN_CLEARED');

  if (isColumnClear) return;
  keys.forEach((key: string) => {
    removeItem(key);
    setItem('COLUMN_CLEARED', 'true');
  });
}

export function clearGeneralLocalStorageValues(params: storageProp) {
  const { getItem: getFromLocalStorage, setItem: setLocalStorage } = localStore;
  const { getItem: getFromSessionStorage, setItem: setToSessionStorage } = sessionStore;
  const localKeys = ['CLOSED_INVOICE_CUSTOMER_STATEMENTS'];
  const sessionKey = ['AGING_FILTERS', 'CLOSED_INVOICES'];
  const status = params.config.showWriteOff ? [] : ['WRITE_OFF'];
  localKeys.forEach((key) => {
    const data = getFromLocalStorage(key) as any;
    const filteredData = filterRemovableStatuses(data, status);
    setLocalStorage(key, filteredData);
  });
  sessionKey.forEach((key) => {
    const data = getFromSessionStorage(key) as any;
    const filteredData = filterRemovableStatuses(data, status);
    setToSessionStorage(key, filteredData);
  });
}
function filterRemovableStatuses(data: any, removableStatuses: string[]): any {
  if (!data) return data;

  const result = { ...data };

  Object.entries(result).forEach(([key, value]) => {
    const values = value as any;

    if (isArray(values?.status)) {
      values.status = values.status.filter((status: string) => !removableStatuses.includes(status));
    } else if (key === 'status' && isArray(values)) {
      result[key] = values.filter((status: string) => !removableStatuses.includes(status));
    }
  });

  return result;
}
