import { sanitizeSubject } from 'components/BaseComponents/RTE/util/sanitizeSubject';
import { deSerializeContacts } from 'components/Common/Contact/util';
import { ActivityMutationProps } from 'components/HigherOrderComponent/KeyActivitesContainer/type';
import produce from 'immer';
import { omit } from 'lodash';
import { TFilterViews } from 'store/aging/types';
import { Attachments } from 'store/email/types';
import { InvoiceStrategyStatus } from 'types/entities/collection-strategy';
import { cleanArrayPredicate } from 'util/predicates';
import ky from './ky';

/** COMMON FORMAT
 *
 * @param params : obj containing filter data to select which invoices to target for bulk action
 * @param baseUrl : to differentiate b/w invoices bulk actions from either:
 *  - customer details page (/customers/${id}/invoices?${searchParams})
 *  - invoice list page (/invoices/${id}/)
 */

function getTransformedFilterParams(params: any) {
  const copy = { ...params.followup_email };

  const filteredParams = omit(params.followup_email, [
    'attach_all_open_invoices',
    'attach_current_invoice',
    'generative_attachments',
    'file_upload_ids',
    'customer_document_ids',
    'customer_document_type_ids',
    'invoice_document_ids',
    'invoice_document_type_ids',
  ]);

  const Attachments: Attachments = [
    {
      type: copy.attach_all_open_invoices
        ? 'ALL_OPEN_INVOICES'
        : copy.attach_current_invoice
        ? 'CURRENT_INVOICE'
        : undefined,
    },
    {
      type: copy.generative_attachments?.length ? 'GENERATIVE_ATTACHMENT' : undefined,
      list: copy.generative_attachments?.length ? copy.generative_attachments : undefined,
    },
    {
      type: copy.file_upload_ids?.length ? 'FILE_UPLOADS' : undefined,
      list: copy.file_upload_ids?.length ? copy.file_upload_ids : undefined,
    },
    {
      type: copy.customer_document_ids?.filter(cleanArrayPredicate)?.length
        ? 'DOCUMENT'
        : copy.customer_document_type_ids?.filter(cleanArrayPredicate).length
        ? 'DOCUMENT'
        : copy.invoice_document_ids?.filter(cleanArrayPredicate).length
        ? 'DOCUMENT'
        : copy.invoice_document_type_ids?.filter(cleanArrayPredicate).length
        ? 'DOCUMENT'
        : undefined,
      customer_document_ids: copy.customer_document_ids?.filter(cleanArrayPredicate).length
        ? copy.customer_document_ids
        : undefined,
      invoice_document_ids: copy.invoice_document_ids?.filter(cleanArrayPredicate).length
        ? copy.invoice_document_ids
        : undefined,
      customer_document_type_ids: copy.customer_document_type_ids?.filter(cleanArrayPredicate)
        .length
        ? copy.customer_document_type_ids
        : undefined,
      invoice_document_type_ids: copy.invoice_document_type_ids?.filter(cleanArrayPredicate).length
        ? copy.invoice_document_type_ids
        : undefined,
    },
  ];

  const modifiedParams = { ...params, followup_email: filteredParams };

  return params.bulk_action === 'EMAIL'
    ? produce(modifiedParams, (item: any) => {
        item.followup_email.to = deSerializeContacts(item.followup_email.to);
        item.followup_email.cc = deSerializeContacts(item.followup_email.cc);
        item.followup_email.bcc = deSerializeContacts(item.followup_email.bcc);
        item.followup_email.subject = sanitizeSubject(item.followup_email.subject);
        item.followup_email.attachments = Attachments.filter((element) => Boolean(element.type));

        return item;
      })
    : params;
}

export async function postInvoiceListKeyActivitiesBulk(params: any, baseUrl: string) {
  const updatedParams = getTransformedFilterParams(params);
  return await ky.post(`${baseUrl}/bulk-action`, { json: updatedParams });
}

export async function addUnFlagBulkInvoices(params: any, baseUrl: string) {
  return await ky.post(`${baseUrl}/bulk-action`, { json: params });
}

export async function postKeyActivitiesCustomerDetailsBulk(
  paramsBody: any,
  baseUrl: string,
  omitedList: string[] = ['page', 'size', 'status']
): Promise<ActivityMutationProps> {
  const modified = omit(paramsBody, omitedList);
  const updatedParams = getTransformedFilterParams(modified);

  return (await ky.post(`${baseUrl}/bulk-action`, {
    json: updatedParams,
  })) as unknown as Promise<ActivityMutationProps>;
}

export async function addFlagToCustomerDetailsBulkInvoices(params: any, baseUrl: string) {
  return await ky.post(`${baseUrl}/bulk-action`, { json: params });
}

export async function postAgingKeyActivitiesBulk(params: any, baseUrl: string) {
  const updatedParams = getTransformedFilterParams(params);

  return await ky.post(`aging/${baseUrl}/bulk-action`, { json: updatedParams });
}

export type BulkActionPayload = TFilterViews & {
  bulk_action: string;
  automation_followup_status: {
    status: InvoiceStrategyStatus;
  };
  filter?: TFilterViews;
  view_child?: boolean;
};

export async function pauseResumeCustomerFollowupDetailsBulkInvoices(
  params: BulkActionPayload,
  baseUrl: string
) {
  return await ky.post(`${baseUrl}/bulk-action`, { json: params });
}
export async function updateStrategyForCustomerBulk(params: any, baseUrl: string) {
  return await ky.post(`aging/${baseUrl}/bulk-action`, { json: params });
}

export async function postAllCustomersKeyActivitiesBulk(params: any, baseUrl: string) {
  const updatedParams = getTransformedFilterParams(params);

  return await ky.post(`${baseUrl}/bulk-action`, { json: updatedParams });
}
