import { Row } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import styled from 'styled-components';

export const FormFooter = styled(Flex)`
  padding: var(--space-12) var(--space-24);
  background-color: var(--gray-2);
  border-radius: var(--br-1);
`;
export const FormBody = styled(Flex)`
  padding: var(--space-24);
  background-color: var(--gray-1);
`;
export const FormHeader = styled(Row)`
  padding: var(--space-16) var(--space-24);
  background-color: var(--gray-2);
  gap: var(--space-4);
`;
