import useFilterUsers, {
  CustomEventListener,
  FilterCustomEvents,
} from 'components/Common/FilterComponents/Common/hooks/useFilterUsers';
import { ShowIfActive } from 'components/Common/FilterComponents/Common/ShowIfActive';
import { DateRangeWrapper } from 'components/Common/FilterComponents/FilterWrapper/DateRangeWrapper';
import { MinMaxWrapper } from 'components/Common/FilterComponents/FilterWrapper/MinMaxWrapper';

import { MultiSelectCheckboxWrapper } from 'components/Common/FilterComponents/FilterWrapper/MultiSelectCheckboxWrapper';
import { MultiSelectCheckboxWrapperWithSearch } from 'components/Common/FilterComponents/FilterWrapper/MultiSelectCheckboxWrapperWithSearch';
import { SingleSelectWrapper } from 'components/Common/FilterComponents/FilterWrapper/SingleSelectWrapper';
import { StandardFilterGroup } from 'components/Common/FilterComponents/types';
import { InvoiceCollectionActivitiesDropDownList } from 'constants/collection-activities/invoice-collection-activities';
import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getBusinessUnits } from 'services/business-units';
import { searchCollectionPeriods } from 'services/collection-period';
import { getCollectionStrategies } from 'services/collection-strategy';

import { SubsidiaryFilter } from 'components/Common/EntityFilter';
import { DayRangeWrapper } from 'components/Common/FilterComponents/FilterWrapper/DayRangeWrapper';
import HideWrapper from 'components/Common/Util/HideWrapper';
import { useConfig } from 'components/HigherOrderComponent/Config/config';
import { isEmpty, isEqual } from 'lodash';
import { useBillingContactsQuery } from 'queries/users';
import { getAllDisputeTypes } from 'services/dispute-type';
import { searchInvoiceContacts } from 'services/invoice';
import { getRegions } from 'services/regions';
import { strategyV2EnabledSelector } from 'store/authentication/authentication';
import { CollectionPeriodFilterSelector } from 'store/collection-period/selector';
import { CollectionPeriod } from 'store/collection-period/types';
import { collectionStatusSelector } from 'store/collection-status/collection-status';
import {
  InvoiceFollowupStatusDropDownList,
  OpenInvoiceStatusDropDownList,
} from 'store/invoice/type';
import styled from 'styled-components';
import { Emailable } from 'types/activities/email';
import { AccountConfigKey } from 'types/entities/account';
import { CustomFieldEntityType } from 'types/entities/custom-field';
import { invoiceEmailDeliveryStatusOptions } from 'types/entities/invoice';
import { User } from 'types/entities/user';
import { useOpenInvoiceListFilter } from '../../hooks/useOpenInvoiceListFilter';
import {
  ArrayKeys,
  PrimitiveKeys,
  updateArrayPayload,
  updatePrimitivePayload,
} from '../../reducers/filterValuesReducer';
import { FilterKeys } from '../../reducers/type';
import { CustomFieldFilters } from '../CustomField';

const t = {
  placeholder: {
    collectionOwner: 'Search Collection Owners',
    CSMmanagers: 'Search CSM Managers',
    accountManager: 'Search Account Managers',
    collectionPeriods: 'Search Collection Periods',
    billingContact: 'Search Billing Contact',
    collectionManager: 'Search Collection Managers',
    salesManager: 'Search Sales Managers',
  },
  emptyText: {
    collectionOwner: 'No Collection Owner Found',
    CSMmanagers: 'No CSM Managers Found',
    accountManager: 'No Account Managers Found',
    collectionPeriods: 'No Collection Periods Found',
    billingContacts: 'No Billing Contacts Found',
    collectionManager: 'No Collection Managers Found',
    salesManager: 'No Sales Managers Found',
  },
};

const StyledFilterDropdownTitle = styled.div`
  padding-bottom: var(--space-8);
  color: var(--gray-gray-9);
  font-weight: var(--fs-semibold);
`;

type InvoiceFilterProps = ReturnType<typeof useOpenInvoiceListFilter>;
export function useInvoiceFilter(props: InvoiceFilterProps) {
  const advancedContactEnabled = useConfig(AccountConfigKey.ADVANCED_CONTACTS);

  const { filterValues, updateFilterValues, filterGroups, updateFilterGroups } = props;
  const { data: billingContacts } = useBillingContactsQuery();

  function filterMemoCallback() {
    //improve by fetching only when it is active.
    return {
      businessUnitids: filterValues.business_unit_ids,
      regionIds: filterValues.region_ids,
      subsidiaryIds: filterValues.subsidiary_ids,
    };
  }

  const searchParams = useMemo(filterMemoCallback, [
    filterValues.business_unit_ids,
    filterValues.region_ids,
    filterValues.subsidiary_ids,
  ]);

  const [collectionManagers, salesManagers] = useFilterUsers({
    filterValues: searchParams,
  });

  function updateArrayValue<T extends ArrayKeys>(payload: updateArrayPayload<T>) {
    const { key, value } = payload;

    updateFilterValues({
      type: 'UPDATE_ARRAY',
      payload: { key, value },
    });
  }

  function updateArrayValueList<T extends ArrayKeys>(payload: updateArrayPayload<T>[]) {
    updateFilterValues({
      type: 'UPDATE_ARRAY_LIST',
      payload: payload,
    });
  }

  const updateArrayValueListCallback = useCallback(updateArrayValueList, [updateFilterValues]);

  function updatePrimitiveValue<T extends PrimitiveKeys>(
    updatedValues: Array<updatePrimitivePayload<T>>
  ) {
    updateFilterValues({
      type: 'UPDATE_PRIMITIVES',
      payload: updatedValues,
    });
  }

  function onRemoveFilter(groupId: string, filterId: string) {
    updateFilterGroups({
      type: 'UPDATE_FILTER',
      payload: { groupId: groupId, filterId: filterId, updatedValues: { active: false } },
    });
  }

  function computedBillingContactOptions(contacts: Emailable[] | undefined) {
    if (contacts && Boolean(contacts.length)) {
      return contacts
        .filter((contact) => !!contact.email)
        .map((contact, contactItemsIndex) => {
          const firstName = contact?.first_name ?? '';
          const lastName = contact?.last_name ?? '';
          const isNameEmpty = isEmpty(firstName) && isEmpty(lastName);
          let label = isNameEmpty ? contact?.email ?? '' : `${firstName} ${lastName}`;
          const contactHasValidName = !isNameEmpty;

          if (contactHasValidName) {
            const contactWithDuplicateName = contacts?.find(
              (item, findContactIndex) =>
                !isEqual(findContactIndex, contactItemsIndex) &&
                isEqual([item.first_name ?? '', item.last_name ?? ''], [firstName, lastName])
            );
            label += contactWithDuplicateName && contact.email ? `(${contact.email})` : '';
          }

          return {
            value: contact.id,
            label,
          };
        });
    }
    return [];
  }

  function computedCollectionManagerOptionsMemo() {
    if (collectionManagers && Boolean(collectionManagers.length)) {
      return collectionManagers.map((manager) => ({
        value: manager.id,
        label: `${manager.first_name} ${manager.last_name ?? ''}`,
      }));
    }
    return [];
  }
  function computedSalesManagerOptionsMemo() {
    if (salesManagers && Boolean(salesManagers.length)) {
      return salesManagers.map((manager) => ({
        value: manager.id,
        label: `${manager.first_name} ${manager.last_name ?? ''}`,
      }));
    }
    return [];
  }

  async function searchCollectionManagers(keyword: string) {
    if (collectionManagers && Boolean(collectionManagers.length)) {
      return collectionManagers.filter(
        (manager) =>
          manager.first_name.toLowerCase().includes(keyword.toLowerCase()) ||
          (manager.last_name && manager.last_name.toLowerCase().includes(keyword.toLowerCase())) ||
          (manager.last_name &&
            `${manager.first_name.toLowerCase()}${manager.last_name.toLowerCase()}`.includes(
              keyword.toLowerCase()
            )) ||
          (manager.last_name &&
            `${manager.first_name.toLowerCase()} ${manager.last_name.toLowerCase()}`.includes(
              keyword.toLowerCase()
            ))
      );
    }
    return [];
  }
  async function searchSalesManagers(keyword: string) {
    if (salesManagers && Boolean(salesManagers.length)) {
      return salesManagers.filter(
        (manager) =>
          manager.first_name.toLowerCase().includes(keyword.toLowerCase()) ||
          (manager.last_name && manager.last_name.toLowerCase().includes(keyword.toLowerCase())) ||
          (manager.last_name &&
            `${manager.first_name.toLowerCase()}${manager.last_name.toLowerCase()}`.includes(
              keyword.toLowerCase()
            )) ||
          (manager.last_name &&
            `${manager.first_name.toLowerCase()} ${manager.last_name.toLowerCase()}`.includes(
              keyword.toLowerCase()
            ))
      );
    }
    return [];
  }

  const handleSearchCollectionManagers = useCallback(searchCollectionManagers, [
    collectionManagers,
  ]);
  const handleSearchSalesManagers = useCallback(searchSalesManagers, [salesManagers]);
  /**  Event callback start */
  useEffect(() => {
    function eventListener(event: CustomEventListener) {
      if (
        filterValues.assigned_to?.length ||
        filterValues.account_manager_ids?.length ||
        filterValues.cs_manager_ids?.length
      ) {
        const payLoad = [] as updateArrayPayload<ArrayKeys>[];

        if (filterValues.assigned_to?.length) {
          const validOwnerIds = filterValues.assigned_to.filter(
            (assignedTo) =>
              event?.detail &&
              event.detail.collectionOwners &&
              Boolean(event.detail.collectionOwners.length) &&
              event.detail.collectionOwners.findIndex(
                (validOwerId) => validOwerId.id === assignedTo
              ) > -1
          );

          payLoad.push({ key: 'assigned_to', value: validOwnerIds });
        }

        if (filterValues.account_manager_ids?.length) {
          const validAccManagerIds = filterValues.account_manager_ids.filter(
            (accManagerId) =>
              event?.detail &&
              event.detail.accountManagers &&
              Boolean(event.detail.accountManagers.length) &&
              event.detail.accountManagers.findIndex((managerId) => managerId.id === accManagerId) >
                -1
          );

          payLoad.push({ key: 'account_manager_ids', value: validAccManagerIds });
        }

        if (filterValues.cs_manager_ids?.length) {
          const validCSManagerIds = filterValues.cs_manager_ids.filter(
            (csManagerId) =>
              event?.detail &&
              event.detail.csmManagers &&
              Boolean(event.detail.csmManagers.length) &&
              event.detail.csmManagers.findIndex((managerId) => managerId.id === csManagerId) > -1
          );

          payLoad.push({ key: 'cs_manager_ids', value: validCSManagerIds });
        }

        updateArrayValueListCallback(payLoad);
      }
    }

    document.addEventListener(
      FilterCustomEvents.USERS_FETCHED as unknown as keyof DocumentEventMap,
      eventListener as EventListener
    );

    return () => {
      document.removeEventListener(
        FilterCustomEvents.USERS_FETCHED as unknown as keyof DocumentEventMap,
        eventListener as EventListener
      );
    };
  }, [
    filterValues.account_manager_ids,
    filterValues.assigned_to,
    filterValues.cs_manager_ids,
    updateArrayValueListCallback,
  ]);
  /**  Event callback end */

  /** InvoiceStatusFilter  start */
  const invoiceStatusFilterConfig = {
    name: 'invoice-status',
    label: 'Invoice status',
    options: OpenInvoiceStatusDropDownList,
  };

  const InvoiceStatusFilter = (
    <MultiSelectCheckboxWrapper
      key={FilterKeys.INVOICE_STATUS}
      filterConfig={invoiceStatusFilterConfig}
      value={filterValues.status}
      showClear
      onSubmit={(value) => updateArrayValue({ key: 'status', value })}
    />
  );
  /** InvoiceStatusFilter  end */

  /** Due Date Filter start */
  const DueDateFilterConfig = {
    name: 'filter-by-due-date',
    label: 'Due Date',
  };

  const DueDateFilterFilter = (
    <DateRangeWrapper
      key={FilterKeys.DUE_DATE}
      showAllRelativeOptions
      filterConfig={DueDateFilterConfig}
      value={{
        from: filterValues.due_date_from,
        to: filterValues.due_date_to,
        type: filterValues.due_date_range,
      }}
      showClear
      onSubmit={({ from, to, type }) =>
        updatePrimitiveValue([
          { key: 'due_date_from', value: from },
          { key: 'due_date_to', value: to },
          { key: 'due_date_range', value: type },
        ])
      }
    />
  );
  /** Due Date Filter end */

  /** TotalBalanceFilter start */
  const totalBalanceFilterConfig = { name: 'total-balance', label: 'Due Amount' };
  const totalBalanceFilterId = FilterKeys.TOTAL_DUE_AMOUNT;

  const TotalBalanceFilter = (
    <ShowIfActive
      filterGroupId={StandardFilterGroup.INVOICE}
      filterId={totalBalanceFilterId}
      filterGroups={filterGroups}
      key={totalBalanceFilterId}
    >
      <MinMaxWrapper
        prefix={{ type: 'ACCOUNT_CURRENCY' }}
        filterConfig={totalBalanceFilterConfig}
        value={{ min: filterValues.amount_min, max: filterValues.amount_max }}
        showClear
        onClear={() => onRemoveFilter(StandardFilterGroup.INVOICE, totalBalanceFilterId)}
        onSubmit={(value) =>
          updatePrimitiveValue([
            { key: 'amount_min', value: value.min },
            { key: 'amount_max', value: value.max },
          ])
        }
      />
    </ShowIfActive>
  );
  /** TotalBalanceFilter end */

  /** FollowupStatusFilter  start */
  const followupStatusFilterConfig = {
    name: 'followup-status',
    label: 'Followup Status',
    options: InvoiceFollowupStatusDropDownList,
  };

  const followupStatusFilterId = FilterKeys.FOLLOWUP_STATUS;

  const FollowupStatusFilter = (
    <ShowIfActive
      filterGroupId={StandardFilterGroup.INVOICE}
      filterId={followupStatusFilterId}
      filterGroups={filterGroups}
      key={followupStatusFilterId}
    >
      <MultiSelectCheckboxWrapper
        filterConfig={followupStatusFilterConfig}
        value={filterValues.followup_status}
        showClear
        onClear={() => onRemoveFilter(StandardFilterGroup.INVOICE, followupStatusFilterId)}
        onSubmit={(value) => updateArrayValue({ key: 'followup_status', value })}
      />
    </ShowIfActive>
  );
  /** FollowupStatusFilter  end */

  /** IssueDateFilter start */
  const IssueDateFilterConfig = {
    name: 'filter-by-due-date',
    label: 'Issued date Range',
  };

  const IssueDateFilterId = FilterKeys.ISSUED_DATE;

  const IssueDateFilter = (
    <ShowIfActive
      filterGroupId={StandardFilterGroup.INVOICE}
      filterId={IssueDateFilterId}
      filterGroups={filterGroups}
      key={IssueDateFilterId}
    >
      <DateRangeWrapper
        filterConfig={IssueDateFilterConfig}
        value={{
          from: filterValues.issue_date_from,
          to: filterValues.issue_date_to,
          type: filterValues.issue_date_range,
        }}
        showClear
        onClear={() => onRemoveFilter(StandardFilterGroup.INVOICE, IssueDateFilterId)}
        onSubmit={({ from, to, type }) =>
          updatePrimitiveValue([
            { key: 'issue_date_from', value: from },
            { key: 'issue_date_to', value: to },
            { key: 'issue_date_range', value: type },
          ])
        }
      />
    </ShowIfActive>
  );
  /** IssueDateFilter end */

  /** LastActivityFilter start */
  const LastActivityFilterConfig = {
    name: 'filter-by-last-activity',
    label: 'Last Activity on',
  };

  const LastActivityFilterId = FilterKeys.LAST_ACTIVITY;

  const LastActivityFilter = (
    <ShowIfActive
      filterGroupId={StandardFilterGroup.INVOICE}
      filterId={LastActivityFilterId}
      filterGroups={filterGroups}
      key={LastActivityFilterId}
    >
      <DateRangeWrapper
        filterConfig={LastActivityFilterConfig}
        value={{
          from: filterValues.last_activity_from,
          to: filterValues.last_activity_to,
          type: filterValues.last_activity_range,
        }}
        showClear
        onClear={() => onRemoveFilter(StandardFilterGroup.INVOICE, LastActivityFilterId)}
        onSubmit={({ from, to, type }) =>
          updatePrimitiveValue([
            { key: 'last_activity_from', value: from },
            { key: 'last_activity_to', value: to },
            { key: 'last_activity_range', value: type },
          ])
        }
      />
    </ShowIfActive>
  );
  /** LastActivityFilter end */

  /** BillingContactFilter start */

  const billingContactComputedList = useCallback(computedBillingContactOptions, []);
  const billingContactoptions = useMemo(
    () => billingContactComputedList(billingContacts),
    [billingContactComputedList, billingContacts]
  );

  const BillingContactFilterTitle = (
    <StyledFilterDropdownTitle>Billing Contacts</StyledFilterDropdownTitle>
  );

  const billingContactFilterConfig = {
    name: 'billing-contacts',
    label: 'Billing Contacts',
    options: billingContactoptions,
    customTitle: BillingContactFilterTitle,
    shouldIncludeUnassigned: true,
  };

  function handleBillingContactFilterSubmit(value: number[]) {
    updatePrimitiveValue([
      {
        key: 'is_billing_contact_assigned',
        value: value.length ? !value.includes(-1) : undefined,
      },
    ]);
    updateArrayValue({ key: 'contact_ids', value });
  }

  const BillingContactFilter = (
    <ShowIfActive
      filterGroupId={StandardFilterGroup.INVOICE}
      filterGroups={filterGroups}
      filterId={FilterKeys.BILLING_CONTACT}
      key={FilterKeys.BILLING_CONTACT}
    >
      <MultiSelectCheckboxWrapperWithSearch<Emailable, number[]>
        filterConfig={billingContactFilterConfig}
        value={filterValues.contact_ids}
        shouldDebounce
        onClear={() => onRemoveFilter(StandardFilterGroup.INVOICE, FilterKeys.BILLING_CONTACT)}
        onSubmit={handleBillingContactFilterSubmit}
        onSearch={searchInvoiceContacts}
        placeholder={t.placeholder.billingContact}
        emptyText={t.emptyText.billingContacts}
        style={{ width: '100%' }}
        onSearchDataTransform={billingContactComputedList}
      />
    </ShowIfActive>
  );

  /** BillingContactFilter End */

  /** NextFollowupFilter start */

  /**
   * Product team requested commenting this for time being
   */

  // const NextFollowupFilterConfig = {
  //   name: 'filter-by-next-followup',
  //   label: 'Next Followup Task on',
  // };

  // const NextFollowupFilterId = FilterKeys.NEXT_FOLLOWUP;

  // const NextFollowupFilter = (
  //   <ShowIfActive
  //     filterGroupId={StandardFilterGroup.INVOICE}
  //     filterId={NextFollowupFilterId}
  //     filterGroups={filterGroups}
  //     key={NextFollowupFilterId}
  //   >
  //     <DateRangeWrapper
  //       filterConfig={NextFollowupFilterConfig}
  //       value={{
  //         from: filterValues.next_followup_from,
  //         to: filterValues.next_followup_to,
  //         type: filterValues.next_followup_range,
  //       }}
  //       showClear
  //       onClear={() => onRemoveFilter(StandardFilterGroup.INVOICE, NextFollowupFilterId)}
  //       onSubmit={({ from, to, type }) =>
  //         updatePrimitiveValue([
  //           { key: 'next_followup_from', value: from },
  //           { key: 'next_followup_to', value: to },
  //           { key: 'next_followup_range', value: type },
  //         ])
  //       }
  //     />
  //   </ShowIfActive>
  // );
  /** NextFollowupFilter end */

  /** PromiseToPayFilter start */
  const PromiseToPayFilterFilterConfig = {
    name: 'filter-by-promise-to-pay',
    label: 'Promise to Pay Date Range',
  };

  const PromiseToPayFilterId = FilterKeys.PROMISE_TO_PAY;

  const PromiseToPayFilter = (
    <ShowIfActive
      filterGroupId={StandardFilterGroup.INVOICE}
      filterId={PromiseToPayFilterId}
      filterGroups={filterGroups}
      key={PromiseToPayFilterId}
    >
      <DateRangeWrapper
        filterConfig={PromiseToPayFilterFilterConfig}
        value={{
          from: filterValues.promise_pay_from,
          to: filterValues.promise_pay_to,
          type: filterValues.promise_pay_range,
        }}
        showClear
        onClear={() => onRemoveFilter(StandardFilterGroup.INVOICE, PromiseToPayFilterId)}
        onSubmit={({ from, to, type }) =>
          updatePrimitiveValue([
            { key: 'promise_pay_from', value: from },
            { key: 'promise_pay_to', value: to },
            { key: 'promise_pay_range', value: type },
          ])
        }
      />
    </ShowIfActive>
  );
  /** PromiseToPayFilter end */

  /** CollectionManagerFilter start */
  const collectionManagerOptions = useMemo(computedCollectionManagerOptionsMemo, [
    collectionManagers,
  ]);

  const collectionManagerFilterConfig = {
    name: 'collection-manager',
    label: 'Collection Manager',
    options: collectionManagerOptions,
    shouldIncludeUnassigned: false,
  };

  const CollectionManagerFilter = (
    <ShowIfActive
      filterGroupId={StandardFilterGroup.CUSTOMER}
      filterId={FilterKeys.COLLECTION_MANAGER_IDS}
      filterGroups={filterGroups}
      key={FilterKeys.COLLECTION_MANAGER_IDS}
    >
      <MultiSelectCheckboxWrapperWithSearch<User, number[]>
        labelAttribute={['first_name', 'last_name']}
        valueAttribute="id"
        filterConfig={collectionManagerFilterConfig}
        value={filterValues.collection_manager_ids}
        showClear
        onClear={() =>
          onRemoveFilter(StandardFilterGroup.CUSTOMER, FilterKeys.COLLECTION_MANAGER_IDS)
        }
        onSubmit={(value) => {
          updateArrayValue({ key: 'collection_manager_ids', value });
        }}
        onSearch={handleSearchCollectionManagers}
        placeholder={t.placeholder.collectionManager}
        emptyText={t.emptyText.collectionManager}
      />
    </ShowIfActive>
  );
  /**   /** CollectionManagerFilter  end */

  /** SalesManagerFilter start */
  const salesManagerOptions = useMemo(computedSalesManagerOptionsMemo, [salesManagers]);

  const salesManagerFilterConfig = {
    name: 'sales-manager',
    label: 'AM/CSM Team Lead',
    options: salesManagerOptions,
    shouldIncludeUnassigned: false,
  };

  const SalesManagerFilter = (
    <ShowIfActive
      filterGroupId={StandardFilterGroup.CUSTOMER}
      filterId={FilterKeys.SALES_MANAGER_IDS}
      filterGroups={filterGroups}
      key={FilterKeys.SALES_MANAGER_IDS}
    >
      <MultiSelectCheckboxWrapperWithSearch<User, number[]>
        labelAttribute={['first_name', 'last_name']}
        valueAttribute="id"
        filterConfig={salesManagerFilterConfig}
        value={filterValues.sales_manager_ids}
        showClear
        onClear={() => onRemoveFilter(StandardFilterGroup.CUSTOMER, FilterKeys.SALES_MANAGER_IDS)}
        onSubmit={(value) => {
          updateArrayValue({ key: 'sales_manager_ids', value });
        }}
        onSearch={handleSearchSalesManagers}
        placeholder={t.placeholder.salesManager}
        emptyText={t.emptyText.salesManager}
      />
    </ShowIfActive>
  );
  /**   /** SalesManagerFilter  end */

  /** CollectionStatusFilter start */
  const collectionStatuses = useSelector(collectionStatusSelector).collectionStatuses;

  const computedCollecionStatus = useMemo(() => {
    return (
      collectionStatuses?.map((status) => ({
        value: status.id,
        label: status.label,
      })) ?? []
    );
  }, [collectionStatuses]);

  const collectionStatusFilterConfig = {
    name: 'collection-status',
    label: 'Collection Status',
    options: computedCollecionStatus,
  };

  const collectionStatusFilterId = FilterKeys.COLLECTION_STATUS;

  const CollectionStatusFilter = (
    <ShowIfActive
      filterGroupId={StandardFilterGroup.CUSTOMER}
      filterId={collectionStatusFilterId}
      filterGroups={filterGroups}
      key={collectionStatusFilterId}
    >
      <MultiSelectCheckboxWrapper
        shouldIncludeUnassigned
        filterConfig={collectionStatusFilterConfig}
        value={filterValues.collection_status_ids}
        showClear
        onClear={() => onRemoveFilter(StandardFilterGroup.CUSTOMER, collectionStatusFilterId)}
        onSubmit={(value) => {
          updatePrimitiveValue([
            { key: 'is_collection_status_not_assigned', value: value.includes(-1) },
          ]);
          updateArrayValue({ key: 'collection_status_ids', value });
        }}
      />
    </ShowIfActive>
  );
  /** CollectionStatusFilter end */

  /** DisputeTypeFilter  start */
  const disputeTypeFetchCallback = useCallback(async () => {
    const options = await getAllDisputeTypes();

    return options?.map((disputeType) => ({
      value: disputeType.id,
      label: disputeType.name,
    }));
  }, []);

  const disputeTypeFilterConfig = {
    name: 'Dispute Type',
    label: 'Dispute Type',
    options: disputeTypeFetchCallback,
  };

  const disputeTypeFilterId = FilterKeys.DISPUTE_TYPE;

  const DisputeTypeFilter = (
    <ShowIfActive
      filterGroupId={StandardFilterGroup.INVOICE}
      filterId={disputeTypeFilterId}
      filterGroups={filterGroups}
      key={disputeTypeFilterId}
    >
      <MultiSelectCheckboxWrapper
        filterConfig={disputeTypeFilterConfig}
        value={filterValues.dispute_type_ids}
        showClear
        onClear={() => onRemoveFilter(StandardFilterGroup.INVOICE, disputeTypeFilterId)}
        onSubmit={(value) => {
          updateArrayValue({ key: 'dispute_type_ids', value });
        }}
      />
    </ShowIfActive>
  );
  /** DisputeTypeFilter  end */

  /** CollectionStrategyFilter start */
  const collectionStrategiesCallback = useCallback(async () => {
    try {
      const collectionStrategies = await getCollectionStrategies();

      return collectionStrategies.map((collectionStrategy) => ({
        value: collectionStrategy.id,
        label: collectionStrategy.title,
      }));
    } catch {
      return [];
    }
  }, []);

  const collectionStrategyFilterConfig = {
    name: 'collection-strategy',
    label: 'Collection Strategy',
    options: collectionStrategiesCallback,
  };

  const collectionStrategyFilterId = FilterKeys.COLLECTION_STRATEGIES;

  const CollectionStrategyFilter = (
    <ShowIfActive
      filterGroupId={StandardFilterGroup.INVOICE}
      filterId={collectionStrategyFilterId}
      filterGroups={filterGroups}
      key={collectionStrategyFilterId}
    >
      <MultiSelectCheckboxWrapper
        shouldIncludeUnassigned
        filterConfig={collectionStrategyFilterConfig}
        value={filterValues.collection_strategy_ids}
        showClear
        onClear={() => onRemoveFilter(StandardFilterGroup.INVOICE, collectionStrategyFilterId)}
        onSubmit={(value) => {
          updateArrayValue({ key: collectionStrategyFilterId, value });
          updatePrimitiveValue([
            { key: 'is_collection_strategy_not_assigned', value: value.includes(-1) },
          ]);
        }}
      />
    </ShowIfActive>
  );

  /** CollectionStrategyFilter end */

  /** CollectionStrategyStatusFilter start */
  const collectionStrategyStatuses = useMemo(
    () => [
      { value: 'RUNNING', label: 'Running' },
      { value: 'PAUSED', label: 'Paused' },
    ],
    []
  );

  const collectionStrategyStatusFilterConfig = {
    name: 'collection-strategy-status',
    label: 'Collection Strategy Status',
    options: collectionStrategyStatuses,
  };

  const collectionStrategyStatusFilterId = FilterKeys.COLLECTION_STRATEGY_STATUSES;

  const CollectionStrategyStatusFilter = (
    <ShowIfActive
      filterGroupId={StandardFilterGroup.INVOICE}
      filterId={collectionStrategyStatusFilterId}
      filterGroups={filterGroups}
      key={collectionStrategyStatusFilterId}
    >
      <MultiSelectCheckboxWrapper
        filterConfig={collectionStrategyStatusFilterConfig}
        value={filterValues.invoice_followup_statuses}
        showClear
        onClear={() =>
          onRemoveFilter(StandardFilterGroup.INVOICE, collectionStrategyStatusFilterId)
        }
        onSubmit={(value) => {
          updateArrayValue({ key: FilterKeys.COLLECTION_STRATEGY_STATUSES, value });
        }}
      />
    </ShowIfActive>
  );
  /** CollectionStrategyStatusFilter end */

  /** SubsidiaryFilter  start */
  const subdsidiaryFilterId = FilterKeys.SUBSIDIARY;
  const isSubsidiaryEnabled = useConfig(AccountConfigKey.SUBSIDIARY_ENABLED);

  const SubsidiaryFilterJSX = isSubsidiaryEnabled ? (
    <HideWrapper hide={!isSubsidiaryEnabled}>
      <SubsidiaryFilter
        value={filterValues.subsidiary_ids}
        onClear={() => onRemoveFilter(StandardFilterGroup.CUSTOMER, subdsidiaryFilterId)}
        onSubmit={(value) => {
          updateArrayValue({ key: 'subsidiary_ids', value });
          updatePrimitiveValue([{ key: 'is_subsidiary_not_assigned', value: value.includes(-1) }]);
        }}
      />
    </HideWrapper>
  ) : null;
  /** SubsidiaryFilter  end */

  /** BusinessUnitFilter  start */
  const businessUnitFetchCallback = useCallback(async () => {
    const options = await getBusinessUnits();

    return options?.map((businessUnit) => ({
      value: businessUnit.id,
      label: businessUnit.name,
    }));
  }, []);

  const businessUnitFilterConfig = {
    name: 'Business Unit',
    label: 'Business Unit',
    options: businessUnitFetchCallback,
  };

  const businessUnitFilterId = FilterKeys.BUSINESS_UNIT;

  const BusinessUnitFilter = (
    <ShowIfActive
      filterGroupId={StandardFilterGroup.CUSTOMER}
      filterId={businessUnitFilterId}
      filterGroups={filterGroups}
      key={businessUnitFilterId}
    >
      <MultiSelectCheckboxWrapper
        shouldIncludeUnassigned
        filterConfig={businessUnitFilterConfig}
        value={filterValues.business_unit_ids}
        showClear
        onClear={() => onRemoveFilter(StandardFilterGroup.CUSTOMER, businessUnitFilterId)}
        onSubmit={(value) => {
          updateArrayValue({ key: 'business_unit_ids', value });
          updatePrimitiveValue([
            { key: 'is_business_unit_not_assigned', value: value.includes(-1) },
          ]);
        }}
      />
    </ShowIfActive>
  );
  /** BusinessUnitFilter  end */

  /** RegionFilter  start */
  const regionFetchCallback = useCallback(async () => {
    const options = await getRegions();

    return options?.map((region) => ({
      value: region.id,
      label: region.name,
    }));
  }, []);

  const regionFilterConfig = {
    name: 'Region',
    label: 'Region',
    options: regionFetchCallback,
  };

  const regionFilterId = FilterKeys.REGION;

  const RegionFilter = (
    <ShowIfActive
      filterGroupId={StandardFilterGroup.CUSTOMER}
      filterId={regionFilterId}
      filterGroups={filterGroups}
      key={regionFilterId}
    >
      <MultiSelectCheckboxWrapper
        shouldIncludeUnassigned
        filterConfig={regionFilterConfig}
        value={filterValues.region_ids}
        showClear
        onClear={() => onRemoveFilter(StandardFilterGroup.CUSTOMER, regionFilterId)}
        onSubmit={(value) => {
          updateArrayValue({ key: 'region_ids', value });
          updatePrimitiveValue([{ key: 'is_region_not_assigned', value: value.includes(-1) }]);
        }}
      />
    </ShowIfActive>
  );
  /** RegionFilter  end */

  const strategyV2Enabled = useSelector(strategyV2EnabledSelector);
  const StrategyFilters = strategyV2Enabled
    ? [CollectionStrategyFilter, CollectionStrategyStatusFilter]
    : [];
  /** CollectionActivitiesFilter  start */
  const collectionActivitiesFilterConfig = {
    name: 'collection-activity',
    label: 'Collection Activity',
    options: InvoiceCollectionActivitiesDropDownList,
  };

  const CollectionActivitiesFilter = (
    <ShowIfActive
      filterGroupId={StandardFilterGroup.INVOICE}
      filterId={FilterKeys.COLLECTION_ACTIVITY}
      filterGroups={filterGroups}
      key={FilterKeys.COLLECTION_ACTIVITY}
    >
      <SingleSelectWrapper
        filterConfig={collectionActivitiesFilterConfig}
        value={filterValues.collection_activity}
        showClear
        onClear={() => onRemoveFilter(StandardFilterGroup.INVOICE, FilterKeys.COLLECTION_ACTIVITY)}
        onSubmit={(value) => updateArrayValue({ key: 'collection_activity', value })}
      />
    </ShowIfActive>
  );
  /** CollectionActivitiesFilter  end */

  const collectionPeriods = useSelector(CollectionPeriodFilterSelector('invoice-list'));

  const computedCollectionPeriods = useMemo(() => {
    return (
      collectionPeriods?.map((status) => ({
        value: status.id,
        label: status.name,
      })) ?? []
    );
  }, [collectionPeriods]);

  const collectionPeriodsFilterConfig = {
    name: 'collection-periods',
    label: 'Collection Periods',
    options: computedCollectionPeriods,
    shouldIncludeUnassigned: true,
  };

  const CollectionPeriodFilter = (
    <ShowIfActive
      filterGroupId={StandardFilterGroup.INVOICE}
      filterId={FilterKeys.COLLECTION_PERIODS}
      filterGroups={filterGroups}
      key={FilterKeys.COLLECTION_PERIODS}
    >
      <MultiSelectCheckboxWrapperWithSearch<CollectionPeriod, number[]>
        filterConfig={collectionPeriodsFilterConfig}
        value={filterValues.collection_period_ids}
        showClear
        shouldDebounce
        onClear={() => onRemoveFilter(StandardFilterGroup.INVOICE, FilterKeys.COLLECTION_PERIODS)}
        onSubmit={(value) => {
          updateArrayValue({ key: 'collection_period_ids', value });
          updatePrimitiveValue([
            { key: 'is_collection_period_not_assigned', value: value.includes(-1) },
          ]);
        }}
        valueAttribute="id"
        labelAttribute="name"
        onSearch={searchCollectionPeriods}
        placeholder={t.placeholder.collectionPeriods}
        emptyText={t.emptyText.collectionPeriods}
      />
    </ShowIfActive>
  );

  const emailDeliveryStatusConfig = {
    name: 'Invoice Delivery Status',
    label: 'Invoice Delivery Status',
    options: invoiceEmailDeliveryStatusOptions,
  };

  const EmailDeliveryStatusFilter = (
    <ShowIfActive
      filterGroupId={StandardFilterGroup.INVOICE}
      filterId={FilterKeys.EMAIL_DELIVERY_STATUS}
      filterGroups={filterGroups}
      key={FilterKeys.EMAIL_DELIVERY_STATUS}
    >
      <MultiSelectCheckboxWrapper
        filterConfig={emailDeliveryStatusConfig}
        value={filterValues.email_delivery_status}
        showClear
        onClear={() =>
          onRemoveFilter(StandardFilterGroup.INVOICE, FilterKeys.EMAIL_DELIVERY_STATUS)
        }
        onSubmit={(value) => {
          updateArrayValue({ key: 'email_delivery_status', value });
        }}
      />
    </ShowIfActive>
  );

  /** CustomFields  start */
  const CustomFieldFilter = (
    <CustomFieldFilters
      key={FilterKeys.CUSTOM_FIELD}
      {...props}
      customFieldType={CustomFieldEntityType.INVOICE}
    />
  );
  /** CustomFields  end */

  /** DueDaysRangeFilter  start */
  const dueDaysRangeFilterConfig = {
    name: 'due-days-range',
    label: 'Due Days Range',
  };

  const DueDaysRangeFilter = (
    <DayRangeWrapper
      filterConfig={dueDaysRangeFilterConfig}
      value={{
        start: filterValues.due_days_min,
        end: filterValues.due_days_max,
        type: filterValues.due_days_range,
      }}
      showClear
      onSubmit={({ start, end, type }) => {
        updatePrimitiveValue([
          { key: 'due_days_min', value: start },
          { key: 'due_days_max', value: end },
          { key: 'due_days_range', value: type },
        ]);
      }}
    />
  );
  /** DueDaysRangeFilter  end */

  const DefaultFilterComponents = [
    InvoiceStatusFilter,
    DueDateFilterFilter,
    DueDaysRangeFilter,
    SubsidiaryFilterJSX,
  ];

  const ExtraFilterComponents = [
    TotalBalanceFilter,
    FollowupStatusFilter,
    IssueDateFilter,
    LastActivityFilter,
    PromiseToPayFilter,
    advancedContactEnabled && BillingContactFilter,
    CollectionStatusFilter,
    DisputeTypeFilter,
    ...StrategyFilters,
    CollectionActivitiesFilter,
    CustomFieldFilter,
    CollectionPeriodFilter,
    BusinessUnitFilter,
    RegionFilter,
    CollectionManagerFilter,
    SalesManagerFilter,
    EmailDeliveryStatusFilter,
  ];

  return {
    DefaultFilterComponents,
    ExtraFilterComponents,
  };
}
