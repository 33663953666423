import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TableInitialState } from '@sinecycle/growcomponents';
import { useConfig } from 'components/HigherOrderComponent/Config/config';
import { EActions } from 'components/HigherOrderComponent/KeyActionsContainer/types';
import { useClosedCustomerDetailsInvoiceListQuery, useOpenClosedListQuery } from 'queries/invoices';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { accountConfigSelector } from 'store/authentication/authentication';
import { IInvoices, IInvoicesQueryParams, StatementsResponse } from 'store/customer/types';
import { ClosedInvoicesListFilters } from 'store/invoice/type';
import { ELocalStorageVariable } from 'store/type';
import { AccountConfigKey } from 'types/entities/account';
import { InvoiceStatus } from 'types/entities/invoice';
import { ClosedInvoiceFilterParams } from 'types/filter-view/closed-invoice';
import { InvoiceStatementTable } from '../InvoiceStatements/InvoiceStatementTable';
import useDownloadPdf from '../InvoiceStatements/useDownloadPdf';
import { useColumn } from './Columns/useColumn';
import { closedInvoiceStatus } from './utils';
interface ClosedInvoicesStatementsProps {
  isFromInvoiceList?: boolean;
  customerId?: number;
  currentFilter?: ClosedInvoicesListFilters;
  saveFilterViews?: (config: ClosedInvoicesListFilters) => void;
  isLoading?: boolean;
  hasActionAccess?: boolean;
  scroll?: {
    x?: number | true | string;
    y?: string;
  };
  currentPage: number;
  handlePageChange: (page: number) => void;
  hasSavedFilterUpdated?: boolean;
}

const defaultSortColumn = 'INVOICE_DATE';

const ClosedInvoicesStatements = (props: ClosedInvoicesStatementsProps) => {
  const { handleDownload } = useDownloadPdf();
  const showWriteOff = useConfig(AccountConfigKey.SHOW_WRITEOFF);
  const closedInvoiceList = useMemo(getClosedInvoiceStatusList, [showWriteOff]);

  const configList = useSelector(accountConfigSelector);
  const parentConfig = configList?.parent_child_config;
  const [pagination, setPagination] = useState<
    Pick<TableInitialState<IInvoices>, 'bulk_records' | 'currentPage'>
  >({ currentPage: props.currentPage, bulk_records: 0 });
  const [pageSize] = useState<number>(20);
  const { column } = useColumn(
    props.isFromInvoiceList,
    0,
    pagination.currentPage,
    props.currentFilter as unknown as ClosedInvoiceFilterParams
  );
  function getParams() {
    return {
      ...(props.currentFilter ? props.currentFilter : {}),
      filter_type: 'CLOSED',
      status: props.currentFilter?.status ?? [],
      sort_col:
        props.currentFilter?.sort_col === 'BALANCE_DUE_AMOUNT' //defensive coding to ensure we don't use due_amount column which might be saved in cache
          ? defaultSortColumn
          : props.currentFilter?.sort_col ?? defaultSortColumn,
      sort_by: props.currentFilter?.sort_by ?? 'ASC',
      sort_by_custom_field: props.currentFilter?.sort_by_custom_field,
      page: props.currentPage,
      size: pageSize,
    } as IInvoicesQueryParams;
  }

  const param = useMemo(getParams, [props.currentFilter, props.currentPage, pageSize]);

  const updateFilterView = (value: ClosedInvoicesListFilters) => {
    if (props.saveFilterViews) {
      props.saveFilterViews({
        ...(props.currentFilter ? props.currentFilter : ({} as ClosedInvoicesListFilters)),
        ...value,
      });
    }
  };

  function handleSuccessCallBack(data: StatementsResponse<IInvoices>) {
    if (!props.isFromInvoiceList) {
      localStorage.setItem(
        ELocalStorageVariable.CLOSED_INVOICE_CUSTOMER_STATEMENTS,
        JSON.stringify(param)
      );
      if (!props.currentFilter) {
        props.saveFilterViews?.(param as unknown as ClosedInvoicesListFilters);
      }
    }
    setPagination({
      bulk_records: data.total_records,
      currentPage: data.current_page,
    });
  }

  const { isLoading, data: closedInvoice } = useOpenClosedListQuery({
    param: param?.status?.length === 0 ? { ...param, status: closedInvoiceList } : param,
    onSuccessCallBack: handleSuccessCallBack,
    enable: props.isFromInvoiceList && props.hasSavedFilterUpdated,
  });
  const closedReqParam = { ...param, view_child: parentConfig?.view_child };
  const { isLoading: customerLoading, data: closedInvoiceCustomer } =
    useClosedCustomerDetailsInvoiceListQuery({
      param:
        closedReqParam?.status?.length === 0
          ? { ...closedReqParam, status: closedInvoiceList }
          : props.isFromInvoiceList
          ? param
          : closedReqParam,
      onSuccessCallBack: handleSuccessCallBack,
      enable: !props.isFromInvoiceList,
      customerId: String(props.customerId),
    });

  const loading = props.isFromInvoiceList ? isLoading : customerLoading;
  function getClosedInvoiceStatusList() {
    return [...closedInvoiceStatus, ...(showWriteOff ? [InvoiceStatus.WRITE_OFF] : [])];
  }

  return (
    <InvoiceStatementTable
      loading={loading}
      data={props.isFromInvoiceList ? closedInvoice?.list : closedInvoiceCustomer?.list}
      onSortChange={(values) => {
        updateFilterView({
          sort_by: values.sortOrder as string,
          sort_col: values.sortCol.startsWith('CUSTOM_FIELD') ? 'CUSTOM_FIELD' : values.sortCol,
          sort_by_custom_field: values.customCol,
        } as ClosedInvoicesListFilters);
      }}
      getUpdatedTableValue={() => {}}
      onPageChange={(page) => {
        props.handlePageChange(page);
      }}
      columns={column}
      tableState={{
        currentPage: pagination.currentPage,
        selectedRows: [],
        selectAll: { select_all: false, selected: false },
        bulk_records: pagination.bulk_records,
      }}
      rowClassRules={{
        'flagged-row': (params) => {
          return Boolean(params.data?.invoice_flag);
        },
      }}
      isFromInvoiceList={props.isFromInvoiceList}
      quickOperations={{
        items: [
          {
            key: EActions.DOWNLOAD_PDF,
            label: 'Download PDF',
            icon: <FontAwesomeIcon icon={['fal', 'download']} />,
          },
        ],
        onClick(key, selectedRows) {
          handleDownload(selectedRows);
        },
      }}
      columnPreserveKey={props.isFromInvoiceList ? 'COLUMNS_CLOSED_IN' : 'COLUMNS_CLOSED_IN_C'}
      currentFilter={props.currentFilter}
    />
  );
};

export default ClosedInvoicesStatements;
