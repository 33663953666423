import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';

import { UserAvatar } from 'components/HigherOrderComponent/UserAvatar';

import { dateFormats } from '@sinecycle/growcomponents';
import { Card, Col, Divider, Row } from 'antd';
import { sanitizeBodyAndSubject } from 'components/BaseComponents/RTE/util/sanitizeSubject';
import { EmailAbleTag } from 'components/CollectionActivities/Email/EmailableTag/EmailAbleTag';
import HideWrapper from 'components/Common/Util/HideWrapper';
import { EmailRecepientStatus } from 'components/HigherOrderComponent/KeyActivitesContainer/Email/EmailRecepientStatus/EmailRecepientStatus';
import { useSelector } from 'react-redux';
import { IConversationParticipant } from 'store/activity-feed/type';
import { dateFormatSelector } from 'store/authentication/authentication';
import { EmailConversation, EmailableType } from 'types/activities/email';
import { extractNameFromEmail } from 'util/regex';
import { PanelDesc } from './PanelDesc';

const { Meta } = Card;
interface PanelProps {
  conversation: EmailConversation;
  expand: boolean;
}

function PanelHeader(props: PanelProps) {
  const { conversation, expand } = props;
  const format = useSelector(dateFormatSelector);
  if (!conversation.from) return null;

  const nameFromEmail = extractNameFromEmail(conversation.from.email);

  const Avatar = (
    <UserAvatar
      name={[
        conversation.from?.first_name ?? nameFromEmail?.firstName,
        conversation.from?.last_name ?? nameFromEmail?.lastName,
      ]}
    />
  );

  const Title = (
    <Row justify="space-between">
      <Col>
        <Flex align="center" gap="var(--space-8)" style={{ lineHeight: '1.2' }}>
          <GrowText strong>{`${conversation.from?.first_name ?? nameFromEmail?.firstName} ${
            conversation.from?.last_name ?? nameFromEmail?.lastName
          }`}</GrowText>
          {conversation.from.emailable &&
          conversation.from.emailable.type === EmailableType.ACCOUNT ? (
            <EmailAbleTag
              type={conversation.from.emailable.type}
              participantRelation={conversation.from.emailable.participant_relation}
            />
          ) : null}
        </Flex>
      </Col>

      <Col>
        <Row align="middle" gutter={[16, 16]}>
          <HideWrapper hide={conversation.incoming}>
            <Col>
              <EmailRecepientStatus
                to={conversation.to as unknown as IConversationParticipant[]}
                bcc={
                  conversation.bcc
                    ? (conversation.bcc as unknown as IConversationParticipant[])
                    : []
                }
                cc={
                  conversation.cc ? (conversation.cc as unknown as IConversationParticipant[]) : []
                }
              />
            </Col>
          </HideWrapper>
          <HideWrapper hide={conversation.incoming}>
            <Divider style={{ margin: '0', height: '16px' }} type="vertical" />
          </HideWrapper>
          {conversation.sent_at ? (
            <Col>
              <GrowText
                style={{ color: 'var(--gray-7)', fontWeight: 'var(--fs-regular)' }}
              >{`${dateFormats.date(conversation.sent_at, format)}, ${dateFormats.time(
                conversation.sent_at
              )}`}</GrowText>
            </Col>
          ) : null}
        </Row>
      </Col>
    </Row>
  );
  const Desc = expand ? (
    <PanelDesc conversation={conversation} />
  ) : (
    <GrowText ellipsis style={{ color: 'var(--gray-7)', maxWidth: '50vw' }}>
      {sanitizeBodyAndSubject(conversation.body)}
    </GrowText>
  );

  return <Meta avatar={Avatar} title={Title} description={Desc} />;
}

export default PanelHeader;
