import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ActivityButtons } from 'components/Common/ActivitiesForm/types';
import { ActivityButtonsDisplayKeys, ActivityConfig } from './type';

function getIconConfig(
  iconType: IconProp,
  label: string,
  disabled?: boolean,
  buttonConfig?: ActivityButtons
): ActivityConfig {
  const iconColor = disabled
    ? undefined
    : buttonConfig?.color
    ? buttonConfig?.color
    : 'var(--primary-7)';

  const icon = <FontAwesomeIcon icon={iconType} color={iconColor} />;
  return { icon, label };
}
export function getTaskConfig(disabled?: boolean, buttonConfig?: ActivityButtons): ActivityConfig {
  return getIconConfig(['far', 'check'], 'Create Task', disabled, buttonConfig);
}

export function getCallLogConfig(
  disabled?: boolean,
  buttonConfig?: ActivityButtons
): ActivityConfig {
  return getIconConfig(['far', 'phone'], 'Call Log', disabled, buttonConfig);
}

export function getNoteConfig(disabled?: boolean, buttonConfig?: ActivityButtons): ActivityConfig {
  return getIconConfig(['far', 'clipboard'], 'Add Note', disabled, buttonConfig);
}

export function getDisputeConfig(
  disabled?: boolean,
  buttonConfig?: ActivityButtons
): ActivityConfig {
  return getIconConfig(['far', 'comment-xmark'], 'Raise a Dispute', disabled, buttonConfig);
}

export function getDocumentConfig(
  disabled?: boolean,
  buttonConfig?: ActivityButtons
): ActivityConfig {
  return getIconConfig(['far', 'file-arrow-up'], 'Add Document', disabled, buttonConfig);
}

export function getEscalationConfig(
  disabled?: boolean,
  buttonConfig?: ActivityButtons
): ActivityConfig {
  return getIconConfig(['far', 'circle-exclamation'], 'Escalate', disabled, buttonConfig);
}

export function getWriteoffConfig(
  disabled?: boolean,
  buttonConfig?: ActivityButtons
): ActivityConfig {
  return getIconConfig(['far', 'scissors'], 'Write Off', disabled, buttonConfig);
}

export function getPTPConfig(disabled?: boolean, buttonConfig?: ActivityButtons): ActivityConfig {
  return getIconConfig(['far', 'handshake'], 'Promise to Pay', disabled, buttonConfig);
}

export function getActivityConfig(
  key: ActivityButtonsDisplayKeys['key'],
  disabled?: boolean,
  buttonConfig?: ActivityButtons
) {
  const config: Record<ActivityButtonsDisplayKeys['key'], ActivityConfig> = {
    TASK: getTaskConfig(disabled, buttonConfig),
    CALL_LOG: getCallLogConfig(disabled, buttonConfig),
    ESCALATION: getEscalationConfig(disabled, buttonConfig),
    PROMISE_TO_PAY: getPTPConfig(disabled, buttonConfig),
    DOCUMENT: getDocumentConfig(disabled, buttonConfig),
    DISPUTE: getDisputeConfig(disabled, buttonConfig),
    NOTE: getNoteConfig(disabled, buttonConfig),
    WRITE_OFF: getWriteoffConfig(disabled, buttonConfig),
  };

  return config[key];
}
