import { StatusTag } from '@sinecycle/growcomponents';
import { ParticipantRelation } from 'store/contacts/contacts';
import { EmailableType } from 'types/activities/email';
import { getEmailableConfig } from './predicate';

interface EmailAbleTagProps {
  type: Exclude<EmailableType, EmailableType.AR_EMAIL>;
  participantRelation?: ParticipantRelation;
}

function EmailAbleTag(props: EmailAbleTagProps) {
  const { type, participantRelation } = props;
  const config = getEmailableConfig(type);
  const computedLabel =
    participantRelation === 'PARENT' ? `${config.label} (Parent)` : config.label;
  return <StatusTag text={computedLabel} style={config.style} />;
}

export { EmailAbleTag };
