import { GrowTypography, StatusTag } from '@sinecycle/growcomponents';
import { Col, Row } from 'antd';
import { take } from 'lodash';
import { getLabelConfig } from 'types/activities/email';
import { InboxDefaultLabels } from 'types/api/inbox/email';

interface EmailLabelsProps {
  labels: InboxDefaultLabels[];
  count?: number;
  textStyle?: React.CSSProperties;
}

function EmailLabels(props: EmailLabelsProps) {
  const { labels, count = 2, textStyle } = props;
  const first = take(labels, count);

  if (labels.includes(InboxDefaultLabels.MANUAL_FOLLOWUP)) return null;
  return (
    <Row gutter={[8, 8]} wrap={false}>
      {first.map((label) => {
        return (
          <Col key={label}>
            <StatusTag
              text={
                <GrowTypography.Text ellipsis style={textStyle}>
                  {getLabelConfig(label).text}
                </GrowTypography.Text>
              }
              style={getLabelConfig(label).style}
            />
          </Col>
        );
      })}
    </Row>
  );
}

export { EmailLabels };
