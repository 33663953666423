import { Button } from 'antd';
import { errorState } from 'assets/image-urls';
import { Texto } from 'components/BaseComponents/Typography/Texto';
import { PageLink } from 'lib/router';
import { pagePath } from 'router/constants/page-info';

function TakeMeTo({ text, url }: { text: string; url: string }) {
  return (
    <PageLink TO_LINK={url} appPage="DASHBOARD">
      <Button type="primary" size="large">
        {text}
      </Button>
    </PageLink>
  );
}

export default function ErrorPage() {
  const searchParams = new URLSearchParams(window.location.search);

  const errMessage = searchParams.get('errMsg') ?? 'Sorry Some Error',
    takeMeTo = searchParams.get('redirectionPage') ?? 'Home',
    redirectionUrl = searchParams.get('redirectionUrl') ?? pagePath('DASHBOARD');

  return (
    <NotFoundPage
      errorMessage={errMessage}
      redirectionPage={takeMeTo}
      redirectionUrl={redirectionUrl}
    />
  );
}

interface NotFoundPageProps {
  errorMessage: string;
  redirectionPage: string;
  redirectionUrl: string;
}

const errorPageStyle: React.CSSProperties = {
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: '10vh',
  gap: '4vh',
};

export function NotFoundPage(props: NotFoundPageProps) {
  const { errorMessage, redirectionPage, redirectionUrl } = props;

  return (
    <div style={errorPageStyle}>
      <img
        src={errorState}
        alt="Not found"
        style={{ width: '50vw', height: '50vh' }}
        draggable={false}
      />
      <Texto as="div" size="20" weight="semibold">
        {errorMessage}
      </Texto>
      <TakeMeTo text={`Take me to ${redirectionPage}`} url={redirectionUrl} />
    </div>
  );
}
