import rootKy, { HTTPError, KyResponse } from 'ky';
import { requestToSnakeCase, responseToCamelCase } from 'util/case-converter';
import { pruneRequestPayload } from 'util/prune-api-request';
import { logError } from './utils';

export type ApiResponse<T> = Omit<KyResponse, 'json'> & {
  json: () => Promise<T>;
};
export type ApiError<T> = Omit<HTTPError, 'response'> & {
  response: ApiResponse<T>;
};

export interface ErrorMessage {
  field_name: string;
  message: string;
  rejected_value: string;
}
const ky = rootKy.create({
  prefixUrl: '/api',
  timeout: false,
  retry: 1,

  hooks: {
    beforeRequest: [pruneRequestPayload],
    beforeError: [logError],
  },
});

// For usage in hitting external APIs
export const unprefixedKy = rootKy.create({});

export const kyWithSerializer = rootKy.create({
  prefixUrl: '/api',
  timeout: false,
  retry: 1,

  hooks: {
    beforeRequest: [requestToSnakeCase],
    afterResponse: [responseToCamelCase],
    beforeError: [logError],
  },
});

export default ky;
export { HTTPError };
