import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { notification } from 'antd';
import { ArgsProps } from 'antd/lib/notification';
import { CSSProperties, ReactNode } from 'react';

enum NotificationType {
  success = 'success',
  info = 'info',
  warning = 'warning',
  error = 'error',
}

const t = {
  defaultNotifications: {
    success: {
      message: 'Success',
    },
    warning: {
      message: 'Warning',
    },
    info: {
      message: 'Info',
    },
    error: {
      message: 'Oops :(',
      description: 'The previous action was unsuccessful. Please retry.',
    },
  } as Record<NotificationType, { message: string; description: string }>,
};

const NotificationTypeColorMapping = {
  success: {
    text: 'var(--gray-9)',
    background: 'var(--green-1)',
    border: 'var(--green-3)',
  },
  info: {
    text: 'var(--gray-9)',
    background: 'var(--blue-1)',
    border: 'var(--blue-3)',
  },

  warning: {
    text: 'var(--gray-9)',
    background: 'var(--gold-1)',
    border: 'var(--gold-3)',
  },
  error: {
    text: 'var(--gray-9)',
    background: 'var(--red-1)',
    border: 'var(--red-3)',
  },
} as Record<NotificationType, { text: string; background: string; border: string }>;

function getNotificationMessage(type: NotificationType, message: ReactNode): ReactNode {
  // fallback to default message
  const messageContent: ReactNode = message ? message : t.defaultNotifications[type].message;

  return typeof messageContent === 'string' ? (
    <span style={{ color: NotificationTypeColorMapping[type].text, fontWeight: 600 }}>
      {messageContent}
    </span>
  ) : (
    messageContent
  );
}

function getNotificationStyle(type: NotificationType): CSSProperties {
  return {
    width: '25vw',
    background: NotificationTypeColorMapping[type].background,
    border: `1px solid ${NotificationTypeColorMapping[type].border}`,
    borderRadius: '8px',
  };
}

type NotificationOptions = Pick<
  ArgsProps,
  'onClick' | 'placement' | 'style' | 'description' | 'duration' | 'onClose' | 'closeIcon'
>;

const defaultOptions: NotificationOptions = {
  placement: 'bottomRight',
  closeIcon: <FontAwesomeIcon icon={['far', 'times']} color="var(--primary-7)" />,
};

export const notify = {
  success: (message: ReactNode, options?: NotificationOptions) => {
    notification.success({
      ...defaultOptions,
      message: getNotificationMessage(NotificationType.success, message),
      style: getNotificationStyle(NotificationType.success),
      icon: <FontAwesomeIcon icon={['far', 'check-circle']} color="var(--green-8)" />,
      ...options,
    });
  },
  info: (message: ReactNode, options?: NotificationOptions) => {
    notification.info({
      ...defaultOptions,
      message: getNotificationMessage(NotificationType.info, message),
      style: getNotificationStyle(NotificationType.info),
      ...options,
    });
  },
  error: (message: ReactNode, options?: NotificationOptions) => {
    notification.error({
      ...defaultOptions,
      message: getNotificationMessage(NotificationType.error, message),
      style: getNotificationStyle(NotificationType.error),
      ...options,
    });
  },
  warning: (message: ReactNode, options?: NotificationOptions) => {
    notification.warning({
      ...defaultOptions,
      message: getNotificationMessage(NotificationType.warning, message),
      style: getNotificationStyle(NotificationType.warning),
      ...options,
    });
  },
};

export const defaultErrorNotification = () => {
  notify.error(t.defaultNotifications.error.message, {
    description: t.defaultNotifications.error.description,
  });
};
