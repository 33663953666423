import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { StyledStatusTag } from './style';

export type StatusTagStyle = {
  borderColor?: string;
  backgroundColor: string;
  textColor: string;
};
type StatusMode =
  | { mode: 'Icon'; text: string; icon?: React.ReactNode; iconColor: string }
  | {
      mode: 'Tags';
      text: string;
      style: StatusTagStyle;
      noBg?: boolean;
      className?: string;
    };
interface StatusTagProps {
  status: StatusMode;
}

function StatusCell(props: StatusTagProps) {
  return props.status.mode === 'Tags' ? (
    <StyledStatusTag
      background={props.status.style.backgroundColor}
      textcolor={props.status.style.textColor}
      className={props.status.className}
    >
      {props.status.text}
    </StyledStatusTag>
  ) : (
    <Flex align="center" gap="var(--space-8)" justify="flex-start" style={{ height: '100%' }}>
      {props.status.icon ? (
        props.status.icon
      ) : (
        <FontAwesomeIcon icon={['far', 'square']} color={props.status.iconColor} />
      )}
      <GrowText style={{ fontSize: 'var(--fs-14)', color: 'var(--gray-9)' }}>
        {props.status.text}
      </GrowText>
    </Flex>
  );
}

export { StatusCell };
