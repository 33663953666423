import { CustomFieldIconWithText } from '@sinecycle/growcomponents';
import { NodeViewWrapper } from '@tiptap/react';
import { useTranslatePlaceholderToLabel } from 'components/BaseComponents/RTE/hooks/useTemplateConverter';

export function PlaceHolderTagView(props: any) {
  const { node } = props;
  const { getPlaceholderLabel, getPlaceholderSection } = useTranslatePlaceholderToLabel();
  const label = getPlaceholderLabel(node?.attrs?.placeholder);
  const section = getPlaceholderSection(node?.attrs?.placeholder);
  const hasCustomFieldIcon = section === 'Custom Fields';

  return (
    <NodeViewWrapper contentEditable={false} as="span">
      <PlaceholderTag
        label={label}
        value={node?.attrs?.placeholder}
        hasCustomFieldIcon={hasCustomFieldIcon}
      />
    </NodeViewWrapper>
  );
}

export function PlaceholderTag({
  label,
  value,
  hasCustomFieldIcon,
}: {
  label: string;
  hasCustomFieldIcon: boolean;
  value?: string;
}) {
  return (
    <span
      data-placeholder={value}
      style={{
        background: 'var(--geekblue-1)',
        color: 'var(--geekblue-6)',
        padding: 'var(--space-2) var(--space-0)',
        borderRadius: 'var(--space-2)',
        margin: 'var(--space-2)',
        width: 'fit-content',
        whiteSpace: 'pre-wrap',
      }}
      className="grow-email-placeholder"
    >
      <CustomFieldIconWithText hasSymbol={hasCustomFieldIcon}> {label} </CustomFieldIconWithText>
    </span>
  );
}
