import { Node, mergeAttributes } from '@tiptap/core';
import EmailContent from 'components/BaseComponents/RTE/Toolbar/common/nodes/EmailContent/EmailContent';
import { getNodeViewRender } from 'lib/RichText';

export const CustomEmailContent = Node.create({
  name: 'CustomEmailContent',
  group: 'block',
  content: 'block*',
  selectable: true,
  isolating: true,
  defining: true,
  addAttributes() {
    return {
      style: {
        default: null,
        parseHTML: (element) => {
          if (element) {
            return element.getAttribute('style');
          }
        },
        renderHTML: ({ style }) => style,
      },
      'data-html': {
        default: null,
        parseHTML: (element) => {
          if (element) {
            return element.outerHTML;
          }
        },
        renderHTML() {
          return null;
        },
      },
      class: {
        default: null,
        parseHTML: (element) => {
          return element.getAttribute('class');
        },
        renderHTML: (HTMLAttributes) => {
          return {
            class: HTMLAttributes.class,
          };
        },
      },
    };
  },
  parseHTML() {
    return [
      {
        tag: 'div.reply-email',
      },
    ];
  },
  renderHTML({ node, HTMLAttributes }) {
    return [
      'div',
      mergeAttributes(
        {
          class: 'gf-quote',
        },
        HTMLAttributes
      ),
      0,
    ];
  },
  addNodeView() {
    return getNodeViewRender(EmailContent);
  },
});

export const CustomSignature = Node.create({
  name: 'CustomSignature',
  group: 'block',
  content: 'block*',
  selectable: true,
  isolating: true,
  defining: true,
  draggable: true,
  addAttributes() {
    return {
      style: {
        default: null,
        parseHTML: (element) => {
          if (element) {
            return element.getAttribute('style');
          }
        },
        renderHTML: ({ style }) => style,
      },
      'data-html': {
        default: null,
        parseHTML: (element) => {
          if (element) {
            return element.outerHTML;
          }
        },
        renderHTML() {
          return null;
        },
      },
      class: {
        default: null,
        parseHTML: (element) => {
          return element.getAttribute('class');
        },
        renderHTML: (HTMLAttributes) => {
          return {
            class: HTMLAttributes.class,
          };
        },
      },
    };
  },
  parseHTML() {
    return [
      {
        tag: 'div.growfin-signature',
      },
    ];
  },
  renderHTML({ node, HTMLAttributes }) {
    return [
      'div',
      mergeAttributes(
        {
          class: 'gf-signature',
        },
        HTMLAttributes
      ),
      0,
    ];
  },
});
