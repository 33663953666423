import { GrowTypography, StatusTag } from '@sinecycle/growcomponents';

interface CustomerNameTagProps {
  customerNameText: string;
  width?: string;
}
export default function CustomerNameTag(props: CustomerNameTagProps) {
  const Text = (
    <GrowTypography.Text
      style={{ color: 'var(--primary-7)', maxWidth: props.width ? props.width : '120px' }}
      ellipsis={{ tooltip: props.customerNameText }}
    >
      {props.customerNameText}
    </GrowTypography.Text>
  );
  return (
    <StatusTag
      style={{
        backgroundColor: 'var(--gray-3)',
        textColor: 'var(--primary-7)',
      }}
      text={Text}
    />
  );
}
