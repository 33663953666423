import { EmailFormType } from 'components/Inbox/EmailConversations/EmailThread/type';
import { EmailConversation, EmailThread } from 'types/activities/email';
import { PaginationProps } from 'types/common/pagination';
import { DateRangeSearchParams, PaginationSearchParams } from '../common';

export enum InboxDefaultLabels {
  COLLECTION_STRATEGY = 'AUTOMATED_FOLLOWUP',
  INVOICE_DELIVERY = 'INVOICE_DELIVERY',
  MANUAL_FOLLOWUP = 'MANUAL_FOLLOWUP',
}
export enum InboxEmailConversationType {
  UNREAD = 'UNREAD',
  ALL = 'ALL',
  INCOMING = 'INCOMING',
  STARRED = 'STARRED',
  SENT = 'SENT',
  LABEL = 'LABEL',
}

export enum InboxEmailMode {
  MY_INBOX = 'MY_INBOX',
  AR_INBOX = 'AR_INBOX',
}

export enum InboxEmailSearchParam {
  page = 'page',
  pageSize = 'page_size',
  emailType = 'status',
  emailMode = 'email_mode',
  fromDate = 'from_date',
  toDate = 'to_date',
  threadId = 'thread_id',
  dateRange = 'date_range',
  email_id = 'email_id',
  form_type = 'form_type',
  customer_ids = 'customer_ids',
  has_attachments = 'has_attachments',
  is_unread = 'is_unread',
  labels = 'labels',
  invoice_ids = 'invoice_ids',
}

export const emailSearchParamKeys = [
  InboxEmailSearchParam.page,
  InboxEmailSearchParam.pageSize,
  InboxEmailSearchParam.emailType,
  InboxEmailSearchParam.emailMode,
  InboxEmailSearchParam.fromDate,
  InboxEmailSearchParam.toDate,
  InboxEmailSearchParam.threadId,
  InboxEmailSearchParam.dateRange,
  InboxEmailSearchParam.email_id,
  InboxEmailSearchParam.form_type,
  InboxEmailSearchParam.customer_ids,
  InboxEmailSearchParam.has_attachments,
  InboxEmailSearchParam.is_unread,
  InboxEmailSearchParam.labels,
  InboxEmailSearchParam.invoice_ids,
];

export interface EmailThreadSearchParams extends DateRangeSearchParams, PaginationSearchParams {
  [InboxEmailSearchParam.emailType]: InboxEmailConversationType;
  [InboxEmailSearchParam.threadId]: number;
  [InboxEmailSearchParam.emailMode]: InboxEmailMode;
  [InboxEmailSearchParam.email_id]: string;
  [InboxEmailSearchParam.form_type]: EmailFormType;
  [InboxEmailSearchParam.customer_ids]: any;
  [InboxEmailSearchParam.has_attachments]: boolean;
  [InboxEmailSearchParam.is_unread]: boolean;
  [InboxEmailSearchParam.labels]: InboxDefaultLabels;
  [InboxEmailSearchParam.invoice_ids]: any;
}
export interface EmailThreadFilters extends DateRangeSearchParams, PaginationSearchParams {}

export interface EmailThreadResponse {
  parent_email: EmailConversation;
  replies: EmailConversation[];
}

export interface EmailThreadsList extends PaginationProps {
  data: EmailThread[];
}

export interface UnReadCountResponse {
  unread_count: number;
}
