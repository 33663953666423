import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Pagination } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import produce from 'immer';
import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { StyledLegendPaginationButton } from '../Styles/Styles';
import LegendItem from './LegendItem';
export interface LegendDataItem {
  label: string;
  color: string;
  disable: boolean;
  pageSize?: number;
  id: number | string;
  toggle?: boolean;
}

interface LegendProps {
  data: LegendDataItem[];
  handleOnClickLegend: Function;
  tooltipTextProp?: string;
  textWidth?: string;
  pageItemsSize?: number;
}

//To hide the page numbers form pagination component
const StyledNavIcon = styled(Pagination)`
  li {
    &.ant-pagination-simple-pager {
      display: none !important;
    }
  }
`;

const t = {
  tooltipText: 'No invoices mapped',
};
const pageSize = 5;
const previousButton = (
  <StyledLegendPaginationButton
    right={'var(--space-4)'}
    className="nav-button"
    size="small"
    icon={<FontAwesomeIcon icon={['far', 'chevron-left']} fontSize={12} />}
  />
);

const nextButton = (
  <StyledLegendPaginationButton
    right={'var(--space-12)'}
    className="nav-button"
    size="small"
    icon={<FontAwesomeIcon icon={['far', 'chevron-right']} fontSize={12} />}
  />
);

export default function Legend(props: LegendProps) {
  const [currentPage, setCurrentPage] = useState(1);
  const { handleOnClickLegend, tooltipTextProp } = props;
  const [legendData, setLegendData] = useState(initializeData);

  function initializeData() {
    return props.data.sort((item1, item2) => {
      if (!item1.disable === !item2.disable) {
        return 0;
      }
      if (!item1.disable) {
        return -1;
      }
      return 1;
    });
  }

  const pageItemsSize = props?.pageItemsSize || pageSize;

  function handleCurrentData() {
    const startIndex = (currentPage - 1) * pageItemsSize;
    const endIndex = startIndex + pageItemsSize;
    return produce(legendData, (draft) => {
      return draft.slice(startIndex, endIndex);
    });
  }

  const currentData = useMemo(handleCurrentData, [currentPage, legendData, pageItemsSize]);

  function updateLegendData(id: string | number) {
    const modifiedData = produce(legendData, (draft) => {
      return draft.map((data) => {
        if (data.id === id) {
          return { ...data, toggle: !data.toggle };
        }
        return data;
      });
    });

    setLegendData(modifiedData);
  }

  if (!props.data.length) return null;

  const { data } = props;

  function itemRender(_: any, type: string, originalElement: React.ReactNode) {
    if (type === 'prev') {
      return previousButton;
    }
    if (type === 'next') {
      return nextButton;
    }
    return originalElement;
  }

  const ToolTipText = tooltipTextProp ? tooltipTextProp : t.tooltipText;

  return (
    <div className="legend-container">
      <Flex className="legend-list" direction="column" justify="flex-start">
        {currentData.map((item: LegendDataItem) => {
          return (
            <LegendItem
              key={item.id}
              item={item}
              tooltipText={ToolTipText}
              onClick={() => {
                handleOnClickLegend(item.id);
                updateLegendData(item.id);
              }}
              textWidth={props.textWidth}
            />
          );
        })}
      </Flex>
      <StyledNavIcon
        simple
        total={data.length}
        itemRender={itemRender}
        onChange={(page) => {
          setCurrentPage(page);
        }}
        current={currentPage}
        hideOnSinglePage
        showSizeChanger={false}
        defaultPageSize={pageItemsSize}
        defaultCurrent={1}
      />
    </div>
  );
}
