import { Options } from 'ky';
import { logRocket } from 'lib/logrocket';
import { pruneObject } from './json-utils';

const castToPrimitive = (str: string) => {
  switch (str) {
    case 'null':
      return null;

    case 'undefined':
      return undefined;

    default:
      const arry = str.split(',');

      return arry.length > 1 ? arry : str;
  }
};

/** Clean the URL query string of nullable value */
const pruneQueryString = (url: string) => {
  const urlObject = new URL(url);
  const searchParam = {} as Record<string, unknown>;

  for (const pair of urlObject.searchParams.entries()) {
    const [key, value] = pair;

    searchParam[key] = castToPrimitive(value);
  }

  const prunedParams = pruneObject(searchParam as object);
  const param = new URLSearchParams(prunedParams as Record<string, string>);

  urlObject.search = param.toString();

  return urlObject.toString();
};

const prepareRequest = (request: Request, requestPayload?: object) => {
  const requestURL = pruneQueryString(request.url);
  const init = {
    method: request.method,
    mode: request.mode,
    headers: request.headers,
  } as RequestInit;

  if (requestPayload) {
    const prunedObject = pruneObject(requestPayload);
    const body = JSON.stringify(prunedObject);

    init.body = body;
  } else {
    init.body = request.body;
  }

  return new Request(requestURL, init);
};

export async function pruneRequestPayload(request: Request, options: Options) {
  // run if request had search params or json payload
  // const sessionURL = await logRocket.getSessionURL();
  // request.headers.set('X-Growfin-LogRocket-URL', sessionURL);
  if (options.searchParams || options.json) {
    return prepareRequest(request, options.json as object | undefined);
  }

  return request;
}
