import DescriptionTextArea from 'components/BaseComponents/DescriptionTextArea/TextArea';
import { useFormWatch } from 'components/BaseComponents/Form/Antd/Antd';
import { EllipsisList } from 'components/Common/EllipsisList';
import { StyledFormItem } from 'components/Common/Styles/Styles';
import HideWrapper from 'components/Common/Util/HideWrapper';
import { isEmpty, map, size } from 'lodash';
import InvoiceSelect from '../FormElements/InvoiceSelect';
import ShowLineItems from '../FormElements/ShowLineItems';
import { ActivityFormItem, EllipsisListContainer } from '../style';
import { NoteFormModalProps, NoteModalFormElementProps } from '../types';

export default function NoteFormItems<NoteElements extends NoteModalFormElementProps>(
  props: Omit<NoteFormModalProps<NoteElements>, 'openModal'>
) {
  const {
    invoices,
    form,
    invoiceQueryKey,
    invoiceSearchFn,
    invoiceQueryFn,
    invoiceTransformSchema,
    notes,
    lineItemsQueryFn,
    lineItemsTransformSchema,
    lineItemsQueryKey,
    invoiceLineItems,
    showInvoicesList,
    text,
    bulkAction,
    bulkRecordsLength,
    customerNames,
    invoiceSingleSelect,
  } = props;

  const {
    descriptionLabel,
    descriptionPlaceholder,
    invoiceLabel,
    invoicePlaceholder,
    lineItemsPlaceholder,
  } = text;

  const selectedInvoiceIds = map(invoices, (item) => (item as { id: number })?.id);
  const selectedInvoiceNo = map(invoices, (item) => (item as any)?.invoice_no);
  const invoiceIdsList: number[] | undefined = useFormWatch('noteInvoices', form);
  const referLineItems: boolean | undefined = useFormWatch('referLineItem', form);

  function onChangeInvoiceList(invoiceList: number[]) {
    if (isEmpty(invoiceList) || size(invoiceList) > 1) {
      form.setFieldValue('noteLineItems', undefined);
    }
    form.setFieldValue('noteInvoices', invoiceList);
  }

  const InvoiceSelectList = (
    <>
      {invoiceQueryFn && invoiceSearchFn && invoiceQueryKey && invoiceTransformSchema && (
        <StyledFormItem
          label={invoiceLabel}
          name={'noteInvoices'}
          initialValue={selectedInvoiceIds}
        >
          <InvoiceSelect<NoteElements['invoiceSelect']>
            placeholder={invoicePlaceholder}
            selectedInvoicesId={selectedInvoiceIds}
            selectedInvoices={invoices}
            onChangeInvoice={onChangeInvoiceList}
            queryFn={invoiceQueryFn}
            searchQueryFn={invoiceSearchFn}
            queryKey={invoiceQueryKey}
            transformSchema={invoiceTransformSchema}
            singleSelect={invoiceSingleSelect}
          />
        </StyledFormItem>
      )}
    </>
  );

  const ShowLineItemsBox = (
    <ShowLineItems<NoteElements['lineItemsSelect']>
      form={form}
      checked={referLineItems}
      disabled={invoiceIdsList && (invoiceIdsList?.length > 1 || !invoiceIdsList?.length)}
      onChange={(e) => form.setFieldValue('referLineItem', e.target.checked)}
      placeholder={lineItemsPlaceholder}
      onChangeLineItems={(value) => form.setFieldValue('noteLineItems', value)}
      queryFn={() => lineItemsQueryFn!(invoiceIdsList)}
      queryKey={lineItemsQueryKey!}
      invoiceLineItems={invoiceLineItems}
      invoiceIdsList={invoiceIdsList}
      lineItemFieldKey={'noteLineItems'}
      transformSchema={lineItemsTransformSchema}
      bulkAction={bulkAction}
    />
  );

  const NoteDescription = (
    <ActivityFormItem
      label={descriptionLabel}
      name="noteDescription"
      initialValue={notes}
      rules={[
        {
          required: true,
          message: `${descriptionLabel} is required`,
        },
      ]}
      required
    >
      <DescriptionTextArea placeholder={descriptionPlaceholder} />
    </ActivityFormItem>
  );

  return (
    <>
      <HideWrapper hide={!customerNames}>
        <EllipsisListContainer>
          <EllipsisList label="Customers" list={customerNames} totalRecords={bulkRecordsLength} />
        </EllipsisListContainer>
      </HideWrapper>
      <HideWrapper hide={!showInvoicesList}>
        <>
          {bulkAction ? (
            <EllipsisListContainer>
              <EllipsisList
                label="Added Invoices"
                list={map(selectedInvoiceNo, (item) => String(item))}
                totalRecords={bulkRecordsLength}
                showScrollablePopover
              />
            </EllipsisListContainer>
          ) : (
            InvoiceSelectList
          )}
          {ShowLineItemsBox}
        </>
      </HideWrapper>
      {NoteDescription}
    </>
  );
}
