import DescriptionTextArea from 'components/BaseComponents/DescriptionTextArea/TextArea';
import { Input } from 'components/BaseComponents/Input';
import { OnInputChange, OnTextAreaChange } from 'components/BaseComponents/Input/type';
import { AddDocTypeDefaultFormElementProps } from './type';

export function DocumentTypeForm(props: AddDocTypeDefaultFormElementProps<string, string>) {
  const { value: selectedValue, onChange } = props;

  function handleChange(event: OnInputChange[number]) {
    onChange?.(event.target.value);
  }
  return (
    <Input
      defaultValue={selectedValue}
      onChange={handleChange}
      placeholder="Enter a type of the document..."
    />
  );
}

export function DocumentDescForm(props: AddDocTypeDefaultFormElementProps<string, string>) {
  const { value: selectedValue, onChange } = props;

  function handleChange(event: OnTextAreaChange[number]) {
    onChange?.(event.target.value);
  }
  return (
    <DescriptionTextArea
      defaultValue={selectedValue}
      onChange={handleChange}
      placeholder="Enter a description..."
      maxLength={512}
    />
  );
}
