import { Button } from 'antd';
import { notify } from 'components/BaseComponents/Notifications';
import { openInvoiceStatus } from 'components/CustomerDetails/Body/Statements/OpenInvoicesStatements';
import { usePauseResumeStrategy, usePauseResumeStrategyBulk } from 'queries/invoices';
import { useEffect, useMemo, useRef } from 'react';
import { BulkActionPayload } from 'services/bulkaction';
import { InvoiceStrategyStatus } from 'types/entities/collection-strategy';
import { IActionsModal } from '../ActionsModal/types';
import { EActions } from '../types';

const t = {
  [InvoiceStrategyStatus.PAUSED]: {
    success: 'Strategy for Invoice(s) Paused',
    error: 'Unable to pause strategy for invoices',
  },
  [InvoiceStrategyStatus.RUNNING]: {
    success: 'Strategy for Invoice(s) Resumed',
    error: 'Unable to resume strategy for invoices',
  },
  [InvoiceStrategyStatus.STOPPED]: {
    success: 'Strategy for Invoice(s) Stopped',
    error: 'Unable to stop strategy for invoices',
  },
};

interface PauseResumeStrategyProps extends IActionsModal {
  strategyStatus: InvoiceStrategyStatus;
}

export function CollectionStrategyQuickAction(props: PauseResumeStrategyProps) {
  const { strategyStatus } = props;
  const ref = useRef<HTMLButtonElement>(null);
  const { mutateAsync: updateCollectionStrategy, isLoading: collectionStrategyLoading } =
    usePauseResumeStrategy();
  const { mutateAsync: updateCollectionStrategyBulk, isLoading: collectionStrategyBulkLoading } =
    usePauseResumeStrategyBulk();

  const invoicesList = useMemo(
    () => (props.invoiceList ?? []).map((inv) => inv.id),
    [props.invoiceList]
  );

  function handleSuccess() {
    // dispatch(updateInvoiceFlagList({ list: invoiceFlagStatusList }));
    notify.success(t[strategyStatus].success);
  }

  function handleError(e: unknown) {
    console.log('Unable to pause/resume strategy for invoice', e);
    notify.error(t[strategyStatus].error);
  }

  useEffect(() => {
    const isStrategyAction =
      props.quickAction === EActions.RESUME_STRATEGY ||
      props.quickAction === EActions.PAUSE_STRATEGY;

    if (isStrategyAction && ref.current) {
      ref.current.click();
    }
  }, [props.quickAction]);

  function handleClick() {
    let invoiceFilterParams;

    if (props.isFromInvoiceList) {
      invoiceFilterParams = {
        ...props.currentFilterView,
        status: props.currentFilterView?.status ?? openInvoiceStatus,
        bulk_action: 'PAUSE_RESUME_FOLLOWUP',
        automation_followup_status: {
          status: strategyStatus,
        },
      };

      handleRequestInvoiceList(invoiceFilterParams as any);
    } else {
      invoiceFilterParams = {
        bulk_action: 'PAUSE_RESUME_FOLLOWUP',
        automation_followup_status: {
          status: strategyStatus,
        },
        filter: props.currentFilterView,
      };
      handleRequestCustomerDetails(invoiceFilterParams);
    }
  }

  function handleRequestInvoiceList(param: BulkActionPayload) {
    if (props.selectAll) {
      return updateCollectionStrategyBulk({ param, baseUrl: 'invoices' })
        .then(() => handleSuccess())
        .catch((e) => handleError(e));
    } else {
      updateCollectionStrategy({ invoiceId: invoicesList, status: strategyStatus })
        .then(() => handleSuccess())
        .catch((e) => handleError(e));
    }
  }

  const handleRequestCustomerDetails = (param: BulkActionPayload) => {
    if (props.selectAll) {
      return updateCollectionStrategyBulk({
        param: param,
        baseUrl: `customers/${props.customerId}/invoices`,
      })
        .then(() => handleSuccess())
        .catch((e) => handleError(e));
    } else {
      updateCollectionStrategy({ invoiceId: invoicesList, status: strategyStatus })
        .then(() => handleSuccess())
        .catch((e) => handleError(e));
    }
  };

  return (
    <Button
      style={{ display: 'none' }}
      onClick={() => handleClick()}
      ref={ref}
      loading={collectionStrategyLoading || collectionStrategyBulkLoading}
    />
  );
}
