import { notify } from 'components/BaseComponents/Notifications';
import {
  AREmailDTO,
  AREmailPatchDTO,
  AREmailPostDTO,
} from 'components/Settings/Company/AREmail/AREmailList';
import { useUpdateAREmailData } from 'components/Settings/Company/AREmail/useAREmailData';
import { useMutation, useQuery, useQueryClient } from 'lib/query-client.ts';
import { head, nth } from 'lodash';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import ky from 'services/ky';

interface UpdateAREmailProps {
  data: Partial<AREmailPatchDTO>;
  id: number;
}

interface VerifyAREmailHashProps {
  onSuccess?: (data: AREmailDTO) => void;
  enabled?: boolean;
}
interface useDeleteUnVerifiedEmailProps {
  onDelete?: (data?: AREmailDTO[]) => void;
  email?: string;
}
interface useGetAREmailListProps {
  onSuccess?: (data: AREmailDTO[]) => void;
}

export function useGetAREmailList() {
  return useQuery({
    queryKey: ['ar-email-list'],
    queryFn: () => ky.get('v1/ar-emails').json<Array<AREmailDTO>>(),
    cacheTime: 12 * 60 * 60 * 1000,
    staleTime: 12 * 60 * 60 * 1000,
  });
}

export function useVerifyArEmailForAccount(props: VerifyAREmailHashProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { handleUpdateData } = useUpdateAREmailData();
  const hasId = searchParams.get('verify_hash');
  const query = useQuery({
    queryKey: ['verify-ar-email'],
    enabled: Boolean(hasId) && props.enabled,
    queryFn: () => ky.post(`v1/ar-emails/verify/${hasId}`).json<AREmailDTO>(),
  });

  useEffect(() => {
    const clearHash = (message: string, type: keyof typeof notify) => {
      notify[type]('', {
        description: message,
      });
      searchParams.delete('verify_hash');
      setSearchParams(searchParams);
    };

    if (query.status === 'error' && hasId) {
      clearHash(
        "The email verification link has expired. Please click 'Resend verification' again to receive a new verification email",
        'warning'
      );
      return;
    }

    if (!(query.status === 'success' && hasId)) {
      return;
    }
    clearHash('Email has been verified successfully', 'success');
    handleUpdateData(query.data);
    props.onSuccess?.(query.data);
  }, [handleUpdateData, hasId, props, query.data, query.status, searchParams, setSearchParams]);

  return query;
}
export function useAREmailResendVerification() {
  return useMutation({
    mutationFn: (id?: number) => ky.post(`v1/ar-emails/${id}/resend-verification`),
    onSuccess() {
      notify.warning('Pending Verification', {
        description: 'Please check your inbox to verify this email address.',
      });
    },
  });
}
export function useUpdateAREmailDetails(hideDefaultNotification?: boolean) {
  const { handleUpdateData } = useUpdateAREmailData();
  return useMutation({
    mutationFn: (value: UpdateAREmailProps) =>
      ky.patch(`v1/ar-emails/${value.id}`, { json: value.data }).json<AREmailDTO>(),
    onSuccess(data) {
      handleUpdateData(data);
      if (!hideDefaultNotification) {
        notify.success('AR Email Details have been updated successfully.', {});
      }
    },
  });
}

export function useCreateARemail() {
  const { handleUpdateData } = useUpdateAREmailData();
  return useMutation({
    mutationFn: (data: Partial<AREmailPostDTO>) =>
      ky.post('v1/ar-emails', { json: data }).json<AREmailDTO>(),
    onSuccess(data) {
      handleUpdateData(data);
    },
  });
}

export function useDeleteUnVerifiedEmail(props: useDeleteUnVerifiedEmailProps) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id?: number) => ky.delete(`v1/ar-emails/${id}`),
    onSuccess() {
      queryClient.invalidateQueries(['ar-email-list']).then(() => {
        const data = head(queryClient.getQueriesData<AREmailDTO[]>(['ar-email-list']));
        const value = nth(data, 1) as AREmailDTO[];
        props.onDelete?.(value);
      });
      notify.success('AR Email Deleted', {
        description: `The AR Email "${props.email}" has been deleted successfully`,
      });
    },
  });
}
