import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { ExternalMappingEventType } from 'types/activities/comment';

const t = {
  salesforce: 'Salesforce',
  via: 'via',
};

interface CommentSourceProps {
  source?: ExternalMappingEventType;
  containerStyle?: React.CSSProperties;
}

export function CommentSource(props: CommentSourceProps) {
  let Element;
  const { source, containerStyle } = props;

  switch (source) {
    case ExternalMappingEventType.SALESFORCE:
      Element = (
        <Flex gap="var(--space-4)" align="center">
          <FontAwesomeIcon
            icon={['fak', 'salesforce']}
            fontSize={24}
            color={'var(--primary-sf-1)'}
          />
          <GrowText style={{ paddingLeft: 'var(--space-4)', color: 'var(--primary-sf-1)' }}>
            {t.salesforce}
          </GrowText>
        </Flex>
      );
      break;
    default:
  }

  if (!source) {
    return null;
  }

  return (
    <Flex gap={'var(--space-4)'} className="comment-source-container" style={containerStyle}>
      {t.via}
      {Element}
    </Flex>
  );
}
