import { getPercentage } from './number-formatter';

function getOverdueRatio(
  overdueAmount?: number,
  receivablesOutstanding?: number,
  locale?: string,
  formatOptions?: Intl.NumberFormatOptions
) {
  if (!overdueAmount || !receivablesOutstanding) return '0%';
  return getPercentage(overdueAmount, receivablesOutstanding, locale, formatOptions);
}

export { getOverdueRatio };
