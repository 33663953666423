import { GrowFlex } from '@sinecycle/growcomponents';
import { Divider, Tabs, TabsProps } from 'antd';
import { GrowText } from 'components/BaseComponents/Typography';
import { Komento } from 'components/CollectionActivities/Comment';
import { CommentsList } from 'components/CollectionActivities/Comment/CommentsList';
import { BaseSecondaryActivityDetailProps } from 'components/CollectionActivities/types';
import HideWrapper from 'components/Common/Util/HideWrapper';
import { WrappedFormProvider } from 'components/HigherOrderComponent/GenerateForm';
import { handleCreateCustomEventCallBack } from 'components/Inbox/CollectionActivitiesV2/hooks/useActivityUpdation';
import { INVOICE_ACTIVITY_EVENTS } from 'events/invoice-activity';
import { dispatchAppEvent } from 'lib/pub-sub';
import { filter, map } from 'lodash';
import { useState } from 'react';
import styled from 'styled-components';
import { ActivityType } from 'types/activities/activity-types';
import {
  PTPSecActivity,
  PromiseToPay as PromiseToPayType,
  SecondaryActivityTypes,
  Slippage,
} from 'types/activities/promise-to-pay';
import { PerformedActionType } from 'types/api/inbox/activity';
import SlippageBlock from '../../PaymentSlip/PaymentSlipBlock';
import PaymentSlipForm from '../../PaymentSlip/PaymentSlipForm';
import { getPaymentSlipSchema } from '../../validation';
interface PTPSecondaryProps
  extends Omit<BaseSecondaryActivityDetailProps<PromiseToPayType>, 'comments' | 'propagateChange'> {
  promiseToPay: PromiseToPayType;
  isOpen?: boolean;
}

/* 
  The below type is used for both display of form as 
  well as in the list 
*/

const t = {
  comment: 'Comment',
  record_payment_slip: 'Record Payment Slip',
};

const StyledTab = styled(Tabs)`
  .ant-tabs-nav {
    z-index: 2 !important;
    box-shadow: none !important;
  }
`;
export function PTPCommentBlock(props: PTPSecondaryProps) {
  const { promiseToPay, activityId, customerId, isOpen } = props;
  const [secondaryActivities, setSecondaryActivities] = useState<PTPSecActivity[]>(
    promiseToPay?.activities ?? []
  );
  const [updatedPayDate, setUpdatedPayDate] = useState<string | null>(null);
  const [updatedPayAmount, setUpdatedPayAmount] = useState<number | null>(null);

  const comments = map(
    filter(secondaryActivities, (comment) => {
      return comment.type === SecondaryActivityTypes.COMMENT;
    }),
    (value) => {
      return value.data;
    }
  );

  const slipage = map(
    filter(secondaryActivities, (comment) => {
      return comment.type === SecondaryActivityTypes.PAYMENT_SLIP;
    }),
    (value) => {
      return value.data;
    }
  );

  function onNewActivityAdded(newActivity: PTPSecActivity) {
    setSecondaryActivities([...secondaryActivities, newActivity]);

    dispatchAppEvent({
      type: INVOICE_ACTIVITY_EVENTS.ACTIVITY_CARD_QUICK_ACTION_SAVE,
      payload: {
        actionName: 'PromiseToPay',
      },
    });
    handleCreateCustomEventCallBack('updating_query', {
      id: String(activityId),
      activity: ActivityType.PROMISE_TO_PAY,
      value: {
        action: PerformedActionType.COMMENT_ADDED,
      },
    });
  }

  function onNewSlipAdded(data: Slippage) {
    setUpdatedPayAmount(Number(data.amount));
    setUpdatedPayDate(data.to_date);
    if (!data.serial_no) {
      // Hack to calculate serial no when backend sends null
      const oldSlipsCounts = secondaryActivities.filter(
        (x) => x.type === SecondaryActivityTypes.PAYMENT_SLIP
      ).length;
      data.serial_no = oldSlipsCounts + 1;
    }
    onNewActivityAdded({ type: SecondaryActivityTypes.PAYMENT_SLIP, data });
  }

  const NoComments = <GrowText color="var(--gray-7)">No comments available</GrowText>;

  const SlipageComments = (
    <GrowFlex vertical space="8">
      {slipage.map((comment, index) => {
        return (
          <SlippageSection
            key={comment.id}
            currency={promiseToPay.currency}
            slippage={comment}
            hideDivider={Boolean(index === slipage.length - 1)}
          />
        );
      })}
    </GrowFlex>
  );

  const SlippageForm = (
    <>
      <div
        style={{
          borderRadius: '6px',
          border: '1px solid var(--gray-4)',
          boxShadow: 'var(--shadow-2)',
          marginBottom: 'var(--space-24)',
        }}
      >
        <WrappedFormProvider
          schema={getPaymentSlipSchema({
            maxAmount: updatedPayAmount ?? promiseToPay.balance_amount ?? 0,
            minDate: updatedPayDate ?? promiseToPay.pay_date,
          })}
          shouldUnregister={true}
          mode={'all'}
        >
          <PaymentSlipForm
            onNewSlipAdded={onNewSlipAdded}
            currentPtpDate={updatedPayDate ?? promiseToPay.pay_date}
            amount={updatedPayAmount ?? promiseToPay.balance_amount ?? 0}
            activityId={activityId}
            currency={promiseToPay.currency}
            onCancel={() => {}}
          />
        </WrappedFormProvider>
      </div>
      {slipage.length ? SlipageComments : NoComments}
    </>
  );

  const CommentForm = (
    <GrowFlex vertical space="24">
      <Komento.Form
        referenceActivityId={activityId}
        newCommentCallBack={(newComment) =>
          onNewActivityAdded({ type: SecondaryActivityTypes.COMMENT, data: newComment })
        }
        customerId={customerId}
        isOpen={true}
        onCancel={() => {}}
        referenceType={ActivityType.PROMISE_TO_PAY}
      />
      <CommentsList comments={comments} />
    </GrowFlex>
  );

  const items: TabsProps['items'] = [
    {
      key: SecondaryActivityTypes.COMMENT,
      label: t.comment,
      children: CommentForm,
    },
    {
      key: SecondaryActivityTypes.PAYMENT_SLIP,
      label: t.record_payment_slip,
      children: SlippageForm,
    },
  ];

  return <StyledTab items={items} />;
}

interface SlippageSectionProps {
  slippage: Slippage;
  currency: string;
  hideDivider: boolean;
}
function SlippageSection(props: SlippageSectionProps) {
  const { slippage, currency, hideDivider } = props;
  return (
    <>
      <SlippageBlock currency={currency} slippage={slippage} />
      <HideWrapper hide={hideDivider}>
        <Divider style={{ margin: 'var(--space-16) 0' }} />
      </HideWrapper>
    </>
  );
}
