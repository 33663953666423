import { TableInitialState } from '@sinecycle/growcomponents';
import { EActions, Eactions } from 'components/HigherOrderComponent/KeyActionsContainer/types';
import { useOpenCustomerDetailsInvoiceListQuery, useOpenInvoiceListQuery } from 'queries/invoices';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ELastContactActivity } from 'store/activity-feed/type';
import { InvoiceStatusType } from 'store/aging/types';
import { accountConfigSelector } from 'store/authentication/authentication';
import { IInvoices, StatementsResponse } from 'store/customer/types';
import { setInvoiceFlagList } from 'store/invoice-flag/invoice-flag';
import { IInvoiceFlagList, IInvoiceIdAndFlag } from 'store/invoice-flag/types';
import { InvoiceDropdownInterfaceProps, OpenInvoicesListFilters } from 'store/invoice/type';
import { ELocalStorageVariable } from 'store/type';
import { OpenInvoiceListSummary } from 'types/api/invoice';
import { InvoiceStatus } from 'types/entities/invoice';
import { OpenInvoiceFilterParams } from 'types/filter-view/open-invoice';
import { InvoiceStatementTable } from '../InvoiceStatements/InvoiceStatementTable';
import useDownloadPdf from '../InvoiceStatements/useDownloadPdf';
import { InvoiceStatementsActions } from '../QuickOperations/QuickOperations';
import { useColumn } from './Columns/useColumn';
import { OpenInvoiceBulkAction } from './OpenInvoiceBulkAction';
import useActions from './useActions';

const sortChildren = ['DOCUMENT_CURRENCY', 'BALANCE_DUE_AMOUNT_CURRENCY', 'BALANCE_CURRENCY'];
export const statusList = [
  {
    label: 'All',
    value: 'ALL',
    color: 'var(--gray-1)',
  },
  {
    label: 'Payment Pending',
    value: InvoiceStatus.PENDING,
    color: 'var(--red-2)',
  },
  {
    label: 'In Dispute',
    value: InvoiceStatus.DISPUTE,
    color: 'var(--volcano-5)',
  },
  {
    label: 'Partially Paid',
    value: InvoiceStatus.PARTIAL_PAYMENT,
    color: 'var(--lime-6)',
  },
  {
    label: 'Fully Paid',
    value: InvoiceStatus.FULL_PAYMENT,
    color: 'var(--green-8)',
  },
  {
    label: 'Draft',
    value: InvoiceStatus.DRAFT,
    color: 'var(--green-3)',
  },
];

export const openInvoiceStatus = [
  InvoiceStatus.PENDING,
  InvoiceStatus.DISPUTE,
  InvoiceStatus.PARTIAL_PAYMENT,
  InvoiceStatus.FULL_PAYMENT,
];

const defaultSortColumn = 'DUE_DATE';
interface InvoicesStatementsProps {
  isFromInvoiceList?: boolean;
  customerId?: number;
  invoiceStatusList: InvoiceStatusType[];
  setQuickAction: (key: ELastContactActivity, invoiceList: InvoiceDropdownInterfaceProps[]) => void;
  currentFilter?: OpenInvoicesListFilters;
  saveFilterViews?: (config: OpenInvoicesListFilters) => void;
  isLoading?: boolean;
  hasActionAccess?: boolean;
  selectAll?: boolean;
  setSelectAll?: React.Dispatch<React.SetStateAction<boolean>>;
  setAction: (key: EActions, invoiceList: InvoiceDropdownInterfaceProps[]) => void;
  setTotalRecords?: React.Dispatch<React.SetStateAction<number | undefined>>;
  setCustomerTotalRecords?: (totalRecords: number | undefined) => void;
  scroll?: {
    x?: number | true | string;
    y?: string;
  };
  currentPage: number;
  handlePageChange: (page: number) => void;
  hasFilterViewLoaded?: boolean;
  action?: EActions;
}

const OpenInvoicesStatements = (props: InvoicesStatementsProps) => {
  const { handleDownload } = useDownloadPdf();
  const [pageSize] = useState<number>(20);
  const configList = useSelector(accountConfigSelector);
  const parentConfig = configList?.parent_child_config;
  const [invoiceListSummary, setInvoiceListSummary] = useState<OpenInvoiceListSummary>();
  const [tableState, setTableState] = useState<TableInitialState<IInvoices>>({
    currentPage: 1,
    selectedRows: [],
    selectAll: { select_all: Boolean(props.selectAll), selected: false },
    bulk_records: 0,
  });
  const dispatch = useDispatch();
  const { customerQuickActions, invoiceQuickActions } = useActions({
    selectAll: tableState.selectAll.select_all,
    setAction: props.setAction,
    setQuickAction: props.setQuickAction,
  });

  function getParams(): OpenInvoicesListFilters {
    return {
      ...(props.currentFilter ? props.currentFilter : ({} as OpenInvoicesListFilters)),
      filter_type: 'OPEN',
      status: props.currentFilter?.status ?? [],
      sort_by: props.currentFilter?.sort_by ?? 'ASC',
      sort_col:
        props.currentFilter?.sort_col === 'DUE_AMOUNT' //defensive coding to ensure we don't use due_amount column which might be saved in cache
          ? defaultSortColumn
          : props.currentFilter?.sort_col ?? defaultSortColumn,
      sort_by_custom_field: props.currentFilter?.sort_by_custom_field,
      size: pageSize,
      page: props.currentPage ?? 1,
    };
  }

  const param = useMemo(getParams, [props.currentFilter, props.currentPage, pageSize]);

  function handleSuccessCallBack(data: StatementsResponse<IInvoices, OpenInvoiceListSummary>) {
    if (!props.isFromInvoiceList) {
      localStorage.setItem(
        ELocalStorageVariable.OPEN_INVOICE_CUSTOMER_STATEMENTS,
        JSON.stringify(param)
      );

      if (!props.currentFilter) {
        props.saveFilterViews?.(param as unknown as OpenInvoicesListFilters);
      }
    }

    setInvoiceListSummary(data.summary);
    setTableState({
      ...tableState,
      bulk_records: data.total_records,
      currentPage: data.current_page,
    });
    props.setTotalRecords && props.setTotalRecords(data.total_records);
    props.setCustomerTotalRecords && props.setCustomerTotalRecords(data.total_customer_records);
    const flagList = {} as IInvoiceFlagList;
    flagList.list = [] as IInvoiceIdAndFlag[];
    data.list.forEach((invoice) => {
      flagList.list.push({
        id: invoice.id,
        flag: invoice.invoice_flag ? true : false,
      } as IInvoiceIdAndFlag);
    });
    dispatch(setInvoiceFlagList(flagList));
  }

  const filter: OpenInvoicesListFilters = useMemo(() => {
    const openReqParam = { ...param, view_child: parentConfig?.view_child };
    if (openReqParam?.status?.length === 0) {
      return { ...openReqParam, status: openInvoiceStatus };
    } else if (props.isFromInvoiceList) {
      return param;
    } else {
      return openReqParam;
    }
  }, [param, parentConfig?.view_child, props.isFromInvoiceList]);
  const { isLoading, data: OpenInvoice } = useOpenInvoiceListQuery({
    onSuccessCallBack: handleSuccessCallBack,
    param: filter,
    enable: props.isFromInvoiceList && props.hasFilterViewLoaded,
  });

  const { isLoading: customerLoading, data: OpenInvoiceCustomer } =
    useOpenCustomerDetailsInvoiceListQuery({
      onSuccessCallBack: handleSuccessCallBack,
      param: filter,
      customerId: String(props.customerId),
      enable: !props.isFromInvoiceList,
    });

  const updateFilterView = (value: OpenInvoicesListFilters) => {
    if (props.saveFilterViews) {
      props.saveFilterViews({
        ...(props.currentFilter ? props.currentFilter : ({} as OpenInvoicesListFilters)),
        ...value,
      });
    }
  };

  const loading = props.isFromInvoiceList ? isLoading : customerLoading;

  const allInvoicesFilters = props.isFromInvoiceList
    ? filter
    : { ...filter, customer_ids: [props.customerId] };
  const { column } = useColumn(
    invoiceListSummary,
    props.isFromInvoiceList,
    tableState.bulk_records,
    tableState.currentPage,
    allInvoicesFilters as unknown as OpenInvoiceFilterParams
  );
  return (
    <InvoiceStatementTable
      loading={loading}
      data={props.isFromInvoiceList ? OpenInvoice?.list : OpenInvoiceCustomer?.list}
      onSortChange={(values) => {
        updateFilterView({
          sort_by: values.sortOrder as string,
          sort_by_custom_field: values.customCol,
          sort_col: sortChildren.includes(values.sortCol)
            ? 'BALANCE_DUE_AMOUNT'
            : values.sortCol.startsWith('CUSTOM_FIELD')
            ? 'CUSTOM_FIELD'
            : values.sortCol,
        } as OpenInvoicesListFilters);
      }}
      getUpdatedTableValue={(value) => {
        props?.setSelectAll?.(value.selectAll.select_all);
        setTableState(value);
      }}
      onPageChange={(page) => {
        props.handlePageChange(page);
      }}
      columns={column}
      tableState={tableState}
      rowClassRules={{
        'flagged-row': (params) => {
          return Boolean(params.data?.invoice_flag?.id);
        },
      }}
      bulkActions={
        <OpenInvoiceBulkAction
          setAction={props.setAction}
          setQuickAction={props.setQuickAction}
          selectAll={tableState.selectAll.select_all}
          isFromInvoiceList={props.isFromInvoiceList}
          currentFilterParam={filter}
          bulkRecordsLength={tableState.bulk_records}
          customerId={props?.customerId}
          action={props?.action}
        />
      }
      quickOperations={{
        items: props.isFromInvoiceList ? invoiceQuickActions : customerQuickActions,
        onClick(key, selectedRows) {
          const selectedRow = selectedRows?.map(
            (f) => f.data
          ) as unknown as InvoiceDropdownInterfaceProps[];

          if ((key as EActions) === EActions.DOWNLOAD_PDF) {
            handleDownload(selectedRows);
            return;
          }
          if (EActions.EMAIL !== key && Eactions.includes(key as EActions)) {
            props.setAction(key as EActions, selectedRow);
          } else if (EActions.EMAIL === key) {
            props.setQuickAction(ELastContactActivity.EMAIL, selectedRow);
          }
        },
        component: <InvoiceStatementsActions />,
      }}
      isFromInvoiceList={props.isFromInvoiceList}
      columnPreserveKey={props.isFromInvoiceList ? 'COLUMNS_OPEN_IN' : 'COLUMNS_OPEN_IN_C'}
      currentFilter={filter}
    />
  );
};

export default React.memo(OpenInvoicesStatements);
