import { useConfig } from 'components/HigherOrderComponent/Config/config';
import { InvoiceStatusLabel } from 'constants/collection-activities/invoice-statuses';
import { useMemo } from 'react';
import { AccountConfigKey } from 'types/entities/account';
import { InvoiceStatus } from 'types/entities/invoice';

interface StatusOptionProps {
  label: string;
  value: InvoiceStatus;
  hide?: boolean;
  color?: string;
}
export function useGetInvoiceStatusOptions(
  baseOptions: StatusOptionProps[] = [],
  includeDraft = true
) {
  const draftInvoiceEnabled = useConfig(AccountConfigKey.DRAFT_INVOICE_ENABLED);
  const draftMetricEnabled = useConfig(AccountConfigKey.DRAFT_METRIC_ENABLED);
  const showWriteOff = useConfig(AccountConfigKey.SHOW_WRITEOFF);

  const isDraftFeatureEnabled = draftInvoiceEnabled || draftMetricEnabled;

  return useMemo(() => {
    const additionalOptions: StatusOptionProps[] = [
      ...(includeDraft
        ? [
            {
              label: InvoiceStatusLabel.DRAFT,
              value: InvoiceStatus.DRAFT,
              hide: !isDraftFeatureEnabled,
            },
          ]
        : []),
      {
        label: InvoiceStatusLabel.WRITE_OFF,
        value: InvoiceStatus.WRITE_OFF,
        hide: !showWriteOff,
        color: 'var(--red-2)',
      },
    ];

    const mergedOptions = [...baseOptions, ...additionalOptions];

    return mergedOptions.filter((status) => !status.hide);
  }, [baseOptions, includeDraft, isDraftFeatureEnabled, showWriteOff]);
}
