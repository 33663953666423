import { StatusTagStyle } from 'components/Common/StatusCell/StatusCell';
import { AssignedToUsers } from 'store/activity-feed/type';
import { Invoice, InvoiceLineItem } from 'types/entities/invoice';
import { User } from 'types/entities/user';
import { ActivityBaseWithAttachments } from './activity';
import { TaskAssociatedActivityType } from './activity-types';
import { CallLog } from './callLog';
import { Dispute } from './dispute';
import { Escalation } from './escalation';
import { Note } from './note';
import { PromiseToPay } from './promise-to-pay';
import { WriteOff } from './write-off';

export enum TaskStatus {
  OPEN = 'OPEN',
  DUE = 'DUE',
  OVERDUE = 'OVERDUE',
  CLOSED = 'CLOSED',
  DUE_TODAY = 'DUE_TODAY',
}

export enum TaskFilter {
  DUE_TODAY = 'DUE_TODAY',
  OVERDUE = 'OVERDUE',
  UPCOMING = 'UPCOMING',
  ALL = 'ALL',
}

export type TaskStatusConfig = Record<TaskStatus, { label: string; style: StatusTagStyle }>;
export type TaskFilterConfig = Record<TaskFilter, { label: string }>;

export const taskStatusConfig: TaskStatusConfig = {
  [TaskStatus.OPEN]: {
    label: 'Open',
    style: {
      backgroundColor: 'var(--orange-1)',
      textColor: 'var(--orange-7)',
    },
  },
  [TaskStatus.DUE]: {
    label: 'Upcoming',
    style: {
      backgroundColor: 'var(--blue-1)',
      textColor: 'var(--blue-8)',
    },
  },
  [TaskStatus.OVERDUE]: {
    label: 'Overdue',
    style: {
      backgroundColor: 'var(--red-1)',
      textColor: 'var(--red-7)',
    },
  },
  [TaskStatus.CLOSED]: {
    label: 'Completed',
    style: {
      backgroundColor: 'var(--green-1)',
      textColor: 'var(--green-8)',
    },
  },
  [TaskStatus.DUE_TODAY]: {
    label: 'Due Today',
    style: {
      backgroundColor: 'var(--blue-1)',
      textColor: 'var(--blue-8)',
    },
  },
};

export const taskFilterConfig: TaskFilterConfig = {
  [TaskFilter.DUE_TODAY]: {
    label: 'Due Today',
  },
  [TaskFilter.OVERDUE]: {
    label: 'Overdue',
  },
  [TaskFilter.UPCOMING]: {
    label: 'Upcoming',
  },
  [TaskFilter.ALL]: {
    label: 'All',
  },
};

type TaskableActivity = CallLog | Dispute | Escalation | Note | PromiseToPay | WriteOff;

export interface Task extends ActivityBaseWithAttachments {
  id: number;
  title: string;
  description?: string;
  status: TaskStatus;
  due_date: string;
  invoices?: Invoice[];
  invoice_line_items: InvoiceLineItem[];
  assigned_to_list?: User[];
  associated_activity_type?: TaskAssociatedActivityType;
  associated_activity_id?: number;
  associated_activity?: TaskableActivity;
  participant_config?: AssignedToUsers[];
  assigned_to_user_ids?: number[];
  customer_ids?: number[];
  due_days: number;
  invoice_ids?: number[];
}

export const TaskTypeMenu = [
  {
    label: 'Open',
    value: TaskStatus.OPEN,
    color: 'var(--red-7)',
  },
  {
    label: 'Completed',
    value: TaskStatus.CLOSED,
    color: 'var(--green-8)',
  },
];
