import { Card, Col, Row } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { ErrorBoundaryWrapper } from 'components/Common/ErrorBoundary';
import Aging from 'components/Dashboard/Aging';
import CollectionStatusCard from 'components/Dashboard/CollectionStatues';
import Delinquencies from 'components/Dashboard/Delinquencies';
import { FlaggedInvoices } from 'components/Dashboard/FlaggedInvoices';
import FollowUpActivitiesCard from 'components/Dashboard/FollowupActivities';
import FollowUpStatusCard from 'components/Dashboard/FollowupStatuses';
import InvoiceDisputesCard from 'components/Dashboard/InvoiceDisputes';
import InvoiceStatusCard from 'components/Dashboard/InvoiceStatus';
import PendingIssues from 'components/Dashboard/PendingIssues';
import styled from 'styled-components';
import { DashboardPostFilterParams } from '../../types';
import TotalCollectionBalanceOutstanding from './CollectionBalanceAndOutstanding/TotalCollectionBalanceOutstanding';

interface SnapShotProps {
  snapshotFilterParams: DashboardPostFilterParams;
}

interface StyledCardProps {
  disabledPadding?: boolean;
}

export const StyledCard = styled(Card)<StyledCardProps>`
  border: 0;
  box-shadow: var(--shadow-2);
  border-radius: var(--br-3);
  height: 100%;
  .ant-card-body {
    padding: ${(props) => (props.disabledPadding ? 0 : 'var(--space-24)')};
  }
`;

export function SnapshotView(props: SnapShotProps) {
  const { snapshotFilterParams } = props;

  const AgingFollowupChart = (
    <>
      <Col span={16}>
        <StyledCard style={{ height: '100%' }}>
          <ErrorBoundaryWrapper>
            <Aging filterParams={snapshotFilterParams} />
          </ErrorBoundaryWrapper>
        </StyledCard>
      </Col>
      <Col span={8}>
        <InvoiceStatusCard snapshotFilterParams={snapshotFilterParams} />
      </Col>
    </>
  );

  const CollectionsAndInvoiceCharts = (
    <>
      <Col span={12}>
        <CollectionStatusCard snapshotFilterParams={snapshotFilterParams} />
      </Col>
      <Col span={12}>
        <InvoiceDisputesCard snapshotFilterParams={snapshotFilterParams} />
      </Col>
    </>
  );

  const FollowupActivities = (
    <>
      <Col span={12}>
        <FollowUpStatusCard snapshotFilterParams={snapshotFilterParams} />
      </Col>
      <Col span={12}>
        <FollowUpActivitiesCard snapshotFilterParams={snapshotFilterParams} />
      </Col>
    </>
  );

  const CollectionsTable = (
    <>
      <Col span={12}>
        <StyledCard>
          <ErrorBoundaryWrapper>
            <PendingIssues filterParams={snapshotFilterParams} />
          </ErrorBoundaryWrapper>
        </StyledCard>
      </Col>
      <Col span={12}>
        <StyledCard>
          <ErrorBoundaryWrapper>
            <FlaggedInvoices filterParams={snapshotFilterParams} />
          </ErrorBoundaryWrapper>
        </StyledCard>
      </Col>
    </>
  );

  const DelinquenciesTable = (
    <Col span={24}>
      <StyledCard>
        <ErrorBoundaryWrapper>
          <Delinquencies filterParams={snapshotFilterParams} />
        </ErrorBoundaryWrapper>
      </StyledCard>
    </Col>
  );

  return (
    <Flex direction="column" gap="var(--space-16)">
      <TotalCollectionBalanceOutstanding snapshotFilterParams={snapshotFilterParams} />
      <Row gutter={[16, 16]}>{AgingFollowupChart}</Row>
      <Row gutter={[16, 16]}>{CollectionsAndInvoiceCharts}</Row>
      <Row gutter={[16, 16]}>{FollowupActivities}</Row>
      <Row gutter={[16, 16]}>{CollectionsTable}</Row>
      <Row gutter={[16, 16]}>{DelinquenciesTable}</Row>
    </Flex>
  );
}
