import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { InputLoader } from 'components/Common/FilterComponents/Common/SkeletonLoader';
import { InfoPopover } from 'components/Common/UtilityPopover';
import { Title } from 'pages/Dashboard/AdminDashboard/SnapShot/CollectionBalanceAndOutstanding/TileTitle';
import { DashboardComponentProps } from 'pages/Dashboard/types';
import { useDSOTileQuery } from 'queries/admin-dashboard';
import React from 'react';
import { StyledTileCard } from '../styles';
import { dashboardTexts } from '../texts';
import BPDSOTile from './BPDSOTile';
import { DangerText, SuccessText, TooltipParagraph } from './style';

const componentTexts = dashboardTexts.dsoTile;

const UpArrorw = (
  <FontAwesomeIcon icon={['far', 'arrow-down']} style={{ color: 'var(--green-7)' }} />
);

const DownArrow = <FontAwesomeIcon icon={['far', 'arrow-up']} />;
function DSOTile({ filterParams }: DashboardComponentProps) {
  const { data: currentDSO, isLoading: loading } = useDSOTileQuery(filterParams);

  const currentMonthDSO = currentDSO?.current_month.dso ?? 0,
    prevMonthDSO = currentDSO?.previous_month.dso ?? 0,
    bpDSO = currentDSO?.bpdso,
    sales = currentDSO && {
      value: currentDSO?.current_month.sales,
      currency: currentDSO?.currency,
    };

  const InputLoaderJSX = <InputLoader active={loading} style={{ width: '90px' }} />;

  const dsoChange = (currentMonthDSO - prevMonthDSO).toFixed(1);

  const SuccessTextJSX = (
    <SuccessText>
      <Flex gap="var(--space-4)">
        {UpArrorw}
        {componentTexts.daysCount(dsoChange)}
      </Flex>
    </SuccessText>
  );

  const WarningTextJSX = (
    <DangerText>
      <Flex gap="var(--space-4)">
        {DownArrow}
        {componentTexts.daysCount(dsoChange)}
      </Flex>
    </DangerText>
  );

  const DsoDays = (
    <Flex direction="column" gap="var(--space-20)">
      <GrowText size={'var(--fs-20)'} strong>
        {loading ? InputLoaderJSX : componentTexts.daysCount(currentMonthDSO.toFixed(0) ?? '')}
      </GrowText>
      <Flex gap="var(--space-4)" align="center">
        {loading ? (
          InputLoaderJSX
        ) : (
          <>
            {Number(dsoChange) >= 0 ? WarningTextJSX : SuccessTextJSX}
            <div>
              <GrowText>{componentTexts.sinceLast}</GrowText>
            </div>
          </>
        )}
      </Flex>
    </Flex>
  );

  const BpdsoSales = (
    <BPDSOTile loading={loading} inputLoaderJSX={InputLoaderJSX} bpdso={bpDSO} sales={sales} />
  );
  const Extra = (
    <div style={{ cursor: 'pointer' }}>
      <InfoPopover
        icon="help"
        title={componentTexts.helpTitle}
        content={<TooltipParagraph>{componentTexts.helpText}</TooltipParagraph>}
      />
    </div>
  );

  return (
    <StyledTileCard>
      <Flex gap="var(--space-12)" align="flex-start" style={{ height: '100%' }}>
        <Flex direction="column" style={{ width: '100%' }} gap="var(--space-4)">
          <Title title={componentTexts.title} extra={Extra} />
          {DsoDays}
        </Flex>
        <>{BpdsoSales}</>
      </Flex>
    </StyledTileCard>
  );
}

export default React.memo(DSOTile);
