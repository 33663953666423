export const dashboardTexts = {
  header: {
    allBusinessUnits: 'All Business Units',
    allRegions: 'All Regions',
  },
  monthFilters: {
    last3: 'Last 3 Months',
    last6: 'Last 6 Months',
    last9: 'Last 9 Months',
    last12: 'Last 12 Months',
  },
  adpGraph: {
    title: 'ADP Trend',
  },
  cashFlow: {
    title: 'Cash Flow Trends',
  },
  ceiGraph: {
    title: 'Collection Effectiveness Index (CEI) Trend',
  },
  dsoGraph: {
    title: 'DSO Trend',
  },
  overdueTile: {
    title: 'Over Due',
    invoicesLabel: '# Invoices',
  },
  overdueRatio: {
    title: 'Overdue Ratio ',
    toolTip: 'Ratio of Overdue and Outstanding Receivables',
  },
  totalCollected: {
    title: 'Total Collections',
    customersLabel: 'Total Customers',
    invoiceLabel: 'Total Invoices',
  },
  totalBalance: {
    title: 'Total Balance',
    customersLabel: 'Total Customers',
    invoiceLabel: 'Total Invoices',
  },
  totalOutstanding: {
    title: 'Total Outstanding',
    customersLabel: 'Total Customers',
    invoiceLabel: 'Total Invoices',
  },
  receivables: {
    title: 'Receivables Outstanding',
    customersLabel: 'Total Customers',
    invoiceLabel: 'Total Invoices',
  },
  ceiTile: {
    title: 'CEI',
    helpText: `
      The Collection Effectiveness Index (CEI) is a calculation of a Company’s
      ability to retrieve their accounts receivable from its customers.
      It is calcuated by measuring the amount collected
      during a time period to the amount of receivables in the same time period.
    `,
    helpTitle: 'What is CEI?',
    daysCount: (days: number | string) => `${days} days`,
    sinceLast: (since: number | string) => `since ${since} days`,
  },
  dsoTile: {
    title: 'Current DSO',
    helpText: `
      Days sales outstanding (DSO) is a measure of
      the average number of days that a Company takes
      to collect a payment after the sale has been made.
    `,
    helpTitle: 'What is DSO?',
    daysCount: (days: number | string) => `${days}`,
    sinceLast: 'since 30 days',
    bpdso: 'BPDSO',
    bdpsoHelpTitle: 'What is BPDSO?',
    bpdsoHelpText: `
      Best Possible Days Sales Outstanding
      refers to the lowest possible number of days,
      credit sales are unpaid.
    `,
    sales: 'Sales',
    net_sales: 'Net Sales',
  },
  badDebtTile: {
    title: 'Bad Debt',
    thisMonth: 'This Month',
  },
  currentDue: {
    title: 'Current Due',
    invoiceLabel: '# Invoices',
  },
  deliquencies: {
    title: 'Top Debtors by',
    columnNames: {
      customerName: 'Customer Name',
      outstandingAmountCustomerCurrency: 'Outstanding Amount',
      baseCurrency: 'Base Currency',
      customerCurrency: 'Customer Currency',
      overdueAmt: 'Overdue Amount',
      lastPayment: 'Last Payment',
    },
  },
  pendingIssues: {
    title: 'List of Pending Issues',
    viewThread: 'View Thread',
    since: 'since',
    by: 'by',
    invoiceCountLabel: 'Invoice',
  },
  extraFollowupActivities: {
    unassigned: 'Unassigned Customers',
    yetToFollowup: 'Yet to Followup',
    customerNotViewed: 'Customer Not Viewed',
  },
  followupActivities: {
    title: 'Followup Activities',
  },
  followupStatuses: {
    title: 'Followup Status',
  },
  invoiceDisputes: {
    title: 'Invoice Disputes',
  },
  invoiceStatus: {
    title: 'Invoice Status',
  },
  unappliedCredits: {
    title: 'Unapplied Credits',
  },
  unappliedPayments: {
    title: 'Unapplied Payments',
  },
  unpostedPayments: {
    title: 'Unposted Payments',
  },
};
