import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge } from 'antd';
import { NAVIGATION_EVENT } from 'events/navigation';
import { dispatchAppEvent } from 'lib/pub-sub';
import { useSelector } from 'react-redux';
import {
  hasUnseenEmailSelector,
  hasUnseenSubscribedActivitiesSelector,
} from 'store/user-notifications';

export function InboxSidebarIcon() {
  const hasUnseenEmail = useSelector(hasUnseenEmailSelector);
  const hasUnseenActivities = useSelector(hasUnseenSubscribedActivitiesSelector);

  const shouldShowRedDot = hasUnseenEmail || hasUnseenActivities;

  return (
    <Badge dot={shouldShowRedDot} style={{ boxShadow: 'none' }} offset={[4, 0]}>
      <FontAwesomeIcon icon={['far', 'inbox']} fontSize={20} color={'var(--gray-1)'} />
    </Badge>
  );
}

export function dispatchNavigationEvent(to: string) {
  dispatchAppEvent({
    type: NAVIGATION_EVENT.NAVIGATION,
    payload: { to, context: 'SideBar' },
  });
}
