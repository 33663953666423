import { Button, Divider } from 'antd';
import { Button as GFButton } from 'components/BaseComponents/Buttons/Button';
import { Flex, FlexBoxChild } from 'components/BaseComponents/Layout/Flex';
import { Editor } from 'lib/RichText/type';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { Redo } from './Options/Edit/Redo';
import { Undo } from './Options/Edit/Undo';
import { Align } from './Options/Format/Align';
import { BulletsNumbering } from './Options/Format/BulletsNumbering';
import { FontFamily } from './Options/Format/FontFamily';
import { FontSize } from './Options/Format/FontSize';
import { Table } from './Options/Format/Table';
import { TextStyles } from './Options/Format/Text';
import { TextColor } from './Options/Format/TextColor';
import { TextHighLight } from './Options/Format/TextHighlight';
import { CustomButton } from './Options/Insert/CustomButton';
import { InsertImage } from './Options/Insert/Image';
import { Link } from './Options/Insert/Link';
import { Signature } from './Options/Insert/Signature';

const optionItemGroup: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  margin: 'var(--space-4) 0',
  padding: ' 0 var(--space-4)',
  gap: 'var(--space-8)',
};

export const commonButtonProps: React.ComponentProps<typeof GFButton>['buttonProps'] = {
  type: 'text',
  size: 'middle',
};

const SMenuContainer = styled.div`
  border-bottom: 1px solid var(--gray-6);
`;

interface ToolBarMenuProps {
  editor: Editor;
  extras?: ReactNode;
  onOpenVisible: (visible: boolean) => void;
  open: boolean;
  signatureText?: string;
  hideSignature?: boolean;
}

const StyledFlexChild = styled(FlexBoxChild)`
  display: flex;
  align-items: center;
  margin: var(--space-4) 0;
  padding: 0 var(--space-4);
  gap: var(--space-8);
  border-right: ${(props) =>
    props.className === 'border-right' ? '1px solid var(--gray-5)' : 'unset'};

  .ant-btn-icon {
    color: var(--gray-9);
  }
`;

export default function Toolbar(props: ToolBarMenuProps) {
  const { editor, signatureText } = props;

  const UndoButton = <Undo editor={editor} />;
  const RedoButton = <Redo editor={editor} />;
  const FontFamilyButton = <FontFamily editor={editor} />;
  const FontSizeButton = <FontSize editor={editor} />;
  const TextStyleButton = <TextStyles editor={editor} />;
  const TextColorButton = <TextColor editor={editor} />;
  const TextHighlightButton = <TextHighLight editor={editor} />;
  const AlignButton = <Align editor={editor} />;
  const BulletsAndNumberingButton = <BulletsNumbering editor={editor} />;
  // const IndentButton = <Indent />;
  // const OutdentButton = <Outdent />;
  const TableButton = <Table editor={editor} />;
  const LinkButton = <Link editor={editor} />;
  const CustomInsertButton = <CustomButton editor={editor} />;
  const SignatureButton = <Signature editor={editor} signature={signatureText} />;
  const ImageButton = <InsertImage editor={editor} />;
  const ExtraActions = props.extras && <Flex>{props.extras}</Flex>;

  const RowActionOne = (
    <Flex wrap="wrap" align="center">
      <StyledFlexChild style={optionItemGroup}>{UndoButton}</StyledFlexChild>
      <StyledFlexChild className="border-right">{RedoButton}</StyledFlexChild>
      <StyledFlexChild className="border-right">{FontFamilyButton}</StyledFlexChild>
      <StyledFlexChild className="border-right">{FontSizeButton}</StyledFlexChild>
      <StyledFlexChild className="border-right">{TextStyleButton}</StyledFlexChild>
      <StyledFlexChild style={optionItemGroup}>{TextColorButton}</StyledFlexChild>
      <StyledFlexChild className="border-right">{TextHighlightButton}</StyledFlexChild>
      <StyledFlexChild className="border-right">{AlignButton}</StyledFlexChild>
      <StyledFlexChild className="border-right">{BulletsAndNumberingButton}</StyledFlexChild>
      <StyledFlexChild className="border-right">{TableButton}</StyledFlexChild>
      <StyledFlexChild style={optionItemGroup}>{LinkButton}</StyledFlexChild>
      <StyledFlexChild className="border-right">{CustomInsertButton}</StyledFlexChild>
      <StyledFlexChild className="border-right">{ImageButton}</StyledFlexChild>
      {props.hideSignature ? null : (
        <StyledFlexChild className="border-right">{SignatureButton}</StyledFlexChild>
      )}
    </Flex>
  );

  const ColumnExtra = (
    <StyledFlexChild
      style={{
        ...optionItemGroup,
        marginLeft: 'auto',
        borderLeft: ExtraActions ? '1px solid var(--gray-5)' : 'unset',
      }}
    >
      <Button type="text" style={{ padding: 0 }} size="middle">
        {ExtraActions}
      </Button>
    </StyledFlexChild>
  );

  return (
    <SMenuContainer style={{ display: 'flex', position: 'relative' }}>
      <div className="row-action-one">{RowActionOne}</div>
      <div className="row-action-two" style={{ marginLeft: 'auto' }}>
        {ColumnExtra}
        <Divider
          plain
          style={{ margin: 0, position: 'absolute', left: '0', background: 'var(--gray-5)' }}
        />
      </div>
    </SMenuContainer>
  );
}
