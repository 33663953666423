import { DashboardPostFilterParams } from 'pages/Dashboard/types';
import { CollectionPeriodsHistory } from 'store/collectors-performace/type';
import {
  AdminFlaggedInvoice,
  ADP,
  CashflowTrend,
  CollectionStatusResponse,
  CollectorsPerformanceResponse,
  CurrentDSO,
  DashboardFilterParams,
  HighRiskCustomer,
  LongPendingIssue,
  MonthlyDSO,
  SalesCollection,
  TotalBadDebt,
  TotalCollectionsResponse,
  TotalOutStandingByDisputeType,
  TotalOutstandingByFollowupActivity,
  TotalOutstandingByInvoiceFollowupStatus,
  TotalOutstandingByInvoiceStatus,
  TotalOutstandingResponse,
} from 'types/api/admin-dashboard';
import { AmountInvoiceCountCustomerCount } from 'types/common/multi-currency';
import { AgingBucket } from 'types/entities/aging';
import ky from './ky';
export interface CollectionPerformanceProps {
  page: number;
  size: number;
  body: CollectionPeriodsHistory | {};
}
function getPostParams({
  business_unit_ids,
  region_ids,
  subsidiary_ids,
  dateFilter,
  reporting_tags,
  period,
  custom_fields,
}: DashboardPostFilterParams): DashboardFilterParams {
  const postParams: DashboardFilterParams = {
    business_unit_ids: (business_unit_ids ?? [])
      .map((businessUnit) => Number(businessUnit))
      .filter((businessUnit) => !isNaN(businessUnit)),
    region_ids: (region_ids ?? [])
      .map((region) => Number(region))
      .filter((region) => !isNaN(region)),
    subsidiary_ids: (subsidiary_ids ?? [])
      .map((subsidiary_id) => Number(subsidiary_id))
      .filter((subsidiary_id) => !isNaN(subsidiary_id)),
  };

  if (Array.isArray(dateFilter) && dateFilter.length) {
    postParams['start_date'] = dateFilter[0];
    postParams['end_date'] = dateFilter[1];
  }

  if (Array.isArray(reporting_tags) && reporting_tags.length) {
    postParams['reporting_tags'] = reporting_tags.map((reportingTag) => ({
      tag_id: reportingTag.tag_id,
      tag_option_ids: reportingTag.tag_option_ids,
    }));
  }

  if (Array.isArray(custom_fields) && custom_fields.length) {
    postParams['custom_fields'] = custom_fields;
  }

  if (period) {
    postParams['period'] = period;
  }

  return postParams;
}

export async function fetchTotalBalanceAmount(params: DashboardPostFilterParams) {
  const modifiedParams = getPostParams(params);

  const searchParams = { ...params, ...modifiedParams };

  return await ky
    .post(`dashboard/total-balances`, {
      json: searchParams,
    })
    .json<TotalOutstandingResponse>();
}

export async function fetchTotalOutstandingAmount(params: DashboardPostFilterParams) {
  const modifiedParams = getPostParams(params);

  const searchParams = { ...params, ...modifiedParams };

  return await ky
    .post(`dashboard/total-outstanding`, {
      json: searchParams,
    })
    .json<TotalOutstandingResponse>();
}

export async function fetchTotalReceivablesAmount(params: DashboardPostFilterParams) {
  const modifiedParams = getPostParams(params);

  const searchParams = { ...params, ...modifiedParams };

  return await ky
    .post(`dashboard/total-receivables`, {
      json: searchParams,
    })
    .json<AmountInvoiceCountCustomerCount>();
}

export async function fetchTotalCollections(params: DashboardPostFilterParams) {
  const modifiedParams = getPostParams(params);

  const searchParams = { ...params, ...modifiedParams };

  return (await ky
    .post(`dashboard/total-collections`, { json: searchParams })
    .json()) as TotalCollectionsResponse;
}

export async function fetchTeamPerf(params: DashboardPostFilterParams) {
  const modifiedParams = getPostParams(params);

  const searchParams = { ...params, ...modifiedParams };
  return await ky.post(`dashboard/team-perf`, { json: searchParams }).json();
}

export async function fetchSalesCollection(params: DashboardPostFilterParams) {
  const modifiedParams = getPostParams(params);

  const searchParams = { ...params, ...modifiedParams };
  return (await ky
    .post(`dashboard/sales-collections`, { json: searchParams })
    .json()) as SalesCollection[];
}

export async function fetchMonthlyDSO(params: DashboardPostFilterParams) {
  const modifiedParams = getPostParams(params);

  const searchParams = { ...params, ...modifiedParams };
  return (await ky.post(`dashboard/monthly-dso`, { json: searchParams }).json()) as MonthlyDSO[];
}

export async function fetchLongPendingIssues(params: DashboardPostFilterParams) {
  const modifiedParams = getPostParams(params);
  const searchParams = { ...params, ...modifiedParams };
  return (await ky
    .post(`dashboard/long-pending-issues`, { json: searchParams })
    .json()) as LongPendingIssue[];
}

export async function fetchTotalOutstandingByInvoiceStatuses(params: DashboardPostFilterParams) {
  const modifiedParams = getPostParams(params);
  const searchParams = { ...params, ...modifiedParams };
  return (await ky
    .post(`dashboard/invoice-statuses`, { json: searchParams })
    .json()) as TotalOutstandingByInvoiceStatus[];
}

export async function fetchTotalOutstandingByInvoiceFollowupStatuses(
  params: DashboardPostFilterParams
) {
  const modifiedParams = getPostParams(params);
  const searchParams = { ...params, ...modifiedParams };
  return (await ky
    .post(`dashboard/invoice-followup-statuses`, { json: searchParams })
    .json()) as TotalOutstandingByInvoiceFollowupStatus[];
}

export async function fetchTotalOutstandingByFollowupActivities(params: DashboardPostFilterParams) {
  const modifiedParams = getPostParams(params);
  const searchParams = { ...params, ...modifiedParams };
  return (await ky
    .post(`dashboard/invoice-followup-activities`, {
      json: searchParams,
    })
    .json()) as TotalOutstandingByFollowupActivity[];
}

export async function fetchTotalOutstandingByInvoiceDisputeTypes(
  params: DashboardPostFilterParams
) {
  const modifiedParams = getPostParams(params);
  const searchParams = { ...params, ...modifiedParams };
  return (await ky
    .post(`dashboard/invoice-dispute-types`, { json: searchParams })
    .json()) as TotalOutStandingByDisputeType[];
}

export async function fetchHighRiskCustomers(params: DashboardPostFilterParams) {
  const modifiedParams = getPostParams(params);
  const searchParams = { ...params, ...modifiedParams };
  return (await ky
    .post(`dashboard/high-risk-customers`, { json: searchParams })
    .json()) as HighRiskCustomer[];
}

export async function fetchCurrentDSO(params: DashboardPostFilterParams) {
  const modifiedParams = getPostParams(params);
  const searchParams = { ...params, ...modifiedParams };
  return (await ky.post(`dashboard/current-dso`, { json: searchParams }).json()) as CurrentDSO;
}

export async function fetchBadDebt(params: DashboardPostFilterParams) {
  const modifiedParams = getPostParams(params);
  const searchParams = { ...params, ...modifiedParams };
  return (await ky.post(`dashboard/bad-debt`, { json: searchParams }).json()) as TotalBadDebt;
}

export async function fetchCashFlowTrends(params: DashboardPostFilterParams) {
  const modifiedParams = getPostParams(params);
  const searchParams = { ...params, ...modifiedParams };
  return (await ky
    .post(`dashboard/cash-flow-trends`, { json: searchParams })
    .json()) as CashflowTrend[];
}

export async function fetchAgingData(params: DashboardPostFilterParams) {
  const modifiedParams = getPostParams(params);
  const searchParams = { ...params, ...modifiedParams };
  return (await ky.post(`dashboard/aging-data`, { json: searchParams }).json()) as AgingBucket[];
}

export async function fetchADP(params: DashboardPostFilterParams) {
  const modifiedParams = getPostParams(params);
  const searchParams = { ...params, ...modifiedParams };
  return (await ky.post(`dashboard/adp`, { json: searchParams }).json()) as ADP[];
}

export async function fetchFlaggedInvoices(params: DashboardPostFilterParams) {
  const modifiedParams = getPostParams(params);
  const searchParams = { ...params, ...modifiedParams };
  return (await ky
    .post(`dashboard/flagged-invoices`, { json: searchParams })
    .json()) as AdminFlaggedInvoice[];
}

export async function getCollectorsPerformance(params: CollectionPerformanceProps) {
  const { page, size, body } = params;
  return await ky
    .post(`admin-dashboard/collectors-performance`, {
      searchParams: { page, size },
      json: { ...body },
    })
    .json<CollectorsPerformanceResponse>();
}

export async function fetchCollectionStatuses(params: DashboardPostFilterParams) {
  const modifiedParams = getPostParams(params);
  const searchParams = { ...params, ...modifiedParams };
  return (await ky
    .post(`dashboard/collection-statuses`, { json: searchParams })
    .json()) as CollectionStatusResponse;
}
