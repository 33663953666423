import { ActivityPaths } from 'components/HigherOrderComponent/KeyActivitesContainer/key-acivities-config';
import { ActivityMutationProps } from 'components/HigherOrderComponent/KeyActivitesContainer/type';
import { map, omit, toNumber } from 'lodash';
import { ActivityKeyValues } from 'queries/activites';
import { IComment } from 'store/activity-feed/type';
import { Emailable } from 'types/activities/email';
import { CustomFieldDataResponseInvoice } from 'types/api/invoice';
import { AmountMaybeWithMultiCurrencySplit } from 'types/common/multi-currency';
import { InvoiceStrategyStatus } from 'types/entities/collection-strategy';
import { DisputeType } from 'types/entities/dispute-type';
import { IInvoices, StatementsResponse } from '../store/customer/types';
import { pruneObject } from '../util/json-utils';
import ky from './ky';

export interface LineItems {
  id: number;
  productName: string;
  quantity?: number;
  amount?: AmountMaybeWithMultiCurrencySplit;
  unitPrice?: AmountMaybeWithMultiCurrencySplit;
  description?: string;
}

const activitiesWithMentions = ['comments'];
const baseUrl = 'invoices';
export type InvoiceType = 'open' | 'closed' | 'draft';
export interface KeyActivityResponse {
  id: number;
  customer_id?: number;
  invoice_ids?: number[];
  invoice_id?: number;
}

export interface KeyActivityParams<T> {
  params: Partial<T>;
  activity: string;
  id: number;
}

export async function createBulkTasks<T>(params: T): Promise<ActivityMutationProps> {
  return await ky.post(`tasks/bulk`, { json: params }).json();
}

export async function postKeyActivites(params: any, activity: string) {
  if (params.amount_details) {
    delete params.amount;
    delete params.invoice_ids;
    params.amount_details = map(params.amount_details, (x) => omit(x, 'max_amount'));
  }

  return await ky.post(activity, { json: params }).json<IComment>();
}

export async function updateKeyActivities<T>(
  params: Partial<T>,
  activity: string,
  id: number
): Promise<T> {
  return await ky.put(`${activity}/${id}`, { json: params }).json();
}

export async function getInvoicesSuggestions<T>(
  keyword: string,
  customer_id?: number,
  viewMode?: boolean
) {
  if (!customer_id) return [];

  const viewModeValue = toNumber(Boolean(viewMode));

  return await ky
    .get(`${baseUrl}/suggestions`, {
      searchParams: { keyword, customer_id, view_child: viewModeValue },
    })
    .json<T[]>();
}

export async function searchInvoiceContacts(searchTerm: string, limit?: number) {
  const contactsLimit = limit ?? 50;
  return (await ky
    .get(`contacts/invoice/suggestions?keyword=${searchTerm}&limit=${contactsLimit}`)
    .json()) as Emailable[];
}

export async function getRecentInvoicesOfCustomer<T>(customer_id?: number, viewMode?: boolean) {
  if (!customer_id) return [];
  const viewModeValue = toNumber(Boolean(viewMode));

  return await ky
    .get(`${baseUrl}/recent`, {
      searchParams: { customer_id, view_child: viewModeValue },
    })
    .json<T[]>();
}

export async function getInvoiceDetail(invoiceId?: string) {
  return (await ky.get(`${baseUrl}/${invoiceId}`).json()) as IInvoices;
}

export async function getInvoiceLineItems(invoiceIds?: number[]) {
  if (!invoiceIds) return [];

  return await ky
    .get(`${baseUrl}/line-items`, { searchParams: { ids: invoiceIds.toString() } })
    .json<LineItems[]>();
}

export async function getKeyActivityData<T>(activity: ActivityPaths, id: number) {
  return await ky.get(`${activity}/${id}`).json<T>();
}

export async function deleteKeyActivityData(activity: ActivityKeyValues, id: number) {
  return await ky.delete(`${activity}/${id}`);
}

export type DisputeItem = { id: number; name: string };
export type Disputes = Array<DisputeItem>;
export async function getDisputeType(keyword: string) {
  return await ky
    .get(`dispute-types/suggestions`, { searchParams: { keyword } })
    .json<DisputeType[]>();
}

export async function createDisputeType(keyword: string) {
  const response = await ky.post(`dispute-types`, { json: { name: keyword } }).json<DisputeType>();
  return response;
}

export async function getAllInvoicesList(type: InvoiceType, searchParams: any) {
  /**
   * removing search params whose value is 'undefined'.
   * ALTERNATIVE: this could be handled in beforeRequest hook of ky
   * ALTERNATIVE: we could extend ky (ky.extend) and add beforeRequest hook which would do this
   * ALTERNATIVE: Make sure the component itself that calls this service function,
   *              filters out the `undefined` search_params (but it's a drag to fix that everywhere)
   */
  const cleanedSearchParams = pruneObject(searchParams) as Record<
    string,
    string | number | boolean
  >;
  return (await ky
    .post(`${baseUrl}/${type}`, { json: cleanedSearchParams })
    .json()) as StatementsResponse<IInvoices>;
}

export async function getInvoiceUrl(invoiceId: number) {
  const response = await ky.get(`${baseUrl}/${invoiceId}/pdf-url`);

  return response.text();
}

export async function getInvoicesCustomFieldValues(invoiceId: number) {
  return (await ky
    .get(`${baseUrl}/${invoiceId}/custom-fields`)
    .json()) as CustomFieldDataResponseInvoice[];
}

export async function setCollectionStrategyStatusForInvoice(
  invoiceId: number,
  status: InvoiceStrategyStatus
) {
  const payload = {
    status,
  };
  return await ky.put(`${baseUrl}/${invoiceId}/followup`, { json: payload });
}

export async function setCollectionStrategyStatusForInvoices(
  invoiceIds: number[],
  status: InvoiceStrategyStatus
) {
  const payload = {
    invoice_ids: invoiceIds,
    status,
  };
  return await ky.put(`${baseUrl}/followup`, { json: payload });
}

export async function getInvoiceEmailRecepients(invoiceId?: number) {
  return (await ky.get(`${baseUrl}/${invoiceId}/email-recipients`).json()) as Emailable[];
}

export async function postActivityFormData<T>(
  params: T,
  activityType: ActivityKeyValues
): Promise<ActivityMutationProps> {
  return await ky.post(activityType, { json: params }).json();
}
