import { QueryKey } from '@tanstack/react-query';
import { Expansion } from 'components/Common/Expansion/Expansion';
import HideWrapper from 'components/Common/Util/HideWrapper';
import { dropRight, takeRight } from 'lodash';
import { Fragment } from 'react';
import { EmailConversation } from 'types/activities/email';
import { EmailThreadResponse } from 'types/api/inbox/email';
import useOpen from 'util/hooks/useOpen';
import { Body } from './Body';
import { ReplyDivider } from './ReplyDivider';

interface RepliesProps {
  replies: EmailConversation[];
  data: EmailThreadResponse;
  queryKey?: QueryKey;
}

interface RepliesCompProps {
  reply: EmailConversation;
  defaultOpen: boolean;
  hideDivider?: boolean;
  data: EmailThreadResponse;
  queryKey?: QueryKey;
}

function Replies(props: RepliesProps) {
  const { replies, data, queryKey } = props;
  const { open, toggleOpen } = useOpen({ open: false });

  const restReplies = dropRight(replies, 2);
  const lastTwoReplies = takeRight(replies, 2);

  const MoreButton = (
    <ReplyDivider>
      <Expansion onClick={toggleOpen} text={`${restReplies.length} more`} />
    </ReplyDivider>
  );

  const hideDivider = !open && Boolean(restReplies.length);

  return (
    <>
      {restReplies.length ? (
        <>
          {open ? (
            <>
              {restReplies.map((reply) => (
                <RepliesContent
                  reply={reply}
                  key={reply.id}
                  defaultOpen={false}
                  data={data}
                  queryKey={queryKey}
                />
              ))}
            </>
          ) : (
            MoreButton
          )}
        </>
      ) : null}
      {lastTwoReplies.map((reply, index) => (
        <RepliesContent
          reply={reply}
          key={reply.id}
          defaultOpen={Boolean(lastTwoReplies.length - 1 === index)}
          hideDivider={Boolean(hideDivider && index === 0)}
          data={data}
          queryKey={queryKey}
        />
      ))}
    </>
  );
}

export { Replies };

function RepliesContent(props: RepliesCompProps) {
  const { reply, defaultOpen, hideDivider, data, queryKey } = props;

  return (
    <Fragment>
      <HideWrapper hide={Boolean(hideDivider)}>
        <ReplyDivider />
      </HideWrapper>
      <Body conversation={reply} defaultOpen={defaultOpen} data={data} queryKey={queryKey} />
    </Fragment>
  );
}
