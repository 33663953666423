import { invoiceTransformSchema } from 'components/HigherOrderComponent/KeyActivitesContainer/ActivitySchema';
import { getAllDisputeTypes } from 'services/dispute-type';
import {
  getDisputeType,
  getInvoiceLineItems,
  getInvoicesSuggestions,
  getRecentInvoicesOfCustomer,
} from 'services/invoice';
import { ActivityType } from 'types/activities/activity-types';
import { InvoiceBasicDetails } from 'types/entities/invoice';

export const EditFormConfigurations = {
  [ActivityType.DISPUTE]: (customerId: number) => {
    return {
      invoiceQueryFn: () => getRecentInvoicesOfCustomer<InvoiceBasicDetails>(customerId),
      invoiceSearchFn: (searchTerm: string) =>
        getInvoicesSuggestions<InvoiceBasicDetails>(searchTerm, customerId),
      invoiceQueryKey: ['recent-customer-invoices', customerId],
      disputeTypesearchQueryFn: (searchTerm: string) => getDisputeType(searchTerm),
      lineItemsQueryFn: (invoiceIdsList?: number[]) => getInvoiceLineItems(invoiceIdsList),
      disputeTypeQueryFn: () => getAllDisputeTypes(),
      disputeTypeQueryKey: ['get-all-dispute-types', customerId],
      lineItemsQueryKey: ['invoiceLineItems', customerId],
      invoiceTransformSchema,
    };
  },
  [ActivityType.CALL_LOG]: (customerId: number) => {
    return {
      invoiceQueryFn: () => getRecentInvoicesOfCustomer<InvoiceBasicDetails>(customerId),
      invoiceSearchFn: (searchTerm: string) =>
        getInvoicesSuggestions<InvoiceBasicDetails>(searchTerm, customerId),
      invoiceQueryKey: ['recent-customer-invoices', customerId],
      invoiceTransformSchema,
    };
  },
  [ActivityType.NOTE]: (customerId: number) => {
    return {
      invoiceQueryFn: () => getRecentInvoicesOfCustomer<InvoiceBasicDetails>(customerId),
      invoiceSearchFn: (searchTerm: string) =>
        getInvoicesSuggestions<InvoiceBasicDetails>(searchTerm, customerId),
      invoiceQueryKey: ['recent-customer-invoices', customerId],
      lineItemsQueryFn: (invoiceIdsList?: number[]) => getInvoiceLineItems(invoiceIdsList),
      lineItemsQueryKey: ['invoiceLineItems', customerId],
      invoiceTransformSchema,
    };
  },
  [ActivityType.ESCALATION]: (customerId: number) => {
    return {
      invoiceQueryFn: () => getRecentInvoicesOfCustomer<InvoiceBasicDetails>(customerId),
      invoiceSearchFn: (searchTerm: string) =>
        getInvoicesSuggestions<InvoiceBasicDetails>(searchTerm, customerId),
      invoiceQueryKey: ['recent-customer-invoices', customerId],
      lineItemsQueryFn: (invoiceIdsList?: number[]) => getInvoiceLineItems(invoiceIdsList),
      lineItemsQueryKey: ['invoiceLineItems', customerId],
      invoiceTransformSchema,
    };
  },
  [ActivityType.TASK]: (customerId: number) => {
    return {
      invoiceQueryFn: () => getRecentInvoicesOfCustomer<InvoiceBasicDetails>(customerId),
      invoiceSearchFn: (searchTerm: string) =>
        getInvoicesSuggestions<InvoiceBasicDetails>(searchTerm, customerId),
      invoiceQueryKey: ['recent-customer-invoices', customerId],
      invoiceTransformSchema,
    };
  },
};
