import { DisputeStatus } from 'components/CollectionActivites/Disputes/types';
import { GenericSelectOption } from 'components/Common/FilterComponents/types';
import { ActivityType } from 'types/activities/activity-types';
import { disputeStatusConfig } from 'types/activities/dispute';
import { escalationStatusConfig, EscalationType } from 'types/activities/escalation';
import { PromiseToPayStatus, promiseToPayStatusConfig } from 'types/activities/promise-to-pay';
import { TaskFilter, taskFilterConfig } from 'types/activities/task';
import { TreeValueConfig } from './type';

const disputeStatusOption: GenericSelectOption<
  TreeValueConfig<'parent', 'child', ActivityType.DISPUTE, DisputeStatus>,
  'children'
>[] = [
  {
    label: disputeStatusConfig.OPEN.label,
    value: 'parent:DISPUTE - child:OPEN',
    key: 'OPEN',
  },
  {
    label: disputeStatusConfig.WAITING_ON_CUSTOMER.label,
    value: 'parent:DISPUTE - child:WAITING_ON_CUSTOMER',
    key: 'WAITING_ON_CUSTOMER',
  },
  {
    label: disputeStatusConfig.CLOSED.label,
    value: 'parent:DISPUTE - child:CLOSED',
    key: 'CLOSED',
  },
];

const taskStatusOption: GenericSelectOption<
  TreeValueConfig<'parent', 'child', ActivityType.TASK, TaskFilter>,
  'children'
>[] = [
  {
    label: taskFilterConfig.ALL.label,
    value: 'parent:TASK - child:ALL',
    key: 'ALL',
  },
  {
    label: taskFilterConfig.UPCOMING.label,
    value: 'parent:TASK - child:UPCOMING',
    key: 'UPCOMING',
  },
  {
    label: taskFilterConfig.DUE_TODAY.label,
    value: 'parent:TASK - child:DUE_TODAY',
    key: 'DUE_TODAY',
  },
  {
    label: taskFilterConfig.OVERDUE.label,
    value: 'parent:TASK - child:OVERDUE',
    key: 'OVERDUE',
  },
];
const escalationStatusOption: GenericSelectOption<
  TreeValueConfig<'parent', 'child', ActivityType.ESCALATION, EscalationType>,
  'children'
>[] = [
  {
    label: escalationStatusConfig.OPEN.label,
    value: 'parent:ESCALATION - child:OPEN',
    key: 'OPEN',
  },
  {
    label: escalationStatusConfig.CLOSED.label,
    value: 'parent:ESCALATION - child:CLOSED',
    key: 'CLOSED',
  },
];
const ptpStatusOption: GenericSelectOption<
  TreeValueConfig<'parent', 'child', ActivityType.PROMISE_TO_PAY, PromiseToPayStatus>,
  'children'
>[] = [
  {
    label: promiseToPayStatusConfig.OPEN.label,
    value: 'parent:PROMISE_TO_PAY - child:OPEN',
    key: 'OPEN',
  },
  {
    label: promiseToPayStatusConfig.CLOSED.label,
    value: 'parent:PROMISE_TO_PAY - child:CLOSED',
    key: 'CLOSED',
  },
];

const disputeOption: GenericSelectOption<ActivityType, 'children'> = {
  label: 'Dispute',
  value: ActivityType.DISPUTE,
  valueObj: {
    children: disputeStatusOption,
  },
};
const taskOption: GenericSelectOption<ActivityType, 'children'> = {
  label: 'Task',
  value: ActivityType.TASK,
  valueObj: {
    children: taskStatusOption,
  },
};
const escalationOption: GenericSelectOption<ActivityType, 'children'> = {
  label: 'Escalation',
  value: ActivityType.ESCALATION,
  valueObj: {
    children: escalationStatusOption,
  },
};
const ptpOption: GenericSelectOption<ActivityType, 'children'> = {
  label: 'Promise to Pay',
  value: ActivityType.PROMISE_TO_PAY,
  valueObj: {
    children: ptpStatusOption,
  },
};
export const WriteOffOption: GenericSelectOption<ActivityType, 'children'>[] = [
  {
    label: 'Write Off',
    value: ActivityType.WRITE_OFF,
  },
];
export const SubscriptionTypeList: GenericSelectOption<ActivityType, 'children'>[] = [
  disputeOption,
  taskOption,
  escalationOption,
  ptpOption,
  {
    label: 'Note',
    value: ActivityType.NOTE,
  },

  {
    label: 'Call Log',
    value: ActivityType.CALL_LOG,
  },
  {
    label: 'Payment',
    value: ActivityType.PAYMENT,
  },
  {
    label: 'Credit Memo',
    value: ActivityType.CREDIT_MEMO,
  },
];

export const InboxAssignedTypeList: GenericSelectOption<ActivityType, 'children'>[] = [
  disputeOption,
  taskOption,
  escalationOption,
];
