import { customerNameFormatter } from '@sinecycle/growcomponents';
import { Divider, Popover } from 'antd';
import { DateText } from 'components/BaseComponents/Date/DateText';
import { Empty } from 'components/BaseComponents/Empty/Empty';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { Spacer } from 'components/BaseComponents/Layout/Spacer';
import { GrowParagraph, GrowText } from 'components/BaseComponents/Typography';
import { Texto } from 'components/BaseComponents/Typography/Texto';
import { FilePreviewList } from 'components/BaseComponents/UploadFiles/FilePreview';
import { InvoicesColumns } from 'components/Common/Invoices/InvoicesColumn';
import { InvoicesText } from 'components/Common/Invoices/InvoicesText';
import {
  mapInvoicesBaseCurrencyPredicate,
  mapInvoicesColumnPredicate,
  mapInvoicesTransactionalCurrencyPredicate,
} from 'components/Common/Invoices/predicate';
import { Amount } from 'components/Common/MultiCurrency';
import InvoiceDetailsList from 'components/HigherOrderComponent/InvoiceList';
import { EActivityQueryParams } from 'components/HigherOrderComponent/KeyActivitesContainer/type';
import { UserAvatar, UserAvatarProps } from 'components/HigherOrderComponent/UserAvatar';
import UserInfoAvatar from 'components/HigherOrderComponent/UserInfoAvatar';
import { PageLink } from 'lib/router';
import { drop, map, take } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { IInvoiceLineItems } from 'store/activity-feed/type';
import { baseCurrencySelector } from 'store/authentication/authentication';
import styled from 'styled-components';
import { Attachment } from 'types/common/attachments';
import { AmountMaybeWithMultiCurrencySplit } from 'types/common/multi-currency';
import {
  Invoice,
  InvoiceBasicDetails,
  InvoiceLineItem,
  StatementViews,
} from 'types/entities/invoice';
import { UserBasic } from 'types/entities/user';
import { UserInfoSection } from '../UserInfo';

const t = {
  associatedInvoices: 'Invoices',
  attachments: 'Attachments',
  assignedTo: 'Assigned To',
};

export const Seperator = () => <Divider style={{ backgroundColor: 'var(--gray-5)' }} />;
const NoData = () => <GrowText color="var(--gray-7)">﹣</GrowText>;

interface CustomerBlockProps {
  id: number;
  name?: string;
}
interface LineItemBlockProps {
  item?: InvoiceLineItem[] | IInvoiceLineItems[];
}

interface InvoicesBlockProps {
  invoices?: Invoice[] | InvoiceBasicDetails[];
  isBaseCurrency?: boolean;
  showTransactionalCurrency?: boolean;
}

interface CreatedBlockProps {
  createdBy: UserBasic;
  createdDate: string;
}

interface MoreInvoiceContentProps {
  invoice: InvoicesColumns[];
  showTransactionalCurrency?: boolean;
  hideAmount?: boolean;
}
function MoreInvoiceContent(props: MoreInvoiceContentProps) {
  const { invoice, showTransactionalCurrency, hideAmount } = props;
  return (
    <Popover
      arrow={false}
      content={
        <Flex
          direction="column"
          gap={'var(--space-8)'}
          style={{ maxHeight: '120px', overflow: 'auto' }}
        >
          {invoice.map((invoice) => {
            return (
              <InvoicesText
                key={invoice.id}
                invoice={invoice}
                overRideStyle={{ gap: 'var(--space-16)' }}
                textStyle={{
                  amount: {
                    strong: true,
                  },
                  title: {
                    strong: !hideAmount,
                    style: {
                      width: hideAmount ? 'fit-content' : '250px',
                      color: 'var(--primary-7)',
                      wordBreak: 'break-all',
                    },
                  },
                }}
                hide={{
                  amount: showTransactionalCurrency || hideAmount,
                  transactionalAmount: !showTransactionalCurrency,
                }}
                align="flex-start"
              />
            );
          })}
        </Flex>
      }
      overlayInnerStyle={{ maxHeight: '160px', overflow: 'auto' }}
      getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
      align={{ offset: [5, 8] }}
    >
      <GrowText
        color="var(--gray-8)"
        className="cursor-pointer"
        style={{ width: 'fit-content' }}
        strong
      >{`+${invoice.length} more`}</GrowText>
    </Popover>
  );
}

function getInvoices(
  baseCurrency?: string,
  invoices?: Invoice[] | InvoiceBasicDetails[],
  showTransactionalCurrency?: boolean,
  isBaseCurrency?: boolean
) {
  if (!invoices) return [];

  if (showTransactionalCurrency) {
    return invoices.map((invoice) =>
      mapInvoicesTransactionalCurrencyPredicate(invoice, baseCurrency ?? 'USD')
    );
  }

  return isBaseCurrency
    ? invoices.map((invoice) => mapInvoicesBaseCurrencyPredicate(invoice, baseCurrency ?? 'USD'))
    : invoices.map(mapInvoicesColumnPredicate);
}

export function InvoicesBlock(props: InvoicesBlockProps) {
  const { invoices, isBaseCurrency, showTransactionalCurrency } = props;
  const baseCurrency = useSelector(baseCurrencySelector);

  const defaultInvoices: InvoicesColumns[] = getInvoices(
    baseCurrency,
    invoices,
    showTransactionalCurrency,
    isBaseCurrency
  );

  const first = take(defaultInvoices, 3);
  const rest = drop(defaultInvoices, 3);

  return (
    <Empty isEmpty={!invoices || !invoices.length}>
      <Flex direction="column" gap="var(--space-8)">
        {first.map((invoice) => {
          return (
            <InvoicesText
              key={invoice.id}
              invoice={invoice}
              overRideStyle={{ gap: 'var(--space-16)' }}
              textStyle={{
                amount: {
                  strong: true,
                },
                title: {
                  strong: true,
                  style: {
                    width: '220px',
                    color: 'var(--primary-7)',
                    wordBreak: 'break-all',
                  },
                },
              }}
              hide={{
                amount: showTransactionalCurrency,
                transactionalAmount: !showTransactionalCurrency,
              }}
              align="flex-start"
            />
          );
        })}

        {rest.length ? (
          <MoreInvoiceContent
            invoice={rest}
            showTransactionalCurrency={showTransactionalCurrency}
          />
        ) : null}
      </Flex>
    </Empty>
  );
}

export function LineItemBlock(props: LineItemBlockProps) {
  const { item } = props;
  return (
    <Empty isEmpty={!item || !item.length}>
      <Flex direction="column" gap="var(--space-4)">
        {item?.map((item, index) => {
          return <GrowText key={item.id} strong>{`${index + 1}. ${item.productName}`}</GrowText>;
        })}
      </Flex>
    </Empty>
  );
}

export function CustomerBlock(props: CustomerBlockProps) {
  const { id, name } = props;
  const customerName = customerNameFormatter({ name });
  return (
    <Empty isEmpty={!id}>
      <PageLink
        pathParams={{ id }}
        appPage="CUSTOMER_DETAILS"
        target={'_blank'}
        searchParams={
          {
            [EActivityQueryParams.CURRENT_SECTION]: StatementViews.INVOICES,
            [EActivityQueryParams.CURRENT_VIEW]: StatementViews.OPEN_INVOICES,
          } as unknown as URLSearchParams
        }
        style={{ display: 'flex', cursor: 'pointer' }}
      >
        <GrowText
          color="var(--primary-7)"
          ellipsis={{ tooltip: customerName }}
          style={{ maxWidth: '180px' }}
        >
          {customerName}
        </GrowText>
      </PageLink>
    </Empty>
  );
}
export function DetailSpacer() {
  return <Spacer axis="vertical" size="24" />;
}

interface UsersBlockProps {
  users?: UserBasic[];
  label?: string;
  className?: string;
}
export function UsersBlock(props: UsersBlockProps) {
  const { users, label } = props;

  if (!users?.length) return null;

  return (
    <Flex direction="column" gap="--space-8">
      <GrowText color="var(--gray-7)">{label ?? t.assignedTo}</GrowText>
      <Flex wrap="wrap">
        {users.map((assignee, key) => (
          <UserInfoAvatar
            key={key}
            name={[assignee.first_name, assignee.last_name as string]}
            size="small"
            title={assignee.designation}
          />
        ))}
      </Flex>
    </Flex>
  );
}

interface UserBlockProps {
  user?: UserBasic;
  dateString?: string;
  label: string;
}
export function UserBlock({ user, dateString, label }: UserBlockProps) {
  return (
    <Flex direction="column" gap="--space-4">
      <GrowText color="var(--gray-7)">{label}</GrowText>
      {user ? <UserInfoSection user={user} dateString={dateString} hideImgAvatar /> : <NoData />}
    </Flex>
  );
}

interface InvoiceListBlockProps {
  invoices: InvoiceBasicDetails[];
  context?: string;
  label?: string;
}
export function InvoiceListBlock({ invoices = [], context, label }: InvoiceListBlockProps) {
  if (!invoices.length) return null;

  return (
    <Flex direction="column" gap="--space-4">
      <Texto size="12" color="--gray-7">
        {label ?? t.associatedInvoices}
      </Texto>
      <InvoiceDetailsList invoiceList={invoices ?? []} spliceLength={3} context={context} />
    </Flex>
  );
}

interface AttachmentsBlockProps {
  attachments: Attachment[];
  hideTitle?: boolean;
  titleColor?: string;
  onRemove?: (id: number) => void;
  color?: string;
}
export function AttachmentsBlock({
  attachments,
  hideTitle,
  onRemove,
  color,
}: AttachmentsBlockProps) {
  if (!attachments.length) return null;

  return (
    <>
      {hideTitle ? (
        <FilePreviewList files={attachments} onRemove={onRemove} />
      ) : (
        <Flex direction="column" gap="var(--space-12)" className="attachments description">
          <LabelBlock label={t.attachments} color={color} />
          <Flex wrap="wrap" gap="var(--space-12)">
            <FilePreviewList files={attachments} onRemove={onRemove} />
          </Flex>
        </Flex>
      )}
    </>
  );
}

interface DateBlockProps {
  label?: string;
  dateString: string;
  dateOnly?: boolean;
  strong?: boolean;
  color?: string;
  style?: React.CSSProperties;
}
export function DateBlock({ dateString, dateOnly, strong, color, style }: DateBlockProps) {
  return (
    <Empty isEmpty={!dateString || !dateString.length}>
      <DateText
        value={dateString}
        showTime={!dateOnly}
        textProps={{ strong, style: { ...style, color: color } }}
      />
    </Empty>
  );
}

interface TextDataBlockProps {
  label: string;
  value: React.ReactNode;
}
interface TextBlockProps {
  value?: string;
  strong?: boolean;
  size?: string;
}

export function TextBlock(props: TextBlockProps) {
  return (
    <Empty isEmpty={!props.value || !props.value.length}>
      <GrowParagraph
        style={{ margin: 0, whiteSpace: 'pre-wrap' }}
        strong={props.strong}
        size={props.size}
      >
        {props.value}
      </GrowParagraph>
    </Empty>
  );
}
export function TextDataBlock({ label, value }: TextDataBlockProps) {
  return (
    <Flex direction="column" gap="--space-4">
      <Texto size="12" color="--gray-7">
        {label}
      </Texto>
      {value ? <Texto weight="semibold">{value}</Texto> : <NoData />}
    </Flex>
  );
}

interface AmountBlockProps {
  label?: string;
  amount?: number;
  currency?: string;
  amountComp?: React.ReactNode;
  size?: string;
}
export function AmountBlock({
  amount,
  currency,
  amountComp,
  size = 'var(--fs-14)',
}: AmountBlockProps) {
  return (
    <Empty isEmpty={!amount}>
      <GrowText strong size={size}>
        {amountComp ? amountComp : <Amount amount={amount} currency={currency} />}
      </GrowText>
    </Empty>
  );
}
interface UserInfoBlockProps {
  user?: UserBasic;
  size?: UserAvatarProps['size'];
  onClick?: () => void;
  className?: string;
}

export function UserInfoBlock(props: UserInfoBlockProps) {
  const { user, size, onClick, className } = props;
  const Avatar = <UserAvatar name={[user?.first_name ?? '', user?.last_name ?? '']} size={size} />;

  return (
    <Empty isEmpty={!user}>
      <Flex align="center" gap="var(--space-8)">
        {Avatar}
        <GrowText
          className={className}
          onClick={onClick}
          ellipsis={{
            tooltip: {
              title: (
                <Flex>
                  <GrowText color="var(--gray-1)">
                    {`${user?.first_name} ${user?.last_name ?? ''}`}
                  </GrowText>
                  {user?.designation ? (
                    <GrowText color="var(--gray-4)">{`(${user.designation})`}</GrowText>
                  ) : null}
                </Flex>
              ),
              placement: 'left',
            },
          }}
          style={{ maxWidth: '140px' }}
        >{`${user?.first_name} ${user?.last_name}`}</GrowText>
      </Flex>
    </Empty>
  );
}
interface LabelBlockProps {
  label?: string;
  color?: string;
  strong?: boolean;
}
export function LabelBlock(props: LabelBlockProps) {
  const { label, color, strong } = props;
  return (
    <GrowText color={color ? color : 'var(--gray-8)'} strong={strong}>
      {label}
    </GrowText>
  );
}
const BlockFlex = styled.div`
  display: flex;
  & > * {
    flex: 1 1 0;
  }
`;
export function BlockRow({ children }: { children: React.ReactNode }) {
  return <BlockFlex>{children}</BlockFlex>;
}

export function EntityInvoiceList(props: InvoicesBlockProps) {
  const { invoices } = props;

  const defaultInvoices = map(invoices, mapInvoicesColumnPredicate);

  const first = take(defaultInvoices, 2);
  const rest = drop(defaultInvoices, 2);

  return (
    <Empty isEmpty={!invoices || !invoices.length}>
      <Flex direction="row" gap={'var(--space-8)'}>
        <Flex direction="row">
          {first.map((invoice, index) => (
            <Flex key={invoice.id} gap={'var(--space-2)'}>
              <InvoicesText
                key={invoice.id}
                invoice={invoice}
                overRideStyle={{ gap: 'var(--space-16)' }}
                textStyle={{
                  title: {
                    ellipsis: { tooltip: invoice.title },
                    style: {
                      maxWidth: '280px',
                      color: 'var(--primary-7)',
                    },
                  },
                }}
                hide={{
                  amount: true,
                  transactionalAmount: true,
                }}
                align="flex-start"
              />
              {first.length - 1 === index ? null : ', '}
            </Flex>
          ))}
        </Flex>
        <Flex>{rest.length ? <MoreInvoiceContent invoice={rest} hideAmount /> : null}</Flex>
      </Flex>
    </Empty>
  );
}

export function CreatedBlock(props: CreatedBlockProps) {
  const { createdBy, createdDate } = props;
  return (
    <Flex direction="column" gap={'var(--space-4)'}>
      <UserInfoBlock user={createdBy} />
      <DateBlock dateString={createdDate} />
    </Flex>
  );
}

export function multiCurrencyAmount(
  amount: number,
  baseCurrency: string,
  currency: string,
  transformedCurrency: number
): AmountMaybeWithMultiCurrencySplit {
  return {
    value: amount,
    multi_currency_split: [
      {
        currency: baseCurrency,
        transformed_currency: currency,
        value: transformedCurrency,
        value_in_transformed_currency: amount,
      },
    ],
    currency: currency,
  };
}
