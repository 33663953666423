import { InputLoader } from 'components/Common/FilterComponents/Common/SkeletonLoader';
import { AmountWithCurrencySplit } from 'components/Common/MultiCurrency';
import { dashboardTexts } from 'components/Dashboard/texts';
import TotalCustomersInvoices from 'components/Dashboard/TotalCustomersInvoices';
import DashboardCardTemplate from 'pages/Dashboard/AdminDashboard/SnapShot/CollectionBalanceAndOutstanding/CardTemplate';
import { DashboardComponentProps } from 'pages/Dashboard/types';
import React from 'react';
import { TotalOutstandingResponse } from 'types/api/admin-dashboard';

interface Props extends DashboardComponentProps {
  totalOutstanding?: TotalOutstandingResponse;
  loading: boolean;
}

function UnappliedCredits({ totalOutstanding, loading }: Props) {
  const unAppliedCredit = totalOutstanding?.total_outstanding.un_applied_credit_amount;
  const unAppliedCreditCustomersCount = unAppliedCredit?.customer_count;

  const InputLoaderJSX = <InputLoader active={loading} />;

  const UnappliedCreditAmount = unAppliedCredit && (
    <AmountWithCurrencySplit
      type="multi"
      amountWithCurrencySplit={unAppliedCredit.amount}
      className="text-20 semi-bold"
      formatOptions={{ maximumFractionDigits: 0 }}
    />
  );

  const TotalCustInvoiceInfo = (
    <TotalCustomersInvoices
      loading={loading}
      customersCount={unAppliedCreditCustomersCount}
      showInvoice={false}
    />
  );

  const AmountInfo = <> {loading ? InputLoaderJSX : UnappliedCreditAmount}</>;
  return (
    <DashboardCardTemplate
      title={dashboardTexts.unappliedCredits.title}
      tagColor="var(--lime-3)"
      amountInfo={AmountInfo}
      customerInvoiceCount={TotalCustInvoiceInfo}
    />
  );
}

export default React.memo(UnappliedCredits);
