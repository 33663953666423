import { Card } from 'antd';
import { Style } from 'lib/style/style';
import { Commento } from 'types/activities/comment';
import { CommentsList } from './CommentsList';
import { Form } from './Form';
const { Meta } = Card;
interface Comments extends Commento {}

export const StyledMeta = Style(Meta, {
  '.ant-card-meta-detail > div:not(:last-child)': {
    marginBottom: 'var(--space-16)',
  },
  '.ant-card-meta-avatar': {
    paddingRight: 'var(--space-8)',
  },
  '.ant-card-meta-detail': {
    width: '100%',
  },
  '.ant-card-meta-title': {
    fontWeight: 'var(--fs-regular)',
  },
  '.ant-card-meta-description': {
    color: 'var(--gray-9)',
  },
});
const Komento = (props: Comments) => {
  return <CommentsList comments={[props]} />;
};

Komento.Form = Form;

export { Komento };
