import TextWithLinkCell from 'components/ARAging/ReceivablesByAgingBucket/AgingCustomersTable/Columns/Customer/CellRenderer/ParentCellRender';
import { AmountColumn } from './AmountCell';
import { CellWithExtraInfo } from './CellWithExtraInfo';
import { CustomColumn } from './CustomColumn';
import { CustomerTagsCell } from './CustomerTags';
import { InvoicesCell } from './InvoicesCell';
import MultiCurrencyCell from './MultiCurrencyCell';
import { NumberCell } from './NumberCell';
import SubsidiaryCell from './SubsidiaryCell';

export const cellComponents = {
  invoices: InvoicesCell,
  tags: CustomerTagsCell,
  multiCcell: MultiCurrencyCell,
  subsidiary: SubsidiaryCell,
  customColumn: CustomColumn,
  amountCell: AmountColumn,
  numberText: NumberCell,
  cellWithExtraInfo: CellWithExtraInfo,
  textWithLinkCell: TextWithLinkCell,
};
export type CellComponents = typeof cellComponents;
export type CellComponentsKeys = keyof typeof cellComponents;
