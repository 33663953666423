import { Select, Skeleton } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { debounce, map } from 'lodash';
import { useCallback, useState } from 'react';
import { invoiceSelectCustomizer, selectOptionsInitializer } from '../Utils';
import useGetInvoiceSearch from '../hooks/useGetInvoiceSearch';
import useGetInvoiceSelectOptions from '../hooks/useGetInvoiceSelectOptions';
import {
  ListOptions,
  MaxTagListRenderer,
  customTagRender,
  generateInvoiceOptions,
} from '../predicate';
import { InvoiceFieldMapping, InvoiceSelectProps } from '../types';

export default function InvoiceSelect<T>(props: InvoiceSelectProps<T>) {
  const {
    singleSelect,
    openInvoices,
    onChangeInvoice,
    selectedInvoicesId,
    placeholder = 'Search Invoice ID',
    transformSchema,
    queryFn,
    queryKey,
    searchQueryFn,
    selectedInvoices,
  } = props;

  const [optionsMap, setOptionsMap] = useState<Map<number, InvoiceFieldMapping>>();
  const [selectedValues, setSelectedValues] = useState<number[] | undefined>(selectedInvoicesId);
  const [searchValue, setSearchValue] = useState<string>();
  const [options, setOptions] = useState<DefaultOptionType[]>([]);
  const onSuccess = useCallback(onSuccessCallBack, []);

  const { isLoading } = useGetInvoiceSelectOptions<T>({
    queryFn,
    queryKey,
    transformSchema,
    openInvoices,
    selectedInvoices,
    onSuccess,
  });

  const { searchInvoiceList, searchLoading, searchOptions } = useGetInvoiceSearch<T>({
    searchQueryFn,
    transformSchema,
    openInvoices,
    selectedInvoices,
    modifyOptionsMap,
  });

  const onSearch = debounce((searchTerm: string) => {
    searchInvoiceList(searchTerm);
    setSearchValue(searchTerm);
  }, 800);

  function onSuccessCallBack(
    optionsList: DefaultOptionType[],
    optionsMapList: Map<number, InvoiceFieldMapping>
  ) {
    modifyOptionsMap(optionsMapList);
    setOptions(optionsList);
  }

  function modifyOptionsMap(optionsMapList: Map<number, InvoiceFieldMapping>) {
    setOptionsMap((prev) => {
      const mergedMap = new Map(
        prev ? [...prev.entries(), ...optionsMapList.entries()] : optionsMapList.entries()
      );
      return mergedMap;
    });
  }

  if (isLoading) {
    return <Skeleton.Input active block />;
  }

  function handleDropDownVisibleChange(open: boolean) {
    if (open) {
      if (selectedValues?.length) {
        const selectedInvoiceOptions = map(selectedValues, (item) =>
          optionsMap?.get(item)
        ) as InvoiceFieldMapping[];
        const optionsList = generateInvoiceOptions(selectedInvoiceOptions, openInvoices);
        const sortedOptions = selectOptionsInitializer(
          options,
          optionsList,
          invoiceSelectCustomizer,
          'value',
          selectedValues
        );

        setOptions(sortedOptions);
      }
    } else {
      setSearchValue('');
    }
  }

  return (
    <Select
      placeholder={placeholder}
      value={selectedValues}
      autoClearSearchValue
      mode={!singleSelect ? 'multiple' : undefined}
      listHeight={250}
      style={{ width: '100%' }}
      filterOption={false}
      suffixIcon={null}
      showSearch
      onSearch={onSearch}
      options={searchValue ? searchOptions : options}
      optionFilterProp="label"
      dropdownRender={(options) => {
        return ListOptions(options, isLoading || searchLoading);
      }}
      onDropdownVisibleChange={handleDropDownVisibleChange}
      tagRender={(props) => {
        return customTagRender<T, InvoiceFieldMapping>(props, 'invoiceNo', optionsMap);
      }}
      maxTagPlaceholder={(list) => {
        return (
          <MaxTagListRenderer<InvoiceFieldMapping>
            list={list}
            optionsMap={optionsMap}
            extractKey={'invoiceNo'}
          />
        );
      }}
      maxTagCount="responsive"
      onChange={(value) => {
        setSelectedValues(value);
        const selectedInvoiceOptions = map(value, (item) =>
          optionsMap?.get(item)
        ) as InvoiceFieldMapping[];
        onChangeInvoice(value, selectedInvoiceOptions);
      }}
    />
  );
}
