import { useQueryClient } from '@tanstack/react-query';
import { Button } from 'antd';
import { notify } from 'components/BaseComponents/Notifications';
import { openInvoiceStatus } from 'components/CustomerDetails/Body/Statements/OpenInvoicesStatements';
import { COMMON_EVENT } from 'events/common';
import produce from 'immer';
import { dispatchAppEvent } from 'lib/pub-sub';
import { useUnFlagInvoice, useUnFlagInvoiceBulk } from 'queries/invoices';
import { useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { FlagInvoiceParams } from 'services/flag-invoices';
import { IInvoiceFlag, IInvoices, StatementsResponse } from 'store/customer/types';
import { updateInvoiceFlagList } from 'store/invoice-flag/invoice-flag';
import { IInvoiceIdAndFlag } from 'store/invoice-flag/types';
import { IActionsModal } from '../ActionsModal/types';
import { EActions } from '../types';
const t = {
  msg: {
    success: 'Invoice(s) Unflagged',
    error: 'Unable to un-flag invoice',
    warn: 'Invoice(s) already unflagged',
  },
};

export const UnFlagInvoice = (props: IActionsModal) => {
  const dispatch = useDispatch();
  const { mutateAsync: unflagInvoice } = useUnFlagInvoice();
  const { mutateAsync: unflagInvoiceBulk } = useUnFlagInvoiceBulk();
  const ref = useRef<HTMLButtonElement>(null);

  const invoicesToUnFlag = useMemo(() => {
    const invoices = [] as number[];
    props.invoiceList?.forEach((inv) => {
      invoices.push(inv.id);
    });

    return invoices;
  }, [props.invoiceList]);
  const handleUnFlag = () => {
    const invoiceFlagStatusList = [] as IInvoiceIdAndFlag[];
    invoicesToUnFlag.forEach((id) => {
      invoiceFlagStatusList.push({ id: id, flag: false } as IInvoiceIdAndFlag);
    });

    dispatch(updateInvoiceFlagList({ list: invoiceFlagStatusList }));
    dispatchAppEvent({
      type: COMMON_EVENT.COLLECTION_ACTION,
      payload: {
        actionName: 'Unflag Invoices',
        actionType: 'Save',
      },
    });

    notify.success(t.msg.success);
    handleUpdatedValues();
    props.resetToIntialState?.();
  };

  const queryClient = useQueryClient();

  function handleUpdatedValues() {
    const currentData = queryClient.getQueriesData<StatementsResponse<IInvoices, any>>(
      props.queryKey ?? []
    );

    if (!currentData.length) return;
    const [, previousData] = currentData[0];
    if (!previousData?.list) return;

    const updatedData = produce(previousData?.list, (draft) => {
      draft.map((invoice) => {
        if (invoicesToUnFlag?.includes(invoice.id)) {
          invoice.invoice_flag = undefined as unknown as IInvoiceFlag;
          return invoice;
        }
        return invoice;
      });
    });

    queryClient.setQueriesData<StatementsResponse<IInvoices, any>>(
      [...(props.queryKey ?? [])],
      (old) => {
        if (!old) return;
        return {
          ...old,
          list: [...updatedData],
        };
      }
    );
  }
  const handleError = (e: any) => {
    console.log('Unable to un-flag invoice', e);
    notify.error(t.msg.error);
  };

  useEffect(() => {
    if (props.quickAction === EActions.UNFLAG_INVOICE) {
      if (ref.current) {
        ref.current.click();
      }
    }
  }, [props.quickAction]);

  const handleClick = () => {
    if (props.isFromInvoiceList) {
      const param = {
        ...props.currentFilterView,
        status: props.currentFilterView?.status ?? openInvoiceStatus,
        bulk_action: 'UN_FLAG',
        flag: {
          invoice_list: invoicesToUnFlag,
        },
        size: 10,
        page: 1,
      };

      handleRequest(param);
    } else {
      const param = {
        bulk_action: 'UN_FLAG',
        flag: {
          invoice_list: invoicesToUnFlag,
        },
        filter: props.currentFilterView,
      };
      handleRequestCustomerDetails(param);
    }
  };

  const handleRequest = (param: any) => {
    if (props.selectAll) {
      unflagInvoiceBulk({ param, baseUrl: 'invoices' })
        .then(() => {
          handleUnFlag();
        })
        .catch((e) => {
          handleError(e);
        });
    }
    if (!props.selectAll) {
      unflagInvoice({ params: { invoice_list: invoicesToUnFlag } as FlagInvoiceParams })
        .then(() => {
          handleUnFlag();
        })
        .catch((e) => {
          handleError(e);
        });
    }
  };

  const handleRequestCustomerDetails = (param: any) => {
    if (props.selectAll) {
      unflagInvoiceBulk({
        param: param,
        baseUrl: `customers/${props.customerId}/invoices`,
      })
        .then(() => {
          handleUnFlag();
        })
        .catch((e) => {
          handleError(e);
        });
    }
    if (!props.selectAll) {
      unflagInvoice({ params: { invoice_list: invoicesToUnFlag } as FlagInvoiceParams })
        .then(() => {
          handleUnFlag();
        })
        .catch((e) => {
          handleError(e);
        });
    }
  };
  return <Button style={{ display: 'none' }} onClick={handleClick} ref={ref} />;
};
