import React, { useCallback, useEffect, useMemo, useState } from 'react';
import SearchComponent from '../Common/SearchComponent';
import SkeletonLoader from '../Common/SkeletonLoader';

import { FilterDropdown } from '../FilterDropdown';
import { FilterConfig, FilterType, SelectOption } from '../types';
import { useGenerateOptionsWithSearch } from './hooks/useGenerateOptionsWithSearch';
import { FilterConfigWrapperProps } from './type';

interface BaseFilterBlockProps<T, M> {
  value?: T;
  onSubmit?: (value: T, valueObj?: M) => void;
  onClear?: () => void;
  showClear?: boolean;
  onCancel?: () => void;
}

type SearchOptions<T> = {
  [P in keyof T]: T[P];
};
type onSearchOptions<M> =
  | SelectOption[]
  | {
      value: SearchOptions<M>[keyof M];
      label: string | SearchOptions<M>[keyof M];
      valueObj: SearchOptions<M>;
    }[];
type attributeKeys<F> = keyof F;
interface MultiSelectFilterBlockProps<T, M> extends BaseFilterBlockProps<T, M> {
  filterConfig: FilterConfigWrapperProps;
  placeholder?: string;
  onSearch?: (value: string) => Promise<SearchOptions<M>[]>;
  valueAttribute?: attributeKeys<M>;
  labelAttribute?: attributeKeys<M> | attributeKeys<M>[];
  style?: React.CSSProperties;
  shouldDebounce?: boolean;
  emptyText?: string;
  optionsPredicate?: (option: SearchOptions<M>) => SelectOption;
  hide?: boolean;
  onSearchDataTransform?: (contacts: SearchOptions<M>[] | undefined) => {
    value: number;
    label: string;
  }[];
  onSearchCallback?: (value: string) => void;
}
type ArrayKey = number[] | string[];

export function MultiSelectCheckboxWrapperWithSearch<M, T extends ArrayKey>(
  props: MultiSelectFilterBlockProps<T, M>
) {
  const {
    filterConfig,
    onClear,
    onSubmit,
    value,
    showClear = true,
    placeholder,
    onSearch,
    shouldDebounce,
    labelAttribute = 'label',
    valueAttribute = 'value',
    style,
    emptyText,
    onSearchCallback,
  } = props;
  const [
    computedOptions,
    setComputedOptions,
    handleResetToDefaultOptions,
    updateComputedOptions,
    updateOptionsWithSelectedValue,
  ] = useGenerateOptionsWithSearch(filterConfig.options, filterConfig.shouldIncludeUnassigned);
  const [shouldReset, setShouldReset] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [selectedValues, setSelectedValues] = useState<T>();
  const filterConfigs: FilterConfig = {
    ...filterConfig,
    options: computedOptions,
    type: FilterType.MULTI_SELECT_CHECKBOX,
  };

  function handleSubmit(value: T) {
    onSubmit && onSubmit(value);
    setShouldReset(true);
    updateComputedOptions(value);
    setLoading(false);
  }
  function handleClear() {
    onClear && onClear();
    onSubmit && onSubmit([] as unknown as T);
    setShouldReset(true);
    setLoading(false);
  }

  function handleCancel() {
    props.onCancel?.();
    if (value) {
      updateComputedOptions(value);
    } else {
      handleResetToDefaultOptions();
      setSelectedValues(undefined);
    }

    setShouldReset(true);
    setLoading(false);
  }

  function handleClearSearch() {
    if (selectedValues) {
      updateComputedOptions(selectedValues);
    } else {
      handleResetToDefaultOptions();
    }

    setShouldReset(true);
    setLoading(false);
  }
  const handleOnClear = useCallback(handleClearSearch, [
    handleResetToDefaultOptions,
    updateComputedOptions,
    selectedValues,
  ]);

  function handleDropdownVisibleChange(visible: boolean) {
    if (visible) {
      setShouldReset(false);
    } else {
      handleCancel();
    }
  }

  const handleDebouncedSearch = useCallback(handleSearch, [
    labelAttribute,
    onSearch,
    props,
    selectedValues,
    setComputedOptions,
    updateOptionsWithSelectedValue,
    valueAttribute,
  ]);

  function handleSearch(keyword: string) {
    setLoading(true);

    const defaultOptionsPredicate = (option: SearchOptions<M>) => {
      return {
        value: option[valueAttribute as keyof M],
        label: Array.isArray(labelAttribute)
          ? `${option[labelAttribute[0]]} ${option[labelAttribute[1]] ?? ''}`
          : option[labelAttribute as keyof M],
        valueObj: option,
      };
    };

    if (onSearch) {
      onSearch(keyword)
        .then((data) => {
          let options: onSearchOptions<M>;
          if (props?.onSearchDataTransform && data.length) {
            options = props?.onSearchDataTransform(data);
          } else {
            options = props?.optionsPredicate
              ? data.map(props.optionsPredicate)
              : data.map(defaultOptionsPredicate);
          }
          setLoading(false);
          if (selectedValues?.length) {
            updateOptionsWithSelectedValue(selectedValues, options as SelectOption[]);
          } else {
            setComputedOptions(options as SelectOption[]);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  function handleSearchCallBack(search: string) {
    if (onSearchCallback) {
      onSearchCallback(search);
    } else if (search.trim() === '') {
      if (selectedValues?.length) {
        updateComputedOptions(selectedValues);
      } else {
        handleResetToDefaultOptions();
      }
    } else {
      handleDebouncedSearch(search);
    }
  }

  const searchCallBack = useCallback(handleSearchCallBack, [
    handleDebouncedSearch,
    handleResetToDefaultOptions,
    onSearchCallback,
    selectedValues,
    updateComputedOptions,
  ]);

  function handleSearchDropdownConfigs() {
    const SearchComp = (
      <SearchComponent
        placeholder={placeholder}
        searchCallBack={searchCallBack}
        style={style}
        shouldReset={shouldReset}
        shouldDebounce={shouldDebounce}
        onClearCallBack={handleOnClear}
        allowClear
        contentOnly={props.filterConfig.contentOnly}
      />
    );

    return {
      enableSearch: true,
      searchComponent: SearchComp,
      loading,
      skeletonLoader: <SkeletonLoader />,
      emptyText,
    };
  }

  const searchDropdownConfigs = useMemo(handleSearchDropdownConfigs, [
    placeholder,
    searchCallBack,
    style,
    shouldReset,
    shouldDebounce,
    handleOnClear,
    props.filterConfig.contentOnly,
    loading,
    emptyText,
  ]);

  useEffect(() => {
    setSelectedValues(value);
  }, [value]);

  if (props.hide) return null;

  return (
    <FilterDropdown
      value={selectedValues}
      filterConfig={filterConfigs}
      onSubmit={handleSubmit}
      onClear={handleClear}
      showClear={showClear}
      onCancel={handleCancel}
      search={searchDropdownConfigs}
      onDropdownVisibilityChange={handleDropdownVisibleChange}
      trigger={['click']}
      onChange={(value) => {
        setSelectedValues(value);
      }}
    />
  );
}
