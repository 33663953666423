import { HideWrapper } from '@sinecycle/growcomponents';
import { Button } from 'antd';
import { getDocumentConfig } from 'components/HigherOrderComponent/KeyActivitesContainer/predicate';
import useOpen from 'util/hooks/useOpen';
import { DocumentFormElementProps, DocumentFormModalProps } from '../types';
import DocumentFormModal from './DocumentFormModal';

export default function DocumentButton<DocumentElements extends DocumentFormElementProps>(
  props: Omit<
    DocumentFormModalProps<DocumentElements>,
    'openModal' | 'form' | 'onClose' | 'onFormClose' | 'handleDisable'
  >
) {
  const { buttonConfig, disabled, mode = 'BUTTON' } = props;
  const { open, toggleOpen, onClose } = useOpen({ open: props.open ?? false });
  const config = getDocumentConfig(disabled, buttonConfig);
  function handleClose() {
    onClose();
    props.handleClose?.();
  }

  return (
    <>
      <HideWrapper hide={mode === 'LIST'}>
        <Button
          icon={!buttonConfig?.hideIcon ? config.icon : null}
          onClick={toggleOpen}
          disabled={disabled}
          {...buttonConfig}
        >
          {!buttonConfig?.hidetext && (buttonConfig?.text || config.label)}
        </Button>
      </HideWrapper>

      <DocumentFormModal<DocumentElements> openModal={open} onClose={handleClose} {...props} />
    </>
  );
}
