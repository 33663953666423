import { useSelector } from 'react-redux';
import { receivableDisplaySelector } from 'store/receivable-display/receivable-display';
import { BaseFilterBlockProps } from '../FilterBlocks/types';
import { FilterDropdown } from '../FilterDropdown';
import { DayRange, DayRangeValueType, DayRanges, dayRangeTypes } from '../Filters/DayRange';
import { FilterConfig, FilterType } from '../types';

interface DayRangeProps extends BaseFilterBlockProps<DayRangeValueType> {
  filterConfig: Pick<FilterConfig, 'name' | 'label'>;
}
const currentDue: DayRange = {
  label: 'Current Due',
  id: 'CURRENT_DUE',
  value: {
    start: -10_000,
    end: 0,
    type: 'CURRENT_DUE',
  },
};
export function DayRangeWrapper(props: DayRangeProps) {
  const { filterConfig, onClear, onSubmit, showClear = true, value } = props;
  const agingConfig = useSelector(receivableDisplaySelector),
    { data: agingConfigData } = agingConfig;

  const agingDayRangeOptions: DayRange[] =
    agingConfigData?.config_data.map((config) => ({
      label: config.display_name,
      id: config.id,
      value: {
        start: Number(config.from_days),
        end: config.to_days ? Number(config.to_days) : undefined,
        type: config.id,
      },
    })) ?? [];
  const dueDaysRangeFilterOptions = [currentDue, ...agingDayRangeOptions];
  const filterConfigo: FilterConfig = {
    ...filterConfig,
    type: FilterType.DAY_RANGE,
    valueToString: (value: DayRangeValueType) => {
      if (dayRangeTypes.includes(value.type as unknown as DayRanges)) {
        switch (value.type) {
          case 'GREATER_THAN':
            return `Greater than or equals ${value.start}`;
          case 'LESS_THAN':
            return `Less than or equals ${value.end}`;
          case 'IN_BETWEEN':
            return `${value.start} to ${value.end} `;
          case 'CURRENT_DUE':
            return 'Current Due';
        }
      }
      return value.start !== undefined && value.end !== undefined && value.end !== null
        ? `${value.start} to ${value.end}`
        : value.start !== undefined
        ? `> ${value.start - 1}`
        : 'All';
    },
    disableAutoAdjust: true,

    additionalProps: {
      dayRangeOptions: dueDaysRangeFilterOptions,
    },
  };

  function handleClear() {
    onClear && onClear();
    onSubmit && onSubmit({ start: undefined, end: undefined, type: undefined });
  }

  function handleSubmit({ start, end, type }: DayRangeValueType) {
    onSubmit && onSubmit({ start, end, type });
  }

  return (
    <FilterDropdown
      value={value}
      filterConfig={filterConfigo}
      onSubmit={handleSubmit}
      onClear={handleClear}
      showClear={showClear}
    />
  );
}
