import { Col } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { ErrorBoundaryWrapper } from 'components/Common/ErrorBoundary';
import { ReactNode } from 'react';
import styled from 'styled-components';

interface TitleProps {
  title: string;
  tagColor?: string;
  extra?: ReactNode;
}
interface TileProps {
  children?: JSX.Element[] | JSX.Element;
}
const StyledTag = styled.div`
  width: var(--space-12);
  height: var(--space-12);
  border-radius: var(--br-2);
`;
export function TileColor(props: { color: string }) {
  return (
    <StyledTag
      style={{
        background: props.color,
      }}
    />
  );
}

export function Tile(props: TileProps) {
  return (
    <Col xs={24} sm={12} md={8}>
      <ErrorBoundaryWrapper>{props.children}</ErrorBoundaryWrapper>
    </Col>
  );
}

export function Title(props: TitleProps) {
  const LabelJSX = (
    <Flex align="center" gap="var(--space-4)">
      {props.tagColor ? <TileColor color={props.tagColor} /> : <></>}
      <GrowText size={'var(--fs-16)'}>{props.title}</GrowText>
      {props.extra}
    </Flex>
  );
  return <>{LabelJSX}</>;
}
