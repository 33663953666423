export const NUMBER_ONLY_REGEX: RegExp = /^-?\d* {0,1}\d+$/;
export const NUMBER_WITH_DECIMAL_REGEX: RegExp = /^[+-]?((\d+(\.\d*)?)|(\.\d+))$/;
export const URL_VALIDATION: RegExp = /^(?:(ftp|http|https):\/\/)?(?:[\w-]+\.)+[a-z]{3,6}$/;
export const MINIMUM_8CHARACTERS: RegExp = /^.{8,}$/;
export const UPPER_CASE: RegExp = /(?=.*[A-Z])/;
export const LOWER_CASE: RegExp = /(?=.*[a-z])/;
export const DIGIT: RegExp = /(?=.*\d)/;
export const SPECIAL_CHARACTERS: RegExp = /(?=.*[!@#$%^&()_+-])/;
export const PHONE_NUMBER_REGEX: RegExp = /^[0-9+\-(). ]*$/;

export function extractNameFromEmail(email: string) {
  // Regex to match the part before "@" and ignore anything after a "+" symbol
  const match = email.match(/^([a-zA-Z]+)(?:[.\-_]([a-zA-Z]+))?(?:\+.*)?@/);

  if (match) {
    // Capitalize the first letters of each name part
    const firstName = match[1].charAt(0).toUpperCase() + match[1].slice(1).toLowerCase();
    const lastName = match[2]
      ? match[2].charAt(0).toUpperCase() + match[2].slice(1).toLowerCase()
      : '';
    return {
      firstName,
      lastName,
    };
  }
  return null; // Return
}
