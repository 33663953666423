import {
  DisputeReport,
  disputeListConfigInterface,
} from 'components/CollectionActivites/Disputes/types';
import { omitBy } from 'lodash';
import {
  BulkActionDelete,
  DisputeBulkSelectComment,
  DisputeMultiSelectComment,
  UpdateDisputeActivityBulkActionParams,
  UpdateDisputeActivityParams,
} from 'types/activities/dispute';

import { ActivityPaths } from 'components/HigherOrderComponent/KeyActivitesContainer/key-acivities-config';
import ky from './ky';

const baseUrl = 'disputes/generate-report';
const disputePath: ActivityPaths = 'disputes';
export async function updateDisputeStatus(params: any, id: number): Promise<unknown> {
  return await ky.put(`${disputePath}/${id}/status`, { json: params });
}

export async function fetchAllDisputes({
  page,
  size,
  filters,
  isExport,
}: disputeListConfigInterface) {
  //cleanup-empty-array to prevent error in backend
  const data = { ...omitBy(filters, (item) => Array.isArray(item) && item.length === 0) };
  const searchParams = {
    ...(page && { page }),
    ...(size && { size }),
    ...(isExport && { export: true }),
  };

  const response = await ky.post(baseUrl, { json: data, searchParams });
  if (isExport) {
    return;
  }

  return response.json<DisputeReport>();
}

export async function deleteDisputes(ids: string[]): Promise<unknown> {
  return await ky.delete(`${disputePath}/multi-select?ids=${ids.toString()}`);
}

export async function updateDisputeActions(params: UpdateDisputeActivityParams) {
  return await ky.put(`${disputePath}/multi-select`, { json: params });
}

export async function updateDisputeBulkAction(params: UpdateDisputeActivityBulkActionParams) {
  return await ky.put(`${disputePath}/bulk-action`, { json: params });
}

export async function deleteDisputesBulkActions(params: BulkActionDelete): Promise<unknown> {
  return await ky.post(`${disputePath}/bulk-action/delete`, { json: params });
}

export async function updateCommentMultiSelect(
  params: DisputeMultiSelectComment
): Promise<unknown> {
  return ky.put(`${disputePath}/multi-select/comment`, { json: params });
}

export async function updateCommentBulkSelect(params: DisputeBulkSelectComment): Promise<unknown> {
  return ky.put(`${disputePath}/bulk-action/comment`, { json: params });
}
