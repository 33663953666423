import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GrowButton, HideWrapper } from '@sinecycle/growcomponents';
import { Divider } from 'antd';
import CallLogButton from 'components/Common/ActivitiesForm/CallLog/CallLogButton';
import DisputeButton from 'components/Common/ActivitiesForm/Dispute/DisputeButton';
import DocumentButton from 'components/Common/ActivitiesForm/Document/DocumentButton';
import EscalationButton from 'components/Common/ActivitiesForm/Escalation/EscalationButton';
import NoteButton from 'components/Common/ActivitiesForm/Note/NoteButton';
import PTPButton from 'components/Common/ActivitiesForm/PromiseToPay/PTPButton';
import TaskButton from 'components/Common/ActivitiesForm/Task/TaskButton';
import WriteOffButton from 'components/Common/ActivitiesForm/WriteOff/WriteOffButton';
import {
  CallLogFormText,
  DisputeFormText,
  DocumentFormText,
  EscalationFormText,
  NoteFormText,
  P2PFormText,
  TaskFormText,
  WriteOffFormText,
} from 'components/Common/ActivitiesForm/text';
import { ActivityButtons as ActivityButtonsConfig } from 'components/Common/ActivitiesForm/types';
import {
  CallLogFormActivityProps,
  CallLogResponseSchema,
  DisputeFormActivityProps,
  DisputeResponseSchema,
  DocumentFormActivityProps,
  DocumentResponseSchema,
  EscalationFormActivityProps,
  EscalationResponseSchema,
  NoteFormActivityProps,
  NoteResponseSchema,
  PTPFormActivityProps,
  PTPResponseSchema,
  TaskFormActivityProps,
  TaskResponseSchema,
} from 'components/Common/hooks/type';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getAllDisputeTypes } from 'services/dispute-type';
import { getDocumentTypesList } from 'services/documents';
import { getDisputeType } from 'services/invoice';
import { dateFormatSelector } from 'store/authentication/authentication';
import { ActivityType } from 'types/activities/activity-types';
import { InvoiceBasicDetails } from 'types/entities/invoice';
import MoreActions from '../MoreActions';
import {
  ActivitySchema,
  invoiceTransformSchema,
  ptpInvoiceTransformSchema,
} from './ActivitySchema';
import { ActionableEntity } from './Email';
import { getActivityConfig } from './predicate';
import {
  ActivityButtonsDisplayKeys,
  ActivityButtonsProps,
  MutationFunctionResponseType,
} from './type';
import { invoiceItemsFn, lineItemsFn } from './utils';

export default function ActivityButtons(props: ActivityButtonsProps) {
  const {
    invoice,
    customerId,
    customerCurrency,
    entityLevelData,
    lineItems,
    displayKeys,
    extra,
    onMutateFn,
    customTransformer,
    bulkAction,
    disableAction,
    bulkRecordsLength,
    customerNames,
    viewChild,
  } = props;

  const lineItemQueries = lineItemsFn(customerId);
  const invoiceItemQueries = invoiceItemsFn<InvoiceBasicDetails>(customerId, viewChild);
  const invoiceQueryWithoutViewChild = invoiceItemsFn<InvoiceBasicDetails>(customerId);
  const dateFormat = useSelector(dateFormatSelector);

  const [selectedKey, setSelectedKey] = useState<ActivityButtonsDisplayKeys['key']>();
  const buttonsMap = {
    [ActivityType.CALL_LOG]: (
      buttonConfig?: ActivityButtonsConfig,
      key?: ActivityButtonsDisplayKeys['key'],
      listmode?: boolean
    ) => (
      <CallLogButton<CallLogFormActivityProps<InvoiceBasicDetails>>
        invoices={invoice}
        open={key === 'CALL_LOG'}
        text={CallLogFormText}
        responseSchema={ActivitySchema.CALL_LOG}
        onMutateFn={onMutateFn as MutationFunctionResponseType<CallLogResponseSchema>}
        invoiceTransformSchema={invoiceTransformSchema}
        taskRemainderResponseSchema={ActivitySchema.TASK_REMAINDER}
        buttonConfig={buttonConfig}
        customTransformer={customTransformer}
        disabled={disableAction}
        bulkAction={bulkAction}
        bulkRecordsLength={bulkRecordsLength}
        dateFormat={dateFormat}
        handleClose={() => {
          setSelectedKey(undefined);
        }}
        mode={listmode ? 'LIST' : 'BUTTON'}
        {...invoiceItemQueries}
      />
    ),
    [ActivityType.PROMISE_TO_PAY]: (
      buttonConfig?: ActivityButtonsConfig,
      key?: ActivityButtonsDisplayKeys['key'],
      listmode?: boolean
    ) => (
      <PTPButton<PTPFormActivityProps<InvoiceBasicDetails>>
        invoices={invoice}
        open={key === 'PROMISE_TO_PAY'}
        text={P2PFormText}
        responseSchema={ActivitySchema.PROMISE_TO_PAY}
        customerCurrency={customerCurrency!}
        onMutateFn={onMutateFn as MutationFunctionResponseType<PTPResponseSchema>}
        invoiceTransformSchema={ptpInvoiceTransformSchema}
        taskRemainderResponseSchema={ActivitySchema.TASK_REMAINDER}
        buttonConfig={buttonConfig}
        customTransformer={customTransformer}
        disabled={disableAction}
        bulkAction={bulkAction}
        bulkRecordsLength={bulkRecordsLength}
        dateFormat={dateFormat}
        handleClose={() => {
          setSelectedKey(undefined);
        }}
        mode={listmode ? 'LIST' : 'BUTTON'}
        {...invoiceItemQueries}
      />
    ),
    [ActivityType.NOTE]: (
      buttonConfig?: ActivityButtonsConfig,
      key?: ActivityButtonsDisplayKeys['key'],
      listmode?: boolean
    ) => (
      <NoteButton<NoteFormActivityProps<InvoiceBasicDetails>>
        invoices={invoice}
        open={key === 'NOTE'}
        text={NoteFormText}
        responseSchema={ActivitySchema.NOTE}
        invoiceTransformSchema={invoiceTransformSchema}
        taskRemainderResponseSchema={ActivitySchema.TASK_REMAINDER}
        invoiceLineItems={lineItems}
        onMutateFn={onMutateFn as MutationFunctionResponseType<NoteResponseSchema>}
        customTransformer={customTransformer}
        showInvoicesList={Boolean(invoice)}
        buttonConfig={buttonConfig}
        disabled={disableAction}
        bulkAction={bulkAction}
        bulkRecordsLength={bulkRecordsLength}
        customerNames={customerNames}
        dateFormat={dateFormat}
        mode={listmode ? 'LIST' : 'BUTTON'}
        handleClose={() => {
          setSelectedKey(undefined);
        }}
        {...lineItemQueries}
        {...invoiceItemQueries}
      />
    ),
    [ActivityType.DISPUTE]: (
      buttonConfig?: ActivityButtonsConfig,
      key?: ActivityButtonsDisplayKeys['key'],
      listmode?: boolean
    ) => (
      <DisputeButton<DisputeFormActivityProps<InvoiceBasicDetails>>
        invoices={invoice}
        open={key === 'DISPUTE'}
        disputeTypesearchQueryFn={(searchTerm: string) => getDisputeType(searchTerm)}
        disputeTypeQueryKey={['get-all-dispute-types', customerId]}
        disputeTypeQueryFn={() => getAllDisputeTypes()}
        text={DisputeFormText}
        invoiceLineItems={lineItems}
        responseSchema={ActivitySchema.DISPUTE}
        taskRemainderResponseSchema={ActivitySchema.TASK_REMAINDER}
        invoiceTransformSchema={invoiceTransformSchema}
        onMutateFn={onMutateFn as MutationFunctionResponseType<DisputeResponseSchema>}
        customTransformer={customTransformer}
        buttonConfig={buttonConfig}
        disabled={disableAction}
        bulkAction={bulkAction}
        bulkRecordsLength={bulkRecordsLength}
        dateFormat={dateFormat}
        handleClose={() => {
          setSelectedKey(undefined);
        }}
        mode={listmode ? 'LIST' : 'BUTTON'}
        {...lineItemQueries}
        {...invoiceItemQueries}
      />
    ),
    [ActivityType.DOCUMENT]: (
      buttonConfig?: ActivityButtonsConfig,
      key?: ActivityButtonsDisplayKeys['key'],
      listmode?: boolean
    ) => (
      <DocumentButton<DocumentFormActivityProps<InvoiceBasicDetails>>
        invoices={invoice}
        documentQueryFn={(entityLevel: ActionableEntity) =>
          getDocumentTypesList({ page: 1, size: 200, filters: { entity_types: [entityLevel] } })
        }
        open={key === 'DOCUMENT'}
        documentQueryKey={['document-types', entityLevelData, customerId]}
        text={DocumentFormText}
        onMutateFn={onMutateFn as MutationFunctionResponseType<DocumentResponseSchema>}
        responseSchema={ActivitySchema.DOCUMENT}
        invoiceTransformSchema={invoiceTransformSchema}
        entityLevel={entityLevelData!}
        buttonConfig={buttonConfig}
        disabled={disableAction}
        bulkAction={bulkAction}
        customTransformer={customTransformer}
        handleClose={() => {
          setSelectedKey(undefined);
        }}
        mode={listmode ? 'LIST' : 'BUTTON'}
        {...invoiceItemQueries}
      />
    ),
    [ActivityType.ESCALATION]: (
      buttonConfig?: ActivityButtonsConfig,
      key?: ActivityButtonsDisplayKeys['key'],
      listmode?: boolean
    ) => (
      <EscalationButton<EscalationFormActivityProps<InvoiceBasicDetails>>
        invoices={invoice}
        open={key === 'ESCALATION'}
        text={EscalationFormText}
        taskRemainderResponseSchema={ActivitySchema.TASK_REMAINDER}
        responseSchema={ActivitySchema.ESCALATION}
        invoiceTransformSchema={invoiceTransformSchema}
        invoiceLineItems={lineItems}
        onMutateFn={onMutateFn as MutationFunctionResponseType<EscalationResponseSchema>}
        customTransformer={customTransformer}
        buttonConfig={buttonConfig}
        disabled={disableAction}
        bulkAction={bulkAction}
        bulkRecordsLength={bulkRecordsLength}
        dateFormat={dateFormat}
        mode={listmode ? 'LIST' : 'BUTTON'}
        handleClose={() => {
          setSelectedKey(undefined);
        }}
        {...lineItemQueries}
        {...invoiceItemQueries}
      />
    ),
    [ActivityType.TASK]: (
      buttonConfig?: ActivityButtonsConfig,
      key?: ActivityButtonsDisplayKeys['key'],
      listmode?: boolean
    ) => (
      <TaskButton<TaskFormActivityProps<InvoiceBasicDetails>>
        invoices={invoice}
        text={TaskFormText}
        open={key === 'TASK'}
        onMutateFn={onMutateFn as MutationFunctionResponseType<TaskResponseSchema>}
        responseSchema={ActivitySchema.TASK}
        invoiceTransformSchema={invoiceTransformSchema}
        customTransformer={customTransformer}
        buttonConfig={buttonConfig}
        disabled={disableAction}
        bulkAction={bulkAction}
        bulkRecordsLength={bulkRecordsLength}
        dateFormat={dateFormat}
        handleClose={() => {
          setSelectedKey(undefined);
        }}
        mode={listmode ? 'LIST' : 'BUTTON'}
        {...invoiceItemQueries}
      />
    ),
    [ActivityType.WRITE_OFF]: (
      buttonConfig?: ActivityButtonsConfig,
      key?: ActivityButtonsDisplayKeys['key'],
      listmode?: boolean
    ) => (
      <WriteOffButton
        invoices={invoice}
        text={WriteOffFormText}
        open={key === 'WRITE_OFF'}
        onMutateFn={onMutateFn as MutationFunctionResponseType<undefined>}
        responseSchema={{}}
        invoiceTransformSchema={invoiceTransformSchema}
        customTransformer={customTransformer}
        buttonConfig={buttonConfig}
        disabled={disableAction}
        bulkAction={bulkAction}
        bulkRecordsLength={bulkRecordsLength}
        dateFormat={dateFormat}
        customerCurrency={customerCurrency!}
        handleClose={() => {
          setSelectedKey(undefined);
        }}
        mode={listmode ? 'LIST' : 'BUTTON'}
        {...invoiceItemQueries}
      />
    ),
  };

  function mapMoreButtonPredicate(item: ActivityButtonsDisplayKeys) {
    const config = getActivityConfig(item.key, disableAction, item.buttonConfig);
    return {
      key: item.key,
      label: config.label,
      icon: config.icon,
    };
  }
  function filterMoreButtonPredicate(item: ActivityButtonsDisplayKeys) {
    return item.type === 'list' && !item.hide;
  }
  const activityButtons = displayKeys
    .filter((item) => item.type === 'button' && !item.hide)
    .map((item) => buttonsMap[item.key](item?.buttonConfig));

  const moreActionsButtons = displayKeys
    .filter(filterMoreButtonPredicate)
    .map(mapMoreButtonPredicate);

  const moreActionsButtonsConfig = displayKeys.filter(filterMoreButtonPredicate);

  const MoreActionsButton = (
    <MoreActions
      direction="down"
      style={{ padding: 0 }}
      options={moreActionsButtons}
      removePadding
      onClick={(value) => {
        setSelectedKey(value as ActivityButtonsDisplayKeys['key']);
      }}
    >
      <GrowButton
        icon={
          <FontAwesomeIcon
            icon={['far', 'plus']}
            color={disableAction ? undefined : 'var(--purple-7)'}
          />
        }
        disabled={disableAction}
      />
    </MoreActions>
  );

  return (
    <>
      {activityButtons}
      {moreActionsButtons.length ? MoreActionsButton : null}
      <>
        {selectedKey &&
          moreActionsButtonsConfig
            .filter((buttonConfig) => buttonConfig.key === selectedKey)
            .map((buttonConfig) =>
              buttonsMap[selectedKey](buttonConfig?.buttonConfig, selectedKey, true)
            )}
      </>
      <HideWrapper hide={!extra}>
        <>
          <Divider
            type="vertical"
            style={{ margin: 0, height: '32px', borderColor: 'var(--gray-5)' }}
          />
          {extra}
        </>
      </HideWrapper>
    </>
  );
}
