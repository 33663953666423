import { Flex } from 'components/BaseComponents/Layout/Flex';
import GrowPopover from 'components/BaseComponents/PopOver';
import { GrowText } from 'components/BaseComponents/Typography';
import { ReactNode } from 'react';
import { Subsidiary } from 'types/entities/subsidiary';
import HideWrapper from '../Util/HideWrapper';

interface SubsidiaryListProps {
  subsidiary?: Subsidiary[];
  emptyPlaceholder?: ReactNode;
}

const t = {
  subsidiary: 'Subsidiaries',
};

export function SubsidiaryList(props: SubsidiaryListProps) {
  const { emptyPlaceholder, subsidiary } = props;

  if (!subsidiary?.length) return <>{emptyPlaceholder}</> ?? <></>;

  const [firstItem, ...otherSubsidiaries] = subsidiary;

  const List = (
    <Flex direction="column">
      <GrowText
        color="var(--gray-8)"
        size="var(--fs-12)"
        style={{ marginBottom: 'var(--space-4)' }}
      >
        {t.subsidiary}
      </GrowText>
      <Flex direction="column" gap="var(--space-8)">
        {subsidiary.map((subItem) => {
          return <GrowText key={subItem.id}>{subItem.display_name}</GrowText>;
        })}
      </Flex>
    </Flex>
  );

  const extra = (
    <GrowPopover content={List} overlayInnerStyle={{ maxWidth: '170px' }} arrow={false}>
      <GrowText
        size="var(--fs-12)"
        color="var(--gray-7)"
      >{`+ ${otherSubsidiaries.length}`}</GrowText>
    </GrowPopover>
  );

  return (
    <Flex style={{ width: '100%', height: '100%' }} align="center" gap="var(--space-4)">
      <GrowText ellipsis={{ tooltip: firstItem.display_name }}>{firstItem.display_name}</GrowText>
      <HideWrapper hide={!otherSubsidiaries.length}>{extra}</HideWrapper>
    </Flex>
  );
}
