import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { usePrivilegeStatus } from 'components/HigherOrderComponent/Privileged';
import { ERolesAndPrivileges } from 'components/HigherOrderComponent/Privileged/privileges';
import { without } from 'lodash';
import { pagePath, pagePrivileges } from 'router/constants/page-info';
import { PageListItems } from './usePageList';

function useSettingsRoute() {
  const SettingsPrivilegeExceptDefaultPrivilege = without(
    pagePrivileges('SETTINGS'),
    ERolesAndPrivileges.DEFAULT_PRIVILEGE
  );
  const hasSettings = usePrivilegeStatus(pagePrivileges('SETTINGS'), {
    shallowCheck: true,
  });
  const hasSettingsWithout = usePrivilegeStatus(SettingsPrivilegeExceptDefaultPrivilege, {
    shallowCheck: true,
  });
  const settingsPageList: Array<PageListItems> = [
    {
      key: 'SETTINGS',
      subLinks: 'primitive',
      label: 'Settings',
      access: hasSettings && hasSettingsWithout,
      icon: <FontAwesomeIcon icon={['far', 'gear']} color="var(--gray-1)" fontSize={20} />,
      url: pagePath('SETTINGS'),
    },
  ];
  return { settingsPageList };
}

export default useSettingsRoute;
