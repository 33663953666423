import { Popconfirm, Skeleton } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { PopConfirmContent } from 'components/BaseComponents/ButtonWithConfirmation/PopconfirmContent';
import SearchableSelect from 'components/Common/SearchableSelect/SearchableSelect';
import useCustomerList from 'hooks/useCustomerList';
import { uniqBy } from 'lodash';
import { FlattenOptionData } from 'rc-select/lib/interface';
import { useEffect, useState } from 'react';
import { Unpacked } from 'types/utils/utils';
interface CustomerSelectProps {
  onChange?: (value: string[]) => void;
  onReset?: () => void;
}

const t = {
  warning_title: 'Change Customer',
  warning_desc: 'Changing customers will change contacts list. Do you want to continue?',
  default_value: 'Single Customer',
};
function CustomerSelect(props: CustomerSelectProps) {
  type optionType = Unpacked<typeof customersList>;
  const [open, setOpen] = useState<boolean>(false);
  const [popupOpen, setPopUpOpen] = useState<boolean>(false);
  const { customersList, customerSearch, optionPredicate } = useCustomerList({});
  const [searchList, setSearchList] = useState(customersList);
  const [selectedValue, setSelectedValue] = useState<optionType>();
  const [search, setSearch] = useState<string>();

  const ConfirmHeader = (
    <PopConfirmContent
      title={t.warning_title}
      desc={t.warning_desc}
      titleStyles={{ color: 'var(--gold-6)', fontWeight: 'var(--fs-semibold)' }}
      descStyles={{ color: 'var(--gray-9)' }}
    />
  );
  useEffect(() => {
    setSearchList(customersList);
  }, [customersList]);

  const optionRender = (option: FlattenOptionData<DefaultOptionType>) => {
    const isDisabled = option.value === selectedValue?.value;
    return (
      <Popconfirm
        icon={false}
        overlayStyle={{ width: '300px' }}
        overlayClassName="ant-popover-title-no-padding"
        title={ConfirmHeader}
        disabled={!selectedValue || isDisabled}
        placement="left"
        onConfirm={() => {
          setPopUpOpen(false);
          setOpen(false);
          setSearch(undefined);
          setSelectedValue(option.data as optionType);
          props.onChange?.([option.data.value as string]);
        }}
        key={option.data.id}
        onCancel={() => {
          setOpen(false);
          setPopUpOpen(false);
          setSearch(undefined);
        }}
        onOpenChange={(open) => {
          setPopUpOpen(open);
        }}
      >
        <div
          style={{ width: '100%' }}
          onClick={(e) => {
            e.stopPropagation();
            if (selectedValue) {
              return;
            }
            setSelectedValue(option.data as optionType);
            props.onChange?.([option.data.value as string]);
            setOpen(false);
          }}
        >
          {option.data.label}
        </div>
      </Popconfirm>
    );
  };

  return (
    <SearchableSelect
      virtual
      open={selectedValue?.value ? open : undefined}
      customSearch
      notFoundContent={customerSearch.isLoading ? <Skeleton.Input /> : undefined}
      options={searchList}
      value={selectedValue?.value}
      placeHolder="Select Customer"
      style={{ width: '100%' }}
      optionRender={optionRender}
      onSearch={(value) => {
        setSearch(value);
        setOpen(true);
        if (value.trim().length > 4) {
          customerSearch.mutateAsync(value).then((list) => {
            const modified = list.map(optionPredicate);
            setSearchList(modified);
          });
        }
      }}
      onVisibleChange={(open) => {
        if (search || popupOpen) {
          return;
        }
        const originalList = selectedValue ? [selectedValue, ...customersList] : customersList;
        setSearchList(uniqBy(originalList, 'value'));
        setOpen(open);
      }}
    />
  );
}

export { CustomerSelect };
