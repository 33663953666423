import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CustomFieldIconWithText, GrowTypography } from '@sinecycle/growcomponents';
import { Empty, Popover } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { Texto } from 'components/BaseComponents/Typography/Texto';
import { useHandleOutsideClickRef } from 'hooks/use-outside-click';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { FilterDropdownContent } from '../FilterDropdownContent';
import { SwitcherFilter } from '../Filters/Switcher';
import { ToggleFilter } from '../Filters/Toggle';
import { ClearIcon, PopoverButton } from '../style';
import { FilterConfig, FilterType, ValidFilterTypes } from '../types';
import {
  getSelectedValues,
  notifyFilterApply,
  notifyFilterCancel,
  notifyFilterFocus,
  notifyFilterRemove,
} from '../utils';

interface FilterDropdownAdditionalProps {
  isCustomFilter?: boolean;
  overrideClassName?: string;
}

const PopoverStyled = styled(Popover)`
  .ant-popover-inner-content {
    padding: var(--space-16);
  }
`;

interface FilterDropdownProps<T extends unknown> {
  filterConfig: FilterConfig;
  value?: T;
  onSubmit?: (value: T) => void;
  onChange?: (value: T) => void;
  onClear?: () => void;
  onCancel?: () => void;
  showClear?: boolean;
  emptyText?: string;
  onDropdownVisibilityChange?: (visible: boolean) => void;
  additionalProps?: FilterDropdownAdditionalProps;
  search?: {
    enableSearch?: boolean;
    searchComponent?: React.ReactNode;
    skeletonLoader?: React.ReactNode;
    loading: boolean;
    emptyText?: string;
  };
  trigger?: React.ComponentProps<typeof Popover>['trigger'];
}

function FilterDropdown<T extends unknown>(props: FilterDropdownProps<T>) {
  const [visible, setVisible] = useState(false);
  const handleOutsideClick = useCallback(() => setVisible(false), []);
  const ref = useHandleOutsideClickRef<HTMLDivElement>(handleOutsideClick);
  const { additionalProps = {} } = props;
  const isCustomFieldFilter = props.filterConfig.name?.includes('custom-field');
  const PopOverTitle = (
    <CustomFieldIconWithText hasSymbol={isCustomFieldFilter}>
      {props.filterConfig.label}
    </CustomFieldIconWithText>
  );
  const PopOverTrigger = props.trigger ?? [];
  const showOnClear = (() => {
    if (!props.showClear) return false;
    if (!props.filterConfig.type) return false;

    return true;
  })();

  const Clear = showOnClear && (
    <ClearIcon
      className="show-on-hover"
      size="1x"
      icon={['fal', 'times-circle']}
      onClick={(e) => {
        handleDropdownFilterClear(e);
        setVisible(false);
      }}
    />
  );

  function defaultValueToString() {
    return getSelectedValues({
      options: props.filterConfig.options,
      value: props.value,
      emptyText: props.emptyText,
    });
  }

  const SelectedValues = (
    <Flex align="baseline" gap="var(--space-8)">
      <div>{PopOverTitle}:</div>

      <Texto
        color="--primary-7"
        weight="semibold"
        showToolTip
        style={{ textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '300px' }}
      >
        {props.filterConfig.valueToString
          ? props.filterConfig.valueToString(props.value, defaultValueToString)
          : defaultValueToString()}
      </Texto>
    </Flex>
  );

  function handleSubmit(value: T) {
    props.onSubmit && props.onSubmit(value as T);
    notifyFilterApply(props.filterConfig.label, additionalProps.isCustomFilter);
  }

  function handleCancel() {
    props.onCancel && props.onCancel();
    notifyFilterCancel(props.filterConfig.label, additionalProps.isCustomFilter);
  }

  function handleClear() {
    props.onClear && props.onClear();
    notifyFilterRemove(props.filterConfig.label, additionalProps.isCustomFilter);
  }

  if (props.filterConfig.type === FilterType.TOGGLE) {
    return (
      <PopoverButton style={{ marginLeft: 0 }}>
        {Clear}
        <ToggleFilter
          showPrimitive
          label={props.filterConfig.label}
          onChange={(value) => handleSubmit(value as T)}
        />
      </PopoverButton>
    );
  }

  if (props.filterConfig.type === FilterType.SWITCHER) {
    return (
      <SwitcherFilter
        value={props.value as string | number | undefined}
        label={props.filterConfig.label}
        name={props.filterConfig.name}
        options={props.filterConfig.options}
        additionalProps={props.filterConfig.additionalProps}
        onChange={(value) => handleSubmit(value as T)}
      />
    );
  }

  if (props.filterConfig.type === FilterType.TEXT) {
    return (
      <PopoverButton style={{ marginLeft: 0 }}>
        {Clear}
        <GrowTypography.Text strong style={{ color: 'var(--primary-7)' }}>
          {props.filterConfig.label}
        </GrowTypography.Text>
      </PopoverButton>
    );
  }

  function handleDropdownFilterCancel() {
    setVisible(false);
    // onCancel -> calls parent onCancel (if onCancel cb is passed)
    handleCancel();
  }

  function handleDropdownFilterSubmit(value: T) {
    setVisible(false);
    // onSubmit -> sends the selected values to parent ()
    handleSubmit(value);
  }

  function handleDropdownFilterClear(e: React.MouseEvent<SVGSVGElement, MouseEvent>) {
    e.stopPropagation();
    // onClear -> calls parent `onChange` with all values cleared (then parent clears value and this component re-renders)
    // props.onSubmit && props.onSubmit(undefined)
    handleClear();
  }

  function toggleVisible() {
    setVisible((visible) => !visible);
    notifyFilterFocus(props.filterConfig.label, !visible);
  }

  const SearchComponent = props.search?.searchComponent;

  const EmptyComponent = (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={props.search?.emptyText} />
  );

  const DefaultPopoverContent = (
    <FilterDropdownContent
      filterConfig={props.filterConfig}
      value={props.value}
      onSubmit={handleDropdownFilterSubmit}
      onCancel={handleDropdownFilterCancel} // onChange -> propagates `onChange` event directly to parent (if onChange cb is passed)
      onChange={(value) => {
        if (props.filterConfig.type === FilterType.SINGLE_SELECT) {
          props.onChange?.(value);
          setVisible(false);
        } else {
          props.onChange?.(value);
        }
      }}
    />
  );

  const SkeletonLoader = props.search?.skeletonLoader;
  const CustomTitle = props?.filterConfig?.customTitle;

  const PopOverContent = props.filterConfig.options?.length
    ? DefaultPopoverContent
    : EmptyComponent;
  const PopOverContentWithSearch = (
    <Flex align="flex-start" gap="var(--space-12)" direction="column">
      {SearchComponent}
      {props.search?.loading ? (
        SkeletonLoader
      ) : (
        <div style={{ width: '100%', overflow: 'auto' }}>{PopOverContent}</div>
      )}
    </Flex>
  );
  const Caret = <FontAwesomeIcon icon={['far', 'chevron-down']} color="var(--primary-7)" />;
  const Content = (
    <>
      {!!CustomTitle && CustomTitle}
      {props.search?.enableSearch
        ? PopOverContentWithSearch
        : ValidFilterTypes.includes(props.filterConfig.type)
        ? PopOverContent
        : DefaultPopoverContent}
    </>
  );

  return props.filterConfig.contentOnly ? (
    Content
  ) : (
    <div ref={ref}>
      <PopoverStyled
        trigger={PopOverTrigger}
        open={visible}
        placement="bottomLeft"
        autoAdjustOverflow={props.filterConfig.disableAutoAdjust ? false : true}
        title={props.filterConfig?.customTitle ? null : props.filterConfig.label}
        content={Content}
        getPopupContainer={() => ref.current as HTMLDivElement}
        onOpenChange={props.onDropdownVisibilityChange}
        destroyTooltipOnHide
        arrow={false}
      >
        {/* Displays selected values with option to clear */}
        <PopoverButton onClick={toggleVisible} style={{ marginLeft: 0 }}>
          {Clear}
          <Flex align="center" justify="space-between" gap="--space-4" style={{ width: '100%' }}>
            {SelectedValues}
            {Caret}
          </Flex>
        </PopoverButton>
      </PopoverStyled>
    </div>
  );
}

export { FilterDropdown };
