import { GrowTypography } from '@sinecycle/growcomponents';
import { useQueryClient } from '@tanstack/react-query';
import { Tooltip } from 'antd';
import { InfoIcon } from 'components/Common/Icons';
import { useConfig } from 'components/HigherOrderComponent/Config/config';
import { useCurrentUser } from 'hooks/use-current-user';
import { useGetQueryData } from 'hooks/useGetQueryData';
import { filter, find, map } from 'lodash';
import { useGetAREmailList } from 'queries/Settings/ar-email';
import { useCallback, useState } from 'react';
import { AccountConfigKey } from 'types/entities/account';
import { Subsidiary } from 'types/entities/subsidiary';
import { AREmailDTO } from './AREmailList';

interface useAREmailDataProps {
  onSuccess?: (data: AREmailDTO[]) => void;
}
function useAREmailData(props?: useAREmailDataProps) {
  const [selectedValue, setSelected] = useState<AREmailDTO | undefined>();
  const { data: arEmailListData, isLoading, isFetched } = useGetAREmailList();
  const subsidiaries = useGetQueryData<Subsidiary[]>(['subsidiaries']);
  const isSubsidiaryEnabled = useConfig(AccountConfigKey.SUBSIDIARY_ENABLED);
  function getLinkedSubsidiaries(ids?: number[]) {
    const linkedSubsidiaries = subsidiaries?.filter((value) => {
      return ids?.includes(value.id);
    });
    const text =
      linkedSubsidiaries && linkedSubsidiaries?.length > 1
        ? 'Linked to subsidiaries'
        : 'Linked to subsidiary';
    const Content = (
      <div className="tw-flex tw-flex-col tw-gap-2">
        <GrowTypography.Text className="tw-text-gray-6">{text}</GrowTypography.Text>
        <GrowTypography.Text className="tw-text-gray-1">
          {linkedSubsidiaries?.map((f) => f.display_name).join(', ')}
        </GrowTypography.Text>
      </div>
    );

    if (!linkedSubsidiaries) return;

    return <Tooltip title={Content}>{linkedSubsidiaries.length ? <InfoIcon /> : null}</Tooltip>;
  }

  function getLabel(email?: string, ids?: number[]) {
    const linkedSubsidiaries = getLinkedSubsidiaries(ids);

    return (
      <div className="tw-flex tw-items-center tw-gap-8 tw-w-full">
        <GrowTypography.Text ellipsis>{email}</GrowTypography.Text>
        {isSubsidiaryEnabled ? (
          <div className="linked-subsidiaries">{linkedSubsidiaries}</div>
        ) : null}
      </div>
    );
  }
  const currentLoggedInUser = useCurrentUser();
  const filteredList = filter(arEmailListData, (list) => {
    return list.status === 'VERIFIED' && Boolean(list.enabled);
  });
  const validEmails = filter(filteredList, (list) => {
    return Boolean(list.associated_user_ids?.includes(currentLoggedInUser.id));
  });

  function handleSelectedValue(data?: AREmailDTO) {
    setSelected(data);
  }
  return {
    filteredList,
    arEmailListData,
    validEmails,
    getLinkedSubsidiaries,
    getLabel,
    isLoading,
    isFetched,
    selectedValue,
    handleSelectedValue,
  };
}

function useUpdateAREmailData() {
  const arEmailListData = useGetQueryData<AREmailDTO[]>(['ar-email-list']);
  const queryClient = useQueryClient();
  function updateAREmailData(data?: AREmailDTO) {
    if (!data) return;
    const existingItem = find(arEmailListData, { id: data.id });

    if (existingItem) {
      const updatedData = map(arEmailListData, (list) => {
        return list.id === data.id ? { ...list, ...data } : { ...list };
      });

      queryClient.setQueriesData<AREmailDTO[]>(['ar-email-list'], (old) => {
        if (!old) return;
        return [...updatedData];
      });
    } else {
      queryClient.setQueriesData<AREmailDTO[]>(['ar-email-list'], (old) => {
        return [data, ...(old ?? [])];
      });
    }
  }
  const handleUpdateData = useCallback(updateAREmailData, [arEmailListData, queryClient]);

  return { handleUpdateData };
}

export { useAREmailData, useUpdateAREmailData };
