import DescriptionTextArea from 'components/BaseComponents/DescriptionTextArea/TextArea';
import { FocusEventHandler } from 'react';

interface IMentionsComponent {
  value?: string;
  onChange?: (comment: string) => void;
  placeholder?: string;
  onBlur?: FocusEventHandler<any>;
  autoSize?:
    | boolean
    | {
        minRows: number;
        maxRows: number;
      };
}

const TextArea = (props: IMentionsComponent) => {

  const handleChange = (e: any) => {
    if (props.onChange) {
      props.onChange(e.target.value);
    }
  };

  return (
    <DescriptionTextArea
      onBlur={props.onBlur}
      style={{ width: '100%' }}
      placeholder={props.placeholder ?? 'Start typing...'}
      defaultValue={props.value}
      onChange={handleChange}
    />
  );
};

export default TextArea;
