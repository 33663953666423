import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { Title } from 'pages/Dashboard/AdminDashboard/SnapShot/CollectionBalanceAndOutstanding/TileTitle';
import React from 'react';
import { cei_tile } from 'services/dashboard-datasets';
import { DangerText, SuccessText } from '../DSOTile/style';
import { StyledTileCard } from '../styles';
import { dashboardTexts } from '../texts';
import { SecondaryText, TooltipParagraph } from './style';

const QuestionCircleIcon = <FontAwesomeIcon icon={['far', 'question-circle']} />;
const Content = (
  <>
    <SecondaryText>{dashboardTexts.ceiTile.helpTitle}</SecondaryText>
    <TooltipParagraph>{dashboardTexts.ceiTile.helpText}</TooltipParagraph>
  </>
);
function CEITile() {
  const Extra = (
    <Popover title="CEI" content={Content}>
      {QuestionCircleIcon}
    </Popover>
  );
  const CeiValue = (
    <GrowText size="var(--fs-20)" strong>
      {cei_tile.cei}%
    </GrowText>
  );
  const Header = (
    <Flex direction="column" gap="var(--space-4)">
      <Title title={dashboardTexts.ceiTile.title} extra={Extra} />
      {CeiValue}
    </Flex>
  );
  const Danger = (
    <DangerText>
      <FontAwesomeIcon icon={['far', 'arrow-down']} color="var(--red-5)" />
      {dashboardTexts.ceiTile.daysCount(cei_tile.cei_change)}
    </DangerText>
  );
  const Success = (
    <SuccessText>
      <FontAwesomeIcon icon={['far', 'arrow-up']} color="var(--green-7)" />
      {dashboardTexts.ceiTile.daysCount(cei_tile.cei_change)}
    </SuccessText>
  );
  const Footer = (
    <Flex gap="var(--space-8)">
      {cei_tile.cei_change >= 0 ? Danger : Success}
      <GrowText>{dashboardTexts.ceiTile.sinceLast(cei_tile.cei_since)}</GrowText>
    </Flex>
  );
  return (
    <StyledTileCard>
      <Flex direction="column" gap="var(--space-12)" style={{ height: '100%' }}>
        {Header}
        {Footer}
      </Flex>
    </StyledTileCard>
  );
}

export default React.memo(CEITile);
