import { head } from 'lodash';
import { IEmailActivity } from 'store/activity-feed/type';
import { EmailConversation, EmailConversationParticipantType } from 'types/activities/email';
import { DeepKeysPrefix } from 'types/utils/utils';
import transformData from 'util/transformData';
import { CustomerDataInfo, EmailConversationData } from '../types';
import { extractParticipants } from './extractParticipants';

const CoversationSchema: Record<string, DeepKeysPrefix<EmailConversation>> = {
  id: 'id',
  reply_recieved: 'reply_received',
  customer: 'customer',
  attachments: 'attachments',
  body: 'body',
  incoming: 'incoming',
  invoices: 'invoices',
  sent_at: 'sent_at',
  subject: 'subject',
  created_by: 'created_by',
  from: 'from',
  to: 'to',
  cc: 'cc',
  bcc: 'bcc',
  thread_id: 'id',
  label: 'label',
};

/**
 * @param email: IEmailActivity
 * @param customer: CustomerDataInfo
 * this function takes the data and customers
 * which is of type IEmailActivity and CustomerDataInfo and
 * transforms the data based on the schema
 */
export function processEmail(
  email: IEmailActivity,
  customer?: CustomerDataInfo | undefined
): EmailConversationData {
  function customConversationTransformer(data: IEmailActivity) {
    return {
      customer: customer,
      from: head(
        extractParticipants(data.conversation_participants, EmailConversationParticipantType.FROM)
      ),
      to: extractParticipants(data.conversation_participants, EmailConversationParticipantType.TO),
      cc: extractParticipants(data.conversation_participants, EmailConversationParticipantType.CC),
      bcc: extractParticipants(
        data.conversation_participants,
        EmailConversationParticipantType.BCC
      ),
    };
  }

  return transformData<IEmailActivity, EmailConversationData>({
    originalData: email,
    schemaMapping: CoversationSchema,
    customTransformers: customConversationTransformer,
  });
}
