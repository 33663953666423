import { InboxAssignedTypeList } from 'components/Inbox/CollectionActivitiesV2/FilterStrip/predicate';
import {
  getEntityTypesTransformer,
  useGetInboxSubscribedInvoiceStatuses,
} from 'components/Inbox/CollectionActivitiesV2/FilterStrip/util';
import { useGetActivityFilterOption } from 'components/Inbox/CollectionActivitiesV2/hooks/useGetActivityFilterOption';
import { IReportingTags } from 'store/reporting-tags/type';
import { InboxActivityMode } from 'types/api/inbox/activity';
import { CustomField, CustomFieldOption } from 'types/entities/custom-field';
import { FilterDropdown } from '../../FilterDropdown';
import { treeValueToString } from '../../Filters/TreeMultiSelect/util';
import { FilterConfig, FilterType } from '../../types';
import { BaseFilterBlockProps } from '../types';

interface ReportingTagsFilterProps extends BaseFilterBlockProps<number[]> {
  reportingTag: IReportingTags;
}
export function ReportingTagsFilter(props: ReportingTagsFilterProps) {
  const { value, reportingTag, onSubmit, showClear, onClear } = props;

  const options = reportingTag.tag_options.map((tagOption) => ({
    label: tagOption.name,
    value: tagOption.id,
  }));

  const filterConfig: FilterConfig = {
    name: `reporting-tag-${reportingTag.id}`,
    label: reportingTag.name,
    type: FilterType.MULTI_SELECT_CHECKBOX,
    options,
  };

  function handleClear() {
    onClear && onClear();
    onSubmit && onSubmit([]);
  }

  return (
    <FilterDropdown
      value={value}
      filterConfig={filterConfig}
      onSubmit={onSubmit}
      showClear={showClear}
      onClear={handleClear}
      additionalProps={{ isCustomFilter: true }}
    />
  );
}

interface CustomFieldFilterProps extends BaseFilterBlockProps<CustomFieldOption[]> {
  customField: CustomField;
  hide?: boolean;
  shouldIncludeUnassigned?: boolean;
}
export function CustomFieldFilter(props: CustomFieldFilterProps) {
  const { value, onSubmit, showClear, onClear, customField, hide } = props;

  if (hide) return null;

  const options = customField.options.map((fieldOption) => ({
    label: `${fieldOption}`,
    value: fieldOption,
  }));

  const filterConfig: FilterConfig = {
    name: `custom-field-${customField.id}`,
    label: customField.display_name,
    type: FilterType.MULTI_SELECT_CHECKBOX,
    options: props.shouldIncludeUnassigned
      ? [{ label: 'Unassigned', value: -1 }, ...options]
      : options,
  };

  function handleClear() {
    onClear?.();
    onSubmit?.([]);
  }

  return (
    <FilterDropdown
      value={value}
      filterConfig={filterConfig}
      onSubmit={onSubmit}
      showClear={showClear}
      onClear={handleClear}
      additionalProps={{ isCustomFilter: true }}
    />
  );
}

interface CollectionActivityMultiSelectProps {
  value: string[];
  onChange: (newValue: string[]) => void;
  mode: InboxActivityMode;
}
export function CollectionActivityTypeMultiSelect(props: CollectionActivityMultiSelectProps) {
  const InboxSubscriptionTypeList = useGetInboxSubscribedInvoiceStatuses();
  const options =
    props.mode === InboxActivityMode.ASSIGNED ? InboxAssignedTypeList : InboxSubscriptionTypeList;

  const filterConfig: FilterConfig = {
    name: 'collection-activity',
    label: 'Activity',
    type: FilterType.TREE_MULTI_SELECT,
    options,
    valueToString: (value) => {
      return !value || !value.length
        ? 'All'
        : treeValueToString(getEntityTypesTransformer(value).parent, options, 'children');
    },
  };

  const { defaultFilterValue } = useGetActivityFilterOption();

  const defaultValue = props.value.length
    ? props.value.includes('ALL')
      ? []
      : props.value
    : props.mode === InboxActivityMode.ASSIGNED
    ? defaultFilterValue.ASSIGNED
    : props.mode === InboxActivityMode.SUBSCRIBED
    ? defaultFilterValue.SUBSCRIBED
    : [];

  return (
    <FilterDropdown<string[]>
      value={defaultValue}
      filterConfig={filterConfig}
      onSubmit={props.onChange}
      onClear={() => {
        props.onChange([]);
        const event = new CustomEvent('clear_filter');
        document.dispatchEvent(event);
      }}
      showClear={Boolean(defaultValue?.length)}
    />
  );
}
