import { GrowCard } from 'components/BaseComponents/Card';
import { GrowCollapse } from 'components/BaseComponents/Collapse/Antd';
import { Style } from 'lib/style/style';
export const Conversation = Style(GrowCard, {
  width: '100%',
  height: 'calc(95vh - calc(56px + 64px + 48px))',
  borderRadius: 'var(--br-3)',
  background: 'var(--gray-1)',
  overflow: 'hidden',
  lineHeight: '1.2',
  boxShadow: 'var(--shadow-2)',
  scrollBehavior: 'smooth',

  '.ant-card-head': {
    padding: 'var(--space-12) var(--space-16)',
    borderRadius: 'var(--br-3) var(--br-3) 0px 0px',
    background: 'var(--gray-2)',
    position: 'sticky',
    top: 0,
    zIndex: 4,
  },
  '.ant-card-head-title, .ant-card-extra': {
    padding: 0,
  },
  '.ant-card-body': {
    background: 'var(--gray-1)',
    borderRadius: '0px 0px var(--br-3) var(--br-3)',
    padding: 'var(--space-24) var(--space-16) 120px var(--space-16)',
    height: 'calc(95% - var(--space-24) - var(--space-24))',
    overflow: 'auto',
    scrollBehavior: 'smooth',
  },
  '.ant-card-head-wrapper': {
    alignItems: 'flex-start',
  },

  variants: {
    modal: {
      true: {
        position: 'fixed',
        zIndex: 1000,
        inset: '0px',
        border: '0px',
        margin: '64px auto',
        width: '90vw',
        height: 'calc(95vh - 64px)',
      },
    },
    mode: {
      activities: {
        height: 'calc(100vh - 145px)',

        '.ant-card-body': {
          padding: 'var(--space-24) var(--space-16) 0 var(--space-16)',
        },
      },
    },
  },
});

export const StyledCollapse = Style(GrowCollapse, {
  '.ant-collapse-header': {
    padding: '12px 0 !important',

    '.ant-card-meta-detail': {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 'var(--space-4)',
    },
    '.ant-card-meta-detail > div:not(:last-child)': {
      margin: '0 !important',
    },
    '.ant-card-meta-avatar': {
      paddingRight: '8px',
    },
  },

  '.ant-collapse-content-box': {
    paddingTop: 'var(--space-24)',
    paddingBottom: 'var(--space-24) ',
    paddingLeft: '40px ',
    overflowX: 'hidden',
  },
  '.ant-collapse-item-disabled': {
    cursor: 'auto',
  },
  '&.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header, .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow':
    {
      cursor: 'auto',
    },
});
