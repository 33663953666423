import { MutationFunction } from '@tanstack/react-query';
import { ActivitySchema } from 'components/HigherOrderComponent/KeyActivitesContainer/ActivitySchema';
import { ActionableEntity } from 'components/HigherOrderComponent/KeyActivitesContainer/Email';
import {
  ActivityMutationProps,
  EActivityQueryParams,
  MutationFunctionResponseType,
} from 'components/HigherOrderComponent/KeyActivitesContainer/type';
import dayjs from 'dayjs';
import { assign, castArray, head, isArray } from 'lodash';
import { useSelector } from 'react-redux';
import { RaiseADisputeActivity } from 'store/activity-feed/type';
import { dateFormatSelector } from 'store/authentication/authentication';
import { ActivityType } from 'types/activities/activity-types';
import { CallLog } from 'types/activities/callLog';
import { Escalation } from 'types/activities/escalation';
import { Note } from 'types/activities/note';
import { Task } from 'types/activities/task';
import { InvoiceBasicDetails } from 'types/entities/invoice';
import CallLogFormModal from '../ActivitiesForm/CallLog/CallLogFormModal';
import DisputeFormModal from '../ActivitiesForm/Dispute/DisputeFormModal';
import EscalationFormModal from '../ActivitiesForm/Escalation/EscalationFormModal';
import NoteFormModal from '../ActivitiesForm/Note/NoteFormModal';
import TaskFormModal from '../ActivitiesForm/Task/TaskFormModal';
import {
  CallLogFormPayloadSchema,
  DisputeFormPayloadSchema,
  NoteFormPayloadSchema,
} from '../ActivitiesForm/predicate';
import {
  CallLogFormText,
  DisputeFormText,
  EscalationFormText,
  NoteFormText,
  TaskFormText,
} from '../ActivitiesForm/text';
import { FormItemNames, MutationFunctionProps } from '../ActivitiesForm/types';
import { CollectionActivityTypes } from '../ActivitiesModal/ActivitiesFormEdit';
import { EditFormConfigurations } from './EditFormConfigurations';
import {
  CallLogFormActivityProps,
  CallLogResponseSchema,
  DisputeFormActivityProps,
  DisputeResponseSchema,
  EscalationFormActivityProps,
  EscalationResponseSchema,
  NoteFormActivityProps,
  NoteResponseSchema,
  TaskFormActivityProps,
  TaskResponseSchema,
} from './type';
import { useEntitySearchParams } from './useEntitySearchParams';

export default function useGetActivityEditForm({
  type,
  openModal,
  onClose,
  activity,
  onMutateFn,
}: {
  type: ActivityType;
  openModal: boolean;
  onClose: () => void;
  activity?: CollectionActivityTypes;
  onMutateFn: MutationFunction<ActivityMutationProps, MutationFunctionProps<ActivityMutationProps>>;
}) {
  const { entitySearchParams } = useEntitySearchParams();
  const dateFormat = useSelector(dateFormatSelector);

  switch (type) {
    case ActivityType.DISPUTE: {
      const disputeActivity = activity as RaiseADisputeActivity;
      const disputeFormProps = EditFormConfigurations[ActivityType.DISPUTE];
      const disputeFormConfigurationProps = disputeFormProps(disputeActivity?.customer?.id);

      return (
        <DisputeFormModal<DisputeFormActivityProps<InvoiceBasicDetails>>
          openModal={openModal}
          onClose={onClose}
          invoices={disputeActivity?.invoices}
          customerId={disputeActivity?.customer.id}
          title={disputeActivity?.title}
          notes={disputeActivity?.description}
          participants={disputeActivity?.participants}
          participantConfig={disputeActivity?.participant_config}
          disputeType={disputeActivity?.types}
          id={disputeActivity?.id}
          text={DisputeFormText}
          responseSchema={DisputeFormPayloadSchema}
          disputeNo={disputeActivity?.dispute_no}
          onMutateFn={onMutateFn as MutationFunctionResponseType<DisputeResponseSchema>}
          disableRemainder
          editMode
          dateFormat={dateFormat}
          {...disputeFormConfigurationProps}
        />
      );
    }
    case ActivityType.CALL_LOG: {
      const callLogActivity = activity as CallLog;
      const callLogFormProps = EditFormConfigurations[ActivityType.CALL_LOG];
      const callLogFormConfigurationProps = callLogFormProps(
        entitySearchParams[EActivityQueryParams.CUSTOMER_ID]
      );
      const responseSchema = assign({}, CallLogFormPayloadSchema, {
        created_date: 'created_date',
        id: 'id',
      });

      function callLogCustomTransformer(): Partial<CallLog> {
        return {
          created_date: dayjs(callLogActivity?.created_date).format('YYYY-MM-DD'),
          id: callLogActivity?.id,
        };
      }

      return (
        <CallLogFormModal<CallLogFormActivityProps<InvoiceBasicDetails>>
          openModal={openModal}
          onClose={onClose}
          invoices={callLogActivity?.invoices}
          onMutateFn={onMutateFn as MutationFunctionResponseType<CallLogResponseSchema>}
          callDetails={callLogActivity?.description}
          callDate={callLogActivity?.call_date}
          attachments={callLogActivity?.attachments}
          customTransformer={callLogCustomTransformer}
          text={CallLogFormText}
          responseSchema={responseSchema}
          disableRemainder
          editMode
          dateFormat={dateFormat}
          {...callLogFormConfigurationProps}
        />
      );
    }
    case ActivityType.NOTE: {
      const noteActivity = activity as Note;
      const customerId = noteActivity?.customer
        ? noteActivity?.customer.id
        : entitySearchParams[EActivityQueryParams.CUSTOMER_ID];
      const customerName = noteActivity?.customer
        ? noteActivity?.customer.name
        : entitySearchParams[EActivityQueryParams.CUSTOMER];
      const showInvoiceList = Boolean(noteActivity?.invoice);
      const noteFormProps = EditFormConfigurations[ActivityType.NOTE];
      const noteFormConfigurationProps = noteFormProps(customerId);
      const responseSchema = assign({}, NoteFormPayloadSchema, {
        ref_id: 'ref_id',
        type: 'type',
      });

      function noteCustomTransformer(data: Record<FormItemNames, any>) {
        const invoiceId = isArray(data.noteInvoices) ? head(data.noteInvoices) : data.noteInvoices;
        return {
          ref_id: noteActivity?.invoice ? invoiceId : customerId,
          type: noteActivity?.invoice ? ActionableEntity.INVOICE : ActionableEntity.CUSTOMER,
        };
      }

      return (
        <NoteFormModal<NoteFormActivityProps<InvoiceBasicDetails>>
          openModal={openModal}
          onClose={onClose}
          notes={noteActivity?.notes}
          customerNames={!showInvoiceList ? castArray(customerName) : undefined}
          customerId={customerId}
          onMutateFn={onMutateFn as MutationFunctionResponseType<NoteResponseSchema>}
          customTransformer={noteCustomTransformer}
          invoices={castArray(noteActivity?.invoice)}
          showInvoicesList={showInvoiceList}
          text={NoteFormText}
          responseSchema={responseSchema}
          invoiceSingleSelect
          editMode
          disableRemainder
          dateFormat={dateFormat}
          {...noteFormConfigurationProps}
        />
      );
    }
    case ActivityType.ESCALATION: {
      const escalationActivity = activity as Escalation;
      const customerId = entitySearchParams[EActivityQueryParams.CUSTOMER_ID];
      const escalationFormProps = EditFormConfigurations[ActivityType.ESCALATION];
      const escalationFormConfigurationProps = escalationFormProps(customerId);

      function escalationCustomTransformer(): Partial<Escalation> {
        return {
          customer_id: customerId,
        };
      }

      return (
        <EscalationFormModal<EscalationFormActivityProps<InvoiceBasicDetails>>
          openModal={openModal}
          onClose={onClose}
          onMutateFn={onMutateFn as MutationFunctionResponseType<EscalationResponseSchema>}
          invoices={escalationActivity?.invoices}
          customerId={entitySearchParams[EActivityQueryParams.CUSTOMER_ID]}
          participantConfig={escalationActivity?.participant_config}
          participants={escalationActivity?.assigned_to_list}
          description={escalationActivity?.description}
          text={EscalationFormText}
          responseSchema={ActivitySchema.ESCALATION}
          customTransformer={escalationCustomTransformer}
          disableRemainder
          editMode
          dateFormat={dateFormat}
          {...escalationFormConfigurationProps}
        />
      );
    }
    case ActivityType.TASK: {
      const taskActivity = activity as Task;
      const customerId = entitySearchParams[EActivityQueryParams.CUSTOMER_ID];
      const taskFormProps = EditFormConfigurations[ActivityType.TASK];
      const taskFormConfigurationProps = taskFormProps(customerId);

      function taskCustomTransformer(): Partial<Task> {
        return {
          customer_ids: castArray(customerId),
        };
      }

      return (
        <TaskFormModal<TaskFormActivityProps<InvoiceBasicDetails>>
          openModal={openModal}
          onClose={onClose}
          onMutateFn={onMutateFn as MutationFunctionResponseType<TaskResponseSchema>}
          customerId={entitySearchParams[EActivityQueryParams.CUSTOMER_ID]}
          title={taskActivity?.title}
          description={taskActivity?.description}
          participants={taskActivity?.assigned_to_list}
          dueDate={taskActivity?.due_date}
          participantConfig={taskActivity?.participant_config}
          invoices={taskActivity?.invoices}
          text={TaskFormText}
          editMode
          responseSchema={ActivitySchema.TASK}
          customTransformer={taskCustomTransformer}
          dateFormat={dateFormat}
          {...taskFormConfigurationProps}
        />
      );
    }
    default: {
      break;
    }
  }
}
