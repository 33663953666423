import { QueryKey } from '@tanstack/react-query';
import { EmailConversation } from 'types/activities/email';
import { EmailThreadResponse } from 'types/api/inbox/email';
import { Body } from './Body';

interface ParentEmailProps {
  defaultOpen: boolean;
  conversation: EmailConversation;
  data: EmailThreadResponse;
  queryKey?: QueryKey;
}

function ParentEmail(props: ParentEmailProps) {
  const { defaultOpen, conversation, data, queryKey } = props;
  return (
    <Body conversation={conversation} defaultOpen={defaultOpen} data={data} queryKey={queryKey} />
  );
}

export { ParentEmail };
