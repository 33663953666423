import styled from 'styled-components';

export const SSettingsTemplate = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  &.no-height {
    height: auto;
  }
`;

export const Sheader = styled.div`
  background: var(--gray-2);
  padding: var(--space-20);
  box-shadow: var(--shadow-2);
`;

export const SBody = styled.div`
  flex: 1;
  overflow: auto;
  background-color: var(--background-1);

  .btn {
    width: 70px;
  }

  .form-action {
    height: 70px;
  }

  .form-body {
    height: calc(100% - 70px);
    overflow: auto;
  }

  .ant-tabs:not(.default-tab) {
    height: 100%;
    margin-left: var(--space-16);
    margin-right: var(--space-16);

    .ant-tabs-nav {
      margin: 0;
      height: 100%;
    }
  }
`;
