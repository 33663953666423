import { AttachmentsBlock } from 'components/CollectionActivities/Common/Details/BodyBlocks';
import { Expansion } from 'components/Common/Expansion/Expansion';
import { drop, take } from 'lodash';
import { Attachment } from 'types/common/attachments';
import useOpen from 'util/hooks/useOpen';

interface AttachmentsProps {
  attachments: Attachment[];
  hideTitle?: boolean;
  onRemove?: (id: number) => void;
}
function Attachments(props: AttachmentsProps) {
  const { attachments } = props;
  const { open, toggleOpen } = useOpen({ open: false });

  function sliceAttachments() {
    if (attachments.length > 5) {
      const first = take(attachments, 5);
      const rest = drop(attachments, 5);
      return { first, rest };
    }
    return;
  }

  const slicedAttachments = sliceAttachments();

  return (
    <>
      {slicedAttachments?.first ? (
        <>
          <AttachmentsBlock
            attachments={slicedAttachments.first}
            hideTitle={Boolean(props.hideTitle)}
            onRemove={props.onRemove}
          />
          {!open && (
            <div>
              <Expansion
                onClick={toggleOpen}
                open={open}
                text={`${slicedAttachments.rest.length} more`}
              />
            </div>
          )}
          {open ? (
            <AttachmentsBlock
              attachments={slicedAttachments.rest}
              hideTitle={Boolean(props.hideTitle)}
              onRemove={props.onRemove}
            />
          ) : null}
        </>
      ) : (
        <AttachmentsBlock
          attachments={attachments}
          hideTitle={Boolean(props.hideTitle)}
          onRemove={props.onRemove}
        />
      )}
    </>
  );
}

export { Attachments };
