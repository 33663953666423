import { GrowTypography } from '@sinecycle/growcomponents';
import { HyerLinkDetailsProps } from 'components/ARAging/ReceivablesByAgingBucket/AgingCustomersTable/Columns/Customer/CellRenderer/ParentCellRender';
import { CSSProperties } from 'react';
import { DisplayAuthorisedName } from './ParentChild';

interface TextWithHyperLinkProps {
  style?: CSSProperties;
  hyperLinkInfo?: HyerLinkDetailsProps;
}
export default function TextWithHyperLink(props: TextWithHyperLinkProps) {
  return (
    <div style={{ ...props.style, overflow: 'hidden' }}>
      <GrowTypography.Text ellipsis={{ tooltip: props.hyperLinkInfo?.name }}>
        <DisplayAuthorisedName
          name={props.hyperLinkInfo?.name}
          id={props.hyperLinkInfo?.id}
          authorised={props.hyperLinkInfo?.authorised}
        />
      </GrowTypography.Text>
    </div>
  );
}
