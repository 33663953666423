import { HideWrapper } from '@sinecycle/growcomponents';
import BadDebtTile from 'components/Dashboard/BadDebtTile';
import CEITile from 'components/Dashboard/CEITile';
import { useConfig } from 'components/HigherOrderComponent/Config/config';
import { cei_tile } from 'services/dashboard-datasets';
import { AccountConfigKey } from 'types/entities/account';
import { IS_DEMO } from 'util/http-utils';
import { Tile } from '../TileTitle';
import { DashBoardCardProps } from './type';

interface OthersCardsProps extends Pick<DashBoardCardProps, 'snapshotFilterParams'> {}
const ceiTile = cei_tile;
export default function Others(props: OthersCardsProps) {
  const showBadDebt = useConfig(AccountConfigKey.SHOW_WRITEOFF);

  return (
    <>
      <HideWrapper hide={!showBadDebt}>
        <Tile>
          <BadDebtTile filterParams={props.snapshotFilterParams} />
        </Tile>
      </HideWrapper>

      {IS_DEMO && ceiTile && (
        <Tile>
          <CEITile />
        </Tile>
      )}
    </>
  );
}
