import { useQueryClient } from '@tanstack/react-query';
import { head } from 'lodash';

function useGetQueryData<T>(queryKey: string[]) {
  const queryClient = useQueryClient();
  const currentData = queryClient.getQueriesData<T>(queryKey);
  if (!currentData.length) return;
  const queryValues = head(currentData);
  if (!queryValues) return;
  const [, previousData] = queryValues;
  return previousData;
}

export { useGetQueryData };
