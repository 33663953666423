import { Flex } from 'components/BaseComponents/Layout/Flex';
import { Texto } from 'components/BaseComponents/Typography/Texto';
import React from 'react';
import { SBody, Sheader, SSettingsTemplate } from './style';

interface ISettingsComponent {
  headerTitle?: string;
  headerDescription?: string;
  children?: React.ReactNode;
  secondaryHeaderAction?: React.ReactNode;
  middleComponent?: React.ReactNode;
  customRender?: React.ReactNode;
  hideBody?: boolean;
}

const SettingsComponent = (props: ISettingsComponent) => {
  return (
    <SSettingsTemplate className={props.hideBody ? 'no-height' : ''}>
      <Sheader className="setting-header">
        {props.customRender ? (
          props.customRender
        ) : (
          <Flex align="center">
            <Flex style={{ flexGrow: 1 }} gap="var(--space-24)">
              <Flex direction="column">
                <Texto size="20" weight="semibold" color="var(--gray-9)">
                  {props.headerTitle}
                </Texto>
                <Texto size="14" color="var(--gray-9)">
                  {props.headerDescription}
                </Texto>
              </Flex>
            </Flex>
            <Flex style={{ flexGrow: 1 }}>{props.middleComponent}</Flex>
            <Flex style={{ flexGrow: 1 }} justify="flex-end">
              {props.secondaryHeaderAction}
            </Flex>
          </Flex>
        )}
      </Sheader>
      {props.hideBody ? props.children : <SBody className={`setting-body`}>{props.children}</SBody>}
    </SSettingsTemplate>
  );
};

export default SettingsComponent;
