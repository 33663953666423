import { Flex } from 'components/BaseComponents/Layout/Flex';
import { Texto } from 'components/BaseComponents/Typography/Texto';
import { Contact } from 'types/entities/contact';

interface CustomDetailBody {
  displayName: string;
  customValue?: string;
  contacts?: Contact[];
}

interface CustomDetailBodyProp {
  detail: CustomDetailBody;
}

function CustomDetailRecord(props: CustomDetailBodyProp) {
  const { detail } = props;
  const EMPTY_STRING = '';
  const ContactJSX = detail.contacts?.map((contact, index) => (
    <Flex key={index} direction="column" gap="var(--space-2)">
      <Texto weight="semibold" color="var(--gray-9)">
        {`${contact.first_name ? contact.first_name : EMPTY_STRING} ${
          contact.last_name ? contact.last_name : EMPTY_STRING
        }`}
      </Texto>
      <Texto size="12" color="var(--gray-9)">
        {contact.email ? contact.email : EMPTY_STRING}
      </Texto>
    </Flex>
  ));

  const CustomJSX = (
    <Texto weight="semibold" color="var(--gray-9)">
      {detail.customValue}
    </Texto>
  );

  return (
    <Flex direction="column" gap="var(--space-4)">
      <Texto size="12" color="var(--gray-7)">
        {detail.displayName}
      </Texto>
      {detail.contacts && Boolean(detail.contacts.length) ? ContactJSX : CustomJSX}
    </Flex>
  );
}

export default CustomDetailRecord;
