import { TypographyText } from 'components/BaseComponents/AntTypography/AntTypography';
import { capitalize, groupBy, includes, keys, startCase } from 'lodash';
import { PlaceholderProps } from 'types/entities/email-templates';
import { OptionProps } from '../../type';
import { OptionItem } from './OptionItem';
import { CategorisedContainerStyled, OptionsContainer } from './style';

const t = {
  customerContactDetails: 'Customer-Contact Details',
  internalContactDetails: 'Customer-Internal Contacts',
  invoiceContactDetails: 'Single Invoice-Customer Contacts',
  invoiceInternalDetails: 'Single Invoice-Internal Contacts',
};

interface PlaceholderOptionsProps {
  placeholdersOptions: OptionProps[];
  onPlaceholderInsertCallback: (value: string) => void;
  viewOnly?: boolean;
  optionsOverrideStyles?: React.CSSProperties;
  selectedSection?: string;
}

function PlaceholderOptions(props: PlaceholderOptionsProps) {
  const [{ placeholders }] = props.placeholdersOptions;
  const optionsTodisplay = transformPlaceHoldersBasedOnContactType();

  function transformPlaceHoldersBasedOnContactType() {
    const subsectionToCategorise = [
      t.customerContactDetails,
      t.invoiceContactDetails,
      t.internalContactDetails,
      t.invoiceInternalDetails,
    ];

    if (includes(subsectionToCategorise, props?.selectedSection)) {
      const categorisedPlaceholders = groupBy(placeholders, 'category');
      return categorisedPlaceholders;
    }
    return placeholders;
  }

  function displayListOfOptions(optionsTodisplay: PlaceholderProps[]) {
    return optionsTodisplay.map((placeholder, index) => {
      return (
        <div key={index}>
          <OptionItem
            key={index}
            placeholder={placeholder}
            onPlaceholderInsertCallback={props?.onPlaceholderInsertCallback}
            viewOnly={props.viewOnly}
          />
        </div>
      );
    });
  }

  const CategorisedOptions = !Array.isArray(optionsTodisplay) ? (
    <CategorisedContainerStyled>
      {keys(optionsTodisplay).map((key, index) => (
        <div key={`${startCase(key)}-${index}`}>
          <TypographyText style={{ color: 'var(--gray-8)', fontSize: '12px' }}>
            {capitalize(startCase(key))}
          </TypographyText>
          <div style={{ marginTop: 'var(--space-2)', marginBottom: 'var(--space-12)' }}>
            {displayListOfOptions(optionsTodisplay[key])}
          </div>
        </div>
      ))}
    </CategorisedContainerStyled>
  ) : null;

  const Options = Array.isArray(optionsTodisplay) ? (
    <div style={{ paddingBottom: 'var(--space-8)' }}>{displayListOfOptions(optionsTodisplay)}</div>
  ) : (
    CategorisedOptions
  );

  return <OptionsContainer style={props.optionsOverrideStyles}>{Options}</OptionsContainer>;
}

export { PlaceholderOptions };
