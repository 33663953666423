import { GrowTypography, customerNameFormatter } from '@sinecycle/growcomponents';
import { Col, Row, Table, Typography } from 'antd';
import { ColumnType, ColumnsType } from 'antd/lib/table';
import { TypographyParagraph } from 'components/BaseComponents/AntTypography/AntTypography';
import { DateText } from 'components/BaseComponents/Date/DateText';
import { Flex, FlexBoxChild } from 'components/BaseComponents/Layout/Flex';
import { Overlay } from 'components/BaseComponents/Overlay';
import { GrowText } from 'components/BaseComponents/Typography';
import { Texto } from 'components/BaseComponents/Typography/Texto';
import CustomDetails from 'components/Common/CustomDetail/CustomDetails';
import CustomerNameWithDetail from 'components/Common/CustomDetail/CustomerNameWithDetail';
import { CustomerTags } from 'components/Common/HighRiskCustomertags/CustomerTags';
import { AmountWithCurrencySplit } from 'components/Common/MultiCurrency';
import { NumberFormat } from 'components/Common/NumberFormat/NumberFormat';
import ArrowSelect from 'components/Common/SelectComponents/ArrowSelect';
import { isSubsidiaryApplied } from 'components/Common/Subsidiary/util';
import { useConfig, useCustomDetailConfig } from 'components/HigherOrderComponent/Config/config';
import { EActivityQueryParams } from 'components/HigherOrderComponent/KeyActivitesContainer/type';
import { PageLink } from 'lib/router';
import { DashboardComponentProps } from 'pages/Dashboard/types';
import { useHighRiskCustomersQuery } from 'queries/admin-dashboard';
import React, { useState } from 'react';
import styled from 'styled-components';
import { HighRiskCustomer } from 'types/api/admin-dashboard';
import { AccountConfigKey } from 'types/entities/account';
import { StatementViews } from 'types/entities/invoice';
import { dashboardTexts } from '../texts';

const { Text } = Typography;

const componentTexts = dashboardTexts.deliquencies;
const t = {
  invoices: 'Invoices',
  totalOutstanding: 'Total Outstanding',
  overDueAmount: 'Overdue Amount',
};

const StyledTable = styled(Table)`
  .ant-table {
    line-height: 1.2;
  }
  tbody {
    .ant-table-cell {
      vertical-align: top;
    }
  }
`;

const AmountText = styled(Text)`
  color: var(--gray-9);
`;

const EmptyCell = () => <span>-</span>;

const DelinqienciesTableContainer = styled.div`
  .ant-table-title {
    padding: 0 8px 8px;
  }
  .ant-table-thead tr th {
    background-color: var(--gray-4);
  }

  .ant-table-cell.outstanding-amount.mc-enabled {
    height: 28px;
    padding: 0;
  }
`;

export enum TopDebtorsSortBy {
  TOTAL_OUTSTANDING_AMOUNT = 'TOTAL_OUTSTANDING_AMOUNT',
  TOTAL_OVERDUE_AMOUNT = 'TOTAL_OVERDUE_AMOUNT',
}

const sortOptions = [
  {
    key: TopDebtorsSortBy.TOTAL_OUTSTANDING_AMOUNT,
    label: t.totalOutstanding,
    value: TopDebtorsSortBy.TOTAL_OUTSTANDING_AMOUNT,
    style: {
      fontSize: 'var(--fs-14)',
      color: 'var(--primary-7)',
    },
  },
  {
    key: TopDebtorsSortBy.TOTAL_OVERDUE_AMOUNT,
    label: t.overDueAmount,
    value: TopDebtorsSortBy.TOTAL_OVERDUE_AMOUNT,
    style: {
      fontSize: 'var(--fs-14)',
      color: 'var(--primary-7)',
    },
  },
];

function Delinquencies({ filterParams }: DashboardComponentProps) {
  const [currentSortBy, setCurrentSortBy] = useState<TopDebtorsSortBy>(
    TopDebtorsSortBy.TOTAL_OVERDUE_AMOUNT
  );

  const { data: highRiskCustomers, isLoading: loading } = useHighRiskCustomersQuery({
    ...filterParams,
    sort_by: currentSortBy,
  });
  const isMulticurrencyEnabled = useConfig(AccountConfigKey.MULTI_CURRENCY_ENABLED);
  const isSubsidiaryFilter = isSubsidiaryApplied(filterParams);

  const customDetailToShow = useCustomDetailConfig();

  const columns: ColumnsType<HighRiskCustomer> = [
    {
      title: componentTexts.columnNames.customerName,
      dataIndex: 'customer_name',
      key: 'customer_name',
      width: 250,
      render: (text: string, record) => {
        const isCustomDetailAvailable =
          record.custom_customer_details && Boolean(record.custom_customer_details.length);
        const customerName = customerNameFormatter({ name: record.customer_name });
        const CustomerLink = (
          <PageLink
            appPage="CUSTOMER_DETAILS"
            pathParams={{ id: record.customer_id }}
            state={{ addPrimaryPathAsChildPath: true }}
            searchParams={
              {
                [EActivityQueryParams.CURRENT_SECTION]: StatementViews.INVOICES,
                [EActivityQueryParams.CURRENT_VIEW]: StatementViews.OPEN_INVOICES,
              } as unknown as URLSearchParams
            }
          >
            <TypographyParagraph
              ellipsis={{
                tooltip: customerName,
              }}
              strong
              style={{
                fontSize: 'var(--fs-16)',
                color: 'var(--primary-7)',
                margin: 0,
                maxWidth: '250px',
              }}
            >
              {customerName}
            </TypographyParagraph>
          </PageLink>
        );

        const CustomerNameWODetailsPopOver = (
          <>
            {CustomerLink}
            {!customDetailToShow && Boolean(record.tags.length) && (
              <Flex.Child>
                <CustomerTags
                  tags={record.tags}
                  currentTagStyle={{ maxWidth: '40px', color: 'var(--purple-7)' }}
                />
              </Flex.Child>
            )}
          </>
        );
        const PopupParent = (
          <Flex
            gap="var(--space-4)"
            direction="column"
            style={{ width: 'fit-content', maxWidth: '200px' }}
            justify="flex-start"
          >
            {CustomerLink}
            {record.custom_customer_details && Boolean(record.custom_customer_details.length) && (
              <CustomDetails
                customDetails={record.custom_customer_details!}
                detailToShow={customDetailToShow!}
              />
            )}
          </Flex>
        );

        const CustomerNameWithDetailsPopOver = (
          <CustomerNameWithDetail
            customerName={customerName}
            customDetails={record.custom_customer_details!}
            parent={PopupParent}
            placement="rightTop"
          />
        );

        return (
          <>
            <Flex gap="var(--space-8)" direction="column" justify="center">
              <div className="value">
                {isCustomDetailAvailable
                  ? CustomerNameWithDetailsPopOver
                  : CustomerNameWODetailsPopOver}
              </div>
            </Flex>
          </>
        );
      },
    },
    {
      title: isMulticurrencyEnabled ? (
        <Flex
          style={{
            background: isMulticurrencyEnabled ? 'var(--geekblue-6)' : 'inherit',
            height: '100%',
            padding: ' 0 var(--space-8)',
          }}
          align="center"
        >
          <Texto
            className="title"
            color={isMulticurrencyEnabled ? 'var(--gray-1)' : 'var(--gray-9)'}
            weight={isMulticurrencyEnabled ? 'regular' : 'semibold'}
          >
            {componentTexts.columnNames.outstandingAmountCustomerCurrency}
          </Texto>
        </Flex>
      ) : (
        componentTexts.columnNames.outstandingAmountCustomerCurrency
      ),
      className: isMulticurrencyEnabled ? 'outstanding-amount mc-enabled' : 'outstanding-amount',
      width: 145,
      render: (text: string, record) => {
        return (
          <Flex direction="column" gap="--space-4" justify="flex-start">
            <AmountWithCurrencySplit
              type="multi"
              style={{}}
              formatOptions={{ notation: 'compact' }}
              styledComponent={AmountText}
              amountWithCurrencySplit={record.outstanding_amount.amount}
              hideSplitTotal
              showTooltip
            />
            <FlexBoxChild>
              {record.outstanding_amount.invoice_count &&
                record.outstanding_amount.invoice_count > 0 && (
                  <Row align="middle" gutter={[2, 2]} wrap={false}>
                    <Col>
                      <NumberFormat
                        value={record.outstanding_amount.invoice_count}
                        textProps={{
                          fs: '12',
                          style: {
                            color: 'var(--gray-7)',
                          },
                        }}
                      />
                    </Col>
                    <Col>
                      <GrowTypography.Text style={{ color: 'var(--gray-7)' }} fs="12">
                        {t.invoices}
                      </GrowTypography.Text>
                    </Col>
                  </Row>
                )}
            </FlexBoxChild>
          </Flex>
        );
      },
      children: isMulticurrencyEnabled
        ? [
            {
              title: componentTexts.columnNames.customerCurrency,
              width: 145,
              dataIndex: 'amount_doc_currency',
              render: (text: string, record) => {
                return (
                  <AmountWithCurrencySplit
                    type="multi"
                    showTooltip
                    formatOptions={{ notation: 'compact' }}
                    styledComponent={AmountText}
                    amountWithCurrencySplit={record.outstanding_amount_customer_currency.amount}
                    context={componentTexts.columnNames.outstandingAmountCustomerCurrency}
                    hideSplitTotal
                  />
                );
              },
            },
            {
              title: componentTexts.columnNames.baseCurrency,
              width: 145,
              dataIndex: 'amount_doc_currency',
              render: (text: string, record) => {
                return (
                  <Flex direction="column" gap="--space-4">
                    <AmountWithCurrencySplit
                      type="multi"
                      formatOptions={{ notation: 'compact' }}
                      styledComponent={AmountText}
                      amountWithCurrencySplit={record.outstanding_amount.amount}
                      hideSplitTotal
                      showTooltip
                    />
                    {record.outstanding_amount.invoice_count &&
                      record.outstanding_amount.invoice_count > 0 && (
                        <Row wrap={false} align="middle" gutter={[4, 4]}>
                          <Col>
                            <NumberFormat
                              value={record.outstanding_amount.invoice_count}
                              textProps={{
                                fs: '12',
                                style: {
                                  color: 'var(--gray-7)',
                                },
                              }}
                            />
                          </Col>
                          <Col>
                            <GrowTypography.Text style={{ color: 'var(--gray-7)' }} fs="12">
                              {t.invoices}
                            </GrowTypography.Text>
                          </Col>
                        </Row>
                      )}
                  </Flex>
                );
              },
            },
          ]
        : undefined,
    },
    {
      title: componentTexts.columnNames.overdueAmt,
      dataIndex: 'over_due',
      key: 'over_due',
      width: 145,
      render: (text: string, record) => {
        if (!record.over_due) return <EmptyCell />;
        return (
          <Flex direction="column" gap="--space-4">
            <AmountWithCurrencySplit
              type="multi"
              showTooltip
              formatOptions={{ notation: 'compact' }}
              styledComponent={AmountText}
              amountWithCurrencySplit={record.over_due.amount}
              context={componentTexts.columnNames.overdueAmt}
            />
            {record.over_due.invoice_count && record.over_due.invoice_count > 0 && (
              <Row align="middle" gutter={[4, 4]} wrap={false}>
                <Col>
                  <NumberFormat
                    value={record.over_due.invoice_count}
                    textProps={{
                      fs: '12',
                      style: {
                        color: 'var(--gray-7)',
                      },
                    }}
                  />
                </Col>
                <Col>
                  <GrowTypography.Text fs="12" style={{ color: 'var(--gray-7)' }}>
                    {t.invoices}
                  </GrowTypography.Text>
                </Col>
              </Row>
            )}
          </Flex>
        );
      },
    },
    {
      title: componentTexts.columnNames.lastPayment,
      dataIndex: 'last_payment_amount',
      key: 'last_payment_amount',
      width: 145,
      render: (text: string, record) => {
        if (!record.last_payment_amount) return <EmptyCell />;
        return (
          <Flex direction="column" gap="--space-4">
            <AmountWithCurrencySplit
              type="multi"
              showTooltip
              formatOptions={{ notation: 'compact' }}
              styledComponent={AmountText}
              amountWithCurrencySplit={record.last_payment_amount}
              context={componentTexts.columnNames.lastPayment}
            />
            {record.last_payment_date ? (
              <DateText
                value={record.last_payment_date}
                textProps={{ style: { color: 'var(--gray-7)', fontSize: 'var(--fs-12)' } }}
              />
            ) : null}
          </Flex>
        );
      },
    },
  ];

  const filteredColumns = () => {
    const computedColumns = columns;
    const blackListedColumns: (string | undefined)[] = [];

    if (!isMulticurrencyEnabled) {
      blackListedColumns.push('outstanding_amount_customer_currency');
    }

    return computedColumns
      .filter(
        (column: ColumnType<HighRiskCustomer>) =>
          !blackListedColumns.includes(column.dataIndex as string)
      )
      .map((column: any) => {
        return {
          ...column,
          children: column?.children?.filter(
            (children: ColumnType<HighRiskCustomer>) =>
              !blackListedColumns.includes(children.dataIndex as string)
          ),
        };
      });
  };

  const Header = (
    <Flex direction="row" gap="var(--space-8)" align="center">
      <GrowText color="var(--gray-9)" size={'var(--fs-20)'} strong>
        {componentTexts.title}
      </GrowText>
      {!isSubsidiaryFilter && (
        <ArrowSelect
          arrowStyle={'Bold'}
          options={sortOptions}
          value={currentSortBy}
          onChange={handleSortBy}
          style={{ fontWeight: 'var(--fs-semibold))' }}
        />
      )}
    </Flex>
  );

  const TopDebtorsTable = (
    <StyledTable
      rowKey="customer_id"
      columns={filteredColumns()}
      dataSource={highRiskCustomers ?? []}
      pagination={false}
      size="small"
      scroll={{ x: 500, y: 500 }}
    />
  );

  const TopDebTorsComponent = (
    <Flex direction="column" gap="var(--space-20)">
      {Header}
      {TopDebtorsTable}
    </Flex>
  );

  function handleSortBy(sortBy: TopDebtorsSortBy) {
    setCurrentSortBy(sortBy);
  }

  return (
    <DelinqienciesTableContainer>
      <Overlay shouldCover={loading}>{TopDebTorsComponent}</Overlay>
    </DelinqienciesTableContainer>
  );
}

export default React.memo(Delinquencies);
