import { GrowTypography } from '@sinecycle/growcomponents';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { TypographyTextProps } from 'components/BaseComponents/Typography/type';
import { AmountBlock } from 'components/CollectionActivities/Common/Details/BodyBlocks';
import { EActivityQueryParams } from 'components/HigherOrderComponent/KeyActivitesContainer/type';
import { PageLink } from 'lib/router';
import { useSelector } from 'react-redux';
import { baseCurrencySelector, localeSelector } from 'store/authentication/authentication';
import { AmountMaybeWithMultiCurrencySplit } from 'types/common/multi-currency';
import { StatementViews } from 'types/entities/invoice';
import { formatAmount } from 'util/number-formatter';
import { AmountWithCurrencySplit } from '../MultiCurrency';
import HideWrapper from '../Util/HideWrapper';
import { InvoicesColumns } from './InvoicesColumn';

interface InvoicesTextProps {
  invoice: InvoicesColumns;
  textStyle?: { amount?: TypographyTextProps; title?: TypographyTextProps };
  hide?: {
    amount?: boolean;
    title?: boolean;
    transactionalAmount?: boolean;
  };
  overRideStyle?: React.CSSProperties;
  onClick?: (id: number) => void;
  align?: 'center' | 'flex-start' | 'flex-end' | 'stretch' | 'baseline';
}
function InvoicesText(props: InvoicesTextProps) {
  const locale = useSelector(localeSelector);
  const { invoice, textStyle, hide, overRideStyle, onClick, align = 'center' } = props;
  const baseCurrency = useSelector(baseCurrencySelector);
  function handleClick() {
    onClick?.(invoice.id);
  }
  const AccessibleText = (
    <GrowTypography.Text
      style={{
        cursor: 'pointer',
        maxWidth: textStyle?.title?.ellipsis ? '80px' : undefined,
        color: 'var(--primary-7)',
      }}
      onClick={handleClick}
      {...textStyle?.title}
    >
      {invoice.title}
    </GrowTypography.Text>
  );
  const AccessibleLink = (
    <PageLink
      appPage="INVOICE_DETAILS"
      pathParams={{ id: invoice.id }}
      style={{ display: 'flex', cursor: 'pointer' }}
      target="_blank"
      searchParams={
        {
          [EActivityQueryParams.CURRENT_SECTION]: StatementViews.ACTIVITIES,
          [EActivityQueryParams.CURRENT_ACTIVITY_TAB]: StatementViews.ALL,
        } as unknown as URLSearchParams
      }
    >
      {AccessibleText}
    </PageLink>
  );
  const NotAccessibleText = (
    <GrowText
      style={{
        maxWidth: textStyle?.title?.ellipsis ? '80px' : undefined,
      }}
      {...textStyle?.title}
    >
      {invoice.title}
    </GrowText>
  );
  const Title = (
    <HideWrapper hide={Boolean(hide?.title)}>
      {props.invoice.isAccessible ? AccessibleLink : NotAccessibleText}
    </HideWrapper>
  );
  const Amount = (
    <HideWrapper hide={Boolean(hide?.amount)}>
      <GrowText style={{ textAlign: 'right' }} {...textStyle?.amount}>
        {formatAmount(String(invoice.amount), baseCurrency ?? 'USD', locale)}
      </GrowText>
    </HideWrapper>
  );
  const transactionalAmount = (
    <HideWrapper hide={Boolean(hide?.transactionalAmount)}>
      <AmountBlock
        amount={(invoice.amount as number) ?? 0}
        currency={invoice.currency}
        amountComp={
          <AmountWithCurrencySplit
            showTooltip
            type="multi"
            amountWithCurrencySplit={invoice.amount as AmountMaybeWithMultiCurrencySplit}
          />
        }
      />
    </HideWrapper>
  );
  return (
    <Flex align={align} style={{ width: '100%', ...overRideStyle }} justify="space-between">
      {Title}
      {Amount}
      {transactionalAmount}
    </Flex>
  );
}

export { InvoicesText };
