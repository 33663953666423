import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from 'antd';
import { MenuProps } from 'antd/lib';
import { map } from 'lodash';
import styled from 'styled-components';
import { MoreActionsProps } from './types';

const DefaultTitle = styled.div`
  cursor: pointer;
  background: var(--gray-1);
  padding: var(--space-8);
  border: 1px solid var(--gray-5);
  border-radius: var(--br-1);
`;

const t = {
  chooseAction: 'Choose Action',
};

const MoreActions = (props: MoreActionsProps) => {
  const { actionArea = 'OTHERS', style, removePadding } = props;
  // do not render trigger element if no options to display
  if (!props.options.length) return null;

  function getItems(): MenuProps['items'] {
    return map(props.options, (option) => {
      return {
        icon: option.icon,
        key: option.key,
        title: option.label,
        label: option.label,
        onClick: option.handleClick,
      };
    });
  }

  const items = getItems();

  const RenderHeading = (
    <DefaultTitle className="default-title">
      <span style={{ marginRight: 'var(--space-8)' }}>{t.chooseAction}</span>
      <FontAwesomeIcon icon={['fal', 'chevron-down']} />
    </DefaultTitle>
  );

  return (
    <Dropdown
      // getPopupContainer={trigger => trigger.parentNode as HTMLElement}   //Needs verification
      menu={{
        items,
        onClick: (info) => {
          props.onClick?.(info.key);
        },
      }}
      trigger={['click']}
      className={`more-actions  drop${props.direction} ${props.className}`}
      onOpenChange={props.onVisibleChange}
    >
      <div
        role="button"
        style={
          actionArea === 'OTHERS' ? { padding: removePadding ? '0' : 'var(--space-8)' } : undefined
        }
      >
        {props.children ? props.children : RenderHeading}
      </div>
    </Dropdown>
  );
};

export default MoreActions;
