import SelectComponent, { GroupedOptions, IProps } from 'components/BaseComponents/ASelect';
import { generateUniqueID, useRelativeContactTypes } from 'components/Common/Contact/ContactType';
import SkeletonLoader from 'components/Common/FilterComponents/Common/SkeletonLoader';
import { RecepientList } from 'components/Common/SelectComponents/RecepientList';
import { debounce, intersectionBy, uniqBy } from 'lodash';
import { useMemo, useState } from 'react';
import { searchGeneralContacts } from 'services/internal-contacts';
import { Emailable, EmailableType } from 'types/activities/email';
import { ContactType } from 'types/entities/contact';
import { ActionableEntity } from '..';
import { stakeHolderListAttributes } from '../type';
import { AddCustomRecipientEmailListItem } from './AddRecepient';
import { optionToRender, tagRender } from './hook/OptionRender';
import {
  EmailGroupedOptions,
  computeRecepient,
  getFormatedEmailData,
  getGroupTemplate,
  transformToGroupOptions,
} from './util';

const dropdownStyle: React.CSSProperties = {
  maxHeight: '300px',
  overflow: 'auto',
  boxShadow: 'var(--shadow-1)',
  border: '1px solid var(--gray-4)',
};

interface RecepientsProps {
  emailRecepients?: EmailGroupedOptions;
  showRelativeOptionsList?: boolean;
  placeHolder: string;
  entity?: ActionableEntity;
  selectedValues: stakeHolderListAttributes[];
  onChangeCallback: (usersList: stakeHolderListAttributes[]) => void;
}

export function Recepients(props: RecepientsProps) {
  const {
    placeHolder,
    selectedValues,
    showRelativeOptionsList,
    onChangeCallback: onChange,
  } = props;

  const [search, setSearch] = useState<string | null>(null);
  const [searchOptions, setSearchOptions] = useState<stakeHolderListAttributes[]>([]);
  const [addCustomEmailButtonVisible, setAddCustomEmailButtonVisible] = useState(false);
  const [searchSelectionTo, setSearchSelectionTo] = useState<stakeHolderListAttributes[]>([]);
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const relativeContactOptions = useRelativeContactTypes();

  const selections = selectedValues?.map((item) => {
    //For reloding relative billing contacts
    if (item.type === 'CONTACT' && item.contact_type_id) {
      const entityType =
        item.participant_relation === 'PARENT' && item.contact_type_identifier === 'CUSTOMER'
          ? 'PARENT_CUSTOMER'
          : item.contact_type_identifier === 'CUSTOMER'
          ? 'CUSTOMER_BILLING_CONTACT'
          : 'INVOICE_BILLING_CONTACT';

      return generateUniqueID(entityType, item.contact_type_id);
    } else if (item.type === 'USER' && item.user_type && item.id) {
      const entityType =
        item.association_level === 'CUSTOMER'
          ? 'CUSTOMER_INTERNAL_CONTACT'
          : 'INVOICE_INTERNAL_CONTACT';

      return generateUniqueID(entityType, item.user_type);
    }

    return item.email;
  }) as string[];

  const groupedSearchOptions = useMemo(() => {
    const groupedOptions = transformToGroupOptions(searchOptions);

    return groupedOptions;
  }, [searchOptions]);

  function RecepientListOptions(options: React.ReactElement) {
    const isEmptyResultOnSearch = search && !searchOptions.length;
    function onClick(option: stakeHolderListAttributes) {
      setAddCustomEmailButtonVisible(false);
      setSearchSelectionTo((oldList) => {
        return [
          ...oldList,
          {
            type: option.type ?? EmailableType.CONTACT,
            email: option.email,
            id: option.id,
            label: option.label,
            contact_type: ContactType.OTHER_CONTACT,
            participant_relation: option.participant_relation,
          },
        ];
      });

      const newRecepientList = [
        ...props.selectedValues,
        {
          type: option.type ?? EmailableType.CONTACT,
          email: option.email,
          participant_relation: option.participant_relation,
        },
      ];

      props.onChangeCallback(newRecepientList);
      setSearch(null);
    }

    if (searchLoading) {
      return (
        <div style={{ padding: 'var(--space-8)' }}>
          <SkeletonLoader />
        </div>
      );
    }

    const AddCustomEmail = isEmptyResultOnSearch && (
      <AddCustomRecipientEmailListItem
        onClick={onClick}
        option={search}
        addCustomEmailButtonVisible={addCustomEmailButtonVisible}
      />
    );

    const RecepientListJSX = !isEmptyResultOnSearch && (
      <RecepientList styleType={'emailSelect'} options={options} />
    );
    return (
      <>
        {RecepientListJSX}
        {AddCustomEmail}
      </>
    );
  }

  function onDropdownVisibleChange(open: boolean) {
    if (!open) {
      setSearch(null);
    }
  }

  async function contactSearch(searchTerm: string) {
    const response = await searchGeneralContacts(searchTerm),
      recipientEmailingList = getFormatedEmailData(response as Emailable[]);

    recipientEmailingList.map((receipent) => {
      receipent.contact_type = receipent.type as unknown as ContactType;
      receipent.type = EmailableType.CONTACT;
      return receipent;
    });

    setSearchOptions(recipientEmailingList);
    setAddCustomEmailButtonVisible(true);
  }

  const debouncedSearch = debounce(contactSearch, 500);

  const onSearchCallBack = async (searchTerm: string) => {
    try {
      setSearchLoading(true);
      if (!searchTerm) {
        setSearch(null);
        setSearchSelectionTo([]);
        return;
      }

      setSearch(searchTerm);
      debouncedSearch(searchTerm);
    } catch (error) {
      console.log('error', error);
    } finally {
      setTimeout(() => {
        setSearchLoading(false);
      }, 700);
    }
  };

  function onChangeCallback(usersList: IProps<stakeHolderListAttributes>[]) {
    const users = usersList
      .filter((userProp) => userProp.valueobject)
      .map((userProp) => userProp.valueobject) as stakeHolderListAttributes[];

    const selection = computeRecepient(users, !!showRelativeOptionsList);
    const selectionsToAdd = intersectionBy(searchOptions, selection, 'email');

    if (selectionsToAdd) {
      setSearchSelectionTo((oldValue) => {
        const uniqueValues = uniqBy([...oldValue, ...selectionsToAdd], 'email');
        return uniqueValues;
      });
    }

    setSearch(null);
    setSearchOptions([]);
    props.onChangeCallback(selection);
  }

  const computedGroupedOptions = useMemo(() => {
    let options = {} as ReturnType<typeof transformToGroupOptions>;

    if (search) {
      options = groupedSearchOptions;
    } else if (props.emailRecepients) {
      options = props.emailRecepients;
    }

    if (searchSelectionTo && !!searchSelectionTo.length) {
      const getKeys = Object.keys as <T extends object>(obj: T) => Array<keyof T>;
      const transformedSearch = transformToGroupOptions(searchSelectionTo);
      const newGroupOptions = getGroupTemplate();

      getKeys(newGroupOptions).forEach((key) => {
        newGroupOptions[key].options = [...options[key].options, ...transformedSearch[key].options];
      });

      options = newGroupOptions;
    }

    return options;
  }, [groupedSearchOptions, props.emailRecepients, search, searchSelectionTo]);

  return (
    <SelectComponent
      selectedValues={selections}
      placeholder={placeHolder}
      mode={'multiple'}
      className="filter-drop-down"
      optionsStyle="grouped"
      groupedOptions={computedGroupedOptions}
      shouldFormat={true}
      labelAtrributeName={'label'}
      valueAtrributeName={'email'}
      onSearchCallBack={onSearchCallBack}
      onDropdownVisibleChange={onDropdownVisibleChange}
      dropdownStyle={dropdownStyle}
      dropdownRender={(options: React.ReactElement, optionsList: GroupedOptions) => {
        return RecepientListOptions(options);
      }}
      onChangeCallBack={onChangeCallback}
      optionRender={optionToRender}
      tagRender={(custom) => {
        return tagRender(custom, selectedValues);
      }}
      virtual
    />
  );
}
