import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GrowButton } from '@sinecycle/growcomponents';
import { Tooltip } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import HideWrapper from 'components/Common/Util/HideWrapper';
import { INBOX_EMAIL_EVENT } from 'events/inbox';
import { dispatchAppEvent } from 'lib/pub-sub';
import React from 'react';
import styled from 'styled-components';
import { EmailFormType } from './type';

const t = {
  reply: 'Reply',
  replyAll: 'Reply All',
  forward: 'Forward',
};

const FooterButton = styled(GrowButton)`
  color: var(--primary-7);
`;

interface EmailConversationFooterProps {
  style?: React.CSSProperties;
  openForm?: (formType: EmailFormType) => void;
  hideReplyAll?: boolean;
  iconMode?: boolean;
}
export function EmailConversationFooter(props: EmailConversationFooterProps) {
  const { openForm, hideReplyAll, iconMode } = props;

  function replyEmail() {
    openForm?.(EmailFormType.REPLY);
    dispatchAppEvent({ type: INBOX_EMAIL_EVENT.REPLY_EMAIL });
  }

  function replyAllEmail() {
    openForm?.(EmailFormType.REPLY_ALL);
    dispatchAppEvent({ type: INBOX_EMAIL_EVENT.REPLY_ALL_EMAIL });
  }

  function forwardEmail() {
    openForm?.(EmailFormType.FORWARD);
    dispatchAppEvent({ type: INBOX_EMAIL_EVENT.FORWARD_EMAIL });
  }

  return (
    <Flex gap="--space-8" style={{ ...props.style, marginTop: iconMode ? 0 : 'var(--space-24)' }}>
      <Tooltip title={iconMode ? t.reply : undefined}>
        <FooterButton
          onClick={replyEmail}
          icon={<FontAwesomeIcon icon={['far', 'reply']} />}
          size={iconMode ? 'small' : 'middle'}
        >
          {iconMode ? null : t.reply}
        </FooterButton>
      </Tooltip>
      <HideWrapper hide={Boolean(hideReplyAll)}>
        <Tooltip title={iconMode ? t.replyAll : undefined}>
          <FooterButton
            onClick={replyAllEmail}
            icon={<FontAwesomeIcon icon={['far', 'reply-all']} />}
            size={iconMode ? 'small' : 'middle'}
          >
            {iconMode ? null : t.replyAll}
          </FooterButton>
        </Tooltip>
      </HideWrapper>
      <Tooltip title={iconMode ? t.forward : undefined}>
        <FooterButton
          onClick={forwardEmail}
          icon={<FontAwesomeIcon icon={['far', 'share']} />}
          size={iconMode ? 'small' : 'middle'}
        >
          {iconMode ? null : t.forward}
        </FooterButton>
      </Tooltip>
    </Flex>
  );
}
