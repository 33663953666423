import { useQueryClient } from '@tanstack/react-query';
import { useURLSearchParams } from 'hooks/use-search-params';
import { filter, isNumber, map, pick, split } from 'lodash';
import { InboxPath } from 'pages/Inbox/type';
import {
  inboxQueryKeys,
  useGetInboxEmailUnReadCount,
  useGetRecentRepliesTreads,
} from 'queries/inbox';
import { useCallback, useEffect, useMemo } from 'react';
import { NavigateOptions } from 'react-router-dom';
import { EmailFilters } from 'types/activities/email';
import {
  emailSearchParamKeys,
  EmailThreadSearchParams,
  InboxEmailConversationType,
  InboxEmailMode,
  InboxEmailSearchParam,
  UnReadCountResponse,
} from 'types/api/inbox/email';
import { getFormattedDateForRelativeOptions, RelativeDateRange } from 'util/relative-date-range';
import { getSanitizedObject } from 'util/remove-null-and-undefined';

export function useEmailSearchParams() {
  const { setSearchParams, getSearchParam, removeSearchParams } = useURLSearchParams();

  const emailSearchParams = useMemo(() => {
    const emailSearchParams: Partial<EmailThreadSearchParams> = {};

    emailSearchParams[InboxEmailSearchParam.page] = getSearchParam(InboxEmailSearchParam.page);
    emailSearchParams[InboxEmailSearchParam.threadId] = getSearchParam(
      InboxEmailSearchParam.threadId
    );
    emailSearchParams[InboxEmailSearchParam.dateRange] = getSearchParam(
      InboxEmailSearchParam.dateRange
    );
    emailSearchParams[InboxEmailSearchParam.fromDate] = getSearchParam(
      InboxEmailSearchParam.fromDate
    );
    emailSearchParams[InboxEmailSearchParam.toDate] = getSearchParam(InboxEmailSearchParam.toDate);
    emailSearchParams[InboxEmailSearchParam.pageSize] = getSearchParam(
      InboxEmailSearchParam.pageSize
    );
    emailSearchParams[InboxEmailSearchParam.emailType] = getSearchParam(
      InboxEmailSearchParam.emailType
    );
    emailSearchParams[InboxEmailSearchParam.emailMode] = getSearchParam(
      InboxEmailSearchParam.emailMode
    );
    emailSearchParams[InboxEmailSearchParam.email_id] = getSearchParam(
      InboxEmailSearchParam.email_id
    );

    emailSearchParams[InboxEmailSearchParam.customer_ids] = getSearchParam(
      InboxEmailSearchParam.customer_ids
    );

    emailSearchParams[InboxEmailSearchParam.form_type] = getSearchParam(
      InboxEmailSearchParam.form_type
    );
    emailSearchParams[InboxEmailSearchParam.is_unread] = getSearchParam(
      InboxEmailSearchParam.is_unread
    );
    emailSearchParams[InboxEmailSearchParam.has_attachments] = getSearchParam(
      InboxEmailSearchParam.has_attachments
    );
    emailSearchParams[InboxEmailSearchParam.labels] = getSearchParam(InboxEmailSearchParam.labels);
    emailSearchParams[InboxEmailSearchParam.invoice_ids] = getSearchParam(
      InboxEmailSearchParam.invoice_ids
    );
    return emailSearchParams;
  }, [getSearchParam]);

  const setEmailSearchParams = useCallback(
    (newValue: Partial<EmailThreadSearchParams>, options?: NavigateOptions) => {
      setSearchParams(newValue, options);
    },
    [setSearchParams]
  );

  const removeEmailSearchParams = useCallback(
    (keys: string[]) => {
      removeSearchParams(keys);
    },
    [removeSearchParams]
  );
  const clearAll = useCallback(() => {
    removeSearchParams(emailSearchParamKeys);
  }, [removeSearchParams]);

  return {
    emailSearchParams,
    setEmailSearchParams,
    removeEmailSearchParams,
    clearAll,
  };
}

function useOpenEmailCardInbox() {
  const [from, to] = getFormattedDateForRelativeOptions(RelativeDateRange.LAST_30_DAYS);
  const searchParams: Partial<EmailFilters> = useMemo(() => {
    return {
      [InboxEmailSearchParam.page]: 1,
      [InboxEmailSearchParam.pageSize]: 10,
      [InboxEmailSearchParam.fromDate]: from,
      [InboxEmailSearchParam.toDate]: to,
      [InboxEmailSearchParam.dateRange]: RelativeDateRange.LAST_30_DAYS,
      [InboxEmailSearchParam.is_unread]: true,
      is_my_inbox: true,
    };
  }, [from, to]);

  const { data: recentReplies, isLoading } = useGetRecentRepliesTreads(searchParams);

  const defaultQueryParams = useMemo(() => {
    return {
      ...searchParams,
      is_my_inbox: true,
    };
  }, [searchParams]);

  function handleOpenInNewWindow(parsedSearchParam: URLSearchParams) {
    const url = `/inbox/${InboxPath.EMAIL_CONVERSATION}?${parsedSearchParam.toString()}`;
    const win = window.open(url, '_blank');
    win?.focus();
  }

  function handleOpenCardInInbox(threadId?: number) {
    const searchQueryParam = {
      ...defaultQueryParams,
      [InboxEmailSearchParam.threadId]: threadId,
    };

    const parsedSearchParam = new URLSearchParams(
      searchQueryParam as unknown as Record<string, string>
    );
    handleOpenInNewWindow(parsedSearchParam);
  }

  function handleViewMore() {
    const searchQueryParam = {
      ...defaultQueryParams,
    };

    const parsedSearchParam = new URLSearchParams(
      searchQueryParam as unknown as Record<string, string>
    );
    handleOpenInNewWindow(parsedSearchParam);
  }
  const openCardInInbox = useCallback(handleOpenCardInInbox, [defaultQueryParams]);
  const onViewMore = useCallback(handleViewMore, [defaultQueryParams]);
  return { recentReplies, isLoading, openCardInInbox, onViewMore };
}

function useUnreadEmailCount() {
  const { data: unreadCount, isLoading: unreadCountLoading } = useGetInboxEmailUnReadCount();

  return { count: unreadCount, countLoading: unreadCountLoading };
}

function useGetInboxUnreadParams() {
  const { emailSearchParams, filters } = useEmailFilters();
  const emailMode = emailSearchParams[InboxEmailSearchParam.emailMode];
  const queryClient = useQueryClient();
  const modifiedFilters = pick(filters, ['ar_email_id', 'is_my_inbox', 'status']);
  const id = emailSearchParams[InboxEmailSearchParam.email_id];

  function handleUnreadUpdateCounts(value: number) {
    queryClient.setQueryData<UnReadCountResponse>(
      [inboxQueryKeys.unread_count, modifiedFilters.ar_email_id, modifiedFilters.is_my_inbox],
      {
        unread_count: value,
      }
    );
  }
  return {
    emailMode,
    requestParam: { ...modifiedFilters, is_unread: true },
    handleUnreadUpdateCounts,
    id,
  };
}

export default function useEmailFilters() {
  const { emailSearchParams, setEmailSearchParams, clearAll } = useEmailSearchParams();

  const defaultFilters = useMemo(() => {
    return {
      status: InboxEmailConversationType.ALL,
      page: 1,
      page_size: 10,
    };
  }, []);
  const filters: Partial<EmailFilters> = useMemo(() => {
    const modifiedParams = pick(emailSearchParams, [
      InboxEmailSearchParam.fromDate,
      InboxEmailSearchParam.toDate,
      InboxEmailSearchParam.page,
      InboxEmailSearchParam.pageSize,
      InboxEmailSearchParam.dateRange,
    ]);

    const customerIds = emailSearchParams[InboxEmailSearchParam.customer_ids]
      ? filter(
          map(split(String(emailSearchParams[InboxEmailSearchParam.customer_ids]), ','), Number),
          isNumber
        )
      : undefined;

    const invoiceIds = emailSearchParams[InboxEmailSearchParam.invoice_ids]
      ? filter(
          map(split(String(emailSearchParams[InboxEmailSearchParam.invoice_ids]), ','), Number),
          isNumber
        )
      : undefined;
    const invoices = map(invoiceIds, String);
    return {
      is_my_inbox: emailSearchParams[InboxEmailSearchParam.emailMode] === InboxEmailMode.MY_INBOX,
      customer_ids: map(customerIds, String),
      is_unread: emailSearchParams[InboxEmailSearchParam.is_unread] ?? false,
      has_attachments: emailSearchParams[InboxEmailSearchParam.has_attachments],
      ar_email_id:
        emailSearchParams[InboxEmailSearchParam.emailMode] === InboxEmailMode.AR_INBOX
          ? emailSearchParams[InboxEmailSearchParam.email_id]
          : undefined,
      status: emailSearchParams[InboxEmailSearchParam.emailType],
      label: emailSearchParams[InboxEmailSearchParam.labels],
      invoice_ids: invoices.length ? invoices : undefined,
      ...modifiedParams,
    };
  }, [emailSearchParams]);

  return { filters, emailSearchParams, setEmailSearchParams, defaultFilters, clearAll };
}
// Update with your actual import path

export function useInitializeEmailFilters(
  props?: Pick<EmailFilters, 'customer_ids' | 'invoice_ids'>
) {
  const { emailSearchParams, setEmailSearchParams } = useEmailSearchParams();

  const defaultFilters = useMemo(() => {
    return {
      status: InboxEmailConversationType.ALL,
      email_mode: InboxEmailMode.MY_INBOX,
      page: 1,
      page_size: 10,
      ...props,
    };
  }, [props]);

  useEffect(() => {
    const sanitizedParams = getSanitizedObject(emailSearchParams);
    if (!Object.keys(sanitizedParams).length) {
      setEmailSearchParams(defaultFilters, { state: null, replace: true });
      return;
    }
  }, [defaultFilters, emailSearchParams, setEmailSearchParams]);
}

export { useGetInboxUnreadParams, useOpenEmailCardInbox, useUnreadEmailCount };
