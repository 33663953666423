import { HideWrapper } from '@sinecycle/growcomponents';
import { Button } from 'antd';
import useOpen from 'util/hooks/useOpen';
import { TaskFormModalProps, TaskModalFormElementProps } from '../types';
import TaskFormModal from './TaskFormModal';
import { getTaskConfig } from 'components/HigherOrderComponent/KeyActivitesContainer/predicate';

export default function TaskButton<TaskElements extends TaskModalFormElementProps>(
  props: Omit<TaskFormModalProps<TaskElements>, 'openModal' | 'form' | 'onClose' | 'onFormClose'>
) {
  const { buttonConfig, disabled, mode = 'BUTTON' } = props;
  const { open, toggleOpen, onClose } = useOpen({ open: props.open ?? false });
  const config = getTaskConfig(disabled, buttonConfig);
  function handleClose() {
    onClose();
    props.handleClose?.();
  }
  const Title = !buttonConfig?.hidetext && (buttonConfig?.text || config.label);
  return (
    <>
      <HideWrapper hide={mode === 'LIST'}>
        <Button
          icon={!buttonConfig?.hideIcon ? config.icon : null}
          onClick={toggleOpen}
          disabled={disabled}
          {...buttonConfig}
        >
          {Title}
        </Button>
      </HideWrapper>

      <TaskFormModal<TaskElements> openModal={open} onClose={handleClose} {...props} />
    </>
  );
}
