import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Drawer, DrawerProps, Tooltip } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import React, { useState } from 'react';
import styled from 'styled-components';
import { ValidFileType } from 'types/common/attachments';

interface IDocViewer {
  docType: ValidFileType;
  docUrl: string;
  invoiceTitle?: string;
  onClose?: () => void;
  onClick?: () => void;
  className?: string;
  toolTipText?: string;
}

const pdfDownloadStyle: React.CSSProperties = {
  color: 'red',
  fontSize: 'var(--fs-14)',
};

const DOC_TYPE_ICONS: Record<ValidFileType, React.ReactNode> = {
  PDF: <FontAwesomeIcon color={'var(--red-7)'} icon={['far', 'file-pdf']} />,
  JPG: <FontAwesomeIcon color={'var(--geekblue-6)'} icon={['far', 'file-image']} />,
  PNG: <FontAwesomeIcon color={'var(--geekblue-6)'} icon={['far', 'file-image']} />,
  DOC: <FontAwesomeIcon color={'var(--blue-6)'} icon={['far', 'file-word']} />,
  XLS: <FontAwesomeIcon color={'var(--green-7)'} icon={['far', 'file-excel']} />,
  CSV: <FontAwesomeIcon color={'var(--green-6)'} icon={['far', 'file-csv']} />,
  XLSX: <FontAwesomeIcon color={'var(--green-7)'} icon={['far', 'file-excel']} />,
  EML: <FontAwesomeIcon color={'var(--geekblue-6)'} icon={['far', 'envelopes']} />,
};

export function getDocTypeIcon(docType: ValidFileType) {
  return DOC_TYPE_ICONS[docType];
}

export const DocViewer = (props: IDocViewer) => {
  const [openViwer, setOpenViewer] = useState(false);

  function onCloseCallBack() {
    setOpenViewer(false);
    props.onClose && props.onClose();
  }

  return props.docUrl ? (
    <div className={props.className}>
      <div
        onClick={() => {
          setOpenViewer(true);
          props.onClick?.();
        }}
      >
        <span onClick={() => onCloseCallBack()}>
          <Flex align="center" gap="var(--space-4)">
            <Tooltip title={props.toolTipText} destroyTooltipOnHide>
              <span style={pdfDownloadStyle}>{getDocTypeIcon(props.docType)}</span>
            </Tooltip>
            <GrowText style={{ color: 'var(--primary-7)', maxWidth: '150px' }} ellipsis>
              {props.invoiceTitle}
            </GrowText>
          </Flex>
        </span>
      </div>
      <DocPreview
        width="70%"
        closeIcon={false}
        open={openViwer}
        onClose={() => setOpenViewer(false)}
        destroyOnClose={true}
        docType={props.docType}
        docUrl={props.docUrl}
      />
    </div>
  ) : null;
};

const SDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 0;
  }
  ${(props) => `
    &.${props.className}{
      display:none;
    }
  `}
`;

interface PreviewProps extends DrawerProps {
  docType: ValidFileType;
  docUrl: string;
}

export function DocPreview(props: PreviewProps) {
  // iframes will trigger onLoad method only when it's content changes.
  const [contentChanged, setContentChanged] = useState(false);
  const Iframe = (
    <iframe
      onLoad={() => setContentChanged(true)}
      allowFullScreen={true}
      title={`${props.docType} viewer`}
      src={`${props.docUrl}`}
      width="100%"
      height="100%"
      style={{ display: contentChanged ? 'inline' : 'none' }}
    ></iframe>
  );
  return (
    <>
      {contentChanged && (
        <SDrawer
          width={props.width ?? '70%'}
          closeIcon={props.closeIcon ?? false}
          open={props.open}
          onClose={(e) => {
            e.stopPropagation();
            props.onClose?.(e);
          }}
          destroyOnClose={props.destroyOnClose ?? true}
          className={props.className}
        >
          {Iframe}
        </SDrawer>
      )}
      {props.open && !contentChanged && Iframe}
    </>
  );
}
