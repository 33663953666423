import { GrowTypography } from '@sinecycle/growcomponents';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { AmountWithCurrencySplit } from 'components/Common/MultiCurrency';
import { NumberFormat } from 'components/Common/NumberFormat/NumberFormat';
import { InfoPopover } from 'components/Common/UtilityPopover';
import { useConfig } from 'components/HigherOrderComponent/Config/config';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { AccountConfigKey } from 'types/entities/account';
import { dashboardTexts } from '../texts';
import { TooltipParagraph } from './style';
interface BPDSOTileProps {
  loading: boolean;
  inputLoaderJSX: ReactNode;
  bpdso?: number;
  sales?: {
    value: number;
    currency: string;
  };
}
const StyledFlex = styled(Flex)`
  border-left: 1px solid var(--gray-5);
  padding-left: var(--space-16);
  height: 100%;
  width: 100%;
`;
const componentTexts = dashboardTexts.dsoTile;
const BPSOText = (
  <GrowTypography.Text size="var(--fs-12)" color={'var(--gray-8)'}>
    {componentTexts.bpdso}
  </GrowTypography.Text>
);
const InfoIconPopOver = (
  <div style={{ cursor: 'pointer' }}>
    <InfoPopover
      icon="help"
      title={componentTexts.bdpsoHelpTitle}
      content={<TooltipParagraph>{componentTexts.bpdsoHelpText}</TooltipParagraph>}
    />
  </div>
);
const Header = (
  <Flex align="center" gap="var(--space-4)">
    {BPSOText}
    {InfoIconPopOver}
  </Flex>
);
export default function BPDSOTile(props: BPDSOTileProps) {
  const enableNetSales = useConfig(AccountConfigKey.NET_SALES_ENABLED);
  const BpsoDays = (
    <>
      {props.loading ? (
        props.inputLoaderJSX
      ) : (
        <NumberFormat
          value={Number(props.bpdso)}
          textProps={{ strong: true, fs: '16' }}
          mode="DECIMAL"
        />
      )}
    </>
  );
  const BpsoAmount = (
    <>
      {props.loading ? (
        props.inputLoaderJSX
      ) : props.sales ? (
        <AmountWithCurrencySplit
          type="multi"
          amountWithCurrencySplit={props.sales}
          showTooltip
          className="text-16 semi-bold"
        />
      ) : null}
    </>
  );
  const BpdsoSales = (
    <StyledFlex direction="column" justify={'space-between'}>
      <Flex direction="column">
        {Header}
        {BpsoDays}
      </Flex>
      <Flex direction="column">
        <GrowTypography.Text color={'var(--gray-8)'} size="var(--fs-12)">
          {enableNetSales ? componentTexts.net_sales : componentTexts.sales}
        </GrowTypography.Text>
        {BpsoAmount}
      </Flex>
    </StyledFlex>
  );
  return <>{BpdsoSales}</>;
}
