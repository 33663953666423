import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { InputLoader } from 'components/Common/FilterComponents/Common/SkeletonLoader';
import { AmountWithCurrencySplit } from 'components/Common/MultiCurrency';
import DashboardCardTemplate from 'pages/Dashboard/AdminDashboard/SnapShot/CollectionBalanceAndOutstanding/CardTemplate';
import { DashboardComponentProps } from 'pages/Dashboard/types';
import { useBadDebtTileQuery } from 'queries/admin-dashboard';
import TotalCustomersInvoices from '../TotalCustomersInvoices';
import { dashboardTexts } from '../texts';

const componentTexts = dashboardTexts.badDebtTile;

function BadDebtTile({ filterParams }: DashboardComponentProps) {
  const { data: totalBadDebt, isLoading: loading } = useBadDebtTileQuery(filterParams);

  const InputLoaderJSX = <InputLoader active={loading} />;

  const customersCount = totalBadDebt?.bad_debt.customer_count;
  const invoicesCount = totalBadDebt?.bad_debt.invoice_count;

  const TotalCustInvoiceInfo = (
    <TotalCustomersInvoices
      loading={loading}
      customersCount={customersCount}
      invoicesCount={invoicesCount}
    />
  );

  const AmountInfo = (
    <>
      {loading ? (
        InputLoaderJSX
      ) : totalBadDebt ? (
        <AmountWithCurrencySplit
          type="multi"
          amountWithCurrencySplit={totalBadDebt.bad_debt.amount}
          className="text-20 semi-bold"
          showTooltip
        />
      ) : null}
    </>
  );
  const Footer = (
    <Flex justify="space-between" align="center">
      <>{TotalCustInvoiceInfo}</>
      <GrowText size={'var(--fs-14)'}>{componentTexts.thisMonth}</GrowText>
    </Flex>
  );
  return (
    <DashboardCardTemplate
      title={componentTexts.title}
      tagColor="var(--red-4)"
      amountInfo={AmountInfo}
      customerInvoiceCount={Footer}
    />
  );
}

export default BadDebtTile;
