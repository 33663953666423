import CurrentDueTile from 'components/Dashboard/CurrentDueTile';
import DSOTile from 'components/Dashboard/DSOTile';
import TotalOutstandingTile from 'components/Dashboard/TotalOutstandingTile';
import { Tile } from '../TileTitle';
import { DashBoardCardProps } from './type';

export default function SecondaryCards(props: DashBoardCardProps) {
  return (
    <>
      <Tile>
        <CurrentDueTile loading={props.totalBalanceLoading} totalOutstanding={props.totalBalance} />
      </Tile>
      <Tile>
        <TotalOutstandingTile
          totalOutstanding={props.totalOutstanding}
          loading={props.totalOutstandingLoading}
        />
      </Tile>
      <Tile>
        <DSOTile filterParams={props.snapshotFilterParams} />
      </Tile>
    </>
  );
}
