import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CustomFieldIconWithText } from '@sinecycle/growcomponents';
import { Button, Dropdown } from 'antd';
import { MenuProps } from 'components/BaseComponents/Menu/Antd/type';
import { useMemo } from 'react';
import { Filter, FilterGroup } from '../types';
import { notifyCustomFilterSelect } from '../utils';

const t = {
  customFilters: 'Filters',
};

interface Props {
  filterGroups: FilterGroup[];
  onSelect: (filter: Filter, filterGroup: FilterGroup) => void;
  filterButtonStyle?: React.CSSProperties;
}

const defaultAddFilterButttonStyle: React.CSSProperties = {
  maxHeight: '350px',
  overflowY: 'auto',
};

function getInactiveFiltersInFilterGroup(filterGroup: FilterGroup) {
  return filterGroup.filterList.filter((filterItem) => !filterItem.active);
}

export function CustomFilterSelect(props: Props) {
  const { filterButtonStyle } = props;
  const menuStyle = {
    ...defaultAddFilterButttonStyle,
    ...(filterButtonStyle && filterButtonStyle),
  };

  const filterGroupsCount = useMemo(() => {
    const activeFilterGroups = props.filterGroups.filter((filterGroup) => {
      const inactiveFilters = getInactiveFiltersInFilterGroup(filterGroup);

      return Boolean(inactiveFilters.length);
    });

    return activeFilterGroups.length;
  }, [props.filterGroups]);

  // do not show dropdown if there's no filters to select
  if (!filterGroupsCount) return null;

  function handleSelect(filter: Filter, filterGroup: FilterGroup) {
    props.onSelect(filter, filterGroup);
    notifyCustomFilterSelect(filter.label);
  }

  const MenuItemGroupsJSX: MenuProps['items'] = props.filterGroups.map((filterGroup) => {
    const inactiveFilters = getInactiveFiltersInFilterGroup(filterGroup);

    if (!inactiveFilters.length) return null;

    if (filterGroup.id === 'default') {
      inactiveFilters.map((filter) => {
        return {
          key: filter.id,
          label: filter.label,
          onClick: () => handleSelect(filter, filterGroup),
        };
      });
    }

    return {
      type: 'group',
      key: filterGroup.id,
      label: filterGroup.label,
      children: inactiveFilters.map((filter) => {
        const isCustomFieldFilter = Boolean(filter.filterObject?.data_type);
        const PopOverTitle = (
          <CustomFieldIconWithText hasSymbol={isCustomFieldFilter}>
            {filter.label}
          </CustomFieldIconWithText>
        );
        return {
          key: filter.id,
          label: PopOverTitle,
          onClick: () => handleSelect(filter, filterGroup),
        };
      }),
    };
  });

  return (
    <Dropdown menu={{ items: MenuItemGroupsJSX, style: menuStyle }} trigger={['click']}>
      <Button type="primary" icon={<FontAwesomeIcon icon={['far', 'plus']} />}>
        {t.customFilters}
      </Button>
    </Dropdown>
  );
}
